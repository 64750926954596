import React, { useContext, useEffect } from "react";
import TableComponent from "../../cashierBillingDetails/components/tableComponent";
import { UIColor } from "../../../themes/theme";
import { useState } from "react";
import { Button, H6, TextInput } from "qdm-component-library";
import { AlertContext, BackdropContext } from "../../../context";
import { AlertProps, AxiosCall, urls } from "../../../utils";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";


function ViewReceipt(props) {
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const practitionerId = decodedUserinfo?.practitionerid;
  const roleid = decodedUserinfo?.roleid;

  const dataType = [
    { name: "checked", type: "CHECKBOX", header: "All" },
    { name: "", type: "INCREMENT", header: "S.No" },
    { name: "billno", type: "TEXT", header: "scroll" },
    { name: "receiptno", type: "TEXT", header: "scroll" },
    { name: "receiptdate", type: "EPOCH_DATE_DISPLAY", header: "scroll" },
    { name: "receiptstatus_Display", type: "TEXT", header: "scroll" },
    { name: "receiptamt", type: "TEXT", header: "scroll" },
    { name: "", type: "ACTION", header: "scroll" },
  ];
  const Header = [
    "All",
    "S.No",
    "Bill No.",
    "Receipt No.",
    "Receipt Date",
    "Receipt Status",
    "Receipt Amount "+ `(${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode})`,
    "Action",
  ];
  const [cancel, setCancel] = useState({
    view: false,
    value: "",
  });
  const [allcheck, setAllCheck] = useState(false);
  const [checkedData, setCheckData] = useState([]);
  const [receiptata, setReceiptdata] = useState([]);
  const [refundStatus, setRefundStatus] = useState('CodingMaster/12678');
  const [canType, setCanType] = useState([]);
  useEffect(() => {
    setReceiptdata(JSON.parse(JSON.stringify(props?.receiptData ?? [])) || []);
  }, [props?.receiptData]);
  const dispatch = useDispatch();
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const handleCheckBox = (e, value, index) => {
    let recdata = receiptata;
    let target = recdata[index];
    if (e.target.checked) {
      setCheckData([...checkedData, value]);
      target["checked"] = true;
      recdata[index] = target;
      setReceiptdata(recdata);
    } else {
      setCheckData((prev) =>
        prev.filter((v) => v?.receiptid !== value?.receiptid)
      );
      target["checked"] = false;
      recdata[index] = target;
      setReceiptdata(recdata);
    }
  };
  const handleHeaderCheckBox = (e, value) => {
    let recdata = receiptata;
    if (e.target.checked) {
      setCancel({ ...cancel, view: true });
      setAllCheck(true);
      setCheckData(receiptata);
      setReceiptdata(
        recdata.map((v) => {
          v["checked"] = true;
          return v;
        })
      );
    } else {
      setAllCheck(false);
      setCheckData([]);

      setReceiptdata(
        recdata.map((v) => {
          v["checked"] = false;
          return v;
        })
      );
    }
  };
  const handleDeleteSelected = (e, value) => {
    setCancel({ ...cancel, view: true });
  };
  const handleSelectedPrint = (e, value, index) => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const payload = {
      reportid: process.env.REACT_APP_RECEIPT_PAYMENT_REPORT_ID,
      inputparams: {
        "@receiptid": value?.receiptid,
        "@loginpract": practitionerId
      },
      result: [],
    };

    AxiosCall("post", urls.jasperReport, payload, header)
    .then(async (res) => {
      const response = await axios.get(res?.downloadUrl, {
        method: "GET",
        responseType: "blob",
      });
      const pdfBlob = new Blob([response.data], {
        type: "application/pdf",
      });
      const pdfDataUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfDataUrl, "_blank");
    })
    .catch((error) => {
      alert.setSnack({
        ...alert,
        open: true,
        msg: "No data found for the given inputs",
        severity: "error",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    });
  };
  const handlesubmit = async (value) => {
    if (!cancel?.value?.length > 0) {
      alert.setSnack({
        ...alert,
        open: true,
        msg: "Please fill the cancellation reason",
        severity: "error",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else if(checkedData.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        msg: "Please select a Receipt for cancellation !",
        severity: "error",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }else if(!refundStatus) {
      alert.setSnack({
        ...alert,
        open: true,
        msg: "Please Choose a Refund Type !",
        severity: "error",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }else {
      // let payload = {
      //   entity: "RCM_Paymentdtls",
      //   doc: [],
      //   // cancelreason: cancel?.value,
      // };
      let doc = [];
      checkedData.forEach((v) => {
        doc.push({
          Paymentdtlsid: v?.receiptid,
          cancelreason: cancel?.value,
          receiptno: v?.receiptno,
          refundtype: refundStatus
        }); 
      });
     
      let data = await dispatch(actions.CANCEL_RECEIPT(doc));
      
      // if (data?.payload?.data?.error) {
      if (data?.payload?.data?.[0]?.error) {
        alert.setSnack({
          ...alert,
          open: true,
          msg: data?.payload?.data?.[0]?.message,
          severity: "error",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else {
        props?.GetBillAndReceipt(props?.receiptData?.[0]?.appoinmentid);
        props?.setViewReceipt(false);
        props?.getOutstanding();
        alert.setSnack({
          ...alert,
          open: true,
          msg: "cancelled successfully",
          severity: "success",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
    }
  };
  console.log({ receiptata });
  const handleRadioChange = (e) => {
    setRefundStatus(e.target.value);
  }
  const getReason =async () =>{
    const cancelReasonList =await dispatch(actions.Masters({ type: ["REFUNDTYPE"] }));
    setCanType(cancelReasonList?.payload?.data?.REFUNDTYPE);
  }
  useEffect(() => {
    getReason();
  }, []);
  return (
    <div
      style={{
        padding: "0px 20px 20px 20px",
        width: "100%",
        margin: "auto",
        position: "relative",
      }}
    >
      <div style={{ maxHeight: "60vh", overflow: "auto" }}>
        <TableComponent
          tableData={receiptata || []}
          Header={Header}
          dataType={dataType}
          handleDeleteSelected={handleDeleteSelected}
          handleCheckBox={handleCheckBox}
          handleHeaderCheckBox={handleHeaderCheckBox}
          allChecked={allcheck}
          handleSelectedPrint={handleSelectedPrint}
        />
      </div>
      
      <div style={{ margin: "20px 0px" }}>
        <H6
          className={"pc_regular"}
          style={{
            color: "#646464",
            fontSize: 12,
            margin: 0,
            marginBottom: 5,
          }}
        >
          Cancel Reason <span style={{ color: "red" }}>*</span>
        </H6>

        <TextInput
          value={cancel?.value}
          onChange={(evt) => setCancel({ ...cancel, value: evt.target.value })}
          variant={"outlined"}
          hoverColor={UIColor.primaryColor.color}
          placeholder="Add reason"
          style={{
            borderRadius: 8,
            borderColor: UIColor.lineBorderFill.color,
            height: 36,
            padding: "5px 10px",
          }}
          isRequired
        />
      </div>
      <div style={{ margin: "20px 0px" }}>
        <H6
          className={"pc_regular"}
          style={{
            color: "#646464",
            fontSize: 12,
            margin: 0,
            marginBottom: 5,
          }}
        >
          Refund Type <span style={{ color: "red" }}>*</span>
        </H6>
        </div>
      <div style={{ display: "flex" }}>
        
        <RadioGroup
          aria-label="position"
          name="Residency Status"
          value={refundStatus}
          onChange={(e) => handleRadioChange(e, "refund_status")}
          row
          style={{ paddingLeft: "20px" }}
        > 
        {canType?.map((type)=>{
          return(
            <FormControlLabel
              //style={{ color: "#6F6F6F" }}
              value={type.value}
              control={
                <Radio
                  size="small"
                  style={{ color: "#0071F2" }}
                />
              }
              style={{ fontSize: "10px" }}
              inLineStyles={{ fontSize: "5px" }}
              // classes={{ label: rodiolabel }}
              label={type.label.toUpperCase()}
              disabled={type.value == "CodingMaster/12679" ? true : false}
            />
          )
        })}
        </RadioGroup>
      </div>
     
      
      <Button
        name={"Cancel Receipt"}
        type="button"
        className="pc_medium"
        variant="outlined"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          marginTop: "20px",
          fontSize: "14px",
          padding: "8px 16px",
          marginBottom: "10px",
          backgroundColor: UIColor?.primaryColor?.color,
          border: "none",
          borderRadius: 8,
        }}
        onClick={handlesubmit}
      ></Button> 
    </div>
  );
}

export default ViewReceipt;
