import React ,{ useState} from "react";
import { Div, Avatar, H6, Card, Text, Image, Modal, Icons, Row, Divider } from 'qdm-component-library';
import { UIColor } from "../../themes/theme";
import icons8clockblack from "../../assets/img/svg/icons8-clock-black.svg";
import { makeStyles } from "@material-ui/core";
import qdmstyles from "./../referrel/styles.json"
import ReferralBannerListView from "./../referrel/referralViewDetails";
import { Popover } from "@material-ui/core";
import viewIcon from '../../assets/img/svg/icons8-eye.svg';
import FileUpload from '../../assets/img/svg/icons8-file.svg';
import { UploadFiles } from "../navBar";

const useStyles = makeStyles((theme) => ({
  avatarCls: {
    '& img': {
      width: '30px !important',
      height: '30px !important',
      objectFit: 'contain !important'
    }
  }
}));

const PatientStatusCard = (props) => {
  console.log(props, "statuscard")
  const classes = useStyles();
  const [open, setOpen] = React.useState(null)
  const [image, setimage] = React.useState("")
  const [openRef, setopenRef] = useState(false)
  const [position, setPosition] = useState({ mouseX: null, mouseY: null });
  const openPopover = (e) => {
      // this.setState({
      //   open: e?.currentTarget ?? null,
      // });
      setPosition({
        mouseX: e.clientX - 350, 
        mouseY: e.clientY - (-40), 
      });
      setOpen(true)
  };
  const closePopover = (e) => {
    setPosition({ mouseX: null, mouseY: null });
      setOpen(null)
  };
  const handleopenRef = () => {
      setopenRef(!openRef)
  }
  const handleOpenModel = (value) => {
      setOpen(null)
      setimage([value])
      setopenRef(!openRef)
  }
  const { ReferralData,
      letter = (
          (ReferralData?.name?.[0])), noright, parent_id
  } = props;

  const styles = {
    rowFlex: {
      display: 'flex',
      alignItems: 'center'
    },
    columnFlex: {
      display: 'flex',
      flexDirection: 'column',
      //height: "10vh"
    },
    h6: {
      fontSize: 13,
      color: UIColor.primaryColor.color,
      margin: 0
    },
    span: {
      fontSize: 12,
      color: UIColor.tertiaryText.color,
      margin: 0
    },
    span_lag: {
      fontSize: 12,
      color: UIColor.tertiaryText.color,
      margin: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    cardStyle: {
      boxShadow: "none",
      borderRadius: 8,
      marginLeft: "5px",
      padding: props.padding ? 10 : 0
    },
    avatar: {
      margin: 0,
      marginRight: 15,
      width: 48,
      height: 48,
      borderRadius: 8,
      backgroundColor: "#F0F0FA"
    },
    img: {
      width: 16,
      height: 16,
      marginRight: 10
    }
  };

  return (
    <Card
      id={`${props.parent_id}_patientstatus_parent_card`}
      style={styles.cardStyle} 
    >
      <Div
        id={`${props.parent_id}_patientstatus_parent_div`}
        style={{ ...styles.rowFlex }}
      >
        {props.pic ? (
          <Avatar
            id={`${props.parent_id}_patientstatus_${props.name?.[0]}_avatar`}
            src={props.pic}
            variant="rounded"
            className={classes.avatarCls}
            inLineStyles={styles.avatar}
          />
        ) : (
          <Avatar
            id={`${props.parent_id}_patientstatus_${props.name?.[0]}_avatar`}
            src={props.pic}
            variant="rounded"
            className={classes.avatarCls}
          >
            {props.name?.[0]}
          </Avatar>
        )}
        <Div
          id={`${props.parent_id}_patientstatus_sub_parent_div`}
          style={styles.columnFlex}
        >
          <Text
            id={`${props.parent_id}_patientstatus_text`}
            style={styles.rowFlex}
          >
            {props?.titles?.length > 0 &&
              props.titles.map((val, i) => {
                if (!val) {
                  return <React.Fragment key={i} />;
                }
                return (
                  <React.Fragment key={i}>
                    <H6
                      id={`${props.parent_id}_${i}_patientstatus_h6`}
                      className="pc_semibold"
                      style={styles.h6}
                    >
                      {val.slice(0,12)}
                    </H6>
                    {i + 1 !== props.titles.length &&
                      (props.titles[i + 1] || props.titles[i + 1] === "") && (
                        <Text
                          id={`${props.parent_id}_${i}_patientstatus_dot_text`}
                          className="pc_regular"
                          style={{ ...styles.span, margin: "0px 3px" }}
                        >
                          •
                        </Text>
                      )}
                  </React.Fragment>
                );
              })}
          </Text>
          <Div
            id={`${props.parent_id}_patientstatus_image_div`}
            style={{ ...styles.rowFlex, marginTop: 7 }}
          >
            {props.date && (
              <Div
                id={`${props.parent_id}_patientstatus_image_div`}
                style={{
                  ...styles.rowFlex,
                  marginRight: 15,
                  minWidth: "120px",
                }}
              >
                <Image
                  id={`${props.parent_id}_patientstatus_black_image`}
                  style={styles.img}
                  src={icons8clockblack}
                  alt="id"
                />
                <Text
                  id={`${props.parent_id}_patientstatus_on_text`}
                  className="pc_regular"
                  style={styles.span}
                >
                  On {props.date}
                </Text>
              </Div>
            )}
            {props?.languages?.length > 0 && (
              <Div
                id={`${props.parent_id}_patientstatus_reading_div`}
                style={styles.rowFlex}
              >
                <Image
                  id={`${props.parent_id}_patientstatus_reading_image`}
                  style={styles.img}
                  src="images/icons8-reading.svg"
                  alt="id"
                />
                <Text
                  id={`${props.parent_id}_patientstatus_language_text`}
                  className="pc_regular"
                  style={styles.span_lag}
                >
                  {props.languages}
                </Text>
              </Div>
            )}
             <Div
                                id={`${parent_id}_referabanner_openref_div`}
                                style={{
                                    position: 'absolute',
                                    right: '5px',
                                    top: '1px',
                                    width: '30px',
                                    height: '30px',
                                    border: '1px solid #E0E0E0',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: "pointer"
                                }}
                                onClick={(e) => openPopover(e)}
                            >
                                <Icons
                                    id={`${parent_id}_referabanner_eye_icons`}
                                    inLineStyles={{ color: "black", fontSize: '20px ​!importan' }}
                                    fontIcon={"eye"}
                                    ariaHidden={"true"}
                                    size={"small"} />
                            </Div>
                             <Popover
        //   anchorEl={isUpload}
        open={Boolean(position.mouseX && position.mouseY)}
        anchorReference="anchorPosition"
        anchorPosition={
          position.mouseY !== null && position.mouseX !== null
            ? { top: position.mouseY, left: position.mouseX }
            : undefined
        }
        onClose={closePopover}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
         
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
           
          }}
         
               
          classes={{ paper: classes.muiPopovers }}
        >
          <UploadFiles
            id={`${parent_id}_topnavbar_upload_uploadfiles`}
            // uploadDocument={uploadDocument}
            // files={files}
            viewfiles={props.attachments}
            isViewMode={true}
          />
        </Popover>
          </Div>
        </Div>
      </Div>
      {/* <Modal
            id={`${parent_id}_referabanner_modal`}
            inLineStyles={{ borderRadius: 16 }}
            open={openRef}
            onClose={handleopenRef}
            width={1000}
        >
            <ReferralBannerListView
                parent_id={'doctor_listing_referral'}
                ReferralData={ReferralData?.[0]}
                attachmenst={props.attachments}
                image={image}
            />

        </Modal> */}
    </Card>
  );
};

PatientStatusCard.defaultProps = {
  pic: null,
  name: "",
  titles: [],
  date: null,
  languages: null,
  padding: false,
};

export default PatientStatusCard;
