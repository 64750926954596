import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  urls,
  defaultState,
  defaultReject,
  fetchData,
  query_ids,
  dbName,
  personRequests,
  registerQuery,
  generateNumber,
  getFacilityId,
} from "../../utils";
import jwtDecode from "jwt-decode";

// const userInfo = localStorage?.getItem("UserInfo");
// const decodedUserinfo = userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
// const facilityId = decodedUserinfo?.facilityid ?? "";
// const getFacilityId = () => {
//   //;
//   const userInfo = localStorage?.getItem("UserInfo");
//   const decodedUserinfo = userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
//   const facilityId = decodedUserinfo?.facilityid ?? "";
//   return facilityId;
// }

const GET_TABLE_DATA = createAsyncThunk(
  `billingDetailsMastersSlice/tableData`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientId, encounterId } = payload;

      const body = {
        db_name: dbName,
        filter: {
          tenantid: "",
          facilityid: "",
          patientid: patientId,
          encounter: encounterId,
        },
        queryid: "98bbc74c-3c02-4039-b467-983d71e6cb47",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      console.log(data, "redux billing");
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const PAYER_DROPDOWN = createAsyncThunk(
  `billingDetailsMastersSlice/payer_dropdown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const encounter_id = payload;

      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounter_id,
          facilityid: "",
          tenantid: "",
        },
        queryid: "af32881e-7ff6-45e0-98b7-8a093e7c1b29",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const PAYMENT_DROPDOWN = createAsyncThunk(
  `billingDetailsMastersSlice/payment_dropDown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid } = payload;

      const body = {
        db_name: dbName,
        filter: {
          type: ["RC_PAYMENTMODE"],
          tenantid: "1234",
          facilityid: "",
          lang: "en",
        },
        queryid: "6c7702a7-b002-4862-ac0b-4a029205735f",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const SEARCH_CODE_DESC = createAsyncThunk(
  `billingDetailsMastersSlice/Search_Code_Desc`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { encounterId, patientId } = payload;

      const body = {
        db_name: dbName,
        filter: {
          tenantid: "",
          facilityid: "",
          patientid: patientId,
          encounter: encounterId,
          searchcode: "%%",
          isbilled: false,
        },
        queryid: "c4847003-26cf-4b0c-b341-eec1d6747545",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const CHARGE_CODE_DROPDOWN = createAsyncThunk(
  `billingDetailsMastersSlice/Change_Code_DropDown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {} = payload;

      const body = {
        db_name: dbName,
        filter: {
          tenantid: "",
          facilityid: "",
        },
        queryid: "a89a7857-b249-454e-aa50-7faa2ce60a60",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const BILL_ROUND_OFF = createAsyncThunk(
  `billingDetailsMastersSlice/billRoundOff`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { orgId } = payload;
      const body = {
        db_name: dbName,
        filter: {
          orgid: orgId,
          paracode: "BILLROUNDDECVAL",
          tenantid: "",
          facilityid: "",
        },
        queryid: "600a188f-3f4f-45f5-a044-3332c86e082d",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const RECIEPT_NO = createAsyncThunk(
  `billingDetailsMastersSlice/recieptNo`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {} = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(generateNumber.recieptGenerator(getFacilityId())),
        },
        process.env.REACT_APP_GENERATE_SEQUENCE
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const BILL_NO = createAsyncThunk(
  `billingDetailsMastersSlice/billGenerator`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      //;
      const {} = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(generateNumber.billGenerator(getFacilityId())),
        },
        process.env.REACT_APP_GENERATE_SEQUENCE
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const UOM_DROPDOWN = createAsyncThunk(
  `billingDetailsMastersSlice/Uom_Dropdown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid } = payload;

      const body = {
        db_name: dbName,
        queryid: "11732e1b-1eaa-421e-abc8-1647709a7572",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const BILL_DETAILS_GET = createAsyncThunk(
  `billingDetailsMastersSlice/billDetailsSave`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { billHrdNo } = payload;
      const body = {
        db_name: dbName,
        filter: {
          billhdr_id: billHrdNo,
          tenantid: "",
          facilityid: "",
        },
        queryid: "ddea80d8-f709-448d-9934-2f21c00321c9",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const Practitoner_Load_Query_DROPDOWN = createAsyncThunk(
  `billingDetailsMastersSlice/practitonerLoadQuery_DROPDOWN`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { practitionerId="" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          practitionerid: practitionerId,
          tenantid: "",
          facilityid: "",
        },
        queryid: "dbd7cc02-f9c5-4121-b5b2-dac2e6c66a49",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const Facility_Load_Query_DROPDOWN = createAsyncThunk(
  `billingDetailsMastersSlice/fractitonerLoadQuery_DROPDOWN`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { organizationId="" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          orgid: organizationId,
        },
        queryid: "7912eff3-8c25-46b7-88e7-bc4d4a6f2344",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const GET_COLLECTIONS_TABLE_DATA = createAsyncThunk(
  `billingDetailsMastersSlice/collectionstableData`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientId ="" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          patientid:patientId,
        },
        queryid: "50fecf65-dd09-4a0c-8f8b-befa7f982167",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const GET_ADVANCE_AMOUNT_DATA = createAsyncThunk(
  `billingDetailsMastersSlice/advanceAmountData`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientAdvance = "" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          advid: patientAdvance,
        },
        queryid: "b9d86c1f-d0fe-463f-a1cb-3eb513efdeb0",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const BillingDetailApiSlice = createSlice({
  name: "BillingDetailApiSlice",
  initialState: {
    tableData: {
      ...defaultState.Info,
    },
    payer_dropdown: {
      ...defaultState.Info,
    },
    payment_dropDown: {
      ...defaultState.Info,
    },
    Search_Code_Desc: {
      ...defaultState.Info,
    },
    recieptNo: {
      ...defaultState.Info,
    },
    Change_Code_DropDown: {
      ...defaultState.Info,
    },
    Uom_Dropdown: {
      ...defaultState.Info,
    },
    billRoundOff: {
      ...defaultState.Info,
    },
    billGenerator: {
      ...defaultState.Info,
    },
    billDetailsSave: {
      ...defaultState.Info,
    },
    practitonerLoadQuery_DROPDOWN: {
      ...defaultState.Info,
    },
    fractitonerLoadQuery_DROPDOWN: {
      ...defaultState.Info,
    },
    collectionstableData: {
      ...defaultState.Info,
    },
    advanceAmountData: {
      ...defaultState.Info,
    },
  },
  extraReducers: {
    // GET_TABLE_DATA
    [GET_TABLE_DATA.fulfilled]: (state, action) => {
      state.tableData = action?.payload ?? {};
    },
    [GET_TABLE_DATA.pending]: (state, action) => {
      state.tableData.loading = true;
    },
    [GET_TABLE_DATA.rejected]: (state, action) => {
      state.tableData = action?.payload ?? {};
    },
    // PAYER_DROPDOWN
    [PAYER_DROPDOWN.fulfilled]: (state, action) => {
      state.payer_dropdown = action?.payload ?? {};
    },
    [PAYER_DROPDOWN.pending]: (state, action) => {
      state.payer_dropdown.loading = true;
    },
    [PAYER_DROPDOWN.rejected]: (state, action) => {
      state.payer_dropdown = action?.payload ?? {};
    },
    //PAYMENT_DROPDOWN
    [PAYMENT_DROPDOWN.fulfilled]: (state, action) => {
      state.payment_dropDown = action?.payload ?? {};
    },
    [PAYMENT_DROPDOWN.pending]: (state, action) => {
      state.payment_dropDown.loading = true;
    },
    [PAYMENT_DROPDOWN.rejected]: (state, action) => {
      state.payment_dropDown = action?.payload ?? {};
    },
    //SEARCH_CODE_DESC
    [SEARCH_CODE_DESC.fulfilled]: (state, action) => {
      state.Search_Code_Desc = action?.payload ?? {};
    },
    [SEARCH_CODE_DESC.pending]: (state, action) => {
      state.Search_Code_Desc.loading = true;
    },
    [SEARCH_CODE_DESC.rejected]: (state, action) => {
      state.Search_Code_Desc = action?.payload ?? {};
    },
    //RECIEPT_NO
    [RECIEPT_NO.fulfilled]: (state, action) => {
      state.recieptNo = action?.payload ?? {};
    },
    [RECIEPT_NO.pending]: (state, action) => {
      state.recieptNo.loading = true;
    },
    [RECIEPT_NO.rejected]: (state, action) => {
      state.recieptNo = action?.payload ?? {};
    },
    //CHARGE_CODE_DROPDOWN
    [CHARGE_CODE_DROPDOWN.fulfilled]: (state, action) => {
      state.Change_Code_DropDown = action?.payload ?? {};
    },
    [CHARGE_CODE_DROPDOWN.pending]: (state, action) => {
      state.Change_Code_DropDown.loading = true;
    },
    [CHARGE_CODE_DROPDOWN.rejected]: (state, action) => {
      state.Change_Code_DropDown = action?.payload ?? {};
    },
    //UOM_DROPDOWN
    [UOM_DROPDOWN.fulfilled]: (state, action) => {
      state.Uom_Dropdown = action?.payload ?? {};
    },
    [UOM_DROPDOWN.pending]: (state, action) => {
      state.Uom_Dropdown.loading = true;
    },
    [UOM_DROPDOWN.rejected]: (state, action) => {
      state.Uom_Dropdown = action?.payload ?? {};
    },
    //BILL_ROUND_OFF
    [BILL_ROUND_OFF.fulfilled]: (state, action) => {
      state.billRoundOff = action?.payload ?? {};
    },
    [BILL_ROUND_OFF.pending]: (state, action) => {
      state.billRoundOff.loading = true;
    },
    [BILL_ROUND_OFF.rejected]: (state, action) => {
      state.billRoundOff = action?.payload ?? {};
    },
    //BILL_NO
    [BILL_NO.fulfilled]: (state, action) => {
      state.billGenerator = action?.payload ?? {};
    },
    [BILL_NO.pending]: (state, action) => {
      state.billGenerator.loading = true;
    },
    [BILL_NO.rejected]: (state, action) => {
      state.billGenerator = action?.payload ?? {};
    },
    //BILL_DETAILS_GET
    [BILL_DETAILS_GET.fulfilled]: (state, action) => {
      state.billDetailsSave = action?.payload ?? {};
    },
    [BILL_DETAILS_GET.pending]: (state, action) => {
      state.billDetailsSave.loading = true;
    },
    [BILL_DETAILS_GET.rejected]: (state, action) => {
      state.billDetailsSave = action?.payload ?? {};
    },
    //Practitoner_Load_Query_DROPDOWN
    [Practitoner_Load_Query_DROPDOWN.fulfilled]: (state, action) => {
      state.practitonerLoadQuery_DROPDOWN = action?.payload ?? {};
    },
    [Practitoner_Load_Query_DROPDOWN.pending]: (state, action) => {
      state.practitonerLoadQuery_DROPDOWN.loading = true;
    },
    [Practitoner_Load_Query_DROPDOWN.rejected]: (state, action) => {
      state.practitonerLoadQuery_DROPDOWN = action?.payload ?? {};
    },
    //Facility_Load_Query_DROPDOWN
    [Facility_Load_Query_DROPDOWN.fulfilled]: (state, action) => {
      state.fractitonerLoadQuery_DROPDOWN = action?.payload ?? {};
    },
    [Facility_Load_Query_DROPDOWN.pending]: (state, action) => {
      state.fractitonerLoadQuery_DROPDOWN.loading = true;
    },
    [Facility_Load_Query_DROPDOWN.rejected]: (state, action) => {
      state.fractitonerLoadQuery_DROPDOWN = action?.payload ?? {};
    },
    //GET_COLLECTIONS_TABLE_DATA
    [GET_COLLECTIONS_TABLE_DATA.fulfilled]: (state, action) => {
      state.collectionstableData = action?.payload ?? {};
    },
    [GET_COLLECTIONS_TABLE_DATA.pending]: (state, action) => {
      state.collectionstableData.loading = true;
    },
    [GET_COLLECTIONS_TABLE_DATA.rejected]: (state, action) => {
      state.collectionstableData = action?.payload ?? {};
    },
     //GET_ADVANCE_AMOUNT_DATA
     [GET_ADVANCE_AMOUNT_DATA.fulfilled]: (state, action) => {
      state.advanceAmountData = action?.payload ?? {};
    },
    [GET_ADVANCE_AMOUNT_DATA.pending]: (state, action) => {
      state.advanceAmountData.loading = true;
    },
    [GET_ADVANCE_AMOUNT_DATA.rejected]: (state, action) => {
      state.advanceAmountData = action?.payload ?? {};
    },
  },
});

export const billingDetailsActions = {
  GET_TABLE_DATA,
  PAYER_DROPDOWN,
  PAYMENT_DROPDOWN,
  SEARCH_CODE_DESC,
  RECIEPT_NO,
  CHARGE_CODE_DROPDOWN,
  UOM_DROPDOWN,
  BILL_ROUND_OFF,
  BILL_NO,
  BILL_DETAILS_GET,
  Practitoner_Load_Query_DROPDOWN,
  Facility_Load_Query_DROPDOWN,
  GET_COLLECTIONS_TABLE_DATA,
  GET_ADVANCE_AMOUNT_DATA,
};

export default BillingDetailApiSlice;
