import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../../themes/theme";

const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "red",
  },
  statusBackground: {
    position: "absolute",
    bottom: "0px",
    backgroundColor: "#000",
    width: "100%",
    borderRadius: "0px 0px 8px 8px",
    opacity: "0.4",
    height: "32px",
  },
  status: {
    position: "absolute",
    bottom: "5px",
    color: "white",
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "0px 10px",
  },
  dot: {
    width: 6,
    height: 6,
    backgroundColor: "#38C20A",
    borderRadius: "50%",
    margin: "0 5px",
  },
  inactiveDot: {
    width: 6,
    height: 6,
    backgroundColor: "#F58B00",
    borderRadius: "50%",
    margin: "0 5px",
  },
  cameraIcon: {
    cursor: "pointer",
    width: "20px",
    position: "absolute",
    top: "18px",
    right: "8%",
    textAlign: "center",
    zIndex: 9999999,
  },
  idSpace: {
    backgroundColor: UIColor.differentiationBackground.color,
    textAlign: "center",
    borderRadius: 8,
    color: UIColor.secondaryButtonColor.color,
  },
  onUpload: {
    position: "absolute",
    top: "19px",
    right: "4%",
    zIndex: 100,
    padding: "10px 0px",
    backgroundColor: "#0000006e",
    borderRadius: "50%",
    height: 26,
  },
};

export default styles;
