import React, { useEffect, useRef, useState } from "react";
import { Div, H6, Col, Row, Text, Avatar, Image } from "qdm-component-library";
import "./style.css";
import moment from "moment";
import { utcTOLocal } from "../../../utils";
import editIcon from "../../../assets/img/svg/icons8-edit1.svg";
import trashIcon from "../../../assets/img/svg/icons8-trash1.svg";
import { UIColor } from "../../../themes/theme";
import { motion, useDragControls, useMotionValue } from 'framer-motion';

const PayerDetailCard = (props) => {
  const itemRef = useRef(null);
  const y = useMotionValue(0);
  const dragControls = useDragControls();
  const {
    imgUrl,
    letter,
    data,
    ondelete,
    onedit,
    parent_id,
    isDelete,
    isEdit,
    priority,
    handleDragEnd,
    index
  } = props;
  const variants = {
    initial: { scale: 1 },
    dragging: { scale: 1.2 },
  };
  const [isSelfPay, setIsSelfPay] = useState(false);
  const selfCheck = () => {
    if (data?.payer?.payer_type?.value === "Self Pay") {
      setIsSelfPay(false);
    } else {
      setIsSelfPay(true);
    }
  };
  useEffect(() => {
    if (data?.payer?.payer_type?.value) {
      selfCheck();
    }
  }, [data]);
  return (
    <>
    {props?.istitle ? (
      <Row
      id={`${parent_id}_addresslist_parent_row`}
      className="sl-root"
      style={{ marginBottom: "18px !important" , backgroundColor:UIColor.differentiationBackground.color,display: "flex"}}
    >
      <Col
        id={`${parent_id}_addresslist_parent_col`}
        md={11}
        lg={11}
        sm={6}
        xs={12}
        className="sl-detail-root"
      >
        <Div id={`${parent_id}_addresslist_sub_div`} className="sl-count">
          <Text
            id={`${parent_id}_addresslist_text`}
            className="pc_medium"
            style={{
              color: "#101010",
            }}
          >
            {( props?.priority)}
          </Text>
        </Div>
        <Div
          id={`${parent_id}_addresslist_sub_parent_div`}
          className={imgUrl || letter ? "sl-details" : "sl-detail"}
          style={{ display: "flex" }}
        >
          {(imgUrl || letter) && (
            <Avatar
              id={`${parent_id}_addresslist_letter_avatar`}
              className="sl-details-avatar"
              src={imgUrl}
              letter={letter}
            />
          )}
          <Div
            id={`${parent_id}_addresslist_name_div`}
            className="sl-details-names"
          >
            <H6 id={`${parent_id}_addresslist_reciver_h6`}>
              {data?.payerdetails?.payer?.value}&nbsp;
            </H6>

            <Text
              id={`${parent_id}_addresslist_sponsor_text`}
              style={{
                color: "#6F6F6F",
                fontSize: 12,
              }}
            >
              {data?.payer?.payer_type?.value ?? ""}{/*&nbsp;&nbsp;Plan:{" "}*/}
              {/* { (data?.payerdetails?.plan?.value || data?.payerdetails?.plan?.shortdesc)} */}
              {/* (data?.payer?.priority?.value || data?.payer?.priority) */}
              {/* {data?.eligibility?.sponsor_id ?? ""} */}
            </Text>
          </Div>
          {isSelfPay && (
            <Div
              id={`${parent_id}_addresslist_date_format_div`}
              style={{
                width: "40%",
                marginLeft: "30px",
                fontSize: "20px",
              }}
            >
              <Text
                id={`${parent_id}_addresslist_membership_text`}
                style={{
                  color: "#6F6F6F",
                  fontSize: 12,
                }}
              >
                {data?.membership?.member_id ?? " "}
              </Text>
              &nbsp;
              <Text
                id={`${parent_id}_addresslist_plan_text`}
                style={{
                  color: "#6F6F6F",
                  fontSize: 12,
                }}
              >
                {data?.payer?.plan?.value ?? " "}
              </Text>
              &nbsp;
              {data?.payer?.policy_start ? (
                <Text
                  id={`${parent_id}_addresslist_date_time_text`}
                  style={{
                    color: "#6F6F6F",
                    fontSize: 12,
                  }}
                >
                  {/* {moment(data?.validity?.policy_start).format(
                    "MMMM Do YYYY, h:mm a"
                  )} */}
                  {utcTOLocal(
                    data?.payer?.policy_start,
                    "MMMM Do YYYY, h:mm a"
                  )}
                </Text>
              ) : null}
              &nbsp;
              <Text
                id={`${parent_id}_addresslist_text`}
                style={{
                  color: "#6F6F6F",
                  fontSize: 12,
                }}
              >
                {data?.membership?.type?.value ?? " "}
              </Text>
            </Div>
          )}
        </Div>
      </Col>
      <Col
        id={`${parent_id}_addresslist_image_div`}
        md={1}
        lg={1}
        sm={6}
        xs={12}
        className="sl-action-root"
      >
        <Div
          id={`${parent_id}_addresslist_edit_delete_div`}
          style={{ display: "flex", alignItems: "center" }}
        >
          {!props?.IsViewMode && isEdit && (
            <Image
              id={`${parent_id}_addresslist_edit_image`}
              onClick={(e) => onedit(data, props.index)}
              style={{ cursor: "pointer" }}
              src={editIcon}
              alt="edit"
            />
          )}
          {!props?.IsViewMode && isDelete && (
            <Image
              id={`${parent_id}_addresslist_trash_image`}
              onClick={() => ondelete(data, props.index)}
              style={{ cursor: "pointer", marginLeft: 15 }}
              src={trashIcon}
              alt="delete"
            />
          )}
        </Div>
      </Col>
    </Row>
    ) : (
      <motion.div
        key={priority}
        drag="y"
        ref={itemRef}
        dragControls={dragControls}
        dragConstraints={{ top: 0, bottom: 0 }}
        dragElastic={0.3}
        onDrag={(e, info) => y.set(info.offset.y) }
        onDragEnd={(e, info) =>  {
          // const dragOffset = parseInt(info.offset.y);
          // let dragDirection;// = dragOffset > 20 ? 1 : -1;
          // if(dragOffset > 20 && dragOffset < 80){
          //   console.log(dragDirection,"{}{}{}")
          //   dragDirection=1
          // } else if (dragOffset > 100){
          //   console.log(dragDirection,"{}{}{}")
          //   dragDirection=dragDirection+1
          // }
          handleDragEnd(index, info.offset.y);
        }}
        variants={variants}
        //whileHover={{ scale: 1 }}
        // transition={{ duration: 0.2 }} // Smooth transition animation
        style={{marginTop:"12px",y}}
      >
        <Row
          id={`${parent_id}_addresslist_parent_row`}
          className="sl-root"
          style={{ marginBottom: "18px !important" , backgroundColor:UIColor.differentiationBackground.color,display: "flex"}}
        >
          <Col
            id={`${parent_id}_addresslist_parent_col`}
            md={11}
            lg={11}
            sm={6}
            xs={12}
            className="sl-detail-root"
          >
            <Div id={`${parent_id}_addresslist_sub_div`} className="sl-count">
              <Text
                id={`${parent_id}_addresslist_text`}
                className="pc_medium"
                style={{
                  color: "#101010",
                }}
              >
                {( props?.priority)}
              </Text>
            </Div>
            <Div
              id={`${parent_id}_addresslist_sub_parent_div`}
              className={imgUrl || letter ? "sl-details" : "sl-detail"}
              style={{ display: "flex" }}
            >
              {(imgUrl || letter) && (
                <Avatar
                  id={`${parent_id}_addresslist_letter_avatar`}
                  className="sl-details-avatar"
                  src={imgUrl}
                  letter={letter}
                />
              )}
              <Div
                id={`${parent_id}_addresslist_name_div`}
                className="sl-details-names"
              >
                <H6 id={`${parent_id}_addresslist_reciver_h6`}>
                  {data?.payerdetails?.payer?.value}&nbsp;
                </H6>

                <Text
                  id={`${parent_id}_addresslist_sponsor_text`}
                  style={{
                    color: "#6F6F6F",
                    fontSize: 12,
                  }}
                >
                  {data?.payer?.payer_type?.value ?? ""}&nbsp;&nbsp;Plan:{" "}
                  { (data?.payerdetails?.plan?.value || data?.payerdetails?.plan?.shortdesc)}
                  {/* (data?.payer?.priority?.value || data?.payer?.priority) */}
                  {/* {data?.eligibility?.sponsor_id ?? ""} */}
                </Text>
              </Div>
              {isSelfPay && (
                <Div
                  id={`${parent_id}_addresslist_date_format_div`}
                  style={{
                    width: "40%",
                    marginLeft: "30px",
                    fontSize: "20px",
                  }}
                >
                  <Text
                    id={`${parent_id}_addresslist_membership_text`}
                    style={{
                      color: "#6F6F6F",
                      fontSize: 12,
                    }}
                  >
                    {data?.membership?.member_id ?? " "}
                  </Text>
                  &nbsp;
                  <Text
                    id={`${parent_id}_addresslist_plan_text`}
                    style={{
                      color: "#6F6F6F",
                      fontSize: 12,
                    }}
                  >
                    {data?.payer?.plan?.value ?? " "}
                  </Text>
                  &nbsp;
                  {data?.payer?.policy_start ? (
                    <Text
                      id={`${parent_id}_addresslist_date_time_text`}
                      style={{
                        color: "#6F6F6F",
                        fontSize: 12,
                      }}
                    >
                      {/* {moment(data?.validity?.policy_start).format(
                        "MMMM Do YYYY, h:mm a"
                      )} */}
                      {utcTOLocal(
                        data?.payer?.policy_start,
                        "MMMM Do YYYY, h:mm a"
                      )}
                    </Text>
                  ) : null}
                  &nbsp;
                  <Text
                    id={`${parent_id}_addresslist_text`}
                    style={{
                      color: "#6F6F6F",
                      fontSize: 12,
                    }}
                  >
                    {data?.membership?.type?.value ?? " "}
                  </Text>
                </Div>
              )}
            </Div>
          </Col>
          <Col
            id={`${parent_id}_addresslist_image_div`}
            md={1}
            lg={1}
            sm={6}
            xs={12}
            className="sl-action-root"
          >
            <Div
              id={`${parent_id}_addresslist_edit_delete_div`}
              style={{ display: "flex", alignItems: "center" }}
            >
              {!props?.IsViewMode && isEdit && (
                <Image
                  id={`${parent_id}_addresslist_edit_image`}
                  onClick={(e) => onedit(data, props.index)}
                  style={{ cursor: "pointer" }}
                  src={editIcon}
                  alt="edit"
                />
              )}
              {!props?.IsViewMode && isDelete && (
                <Image
                  id={`${parent_id}_addresslist_trash_image`}
                  onClick={() => ondelete(data, props.index)}
                  style={{ cursor: "pointer", marginLeft: 15 }}
                  src={trashIcon}
                  alt="delete"
                />
              )}
            </Div>
          </Col>
        </Row>
        </motion.div>
    )}
    </>
  );
};

export default PayerDetailCard;
