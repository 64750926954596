import React, { useState, useEffect } from "react";
import { Row, Col, Text, Div, Icons } from "qdm-component-library";
import {
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { UIColor } from "../../../themes/theme";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PatientReferral from "../../patientReferral";
import withAllContexts from "../../../hoc/withAllContexts";
import { useLocation } from "react-router-dom";

// function jsonconverter(data = []) {
//   function removeSpaces(str = "") {
//     return str.replace(/\s/g, "");
//   }
//   let result = {};
//   data.map((v) => {
//     result[removeSpaces(v?.vitalscode)] = {
//       obsinternalid: v?._id,
//       obscode: removeSpaces(v?.vitalscode),
//       obsvalue: "",
//       shortdesc: v?.shortdesc,
//       isabnormal: true,
//       abnormalmsg: '"Min"',
//       type: v?.type,
//       obsuom: v?.units_allowed?.[0]?._id,
//       time: Date.now(),
//       dropdownvalues: v?.dropdownvalues,
//       groupname: v?.groupname,
//       mandatory: v?.mandatory,
//       validation_max: v?.VitalsCodesReferenceRanges?.[0]?.validation_max,
//       validation_min: v?.VitalsCodesReferenceRanges?.[0]?.validation_min,
//       default_unit: v?.default_unit,
//     };
//   });
//   return result;
// }

const styles = {
  vitalsHead: {
    padding: "6px 14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
    paddind: 10,
  },
  vitailsCard: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    marginBottom: 0,
    background: "#fff",
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "red",
  },
  inputStyle: {
    padding: 5,
    marginBottom: 20,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  listVitals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagsStyle: {
    padding: "4px 12px",
    borderRadius: "50px",
    border: "1px solid #0071F2",
    color: "#0071F2",
    background: "#F5FAFF",
    fontSize: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  tapSelectStyle: {
    padding: "4px 8px",
    borderColor: UIColor.lineBorderFill.color,
    margin: "4px 10px 6px 0px",
    borderRadius: "8px",
    minWidth: "auto",
    "& span": {
      fontSize: "12px",
    },
  },
  TapSelectcol: {
    display: "flex",
    margin: "auto 0",
    marginBottom: "12px",
    paddingLeft: "4px",
  },
  CalendarStyle: {
    padding: 5,
    marginBottom: 10,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  CalendarStyleIcon: {
    position: "absolute",
    right: "14px",
    bottom: "8px",
    cursor: "pointer",
  },
};

const ReferralPatient = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [dailogComp, setDailogComp] = useState(<></>);
  const [editId, setEditId] = useState("");
  const [refValue, setrefValue] = useState([]);
  const [patientDetails,setPatientDetails]= useState({});
  useEffect(() => {
    debugger;
    getRefData(editId || props?.location?.state?.referralId);
  }, [editId]);

  const getRefData = async (editId) => {
    debugger;
    let patientDetails = {};
    if (!(editId || props?.location?.state?.referralId)) {
      patientDetails = await dispatch(
        actions.PATIENT_INFO({
          patientid: location.state.patientId,
          tenantid: "",
          facilityid: "",
          lang: "",
        })
      );
      setPatientDetails(patientDetails?.payload?.data)
    }
    if (
      patientDetails?.payload?.data?.referraldetails?._id ||
      editId ||
      props?.location?.state?.referralId
    ) {
      console.log({ patientDetails });
      const data = await dispatch(
        actions?.GET_ALL_REFERRAL({
          refid: editId || patientDetails?.payload?.data?.referraldetails?._id,
        })
      );
      console.log(data, "readdata??//");
      if (data?.payload?.response?.length > 0) {
        const visitform = props.referralData;
        visitform.setReferralDetails({
          ...visitform,
          from: data?.payload?.data?.from ?? {},
          to: data?.payload?.data?.to ?? {},
          details: data?.payload?.data?.details ?? {},
          note: data?.payload?.data?.note ?? {},
        });

        setrefValue(data?.payload?.response);
      }
    }
  };
  const getEpochTIme = (date) => {
    const unixMilliSeconds = date * 1000;
    const myDate = new Date(unixMilliSeconds);
    return myDate.toLocaleString();
  };

  const handleDialog = (json, statekey, edit, editstate) => {
    setOpen(true);
    setDailogComp(
      <PatientReferral
        handleClose={handleClose}
        location={props?.location}
        editIDupdate={editIDupdate}
        isPatient={true}
        editId={editId || props?.location?.state?.referralId || patientDetails?.referraldetails?._id}
        getRefData={getRefData}
        refValue={refValue}
      />
    );
  };

  const editIDupdate = (editId) => {
    setEditId(editId);
  };

  const handleClose = () => {
    setOpen(false);
    // props?.referralData?.setReferralDetails({
    //   from: {},
    //   details: {},
    //   to: {},
    //   letter: {},
    //   note: {}
    // })
  };
  const [errorField, setErrorFields] = React.useState({});

  const breakDisplay = (text) => {
    if (text && text.length > 20) {
      return (
        <span style={{ display: "inline-block", width: "100%" }}>
          {text}
          <br />
        </span>
      );
    }
    return text;
  };
  console.log("refValue", refValue);
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <Row inLineStyles={{ ...styles.vitailsCard }}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.vitalsHead}
          >
            <Text
              inLineStyles={{
                fontWeight: 600,
                fontSize: "14px",
                color: UIColor.primaryColor.color,
                margin: "5px 5px 0px 5px",
              }}
            >
              {"Patient Referral"}
            </Text>
            <Grid>
              {!refValue?.length > 0 && (
                <IconButton
                  onClick={() => handleDialog()}
                  style={{
                    display: "block",
                  }}
                >
                  <AddCircleIcon />
                </IconButton>
              )}
              {refValue?.length > 0 && (
                <IconButton
                  size="small"
                  onClick={() => handleDialog()}
                  style={{
                    display: refValue.length === 0 ? "none" : "block",
                  }}
                >
                  {" "}
                  <Div id={"_body_pencil_div"} className="showWhenHover">
                    <Icons id={`_body_pencil_edit_icons`} fontIcon="pencil" />
                  </Div>
                </IconButton>
              )}
            </Grid>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={{ padding: "0px 8px 10 8px" }}
          >
            {loading ? (
              <Div
                id={`vitals_CircularProgress_div`}
                style={{ display: "flex", height: "100px" }}
              >
                <CircularProgress
                  id={`vitals_CircularProgress`}
                  style={{ margin: "auto" }}
                  size={23}
                />
              </Div>
            ) : (
              <div style={{ width: "100%" }}>
                {refValue?.length > 0 ? (
                  refValue?.map((val, i) => {
                    return (
                      <div key={val.id} style={{ width: "100%" }}>
                        <div style={{ margin: "10px 5px" }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <Grid
                              container
                              xs={12}
                              spacing={12}
                              justifyContent="space-between"
                            >
                              <Grid
                                item
                                xs={4}
                                container
                                justifyContent="space-around"
                                style={{ margin: "5px 0px" }}
                              >
                                <Grid item xs={5}>
                                  {" "}
                                  <Text
                                    inLineStyles={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      fontFamily: "pc_regular",
                                      color: UIColor.secondaryButtonColor.color,
                                    }}
                                  >
                                    REFERRAL DATE
                                  </Text>
                                </Grid>
                                <Grid item xs={7}>
                                  <Text
                                    inLineStyles={{
                                      fontFamily: "pc_regular",
                                      fontSize: 12,
                                      color: UIColor.tertiaryText.color,
                                    }}
                                  >
                                    &nbsp;{getEpochTIme(val?.RefSourceLetDate)}
                                  </Text>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={4}
                                container
                                justifyContent="space-around"
                                style={{ margin: "5px 0px" }}
                              >
                                <Grid item xs={5}>
                                  {" "}
                                  <Text
                                    inLineStyles={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      fontFamily: "pc_regular",
                                      color: UIColor.secondaryButtonColor.color,
                                    }}
                                  >
                                    LETTER NO.
                                  </Text>
                                </Grid>
                                <Grid item xs={7}>
                                  <Text
                                    inLineStyles={{
                                      fontFamily: "pc_regular",
                                      fontSize: 12,
                                      color: UIColor.tertiaryText.color,
                                    }}
                                  >
                                    &nbsp;{val?.RefSourceLetRef}
                                  </Text>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={4}
                                container
                                justifyContent="space-around"
                                style={{ margin: "5px 0px" }}
                              >
                                <Grid item xs={5}>
                                  {" "}
                                  <Text
                                    inLineStyles={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      fontFamily: "pc_regular",
                                      color: UIColor.secondaryButtonColor.color,
                                    }}
                                  >
                                    RECEIVED DATE
                                  </Text>
                                </Grid>
                                <Grid item xs={7}>
                                  <Text
                                    inLineStyles={{
                                      fontFamily: "pc_regular",
                                      fontSize: 12,
                                      color: UIColor.tertiaryText.color,
                                    }}
                                  >
                                    &nbsp;
                                    {getEpochTIme(val?.RefLetReceivedDate)}
                                  </Text>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={4}
                                container
                                justifyContent="space-around"
                                style={{ margin: "5px 0px" }}
                              >
                                {" "}
                                <Grid item xs={5}>
                                  <Text
                                    inLineStyles={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      fontFamily: "pc_regular",
                                      color: UIColor.secondaryButtonColor.color,
                                    }}
                                  >
                                    FROM
                                  </Text>
                                </Grid>
                                <Grid item xs={7}>
                                  <Text
                                    inLineStyles={{
                                      fontFamily: "pc_regular",
                                      fontSize: 12,
                                      color: UIColor.tertiaryText.color,
                                    }}
                                  >
                                    {/* &nbsp; */}
                                    {/* {breakDisplay(
                                      val?.RequestorOrganizationType?.value
                                    )}{val?.RequestorOrganizationType?.value && " - "} */}
                                    {/* &nbsp; */}
                                    {/* {breakDisplay(
                                      val?.RequestorOrganizationEntityType
                                        ?.value
                                    )}{val?.RequestorOrganizationEntityType
                                      ?.value && " - "} */}
                                    {/* &nbsp; */}
                                    {breakDisplay(
                                      val?.HealthCareServiceRequested?.value
                                    )}
                                    {val?.HealthCareServiceRequested?.value &&
                                      " - "}

                                    {breakDisplay(
                                      val?.RequestorSpecialty?.value
                                    )}
                                    {val?.RequestorSpecialty?.value && ""}

                                    {/* {breakDisplay(
                                      val?.RequestorPractionerType?.value
                                    )}
                                    {val?.RequestorPractionerType?.value && "-"}  &nbsp;
                                    {breakDisplay(
                                      val?.RequestorPractioner?.value
                                    )}{ val?.RequestorPractioner?.value && "-"} */}
                                  </Text>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                container
                                justifyContent="space-around"
                                style={{ margin: "5px 0px" }}
                              >
                                {" "}
                                <Grid item xs={5}>
                                  <Text
                                    inLineStyles={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      fontFamily: "pc_regular",
                                      color: UIColor.secondaryButtonColor.color,
                                    }}
                                  >
                                    TO
                                  </Text>
                                </Grid>
                                <Grid item xs={7}>
                                  <Text
                                    inLineStyles={{
                                      fontFamily: "pc_regular",
                                      fontSize: 12,
                                      color: UIColor.tertiaryText.color,
                                    }}
                                  >
                                    {/* &nbsp; */}
                                    {/* {breakDisplay(
                                      val?.receiptOrganizationType?.value
                                    )}{ val?.receiptOrganizationType?.value && "/ "}
                                     &nbsp;
                                    {breakDisplay(
                                      val?.receiptOrganizationEntityType?.value
                                    )}{ val?.receiptOrganizationEntityType?.value && "/ "}
                                     &nbsp; */}
                                    {breakDisplay(val?.receiptfacility?.value)}
                                    {val?.receiptfacility?.value && "- "}

                                    {breakDisplay(
                                      val?.recipientSpecialty?.value
                                    )}
                                    {val?.recipientSpecialty?.value && ""}

                                    {/* {breakDisplay(val?.recipientLocType?.value)}{val?.recipientLocType?.value && "/ "}
                                    &nbsp;
                                    {breakDisplay(
                                      val?.recipientPractType?.value
                                    )}{  val?.recipientPractType?.value && "/"}
                                     &nbsp;
                                    {breakDisplay(val?.recipientPract?.value)}{val?.recipientPract?.value && "/"} */}
                                  </Text>
                                </Grid>
                              </Grid>
                              {/* <Grid
                                item
                                xs={4}
                                container
                                justifyContent="space-around"
                                style={{ margin: "5px 0px" }}
                              >
                                {" "}
                                <Grid item xs={5}>
                                  {" "}
                                  <Text
                                    inLineStyles={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      fontFamily: "pc_regular",
                                      color: UIColor.secondaryButtonColor.color,
                                    }}
                                  >
                                    FACILITY TYPE
                                  </Text>
                                </Grid>
                                <Grid item xs={7}>
                                  {" "}
                                  <Text
                                    inLineStyles={{
                                      fontFamily: "pc_regular",
                                      fontSize: 12,
                                      color: UIColor.tertiaryText.color,
                                    }}
                                  >
                                    &nbsp;
                                    {
                                      val?.RequestorOrganizationEntityType
                                        ?.value
                                    }
                                  </Text>
                                </Grid>
                              </Grid> */}
                              <Grid
                                item
                                xs={4}
                                container
                                justifyContent="space-around"
                                style={{ margin: "5px 0px" }}
                              >
                                {" "}
                                <Grid items xs={5}>
                                  {" "}
                                  <Text
                                    inLineStyles={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      fontFamily: "pc_regular",
                                      color: UIColor.secondaryButtonColor.color,
                                    }}
                                  >
                                    FACILITY NAME
                                  </Text>
                                </Grid>
                                <Grid items xs={7}>
                                  <Text
                                    inLineStyles={{
                                      fontFamily: "pc_regular",
                                      fontSize: 12,
                                      color: UIColor.tertiaryText.color,
                                    }}
                                  >
                                    &nbsp;
                                    {val?.HealthCareServiceRequested?.value}
                                  </Text>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                container
                                justifyContent="space-around"
                                style={{ margin: "5px 0px" }}
                              >
                                {" "}
                                <Grid item xs={5}>
                                  {" "}
                                  <Text
                                    inLineStyles={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      fontFamily: "pc_regular",
                                      color: UIColor.secondaryButtonColor.color,
                                    }}
                                  >
                                    SPECIALTY
                                  </Text>
                                </Grid>
                                <Grid item xs={7}>
                                  {" "}
                                  <Text
                                    inLineStyles={{
                                      fontFamily: "pc_regular",
                                      fontSize: 12,
                                      color: UIColor.tertiaryText.color,
                                    }}
                                  >
                                    &nbsp;{val?.RequestorSpecialty?.value}
                                  </Text>
                                </Grid>
                              </Grid>
                              {/* <Grid
                                item
                                xs={4}
                                container
                                justifyContent="space-around"
                                style={{ margin: "5px 0px" }}
                              >
                                {" "}
                                <Grid item xs={5}>
                                  {" "}
                                  <Text
                                    inLineStyles={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      fontFamily: "pc_regular",
                                      color: UIColor.secondaryButtonColor.color,
                                    }}
                                  >
                                    PRACTITIONER TYPE
                                  </Text>
                                </Grid>
                                <Grid item xs={7}>
                                  <Text
                                    inLineStyles={{
                                      fontFamily: "pc_regular",
                                      fontSize: 12,
                                      color: UIColor.tertiaryText.color,
                                    }}
                                  >
                                    &nbsp;{val?.RequestorPractionerType?.value}
                                  </Text>
                                </Grid>
                              </Grid> */}
                              {/* <Grid
                                item
                                xs={4}
                                container
                                justifyContent="space-around"
                                style={{ margin: "5px 0px" }}
                              >
                                {" "}
                                <Grid item xs={5}>
                                  {" "}
                                  <Text
                                    inLineStyles={{
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      fontFamily: "pc_regular",
                                      color: UIColor.secondaryButtonColor.color,
                                    }}
                                  >
                                    PRACTITIONER
                                  </Text>
                                </Grid>
                                <Grid item xs={7}>
                                  {" "}
                                  <Text
                                    inLineStyles={{
                                      fontFamily: "pc_regular",
                                      fontSize: 12,
                                      color: UIColor.tertiaryText.color,
                                    }}
                                  >
                                    &nbsp;{val?.RequestorPractioner?.value}
                                  </Text>
                                </Grid>
                              </Grid> */}
                            </Grid>
                          </div>
                        </div>
                        <Divider orientation="horizontal" variant="fullWidth" />
                      </div>
                    );
                  })
                ) : (
                  <Typography
                    variant="caption"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "5px",
                      display: "block",
                    }}
                  >
                    No data available
                  </Typography>
                )}
              </div>
            )}
          </Col>
        </Row>
        <Dialog
          fullWidth={true}
          maxWidth={"xl"}
          open={open}
          // onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          {dailogComp}
        </Dialog>
      </div>
    </>
  );
};

export default withAllContexts(ReferralPatient);
