export const Vitals = {
  result: [
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10085",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 50,
        },
      ],
      _id: "VitalsCodes/10000",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "Bpm",
      id: 1,
      longdesc: "P",
      shortdesc: "Pulse",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10000",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "Minutes",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "PULSE",
      default_unit_id: "VitalsMeasureCodes/10000",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodeableConceptMaster/10241",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10026",
          _key: "10026",
          _rev: "_eF9cd8i--M",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1636347816,
          gender: "CodeableConceptMaster/10264",
          id: 20,
          meaningtype: "",
          normal_max: 250,
          normal_min: 30,
          raceappliesto: "",
          updatedate: 1636347816,
          updatedby: "",
          valdatatype: "",
          validation_max: 300,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10007",
      _key: "10007",
      _rev: "_eF9cdvy--B",
      activestatus: true,
      createdby: "",
      createddate: 1636347816,
      default_unit: "Cm",
      id: 8,
      longdesc: "Height - Feet",
      shortdesc: "Height - Feet",
      status: true,
      units_allowed: [
        {
          _key: "10008",
          _id: "VitalsMeasureCodes/10008",
          _rev: "_eF9ceFG--A",
          ObsValMeasCode: "FEET",
          activestatus: true,
          createdby: "",
          createddate: 1636347026,
          longdesc: "Feet",
          shortdesc: "Ft",
          status: true,
          updatedate: 1636347026,
          updatedby: "",
        },
        {
          _key: "10007",
          _id: "VitalsMeasureCodes/10007",
          _rev: "_eF9ceFG--L",
          ObsValMeasCode: "METER",
          activestatus: true,
          createdby: "",
          createddate: 1636347023,
          longdesc: "Meter",
          shortdesc: "Mt",
          status: true,
          updatedate: 1636347023,
          updatedby: "",
        },
        {
          _key: "10006",
          _id: "VitalsMeasureCodes/10006",
          _rev: "_eF9ceFG--H",
          ObsValMeasCode: "CENTIMETER",
          activestatus: true,
          createdby: "",
          createddate: 1636347020,
          longdesc: "Centimetre",
          shortdesc: "Cm",
          status: true,
          updatedate: 1636347020,
          updatedby: "",
        },
        {
          _key: "10005",
          _id: "VitalsMeasureCodes/10005",
          _rev: "_eF9ceFG--G",
          ObsValMeasCode: "INCHES",
          activestatus: true,
          createdby: "",
          createddate: 1636347017,
          longdesc: "Inches",
          shortdesc: "In",
          status: true,
          updatedate: 1636347017,
          updatedby: "",
        },
      ],
      updatedate: 1636347816,
      updatedby: "",
      vitalscode: "HEIGHT",
      default_unit_id: "VitalsMeasureCodes/10006",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodeableConceptMaster/10241",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10027",
          _key: "10027",
          _rev: "_eF9cd8i--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1636347886,
          gender: "CodeableConceptMaster/10264",
          id: 21,
          meaningtype: "",
          normal_max: 300,
          normal_min: 1,
          raceappliesto: "",
          updatedate: 1636347886,
          updatedby: "",
          valdatatype: "",
          validation_max: 500,
          validation_min: 1,
        },
      ],
      _id: "VitalsCodes/10008",
      _key: "10008",
      _rev: "_eF9cdvy--J",
      activestatus: true,
      createdby: "",
      createddate: 1636347886,
      default_unit: "Kg",
      id: 9,
      longdesc: "Weight - Kilogram",
      shortdesc: "Weight - Kg",
      status: true,
      units_allowed: [
        {
          _key: "10009",
          _id: "VitalsMeasureCodes/10009",
          _rev: "_eF9ceFG---",
          ObsValMeasCode: "KILOGRAM",
          activestatus: true,
          createdby: "",
          createddate: 1636347030,
          longdesc: "Kilogram",
          shortdesc: "Kg",
          status: true,
          updatedate: 1636347030,
          updatedby: "",
        },
        {
          _key: "10010",
          _id: "VitalsMeasureCodes/10010",
          _rev: "_eF9ceFG--B",
          ObsValMeasCode: "GRAM",
          activestatus: true,
          createdby: "",
          createddate: 1636347033,
          longdesc: "Gram",
          shortdesc: "Gm",
          status: true,
          updatedate: 1636347033,
          updatedby: "",
        },
        {
          _key: "10011",
          _id: "VitalsMeasureCodes/10011",
          _rev: "_eF9ceFG--O",
          ObsValMeasCode: "POUNDS",
          activestatus: true,
          createdby: "",
          createddate: 1636347036,
          longdesc: "Pounds",
          shortdesc: "lb",
          status: true,
          updatedate: 1636347036,
          updatedby: "",
        },
      ],
      updatedate: 1636347886,
      updatedby: "",
      vitalscode: "WEIGHT",
      default_unit_id: "VitalsMeasureCodes/10009",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10029",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 10,
        },
      ],
      _id: "VitalsCodes/10001",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "mmHg",
      id: 2,
      longdesc: "Systolic",
      shortdesc: "Systolic",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10004",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "MMHG",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "mmHg",
          shortdesc: "mmHg",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "LOWBP",
      default_unit_id: "VitalsMeasureCodes/10004",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10030",
          _key: "10030",
          _rev: "_eF9cd8m--I",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129722,
          gender: "CodeableConceptMaster/10264",
          id: 24,
          meaningtype: "",
          normal_max: 120,
          normal_min: 80,
          raceappliesto: "",
          updatedate: 1637129722,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 10,
        },
      ],
      _id: "VitalsCodes/10002",
      _key: "10002",
      _rev: "_eF9cdvy--D",
      activestatus: true,
      createdby: "",
      createddate: 1636347454,
      default_unit: "mmHg",
      id: 3,
      longdesc: "Diastolic",
      shortdesc: "Diastolic",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10004",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "MMHG",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "mmHg",
          shortdesc: "mmHg",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1637129722,
      updatedby: "",
      vitalscode: "HIGHBP",
      default_unit_id: "VitalsMeasureCodes/10004",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodeableConceptMaster/10241",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10019",
          _key: "10019",
          _rev: "_eF9cd8m--K",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1636347699,
          gender: "CodeableConceptMaster/10264",
          id: 13,
          meaningtype: "",
          normal_max: 30,
          normal_min: 18,
          raceappliesto: "",
          updatedate: 1636347699,
          updatedby: "",
          valdatatype: "",
          validation_max: 50,
          validation_min: 10,
        },
      ],
      _id: "VitalsCodes/10005",
      _key: "10005",
      _rev: "_eF9cdvy--_",
      activestatus: true,
      createdby: "",
      createddate: 1636347699,
      default_unit: "Min",
      id: 6,
      longdesc: "Respiratory",
      shortdesc: "Respiratory",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10000",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "Minutes",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1636347699,
      updatedby: "",
      vitalscode: "RESP",
      default_unit_id: "VitalsMeasureCodes/10000",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodingMaster/10244",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10095",
          _key: "10095",
          _rev: "_fIesZT6---",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1669268925,
          gender: "CodeableConceptMaster/10264",
          id: 89,
          meaningtype: "",
          normal_max: 37,
          normal_min: 36,
          raceappliesto: "",
          updatedate: 1669268925,
          updatedby: "",
          valdatatype: "",
          validation_max: 42,
          validation_min: 35,
        },
      ],
      _id: "VitalsCodes/10003",
      _key: "10003",
      _rev: "_fIesZT6--A",
      activestatus: true,
      createdby: "",
      createddate: 1636347527,
      default_unit: "°C",
      id: 4,
      longdesc: "Temperature",
      shortdesc: "Temperature",
      status: true,
      units_allowed: [
        {
          _key: "10002",
          _id: "VitalsMeasureCodes/10002",
          _rev: "_eUzupGa---",
          ObsValMeasCode: "CELCIUS",
          activestatus: true,
          createdby: "",
          createddate: 1636347006,
          longdesc: "Celcius",
          shortdesc: "Celcius",
          status: true,
          updatedate: 1655398506,
          updatedby: "",
        },
      ],
      updatedate: 1669268925,
      updatedby: "",
      vitalscode: "TEMP",
      default_unit_id: "VitalsMeasureCodes/10002",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodingMaster/10244",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10099",
          _key: "10099",
          _rev: "_gd5PQfe---",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10016",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1692197276,
          gender: "CodeableConceptMaster/10264",
          id: 93,
          meaningtype: "",
          normal_max: 30,
          normal_min: 1,
          raceappliesto: "",
          updatedate: 1692197276,
          updatedby: "",
          valdatatype: "",
          validation_max: 40,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10018",
      _key: "10018",
      _rev: "_gd5PQfi--_",
      activestatus: true,
      createdby: "",
      createddate: 1692167646,
      default_unit: "MG/L",
      id: 19,
      longdesc: "Hemodialysis",
      shortdesc: "Hemodialysis",
      status: true,
      units_allowed: [
        {
          _key: "10021",
          _id: "VitalsMeasureCodes/10021",
          _rev: "_gd5LXU6---",
          ObsValMeasCode: "MG/L",
          shortdesc: "MG/L",
          longdesc: "MG/L",
          status: true,
          id: 5,
          createdby: "",
          createddate: 1692197021,
          updatedby: "",
          updatedate: 1692197021,
          activestatus: true,
        },
      ],
      updatedate: 1692197276,
      updatedby: "",
      vitalscode: "HAEMODIALYSIS",
      default_unit_id: "VitalsMeasureCodes/10021",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodeableConceptMaster/10241",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10026",
          _key: "10026",
          _rev: "_eF9cd8i--M",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1636347816,
          gender: "CodeableConceptMaster/10264",
          id: 20,
          meaningtype: "",
          normal_max: 250,
          normal_min: 30,
          raceappliesto: "",
          updatedate: 1636347816,
          updatedby: "",
          valdatatype: "",
          validation_max: 300,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10007",
      _key: "10007",
      _rev: "_eF9cdvy--B",
      activestatus: true,
      createdby: "",
      createddate: 1636347816,
      default_unit: "",
      id: 100,
      longdesc: "BMI",
      shortdesc: "BMI",
      status: true,
      units_allowed: [
        {
          _key: "10008",
          _id: "VitalsMeasureCodes/10008",
          _rev: "_eF9ceFG--A",
          ObsValMeasCode: "FEET",
          activestatus: true,
          createdby: "",
          createddate: 1636347026,
          longdesc: "Feet",
          shortdesc: "Ft",
          status: true,
          updatedate: 1636347026,
          updatedby: "",
        },
        {
          _key: "10007",
          _id: "VitalsMeasureCodes/10007",
          _rev: "_eF9ceFG--L",
          ObsValMeasCode: "METER",
          activestatus: true,
          createdby: "",
          createddate: 1636347023,
          longdesc: "Meter",
          shortdesc: "Mt",
          status: true,
          updatedate: 1636347023,
          updatedby: "",
        },
        {
          _key: "10006",
          _id: "VitalsMeasureCodes/10006",
          _rev: "_eF9ceFG--H",
          ObsValMeasCode: "CENTIMETER",
          activestatus: true,
          createdby: "",
          createddate: 1636347020,
          longdesc: "Centimetre",
          shortdesc: "Cm",
          status: true,
          updatedate: 1636347020,
          updatedby: "",
        },
        {
          _key: "10005",
          _id: "VitalsMeasureCodes/10005",
          _rev: "_eF9ceFG--G",
          ObsValMeasCode: "INCHES",
          activestatus: true,
          createdby: "",
          createddate: 1636347017,
          longdesc: "Inches",
          shortdesc: "In",
          status: true,
          updatedate: 1636347017,
          updatedby: "",
        },
      ],
      updatedate: 1636347816,
      updatedby: "",
      vitalscode: "BMI",
      default_unit_id: "VitalsMeasureCodes/10006",
      mandatory: true,
      disabled: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodeableConceptMaster/10241",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10026",
          _key: "10026",
          _rev: "_eF9cd8i--M",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1636347816,
          gender: "CodeableConceptMaster/10264",
          id: 20,
          meaningtype: "",
          normal_max: 250,
          normal_min: 30,
          raceappliesto: "",
          updatedate: 1636347816,
          updatedby: "",
          valdatatype: "",
          validation_max: 300,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10007",
      _key: "10007",
      _rev: "_eF9cdvy--B",
      activestatus: true,
      createdby: "",
      createddate: 1636347816,
      default_unit: "",
      id: 130,
      longdesc: "BSA",
      shortdesc: "BSA",
      status: true,
      units_allowed: [
        {
          _key: "10008",
          _id: "VitalsMeasureCodes/10008",
          _rev: "_eF9ceFG--A",
          ObsValMeasCode: "FEET",
          activestatus: true,
          createdby: "",
          createddate: 1636347026,
          longdesc: "Feet",
          shortdesc: "Ft",
          status: true,
          updatedate: 1636347026,
          updatedby: "",
        },
        {
          _key: "10007",
          _id: "VitalsMeasureCodes/10007",
          _rev: "_eF9ceFG--L",
          ObsValMeasCode: "METER",
          activestatus: true,
          createdby: "",
          createddate: 1636347023,
          longdesc: "Meter",
          shortdesc: "Mt",
          status: true,
          updatedate: 1636347023,
          updatedby: "",
        },
        {
          _key: "10006",
          _id: "VitalsMeasureCodes/10006",
          _rev: "_eF9ceFG--H",
          ObsValMeasCode: "CENTIMETER",
          activestatus: true,
          createdby: "",
          createddate: 1636347020,
          longdesc: "Centimetre",
          shortdesc: "Cm",
          status: true,
          updatedate: 1636347020,
          updatedby: "",
        },
        {
          _key: "10005",
          _id: "VitalsMeasureCodes/10005",
          _rev: "_eF9ceFG--G",
          ObsValMeasCode: "INCHES",
          activestatus: true,
          createdby: "",
          createddate: 1636347017,
          longdesc: "Inches",
          shortdesc: "In",
          status: true,
          updatedate: 1636347017,
          updatedby: "",
        },
      ],
      updatedate: 1636347816,
      updatedby: "",
      vitalscode: "BSA",
      default_unit_id: "VitalsMeasureCodes/10006",
      mandatory: true,
      disabled: true,
    },
  ],
};
export const predialysis = {
  result: [
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10001",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10001",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "",
      shortdesc: "Hepatatis",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10000",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Hepatatis",
      type: "select",
      dropdownvalues: ["No", "Yes"],
      default_unit_id: "VitalsMeasureCodes/10000",
      // mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10001",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10001",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "",
      shortdesc: "Hepatatis Type",
      hide: true,
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10000",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "HepatatisType",
      type: "select",
      dropdownvalues: ["Hepatatis B", "Hepatatis C"],
      default_unit_id: "VitalsMeasureCodes/10000",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10021",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 10,
        },
      ],
      _id: "VitalsCodes/10008",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "",
      id: 2,
      longdesc: "Type of Vascular Access",
      shortdesc: "Type of Vascular Access",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10007",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "TVA",
      default_unit_id: "VitalsMeasureCodes/10007",
      type: "text",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10022",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 10,
        },
      ],
      _id: "VitalsCodes/10009",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "",
      id: 2,
      longdesc: "Condition of Cascular Access",
      shortdesc: "Condition of Cascular Access",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10008",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "CCA",
      default_unit_id: "VitalsMeasureCodes/10008",
      type: "text",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10023",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 10,
        },
      ],
      _id: "VitalsCodes/10010",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "",
      id: 2,
      longdesc: "Patient Condition/ Symptoms",
      shortdesc: "Patient Condition/ Symptoms",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10009",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "PCS",
      default_unit_id: "VitalsMeasureCodes/10009",
      type: "text",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10023",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 10,
        },
      ],
      _id: "VitalsCodes/10010",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "",
      id: 2,
      longdesc: "time(Arrived)",
      shortdesc: "Time(Arrived)",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10009",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "TimeArrived",
      default_unit_id: "VitalsMeasureCodes/10009",
      type: "time",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10023",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 10,
        },
      ],
      _id: "VitalsCodes/10010",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "",
      id: 2,
      longdesc: "time(Start HD)",
      shortdesc: "Time(Start HD)",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10009",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "TimeStart",
      default_unit_id: "VitalsMeasureCodes/10009",
      type: "time",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodingMaster/10244",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10095",
          _key: "10095",
          _rev: "_fIesZT6---",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1669268925,
          gender: "CodeableConceptMaster/10264",
          id: 89,
          meaningtype: "",
          normal_max: 37,
          normal_min: 36,
          raceappliesto: "",
          updatedate: 1669268925,
          updatedby: "",
          valdatatype: "",
          validation_max: 42,
          validation_min: 35,
        },
      ],
      _id: "VitalsCodes/10006",
      _key: "10003",
      _rev: "_fIesZT6--A",
      activestatus: true,
      createdby: "",
      createddate: 1636347527,
      default_unit: "°C",
      id: 4,
      longdesc: "Temperature",
      shortdesc: "Temperature",
      status: true,
      units_allowed: [
        {
          _key: "10002",
          _id: "VitalsMeasureCodes/10005",
          _rev: "_eUzupGa---",
          ObsValMeasCode: "CELCIUS",
          activestatus: true,
          createdby: "",
          createddate: 1636347006,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1655398506,
          updatedby: "",
        },
      ],
      updatedate: 1669268925,
      updatedby: "",
      vitalscode: "TEMP",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10005",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10020",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 40,
        },
      ],
      _id: "VitalsCodes/10007",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "mmHg",
      id: 2,
      longdesc: "Blood Pressure",
      shortdesc: "Blood Pressure",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10006",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "MMHG",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "BP",
      default_unit_id: "VitalsMeasureCodes/10006",
      type: "number",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10005",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 50,
        },
      ],
      _id: "VitalsCodes/10005",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "Bpm",
      id: 1,
      longdesc: "",
      shortdesc: "Pulse",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10004",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "PULSE",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10004",
      mandatory: true,
    },
    {
      _id: "VitalsCodes/10011",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "",
      id: 2,
      longdesc: "Started by SN",
      shortdesc: "Started by SN",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10010",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "SS",
      default_unit_id: "VitalsMeasureCodes/10010",
      // type: "autocomplete",
      // dropdownvalues: ["SN F"],
      type: "text",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10002",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10002",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      id: 1,
      longdesc: "staff id",
      shortdesc: "Staff ID",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10001",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "text",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "StaffID",
      mandatory: true,
      type: "autocomplete",
      // dropdownvalues: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      // type: "labelName",
      default_unit_id: "VitalsMeasureCodes/10001",
    },
  ],
};

export const intradialysis = {
  result: [
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10085",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10000",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "",
      shortdesc: "Type",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10000",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Type",
      type: "text",
      dropdownvalues: ["Baseline", "1st Hour", "2nd Hour", "3rd Hour"],
      default_unit_id: "VitalsMeasureCodes/10000",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10029",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 10,
        },
      ],
      _id: "VitalsCodes/10001",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "",
      id: 2,
      longdesc: "time",
      shortdesc: "Time",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10004",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "min",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "min",
          shortdesc: "min",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "Time",
      default_unit_id: "VitalsMeasureCodes/10004",
      type: "time",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10029",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 40,
        },
      ],
      _id: "VitalsCodes/10001",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "mmHg",
      id: 2,
      longdesc: "Blood Pressure",
      shortdesc: "Blood Pressure",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10004",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "MMHG",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "mmHg",
          shortdesc: "mmHg",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "BP",
      default_unit_id: "VitalsMeasureCodes/10004",
      type: "number",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10085",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 50,
        },
      ],
      _id: "VitalsCodes/10000",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "Bpm",
      id: 1,
      longdesc: "P",
      shortdesc: "Pulse",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10000",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "Minutes",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "PULSE",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10000",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10085",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10000",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "Heparin",
      shortdesc: "Heparin",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10000",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "Minutes",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Heparin",
      // type: "autocomplete",
      // dropdownvalues: ["500", "1500", "5000"],
      type: "select",
      default_unit_id: "VitalsMeasureCodes/10000",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodeableConceptMaster/10241",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10027",
          _key: "10027",
          _rev: "_eF9cd8i--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1636347886,
          gender: "CodeableConceptMaster/10264",
          id: 21,
          meaningtype: "",
          normal_max: 300,
          normal_min: 1,
          raceappliesto: "",
          updatedate: 1636347886,
          updatedby: "",
          valdatatype: "",
          validation_max: 500,
          validation_min: 1,
        },
      ],
      _id: "VitalsCodes/10008",
      _key: "10008",
      _rev: "_eF9cdvy--J",
      activestatus: true,
      createdby: "",
      createddate: 1636347886,
      default_unit: "mL/min",
      id: 9,
      longdesc: "Qb - mL/min",
      shortdesc: "Qb - mL/min",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10009",
          _id: "VitalsMeasureCodes/10009",
          _rev: "_eF9ceFG---",
          ObsValMeasCode: "mL/min",
          activestatus: true,
          createdby: "",
          createddate: 1636347030,
          longdesc: "mL/min",
          shortdesc: "mL/min",
          status: true,
          updatedate: 1636347030,
          updatedby: "",
        },
        {
          _key: "10010",
          _id: "VitalsMeasureCodes/10010",
          _rev: "_eF9ceFG--B",
          ObsValMeasCode: "mL/min",
          activestatus: true,
          createdby: "",
          createddate: 1636347033,
          longdesc: "mL/min",
          shortdesc: "mL/min",
          status: true,
          updatedate: 1636347033,
          updatedby: "",
        },
        {
          _key: "10011",
          _id: "VitalsMeasureCodes/10011",
          _rev: "_eF9ceFG--O",
          ObsValMeasCode: "mL/min",
          activestatus: true,
          createdby: "",
          createddate: 1636347036,
          longdesc: "mL/min",
          shortdesc: "mL/min",
          status: true,
          updatedate: 1636347036,
          updatedby: "",
        },
      ],
      updatedate: 1636347886,
      updatedby: "",
      vitalscode: "QB",
      default_unit_id: "VitalsMeasureCodes/10009",
      mandatory: true,
      // type: "autocomplete",
      // dropdownvalues: ["150", "200", "250", "300", "350", "400", "450"],
      type: "select",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10029",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          // validation_max: 250,
          // validation_min: 50,
        },
      ],
      _id: "VitalsCodes/10001",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "mmHg",
      id: 2,
      longdesc: "ANTERIAL PRESSURE",
      shortdesc: "ANTERIAL PRESSURE",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10004",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "MMHG",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "mmHg",
          shortdesc: "mmHg",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "AP",
      default_unit_id: "VitalsMeasureCodes/10004",
      mandatory: true,
      type: "number",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10029",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          // validation_max: 100,
          // validation_min: 50,
        },
      ],
      _id: "VitalsCodes/10001",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "mmHg",
      id: 2,
      longdesc: "VENOUS PRESSURE",
      shortdesc: "VENOUS PRESSURE",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10004",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "MMHG",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "mmHg",
          shortdesc: "mmHg",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "VP",
      default_unit_id: "VitalsMeasureCodes/10004",
      mandatory: true,
      type: "number",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodingMaster/10244",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10095",
          _key: "10095",
          _rev: "_fIesZT6---",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1669268925,
          gender: "CodeableConceptMaster/10264",
          id: 89,
          meaningtype: "",
          normal_max: 37,
          normal_min: 36,
          raceappliesto: "",
          updatedate: 1669268925,
          updatedby: "",
          valdatatype: "",
          validation_max: 100,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10003",
      _key: "10003",
      _rev: "_fIesZT6--A",
      activestatus: true,
      createdby: "",
      createddate: 1636347527,
      default_unit: "°C",
      id: 4,
      longdesc: "Temperature",
      shortdesc: "Temperature",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10002",
          _id: "VitalsMeasureCodes/10002",
          _rev: "_eUzupGa---",
          ObsValMeasCode: "CELCIUS",
          activestatus: true,
          createdby: "",
          createddate: 1636347006,
          longdesc: "Celcius",
          shortdesc: "Celcius",
          status: true,
          updatedate: 1655398506,
          updatedby: "",
        },
      ],
      updatedate: 1669268925,
      updatedby: "",
      vitalscode: "TEMP",
      default_unit_id: "VitalsMeasureCodes/10002",
      mandatory: true,
      type: "number",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodeableConceptMaster/10241",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10027",
          _key: "10027",
          _rev: "_eF9cd8i--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1636347886,
          gender: "CodeableConceptMaster/10264",
          id: 21,
          meaningtype: "",
          normal_max: 300,
          normal_min: 1,
          raceappliesto: "",
          updatedate: 1636347886,
          updatedby: "",
          valdatatype: "",
          validation_max: 800,
          validation_min: 300,
        },
      ],
      _id: "VitalsCodes/10008",
      _key: "10008",
      _rev: "_eF9cdvy--J",
      activestatus: true,
      createdby: "",
      createddate: 1636347886,
      default_unit: "mL/min",
      id: 9,
      longdesc: "Qd - mL/min",
      shortdesc: "Qd - mL/min",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10009",
          _id: "VitalsMeasureCodes/10009",
          _rev: "_eF9ceFG---",
          ObsValMeasCode: "mL/min",
          activestatus: true,
          createdby: "",
          createddate: 1636347030,
          longdesc: "mL/min",
          shortdesc: "mL/min",
          status: true,
          updatedate: 1636347030,
          updatedby: "",
        },
        {
          _key: "10010",
          _id: "VitalsMeasureCodes/10010",
          _rev: "_eF9ceFG--B",
          ObsValMeasCode: "mL/min",
          activestatus: true,
          createdby: "",
          createddate: 1636347033,
          longdesc: "mL/min",
          shortdesc: "mL/min",
          status: true,
          updatedate: 1636347033,
          updatedby: "",
        },
        {
          _key: "10011",
          _id: "VitalsMeasureCodes/10011",
          _rev: "_eF9ceFG--O",
          ObsValMeasCode: "mL/min",
          activestatus: true,
          createdby: "",
          createddate: 1636347036,
          longdesc: "mL/min",
          shortdesc: "mL/min",
          status: true,
          updatedate: 1636347036,
          updatedby: "",
        },
      ],
      updatedate: 1636347886,
      updatedby: "",
      vitalscode: "QD",
      default_unit_id: "VitalsMeasureCodes/10009",
      mandatory: true,
      type: "select",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodingMaster/10244",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10095",
          _key: "10095",
          _rev: "_fIesZT6---",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1669268925,
          gender: "CodeableConceptMaster/10264",
          id: 89,
          meaningtype: "",
          normal_max: 37,
          normal_min: 36,
          raceappliesto: "",
          updatedate: 1669268925,
          updatedby: "",
          valdatatype: "",
          validation_max: 15,
          validation_min: 12,
        },
      ],
      _id: "VitalsCodes/10003",
      _key: "10003",
      _rev: "_fIesZT6--A",
      activestatus: true,
      createdby: "",
      createddate: 1636347527,
      default_unit: "",
      id: 4,
      longdesc: "Conductivity",
      shortdesc: "Conductivity",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10002",
          _id: "VitalsMeasureCodes/10002",
          _rev: "_eUzupGa---",
          ObsValMeasCode: "text",
          activestatus: true,
          createdby: "",
          createddate: 1636347006,
          longdesc: "text",
          shortdesc: "text",
          status: true,
          updatedate: 1655398506,
          updatedby: "",
        },
      ],
      updatedate: 1669268925,
      updatedby: "",
      vitalscode: "CONDUCTIVITY",
      default_unit_id: "VitalsMeasureCodes/10002",
      mandatory: true,
      type: "number",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodeableConceptMaster/10241",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10027",
          _key: "10027",
          _rev: "_eF9cd8i--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1636347886,
          gender: "CodeableConceptMaster/10264",
          id: 21,
          meaningtype: "",
          normal_max: 300,
          normal_min: 1,
          raceappliesto: "",
          updatedate: 1636347886,
          updatedby: "",
          valdatatype: "",
          validation_max: 2000,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10008",
      _key: "10008",
      _rev: "_eF9cdvy--J",
      activestatus: true,
      createdby: "",
      createddate: 1636347886,
      default_unit: "Kg",
      id: 9,
      longdesc: "Accumulated UFV - Kilogram",
      shortdesc: "Accumulated UFV - Kg",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10009",
          _id: "VitalsMeasureCodes/10009",
          _rev: "_eF9ceFG---",
          ObsValMeasCode: "KILOGRAM",
          activestatus: true,
          createdby: "",
          createddate: 1636347030,
          longdesc: "Kilogram",
          shortdesc: "Kg",
          status: true,
          updatedate: 1636347030,
          updatedby: "",
        },
        {
          _key: "10010",
          _id: "VitalsMeasureCodes/10010",
          _rev: "_eF9ceFG--B",
          ObsValMeasCode: "GRAM",
          activestatus: true,
          createdby: "",
          createddate: 1636347033,
          longdesc: "Gram",
          shortdesc: "Gm",
          status: true,
          updatedate: 1636347033,
          updatedby: "",
        },
        {
          _key: "10011",
          _id: "VitalsMeasureCodes/10011",
          _rev: "_eF9ceFG--O",
          ObsValMeasCode: "POUNDS",
          activestatus: true,
          createdby: "",
          createddate: 1636347036,
          longdesc: "Pounds",
          shortdesc: "lb",
          status: true,
          updatedate: 1636347036,
          updatedby: "",
        },
      ],
      updatedate: 1636347886,
      updatedby: "",
      vitalscode: "AUFV",
      default_unit_id: "VitalsMeasureCodes/10009",
      mandatory: true,
      type: "number",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodingMaster/10244",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10095",
          _key: "10095",
          _rev: "_fIesZT6---",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1669268925,
          gender: "CodeableConceptMaster/10264",
          id: 89,
          meaningtype: "",
          normal_max: 37,
          normal_min: 36,
          raceappliesto: "",
          updatedate: 1669268925,
          updatedby: "",
          valdatatype: "",
          validation_max: 42,
          validation_min: 35,
        },
      ],
      _id: "VitalsCodes/10003",
      _key: "10003",
      _rev: "_fIesZT6--A",
      activestatus: true,
      createdby: "",
      createddate: 1636347527,
      default_unit: "time",
      id: 4,
      longdesc: "Time",
      shortdesc: "Time",
      groupname: "Complication",
      status: true,
      units_allowed: [
        {
          _key: "10002",
          _id: "VitalsMeasureCodes/10002",
          _rev: "_eUzupGa---",
          ObsValMeasCode: "text",
          activestatus: true,
          createdby: "",
          createddate: 1636347006,
          longdesc: "text",
          shortdesc: "text",
          status: true,
          updatedate: 1655398506,
          updatedby: "",
        },
      ],
      updatedate: 1669268925,
      updatedby: "",
      vitalscode: "TIME",
      default_unit_id: "VitalsMeasureCodes/10002",
      mandatory: true,
      type: "time",
    },

    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodingMaster/10244",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10095",
          _key: "10095",
          _rev: "_fIesZT6---",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1669268925,
          gender: "CodeableConceptMaster/10264",
          id: 89,
          meaningtype: "",
          normal_max: 37,
          normal_min: 36,
          raceappliesto: "",
          updatedate: 1669268925,
          updatedby: "",
          valdatatype: "",
          validation_max: 42,
          validation_min: 35,
        },
      ],
      _id: "VitalsCodes/10003",
      _key: "10003",
      _rev: "_fIesZT6--A",
      activestatus: true,
      createdby: "",
      createddate: 1636347527,
      default_unit: "text",
      id: 4,
      longdesc: "Complication",
      shortdesc: "Complication",
      groupname: "Complication",
      status: true,
      units_allowed: [
        {
          _key: "10002",
          _id: "VitalsMeasureCodes/10002",
          _rev: "_eUzupGa---",
          ObsValMeasCode: "text",
          activestatus: true,
          createdby: "",
          createddate: 1636347006,
          longdesc: "text",
          shortdesc: "text",
          status: true,
          updatedate: 1655398506,
          updatedby: "",
        },
      ],
      updatedate: 1669268925,
      updatedby: "",
      vitalscode: "COMPLICATION",
      default_unit_id: "VitalsMeasureCodes/10002",
      mandatory: true,
      type: "text",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodingMaster/10244",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10095",
          _key: "10095",
          _rev: "_fIesZT6---",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1669268925,
          gender: "CodeableConceptMaster/10264",
          id: 89,
          meaningtype: "",
          normal_max: 37,
          normal_min: 36,
          raceappliesto: "",
          updatedate: 1669268925,
          updatedby: "",
          valdatatype: "",
          validation_max: 42,
          validation_min: 35,
        },
      ],
      _id: "VitalsCodes/10003",
      _key: "10003",
      _rev: "_fIesZT6--A",
      activestatus: true,
      createdby: "",
      createddate: 1636347527,
      default_unit: "text",
      id: 4,
      longdesc: "Action",
      shortdesc: "Action",
      groupname: "Complication",
      status: true,
      units_allowed: [
        {
          _key: "10002",
          _id: "VitalsMeasureCodes/10002",
          _rev: "_eUzupGa---",
          ObsValMeasCode: "text",
          activestatus: true,
          createdby: "",
          createddate: 1636347006,
          longdesc: "text",
          shortdesc: "text",
          status: true,
          updatedate: 1655398506,
          updatedby: "",
        },
      ],
      updatedate: 1669268925,
      updatedby: "",
      vitalscode: "ACTION",
      default_unit_id: "VitalsMeasureCodes/10002",
      mandatory: true,
      type: "text",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodingMaster/10244",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10095",
          _key: "10095",
          _rev: "_fIesZT6---",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1669268925,
          gender: "CodeableConceptMaster/10264",
          id: 89,
          meaningtype: "",
          normal_max: 37,
          normal_min: 36,
          raceappliesto: "",
          updatedate: 1669268925,
          updatedby: "",
          valdatatype: "",
          validation_max: 42,
          validation_min: 35,
        },
      ],
      _id: "VitalsCodes/10003",
      _key: "10003",
      _rev: "_fIesZT6--A",
      activestatus: true,
      createdby: "",
      createddate: 1636347527,
      default_unit: "",
      id: 4,
      longdesc: "nursing notes",
      shortdesc: "Nursing Notes",
      groupname: "Nursing Notes",
      status: true,
      units_allowed: [
        {
          _key: "10002",
          _id: "VitalsMeasureCodes/10002",
          _rev: "_eUzupGa---",
          ObsValMeasCode: "text",
          activestatus: true,
          createdby: "",
          createddate: 1636347006,
          longdesc: "text",
          shortdesc: "text",
          status: true,
          updatedate: 1655398506,
          updatedby: "",
        },
      ],
      updatedate: 1669268925,
      updatedby: "",
      vitalscode: "NN",
      default_unit_id: "VitalsMeasureCodes/10002",
      mandatory: true,
      type: "autocomplete",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodingMaster/10244",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10095",
          _key: "10095",
          _rev: "_fIesZT6---",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1669268925,
          gender: "CodeableConceptMaster/10264",
          id: 89,
          meaningtype: "",
          normal_max: 37,
          normal_min: 36,
          raceappliesto: "",
          updatedate: 1669268925,
          updatedby: "",
          valdatatype: "",
          validation_max: 42,
          validation_min: 35,
        },
      ],
      _id: "VitalsCodes/10003",
      _key: "10003",
      _rev: "_fIesZT6--A",
      activestatus: true,
      createdby: "",
      createddate: 1636347527,
      default_unit: "",
      id: 4,
      longdesc: "staff id",
      shortdesc: "Staff ID",
      groupname: "Nursing Notes",
      status: true,
      units_allowed: [
        {
          _key: "10002",
          _id: "VitalsMeasureCodes/10002",
          _rev: "_eUzupGa---",
          ObsValMeasCode: "text",
          activestatus: true,
          createdby: "",
          createddate: 1636347006,
          longdesc: "text",
          shortdesc: "text",
          groupname: "Nursing Notes",
          status: true,
          updatedate: 1655398506,
          updatedby: "",
        },
      ],
      updatedate: 1669268925,
      updatedby: "",
      vitalscode: "StaffID",
      default_unit_id: "VitalsMeasureCodes/10002",
      mandatory: true,
      type: "autocomplete",
      // dropdownvalues: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      // type: "labelName",
    },
  ],
};

export const postdialysis = {
  result: [
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10029",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 10,
        },
      ],
      _id: "VitalsCodes/10001",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "",
      id: 2,
      longdesc: "End Time",
      shortdesc: "End Time",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10004",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "Min",
          shortdesc: "min",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "EndTime",
      default_unit_id: "VitalsMeasureCodes/10004",
      type: "time",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10029",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 10,
        },
      ],
      _id: "VitalsCodes/10001",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "",
      id: 2,
      longdesc: "Ended By SN",
      shortdesc: "Ended By SN",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10004",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "Min",
          shortdesc: "min",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "EndedBySN",
      default_unit_id: "VitalsMeasureCodes/10004",
      // type: "autocomplete",
      // dropdownvalues: ["SN F"],
      type: "text",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodingMaster/10244",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10095",
          _key: "10095",
          _rev: "_fIesZT6---",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1669268925,
          gender: "CodeableConceptMaster/10264",
          id: 89,
          meaningtype: "",
          normal_max: 37,
          normal_min: 36,
          raceappliesto: "",
          updatedate: 1669268925,
          updatedby: "",
          valdatatype: "",
          validation_max: 42,
          validation_min: 35,
        },
      ],
      _id: "VitalsCodes/10003",
      _key: "10003",
      _rev: "_fIesZT6--A",
      activestatus: true,
      createdby: "",
      createddate: 1636347527,
      default_unit: "°C",
      id: 4,
      longdesc: "Temperature",
      shortdesc: "Temperature",
      status: true,
      units_allowed: [
        {
          _key: "10002",
          _id: "VitalsMeasureCodes/10002",
          _rev: "_eUzupGa---",
          ObsValMeasCode: "CELCIUS",
          activestatus: true,
          createdby: "",
          createddate: 1636347006,
          longdesc: "Celcius",
          shortdesc: "Celcius",
          status: true,
          updatedate: 1655398506,
          updatedby: "",
        },
      ],
      updatedate: 1669268925,
      updatedby: "",
      vitalscode: "TEMP",
      default_unit_id: "VitalsMeasureCodes/10002",
      mandatory: true,
      type: "number",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10029",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 40,
        },
      ],
      _id: "VitalsCodes/10001",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "mmHg",
      id: 2,
      longdesc: "Blood Pressure(Standing)",
      shortdesc: "Blood Pressure(Standing)",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10004",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "MMHG",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "mmHg",
          shortdesc: "mmHg",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "BPStnd",
      default_unit_id: "VitalsMeasureCodes/10004",
      type: "number",
      mandatory: false,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10029",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 40,
        },
      ],
      _id: "VitalsCodes/10001",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "mmHg",
      id: 2,
      longdesc: "Blood Pressure(Sitting)",
      shortdesc: "Blood Pressure(Sitting)",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10004",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "MMHG",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "mmHg",
          shortdesc: "mmHg",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "BPSit",
      default_unit_id: "VitalsMeasureCodes/10004",
      type: "number",
      mandatory: false,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10085",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 50,
        },
      ],
      _id: "VitalsCodes/10000",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "Bpm",
      id: 1,
      longdesc: "P",
      shortdesc: "Pulse(Standing)",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10000",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "Minutes",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "PULSESTND",
      default_unit_id: "VitalsMeasureCodes/10000",
      mandatory: false,
      type: "number",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10085",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 50,
        },
      ],
      _id: "VitalsCodes/10000",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "Bpm",
      id: 1,
      longdesc: "P",
      shortdesc: "Pulse(Sitting)",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10000",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "Minutes",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "PULSESIT",
      default_unit_id: "VitalsMeasureCodes/10000",
      mandatory: false,
      type: "number",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodeableConceptMaster/10241",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10027",
          _key: "10027",
          _rev: "_eF9cd8i--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1636347886,
          gender: "CodeableConceptMaster/10264",
          id: 21,
          meaningtype: "",
          normal_max: 300,
          normal_min: 1,
          raceappliesto: "",
          updatedate: 1636347886,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10008",
      _key: "10008",
      _rev: "_eF9cdvy--J",
      activestatus: true,
      createdby: "",
      createddate: 1636347886,
      default_unit: "Kg",
      id: 9,
      longdesc: "Post HD Weight - Kilogram",
      shortdesc: "Post HD Weight - Kg",
      status: true,
      units_allowed: [
        {
          _key: "10009",
          _id: "VitalsMeasureCodes/10009",
          _rev: "_eF9ceFG---",
          ObsValMeasCode: "KILOGRAM",
          activestatus: true,
          createdby: "",
          createddate: 1636347030,
          longdesc: "Kilogram",
          shortdesc: "Kg",
          status: true,
          updatedate: 1636347030,
          updatedby: "",
        },
        {
          _key: "10010",
          _id: "VitalsMeasureCodes/10010",
          _rev: "_eF9ceFG--B",
          ObsValMeasCode: "GRAM",
          activestatus: true,
          createdby: "",
          createddate: 1636347033,
          longdesc: "Gram",
          shortdesc: "Gm",
          status: true,
          updatedate: 1636347033,
          updatedby: "",
        },
        {
          _key: "10011",
          _id: "VitalsMeasureCodes/10011",
          _rev: "_eF9ceFG--O",
          ObsValMeasCode: "POUNDS",
          activestatus: true,
          createdby: "",
          createddate: 1636347036,
          longdesc: "Pounds",
          shortdesc: "lb",
          status: true,
          updatedate: 1636347036,
          updatedby: "",
        },
      ],
      updatedate: 1636347886,
      updatedby: "",
      vitalscode: "PHDW",
      default_unit_id: "VitalsMeasureCodes/10009",
      mandatory: true,
      type: "number",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodeableConceptMaster/10241",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10027",
          _key: "10027",
          _rev: "_eF9cd8i--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1636347886,
          gender: "CodeableConceptMaster/10264",
          id: 21,
          meaningtype: "",
          normal_max: 300,
          normal_min: 1,
          raceappliesto: "",
          updatedate: 1636347886,
          updatedby: "",
          valdatatype: "",
          validation_max: 10,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10008",
      _key: "10008",
      _rev: "_eF9cdvy--J",
      activestatus: true,
      createdby: "",
      createddate: 1636347886,
      default_unit: "Kg",
      id: 9,
      longdesc: "Total Weight Loss - Kilogram",
      shortdesc: "Total Weight Loss - Kg",
      status: true,
      units_allowed: [
        {
          _key: "10009",
          _id: "VitalsMeasureCodes/10009",
          _rev: "_eF9ceFG---",
          ObsValMeasCode: "KILOGRAM",
          activestatus: true,
          createdby: "",
          createddate: 1636347030,
          longdesc: "Kilogram",
          shortdesc: "Kg",
          status: true,
          updatedate: 1636347030,
          updatedby: "",
        },
        {
          _key: "10010",
          _id: "VitalsMeasureCodes/10010",
          _rev: "_eF9ceFG--B",
          ObsValMeasCode: "GRAM",
          activestatus: true,
          createdby: "",
          createddate: 1636347033,
          longdesc: "Gram",
          shortdesc: "Gm",
          status: true,
          updatedate: 1636347033,
          updatedby: "",
        },
        {
          _key: "10011",
          _id: "VitalsMeasureCodes/10011",
          _rev: "_eF9ceFG--O",
          ObsValMeasCode: "POUNDS",
          activestatus: true,
          createdby: "",
          createddate: 1636347036,
          longdesc: "Pounds",
          shortdesc: "lb",
          status: true,
          updatedate: 1636347036,
          updatedby: "",
        },
      ],
      updatedate: 1636347886,
      updatedby: "",
      vitalscode: "TWL",
      default_unit_id: "VitalsMeasureCodes/10009",
      mandatory: true,
      type: "number",
    },
    // {
    //   VitalsCodesReferenceRanges: [
    //     {
    //       RefValUOM: "CodeableConceptMaster/10241",
    //       UOM: null,
    //       _id: "VitalsCodesReferenceRanges/10027",
    //       _key: "10027",
    //       _rev: "_eF9cd8i--H",
    //       activestatus: true,
    //       age_maximum: 1440,
    //       age_minimum: 0,
    //       agecategory: "AgeCategory/10000",
    //       agerangecode: "AgeRanges/10000",
    //       createdby: "",
    //       createddate: 1636347886,
    //       gender: "CodeableConceptMaster/10264",
    //       id: 21,
    //       meaningtype: "",
    //       normal_max: 300,
    //       normal_min: 1,
    //       raceappliesto: "",
    //       updatedate: 1636347886,
    //       updatedby: "",
    //       valdatatype: "",
    //       validation_max: 500,
    //       validation_min: 1,
    //     },
    //   ],
    //   _id: "VitalsCodes/10008",
    //   _key: "10008",
    //   _rev: "_eF9cdvy--J",
    //   activestatus: true,
    //   createdby: "",
    //   createddate: 1636347886,
    //   default_unit: "Kg",
    //   id: 9,
    //   longdesc: "Total UF - Kilogram",
    //   shortdesc: "Total UF - Kg",
    //   status: true,
    //   units_allowed: [
    //     {
    //       _key: "10009",
    //       _id: "VitalsMeasureCodes/10009",
    //       _rev: "_eF9ceFG---",
    //       ObsValMeasCode: "KILOGRAM",
    //       activestatus: true,
    //       createdby: "",
    //       createddate: 1636347030,
    //       longdesc: "Kilogram",
    //       shortdesc: "Kg",
    //       status: true,
    //       updatedate: 1636347030,
    //       updatedby: "",
    //     },
    //     {
    //       _key: "10010",
    //       _id: "VitalsMeasureCodes/10010",
    //       _rev: "_eF9ceFG--B",
    //       ObsValMeasCode: "GRAM",
    //       activestatus: true,
    //       createdby: "",
    //       createddate: 1636347033,
    //       longdesc: "Gram",
    //       shortdesc: "Gm",
    //       status: true,
    //       updatedate: 1636347033,
    //       updatedby: "",
    //     },
    //     {
    //       _key: "10011",
    //       _id: "VitalsMeasureCodes/10011",
    //       _rev: "_eF9ceFG--O",
    //       ObsValMeasCode: "POUNDS",
    //       activestatus: true,
    //       createdby: "",
    //       createddate: 1636347036,
    //       longdesc: "Pounds",
    //       shortdesc: "lb",
    //       status: true,
    //       updatedate: 1636347036,
    //       updatedby: "",
    //     },
    //   ],
    //   updatedate: 1636347886,
    //   updatedby: "",
    //   vitalscode: "TUF",
    //   default_unit_id: "VitalsMeasureCodes/10009",
    //   mandatory: true,
    //   type: "number",
    // },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10085",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10000",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "Kt/V",
      shortdesc: "Kt/V",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10000",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "Minutes",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Ktv",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10000",
      mandatory: false,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodingMaster/10244",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10095",
          _key: "10095",
          _rev: "_fIesZT6---",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1669268925,
          gender: "CodeableConceptMaster/10264",
          id: 89,
          meaningtype: "",
          normal_max: 37,
          normal_min: 36,
          raceappliesto: "",
          updatedate: 1669268925,
          updatedby: "",
          valdatatype: "",
          validation_max: 42,
          validation_min: 35,
        },
      ],
      _id: "VitalsCodes/10003",
      _key: "10003",
      _rev: "_fIesZT6--A",
      activestatus: true,
      createdby: "",
      createddate: 1636347527,
      default_unit: "text",
      id: 4,
      longdesc: "Nursing Notes",
      shortdesc: "Nursing Notes",
      status: true,
      units_allowed: [
        {
          _key: "10002",
          _id: "VitalsMeasureCodes/10002",
          _rev: "_eUzupGa---",
          ObsValMeasCode: "text",
          activestatus: true,
          createdby: "",
          createddate: 1636347006,
          longdesc: "text",
          shortdesc: "text",
          status: true,
          updatedate: 1655398506,
          updatedby: "",
        },
      ],
      updatedate: 1669268925,
      updatedby: "",
      vitalscode: "NN",
      default_unit_id: "VitalsMeasureCodes/10002",
      mandatory: true,
      type: "text",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10002",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10002",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      id: 1,
      longdesc: "staff id",
      shortdesc: "Staff ID",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10001",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "text",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "StaffID",
      mandatory: true,
      type: "autocomplete",
      // dropdownvalues: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      // type: "labelName",
      default_unit_id: "VitalsMeasureCodes/10001",
    },
  ],
};

export const treatmentChart = {
  result: [
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10002",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10002",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      id: 1,
      longdesc: "",
      shortdesc: "Last Visit Date",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10001",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "LastVisitDate",
      type: "date",
      default_unit_id: "VitalsMeasureCodes/10001",
      mandatory: false,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10003",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10003",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "",
      shortdesc: "Dialysis Date",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10002",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "DialysisDate",
      type: "date",
      default_unit_id: "VitalsMeasureCodes/10002",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10004",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10004",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "",
      shortdesc: "HD Machine No",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10003",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "HDMachineNo",
      type: "autocomplete",
      dropdownvalues: ["D1", "D2", "D3"],
      // type:"text",
      default_unit_id: "VitalsMeasureCodes/10003",
      mandatory: true,
    },
    //..........
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10004",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10078",
      _key: "10078",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "",
      shortdesc: "Strip Test By",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10003",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "striptest",
      type: "autocomplete",
      dropdownvalues: [],
      // type:"text",
      default_unit_id: "VitalsMeasureCodes/10003",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10006",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10012",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "Kg",
      id: 1,
      longdesc: "",
      shortdesc: "Target Dry Weight",
      groupname: "Weight",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10011",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "TargetDryWeight",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10011",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10007",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 10,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10013",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "Kg",
      id: 1,
      longdesc: "",
      shortdesc: "Target UF",
      groupname: "Weight",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10012",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "TargetUF",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10012",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10008",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10014",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "Kg",
      id: 1,
      longdesc: "",
      shortdesc: "Pre HD Weight",
      groupname: "Weight",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10013",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "PreHDWeight",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10013",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10009",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10015",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "Kg",
      id: 1,
      longdesc: "",
      shortdesc: "Prev Post HD Weight",
      groupname: "Weight",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10014",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "PrevPostHDWeight",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10014",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10010",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 10,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10016",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "i/Kg",
      id: 1,
      longdesc: "",
      shortdesc: "IDWG (Pre last HDi/kg)",
      groupname: "Weight",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10015",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "IDWG",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10015",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10011",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10017",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "P",
      shortdesc: "Shift",
      groupname: "Hd Duration",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10016",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Shift",
      // type: "autocomplete",
      dropdownvalues:[], // ["Afternoon","Evening","Morning","Night"],
      type: "autocomplete",
      default_unit_id: "VitalsMeasureCodes/10016",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10012",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10018",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "/week",
      id: 1,
      longdesc: "",
      shortdesc: "Frequency (/week)",
      groupname: "Hd Duration",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10017",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Frequency",
      // type: "autocomplete",
      dropdownvalues:[], // ["Shift Type 1: 1,3,5","Shift Type 2: 2,4, 6"], //["1", "2", "3"],
      type: "select",
      default_unit_id: "VitalsMeasureCodes/10017",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10013",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 12,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10019",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "Hrs",
      id: 1,
      longdesc: "",
      shortdesc: "Duration (hrs)",
      groupname: "Hd Duration",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10018",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Duration",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10018",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10014",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 500,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10020",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "ml/min",
      id: 1,
      longdesc: "",
      shortdesc: "Qb (ml/min)",
      groupname: "Flow Rate",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10019",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Qb",
      type: "autocomplete",
      default_unit_id: "VitalsMeasureCodes/10019",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10015",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 500,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10021",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "ml/min",
      id: 1,
      longdesc: "",
      shortdesc: "Qd (ml/min)",
      groupname: "Flow Rate",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10020",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Qd",
      type: "autocomplete",
      default_unit_id: "VitalsMeasureCodes/10020",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10016",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10022",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "",
      shortdesc: "AVF needle size",
      groupname: "Flow Rate",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10021",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "AVFneedlesize",
      type: "autocomplete",
      // dropdownvalues: ["14G", "15G", "16G", "17G"],
      // type:"text",
      default_unit_id: "VitalsMeasureCodes/10021",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10017",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10023",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "",
      shortdesc: "Dialyzer Type",
      groupname: "Dialyzer",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10022",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "DialyzerType",
      type: "autocomplete",
      // dropdownvalues: ["High Flux"],
      // type:"text",
      default_unit_id: "VitalsMeasureCodes/10022",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10018",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 5,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10024",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "m²",
      id: 1,
      longdesc: "P",
      shortdesc: "Surface Area",
      groupname: "Dialyzer",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10023",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "SurfaceArea",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10023",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10019",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10025",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "P",
      shortdesc: "Dialysate Ca2+",
      groupname: "Dialyzer",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10024",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "DialysateCa2",
      type: "autocomplete",
      default_unit_id: "VitalsMeasureCodes/10024",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10024",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10026",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "P",
      shortdesc: "No of reuse",
      groupname: "Dialyzer",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10025",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Noofreuse",
      // type: "autocomplete",
      // dropdownvalues: ["1", "2", "3", "4", "5"],
      type: "select",
      default_unit_id: "VitalsMeasureCodes/10025",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10025",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10027",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "",
      shortdesc: "Type",
      groupname: "Heparin",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10026",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Type",
      type: "autocomplete",
      // dropdownvalues: ["Normal Hep"],
      // type:"text",
      default_unit_id: "VitalsMeasureCodes/10026",
      mandatory: true,
    },

    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10025",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10027",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "",
      shortdesc: "Comments",
      groupname: "Heparin",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10026",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "CMD",
      type: "textC",
      // dropdownvalues: ["Normal Hep"],
      // type:"text",
      default_unit_id: "VitalsMeasureCodes/10026",
      mandatory: false,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10026",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10028",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "µ",
      id: 1,
      longdesc: "P",
      shortdesc: "Loading",
      groupname: "Heparin",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10027",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Loading",
      // type: "autocomplete",
      // dropdownvalues: ["500", "1000"],
      type: "select",
      default_unit_id: "VitalsMeasureCodes/10027",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10027",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10029",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "µ",
      id: 1,
      longdesc: "",
      shortdesc: "Maintenance",
      groupname: "Heparin",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10028",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Maintenance",
      // type: "autocomplete",
      // dropdownvalues: ["500", "1000", "1500"],
      type: "select",
      default_unit_id: "VitalsMeasureCodes/10028",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10002",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10002",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      id: 1,
      longdesc: "staff id",
      shortdesc: "Staff ID",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10001",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "text",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "StaffID",
      mandatory: true,
      type: "autocomplete",
      // dropdownvalues: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      // type: "labelName",
      default_unit_id: "VitalsMeasureCodes/10001",
    },
  ],
};
export const treatmentPlan = {
  results: [
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10021",
          _key: "10029",
          _rev: "_eF9cd8m--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1637129702,
          gender: "CodeableConceptMaster/10264",
          id: 23,
          meaningtype: "",
          normal_max: 120,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1637129702,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 10,
        },
      ],
      _id: "VitalsCodes/10008",
      _key: "10001",
      _rev: "_erjj-yq---",
      activestatus: true,
      createdby: "",
      createddate: 1636347376,
      default_unit: "",
      id: 2,
      longdesc: "Vascular Access",
      shortdesc: "Vascular Access",
      groupname: "Common Field",
      status: true,
      units_allowed: [
        {
          _key: "10004",
          _id: "VitalsMeasureCodes/10007",
          _rev: "_eF9ceFG--D",
          ObsValMeasCode: "",
          activestatus: true,
          createdby: "",
          createddate: 1636347013,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1636347013,
          updatedby: "",
        },
      ],
      updatedate: 1661504652,
      updatedby: "",
      vitalscode: "TVA",
      default_unit_id: "VitalsMeasureCodes/10007",
      type: "text",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10006",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 10,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 250,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10012",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "Kg",
      id: 1,
      longdesc: "",
      shortdesc: "Dry Weight",
      groupname: "Common Field",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10011",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "TargetDryWeight",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10011",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10016",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10022",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "",
      shortdesc: "Needle size",
      groupname: "Flow Rate",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10021",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "AVFneedlesize",
      type: "autocomplete",
      // dropdownvalues: ["14G", "15G", "16G", "17G"],
      // type:"text",
      default_unit_id: "VitalsMeasureCodes/10021",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10013",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 12,
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10019",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "Hrs",
      id: 1,
      longdesc: "",
      shortdesc: "Duration (hrs)",
      groupname: "Hd Duration",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10018",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Duration",
      type: "number",
      default_unit_id: "VitalsMeasureCodes/10018",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10012",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10018",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "/week",
      id: 1,
      longdesc: "",
      shortdesc: "Frequency (/week)",
      groupname: "Hd Duration",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10017",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Frequency",
      // type: "autocomplete",
      dropdownvalues: [], //["Shift Type 1: 1,3,5","Shift Type 2: 2,4, 6"],
      type: "select",
      default_unit_id: "VitalsMeasureCodes/10017",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10011",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10017",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "P",
      shortdesc: "Shift",
      groupname: "Hd Duration",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10016",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Shift",
      type: "autocomplete",
      dropdownvalues: [], //["Shift Type 1: 1,3,5","Shift Type 2: 2,4, 6"],
      // type:"text",
      default_unit_id: "VitalsMeasureCodes/10016",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodeableConceptMaster/10241",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10027",
          _key: "10027",
          _rev: "_eF9cd8i--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1636347886,
          gender: "CodeableConceptMaster/10264",
          id: 21,
          meaningtype: "",
          normal_max: 300,
          normal_min: 1,
          raceappliesto: "",
          updatedate: 1636347886,
          updatedby: "",
          valdatatype: "",
          validation_max: 500,
          validation_min: 1,
        },
      ],
      _id: "VitalsCodes/10008",
      _key: "10008",
      _rev: "_eF9cdvy--J",
      activestatus: true,
      createdby: "",
      createddate: 1636347886,
      default_unit: "mL/min",
      id: 9,
      longdesc: "Qb - mL/min",
      shortdesc: "Qb - mL/min",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10009",
          _id: "VitalsMeasureCodes/10009",
          _rev: "_eF9ceFG---",
          ObsValMeasCode: "mL/min",
          activestatus: true,
          createdby: "",
          createddate: 1636347030,
          longdesc: "mL/min",
          shortdesc: "mL/min",
          status: true,
          updatedate: 1636347030,
          updatedby: "",
        },
        {
          _key: "10010",
          _id: "VitalsMeasureCodes/10010",
          _rev: "_eF9ceFG--B",
          ObsValMeasCode: "mL/min",
          activestatus: true,
          createdby: "",
          createddate: 1636347033,
          longdesc: "mL/min",
          shortdesc: "mL/min",
          status: true,
          updatedate: 1636347033,
          updatedby: "",
        },
        {
          _key: "10011",
          _id: "VitalsMeasureCodes/10011",
          _rev: "_eF9ceFG--O",
          ObsValMeasCode: "mL/min",
          activestatus: true,
          createdby: "",
          createddate: 1636347036,
          longdesc: "mL/min",
          shortdesc: "mL/min",
          status: true,
          updatedate: 1636347036,
          updatedby: "",
        },
      ],
      updatedate: 1636347886,
      updatedby: "",
      vitalscode: "QB",
      default_unit_id: "VitalsMeasureCodes/10009",
      mandatory: true,
      // type: "autocomplete",
      // dropdownvalues: ["150", "200", "250", "300", "350", "400", "450"],
      type: "select",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "CodeableConceptMaster/10241",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10027",
          _key: "10027",
          _rev: "_eF9cd8i--H",
          activestatus: true,
          age_maximum: 1440,
          age_minimum: 0,
          agecategory: "AgeCategory/10000",
          agerangecode: "AgeRanges/10000",
          createdby: "",
          createddate: 1636347886,
          gender: "CodeableConceptMaster/10264",
          id: 21,
          meaningtype: "",
          normal_max: 300,
          normal_min: 1,
          raceappliesto: "",
          updatedate: 1636347886,
          updatedby: "",
          valdatatype: "",
          validation_min: 0,
        },
      ],
      _id: "VitalsCodes/10008",
      _key: "10008",
      _rev: "_eF9cdvy--J",
      activestatus: true,
      createdby: "",
      createddate: 1636347886,
      default_unit: "mL/min",
      id: 9,
      longdesc: "Qd - mL/min",
      shortdesc: "Qd - mL/min",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10009",
          _id: "VitalsMeasureCodes/10009",
          _rev: "_eF9ceFG---",
          ObsValMeasCode: "mL/min",
          activestatus: true,
          createdby: "",
          createddate: 1636347030,
          longdesc: "mL/min",
          shortdesc: "mL/min",
          status: true,
          updatedate: 1636347030,
          updatedby: "",
        },
        {
          _key: "10010",
          _id: "VitalsMeasureCodes/10010",
          _rev: "_eF9ceFG--B",
          ObsValMeasCode: "mL/min",
          activestatus: true,
          createdby: "",
          createddate: 1636347033,
          longdesc: "mL/min",
          shortdesc: "mL/min",
          status: true,
          updatedate: 1636347033,
          updatedby: "",
        },
        {
          _key: "10011",
          _id: "VitalsMeasureCodes/10011",
          _rev: "_eF9ceFG--O",
          ObsValMeasCode: "mL/min",
          activestatus: true,
          createdby: "",
          createddate: 1636347036,
          longdesc: "mL/min",
          shortdesc: "mL/min",
          status: true,
          updatedate: 1636347036,
          updatedby: "",
        },
      ],
      updatedate: 1636347886,
      updatedby: "",
      vitalscode: "QD",
      default_unit_id: "VitalsMeasureCodes/10009",
      mandatory: true,
      type: "select",
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10085",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10000",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "Heparin",
      shortdesc: "Heparin",
      groupname: "Hourly Monitoring",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10000",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "Minutes",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "Heparin",
      // type: "autocomplete",
      // dropdownvalues: ["500", "1500", "5000"],
      type: "autocomplete",
      default_unit_id: "VitalsMeasureCodes/10000",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10017",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10023",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "",
      shortdesc: "Dialyzer",
      groupname: "Dialyzer",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10022",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "DialyzerType",
      type: "autocomplete",
      // dropdownvalues: ["High Flux"],
      // type:"text",
      default_unit_id: "VitalsMeasureCodes/10022",
      mandatory: true,
    },
    {
      VitalsCodesReferenceRanges: [
        {
          RefValUOM: "",
          UOM: null,
          _id: "VitalsCodesReferenceRanges/10019",
          _key: "10085",
          _rev: "_edB4fMi--A",
          activestatus: true,
          age_maximum: 24,
          age_minimum: 13,
          agecategory: "AgeCategory/10004",
          agerangecode: "AgeRanges/10002",
          createdby: "",
          createddate: 1657605358,
          gender: "CodeableConceptMaster/10264",
          id: 79,
          meaningtype: "",
          normal_max: 200,
          normal_min: 130,
          raceappliesto: "",
          updatedate: 1657605358,
          updatedby: "",
          valdatatype: "",
          validation_max: 200,
          validation_min: 20,
        },
      ],
      _id: "VitalsCodes/10025",
      _key: "10000",
      _rev: "_edB4fMm--I",
      activestatus: true,
      createdby: "",
      createddate: 1636347317,
      default_unit: "",
      id: 1,
      longdesc: "P",
      shortdesc: "Dialysate",
      groupname: "Dialysate",
      status: true,
      units_allowed: [
        {
          _key: "10000",
          _id: "VitalsMeasureCodes/10024",
          _rev: "_edB4ZhC---",
          ObsValMeasCode: "MINUTES",
          activestatus: true,
          createdby: "",
          createddate: 1636347000,
          longdesc: "",
          shortdesc: "Min",
          status: true,
          updatedate: 1657605352,
          updatedby: "",
        },
      ],
      updatedate: 1657605358,
      updatedby: "",
      vitalscode: "DialysateCa2",
      type: "autocomplete",
      default_unit_id: "VitalsMeasureCodes/10024",
      mandatory: true,
    },
    // {
    //   VitalsCodesReferenceRanges: [
    //     {
    //       RefValUOM: "CodingMaster/10244",
    //       UOM: null,
    //       _id: "VitalsCodesReferenceRanges/10095",
    //       _key: "10095",
    //       _rev: "_fIesZT6---",
    //       activestatus: true,
    //       age_maximum: 1440,
    //       age_minimum: 0,
    //       agecategory: "AgeCategory/10000",
    //       agerangecode: "AgeRanges/10000",
    //       createdby: "",
    //       createddate: 1669268925,
    //       gender: "CodeableConceptMaster/10264",
    //       id: 89,
    //       meaningtype: "",
    //       normal_max: 37,
    //       normal_min: 36,
    //       raceappliesto: "",
    //       updatedate: 1669268925,
    //       updatedby: "",
    //       valdatatype: "",
    //       validation_max: 100,
    //       validation_min: 0,
    //     },
    //   ],
    //   _id: "VitalsCodes/10003",
    //   _key: "10003",
    //   _rev: "_fIesZT6--A",
    //   activestatus: true,
    //   createdby: "",
    //   createddate: 1636347527,
    //   default_unit: "",
    //   id: 4,
    //   longdesc: "Buffer",
    //   shortdesc: "Buffer",
    //   groupname: "Hourly Monitoring",
    //   status: true,
    //   units_allowed: [
    //     {
    //       _key: "10002",
    //       _id: "VitalsMeasureCodes/10002",
    //       _rev: "_eUzupGa---",
    //       ObsValMeasCode: "text",
    //       activestatus: true,
    //       createdby: "",
    //       createddate: 1636347006,
    //       longdesc: "text",
    //       shortdesc: "text",
    //       status: true,
    //       updatedate: 1655398506,
    //       updatedby: "",
    //     },
    //   ],
    //   updatedate: 1669268925,
    //   updatedby: "",
    //   vitalscode: "CONDUCTIVITY",
    //   default_unit_id: "VitalsMeasureCodes/10002",
    //   mandatory: true,
    //   type: "text",
    // },
  ],
};
