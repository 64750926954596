import React from "react";
import { Div, Select, Col, Row, Paper, H6, Text } from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../hoc/withAllContexts";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { UIColor } from "../../themes/theme";
import { Grid, IconButton } from "@material-ui/core";
import Addicon from "../../assets/img/icons8-add.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import actions from "../../redux/actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Delicon from "../../assets/img/svg/deleteIcons.svg"
function RefDetails(props) {
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    selectBorderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorselectBorderStyle: {
      borderRadius: 8,
    },
  };

  // const { details } = props.referralData;
  const { refDetails, setRefDetails } = props;
  console.log("refDetails", refDetails);
  const location = useLocation();
  const dispatch = useDispatch();
  const viewMode = location?.state?.isERefView;
  const selector = useSelector((state) => {
    console.log(state);
    return {
      diagnosisname: state?.TransferandSchedule?.daignosisName?.data || [],
      diagnosistype:
        state?.TransferandSchedule?.daignosisTypeANDConsent?.data
          ?.DIAGNOSISTYPE || [],
    };
  });
  const getData = async () => {
    dispatch(actions.DAIGNOSIS_NAME());
    dispatch(actions.DAIGNOSIS_TYPE_AND_CONSENT());
  };
  useEffect(() => {
    getData();
  }, []);
  const setDetails = (name, value) => {
    setRefDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (refDetails.error) {
      refDetails.error[name] = false;
    }
    setRefDetails({
      ...refDetails,
      [name]: value,
    });
  };

  const HandleChangeDialysis = (type, name, value, index) => {
    
    try {
      let details = JSON.parse(JSON.stringify(refDetails));
      let diagnosis = details?.diagnosis;
      if (type === "add") {
        diagnosis = [
          ...diagnosis,
          {
            diagnosisName: {},
            diagnosisType: {},
          },
        ];
      } else if (type === "change") {
        diagnosis[index][name] = value;
      } else if (type === "delete") {
        diagnosis = diagnosis.filter((v, i) => index !== i);
      }
      setRefDetails({...refDetails,diagnosis})
    } catch (error) {}
  };
  // const FlterOptions = (type) => {
  //     const { allMasters } = props;
  //     var lista = []
  //     const data = allMasters?.[type]
  //     for (let index in data) {
  //         var datatemp = {
  //             "value": data[index]?.coding?.display,
  //         };
  //         lista.push(datatemp);
  //     }
  //     return lista
  // }

  const { labelStyle, selectBorderStyle, errorselectBorderStyle } = styles;
  const { parent_id } = props;

  const FlterOptions3 = (props, type) => {
    let filteredArray = props.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?.display,
        _id: data[index]?._id,
      };

      list.push(datatemp);
    }

    return list;
  };
  return (
    <Div id={`${parent_id}_refdetails_parent_div`} className="pv-root">
      <Paper id={`${parent_id}_refdetails_parent_paper`} className="pl-root">
        <Grid
          conatiner
          id={`${parent_id}_refdetails_parent_col`}
          md={12}
          lg={12}
          sm={12}
          xs={12}
          className="no-padding-left"
        >
          <Div id={`${parent_id}_refdetails_title_div`}>
            <H6
              id={`${parent_id}_refdetails_label_h6`}
              className="en-title space-padding-bottom"
            >
              REFERRAL DETAILS
            </H6>
          </Div>
          <Grid container xs={12} spacing={3} style={{ paddingLeft: "12px" }}>
            <Grid
              item
              id={`${parent_id}_refdetails_intent_type_col`}
              xs={2}
              className="no-padding-left"
            >
              <Select
                id={`${parent_id}_refdetails_intent_type_select`}
                isRequired
                placeholder="select..."
                options={FlterOptions3(props?.allMasters, "INTENTTYPE")}
                // helperText={(details?.error && details?.error['intent_type']) ? 'Is required' : ''}
                error={
                  refDetails?.error && refDetails?.error["intentType"]
                    ? true
                    : false
                }
                value={refDetails?.intentType?.value}
                onChangeValue={(e) => setDetails("intentType", e)}
                label="Intent Type"
                showArrow
                labelStyle={labelStyle}
                inLineStyles={
                  refDetails?.error && refDetails?.error["intentType"]
                    ? errorselectBorderStyle
                    : selectBorderStyle
                }
                hoverColor={UIColor.primaryColor.color}
                disabled={viewMode}
              />
            </Grid>
            {/* <Grid item
                id={`${parent_id}_refdetails_for_col`}
                xs={1}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_refdetails_for_select`}
                  isRequired
                  options={FlterOptions3(props?.allMasters, "REFERRALFOR")}
                  // helperText={(details?.error && details?.error['for']) ? 'Is required' : ''}
                  error={
                    (refDetails?.error && refDetails?.error['for']) ? true :
                    false
                  }
                  placeholder="select..."
                  value={refDetails?.for?.value}
                  onChangeValue={(e) => setDetails("for", e)}
                  label="For"
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={
                    (refDetails?.error && refDetails?.error['for']) ? errorselectBorderStyle :
                    selectBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  disabled={viewMode}
                />
              </Grid> */}
            <Grid
              item
              id={`${parent_id}_refdetails_priority_col`}
              xs={2}
              className="no-padding-left"
            >
              <Select
                id={`${parent_id}_refdetails_priority_select`}
                isRequired
                options={FlterOptions3(props?.allMasters, "LABPRIORITY")}
                // helperText={(details?.error && details?.error['priority']) ? 'Is required' : ''}
                error={
                  refDetails?.error && refDetails?.error["priority"]
                    ? true
                    : false
                }
                placeholder="select..."
                value={refDetails?.priority?.value}
                onChangeValue={(e) => setDetails("priority", e)}
                label="Priority"
                showArrow
                labelStyle={labelStyle}
                inLineStyles={
                  refDetails?.error && refDetails?.error["priority"]
                    ? errorselectBorderStyle
                    : selectBorderStyle
                }
                hoverColor={UIColor.primaryColor.color}
                disabled={viewMode}
              />
            </Grid>
            <Grid
              item
              id={`${parent_id}_refdetails_request_reason_col`}
              xs={2}
              className="no-padding-left"
            >
              <Select
                id={`${parent_id}_refdetails_request_reason_select`}
                options={FlterOptions3(props?.allMasters, "REASON")}
                placeholder="select..."
                isRequired
                error={
                  refDetails?.error && refDetails?.error["requestReason"]
                    ? true
                    : false
                }
                value={refDetails?.requestReason?.value}
                onChangeValue={(e) => setDetails("requestReason", e)}
                label="Reason For Referral"
                showArrow
                labelStyle={labelStyle}
                inLineStyles={
                  refDetails?.error && refDetails?.error["requestReason"]
                    ? errorselectBorderStyle
                    : selectBorderStyle
                }
                hoverColor={UIColor.primaryColor.color}
                disabled={viewMode}
              />
            </Grid>
            <Grid
              item
              id={`${parent_id}_refdetails_service_requested_col`}
              xs={6}
              className="no-padding-left"
            >
              <Text
                id={`${parent_id}_refdetails_service_requested_text`}
                style={{
                  color: "#6F6F6F",
                  fontSize: "12px",
                  marginBottom: "6px",
                  fontFamily: "pc_regular",
                }}
              >
                Service Requested
                <span style={{ marginLeft: "5px", color: UIColor.error.color }}>
                  *
                </span>
              </Text>
              <Autocomplete
                id={`${parent_id}_refdetails_service_requested_autocomplete`}
                isRequired
                multiple
                fullWidth
                value={refDetails?.serviceRequested ?? []}
                onChange={(e, v) => setDetails("serviceRequested", v)}
                options={FlterOptions3(props?.allMasters, "SERVICE")}
                getOptionLabel={(option) => option.value}
                disabled={viewMode}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      id={`${parent_id}_${index}_refdetails_service_requested_chip`}
                      style={{ height: "22px", borderRadius: 8 }}
                      label={option.value}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    id={`${parent_id}_refdetails_service_requested_error_txtfield`}
                    // helperText={(details?.error && details?.error['service_requested']) ? 'Is required' : ''}
                    error={
                      refDetails?.error && refDetails?.error["serviceRequested"]
                        ? true
                        : false
                    }
                    //     inLineStyles={
                    //   (refDetails?.error && refDetails?.error['serviceRequested']) ? errorselectBorderStyle :
                    //   selectBorderStyle
                    // }
                    {...params}
                    label={false}
                    variant="outlined"
                    placeholder="select..."
                    size="small"
                  />
                )}
              />
              {/* <MultiSelect
                                options={[
                                    { label: "SR001", value: "Occupational therapy" },
                                    { label: "SR002", value: "Treatment" },
                                    { label: "SR003", value: "Assessment" },
                                    { label: "SR004", value: "Physiotherapy" },
                                    { label: "SR005", value: "Acupuncture" },
                                    { label: "SR006", value: "Consultation" },
                                    { label: "SR007", value: "Immunization" },
                                    { label: "SR008", value: "Dressing" },
                                    { label: "SR009", value: "Oral Medicine" },
                                    { label: "SR010", value: "Drug and/or alcohol counselling" },
                                    { label: "SR011", value: "Plan Management" },
                                    { label: "SR012", value: "X-Ray" },
                                    { label: "SR013", value: "CT" },
                                    { label: "SR014", value: "MRI" },
                                    { label: "SR015", value: "Ambulance" },
                                    { label: "SR016", value: "Yoga" },
                                    { label: "SR017", value: "Blood Donation" },
                                    { label: "SR018", value: "Surgery" },
                                    { label: "SR019", value: "Chemotherapy" },
                                    { label: "SR020", value: "Oral Medicine" },
                                    { label: "SR021", value: "Scaling" },
                                    { label: "SR022", value: "Root Canal Treatment" },
                                    { label: "SR023", value: "Anaesthesia" },
                                    { label: "SR024", value: "Dialysis" },
                                    { label: "SR025", value: "Birth Control" },
                                    { label: "SR026", value: "Depression Control" },
                                    { label: "SR027", value: "Detoxification" },
                                    { label: "SR028", value: "Exercise" },
                                    { label: "SR029", value: "Home Visit" },
                                    { label: "SR030", value: "Pathology - Clinical Chemistry" },
                                    { label: "SR031", value: "Pathology - Haematology" },
                                    { label: "SR032", value: "Pathology - Immunology" },
                                    { label: "SR033", value: "Pathology - Microbiology" },
                                ]} placeholder='select...' 
                                value={details.service_requested?.value}
                                 onChangeValue={e => setDetails("service_requested", e)} label="Service Requested" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} /> */}
            </Grid>
            <Grid container item spacing={2}>
            {refDetails?.diagnosis?.map((v, i) => (
              <Grid container item key={i + "diagnosis"} spacing={2}>
                <Grid
                  item
                  id={`${parent_id}_refdetails_request_reason_col`}
                  xs={4}
                  className="no-padding-left"
                >
                  <Select
                    id={`${parent_id}_refdetails_request_reason_select`}
                    options={selector?.diagnosisname}
                    isRequired
                    error={refDetails?.error?.diagnosis?.[i]?.diagnosisName
                    }
                    placeholder="select..."
                    value={refDetails?.diagnosis?.[i]?.diagnosisName?.value}
                    onChangeValue={(e) => HandleChangeDialysis("change","diagnosisName", e, i)}
                    label="Diagnosis Name"
                    showArrow
                    labelStyle={labelStyle}
                    hoverColor={UIColor.primaryColor.color}
                    inLineStyles={
                      refDetails?.error?.diagnosis?.[i]?.diagnosisName
                        ? errorselectBorderStyle
                        : selectBorderStyle
                    }
                    disabled={viewMode}
                  />
                </Grid>
                <Grid
                  item
                  id={`${parent_id}_refdetails_request_reason_col`}
                  xs={2}
                  className="no-padding-left"
                >
                  <Select
                    id={`${parent_id}_refdetails_request_reason_select`}
                    options={selector?.diagnosistype}
                    placeholder="select..."
                    value={refDetails?.diagnosis?.[i]?.diagnosisType?.value}
                    onChangeValue={(e) => HandleChangeDialysis("change","diagnosisType", e, i)}
                    label="Diagnosis Type"
                    showArrow
                    labelStyle={labelStyle}
                    inLineStyles={selectBorderStyle}
                    hoverColor={UIColor.primaryColor.color}
                    disabled={viewMode}
                  />
                </Grid>
                {i===0&&<Grid item xs={2} style={{ marginTop: "20px" }}>
                  <IconButton onClick={() => {HandleChangeDialysis("add")}}  disabled={viewMode}>
                    <img src={Addicon} alt="Add" />
                  </IconButton>
                </Grid>}
                {(i!==0) &&<Grid item xs={2} style={{ marginTop: "20px" }}>
                  <IconButton onClick={() => {HandleChangeDialysis("delete","","",i)}}  disabled={viewMode}>
                    <img src={Delicon} alt="delete" />
                  </IconButton>
                </Grid>}
              </Grid>
            ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Div>
  );
}

export default withAllContexts(RefDetails);
