import React, { isValidElement, useContext, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "../../redux/actions";
import useStyles from "./styles";
import {
  Div,
  Button,
  Paper,
  H6,
  TextInput,
  ColProps,
} from "qdm-component-library";
import { UIColor } from "../../themes/theme";
import { AlertProps } from "../../utils/constants";
import { AppRoutes } from "../../router/routes";
import {
  checkError,
  getUserInfo,
  isFullScreen,
} from "../../utils/helperFunction";
import { AlertContext, AuthContext } from "../../context";
import withAllContexts from "../../hoc/withAllContexts";
import WithUnsecurePage from "../../hoc/withUnsecurePage";
import { __tenantid__, urls } from "../../utils";
import jwtDecode from "jwt-decode";
import LocationRoles from "../../components/locationAndRoles/locationRoles";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Loading from "../../components/global/loading";

const SignIn = (props) => {
  const alertContext = useContext(AlertContext);
  const classes = useStyles(props);
  const navigate = useNavigate();
  const auth = useContext(AuthContext)
  const [state, setState] = useState({ userId: "", password: "", error: "" });
  const [roleOpen, setRoleOpen] = React.useState(false);
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [roleEmail, setRoleEmail] = useState("");
  React.useEffect(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("role_name") &&
      localStorage.getItem("role_data")
    ) {
      navigate(AppRoutes.dashboard);
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("role_name");
      localStorage.removeItem("role_data");
    }
  }, []);

  const dispatch = useDispatch();

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const practitionerLogin = useSelector((state) => state?.practitionerLogin);

  const handlesendOtp = async () => {
    const storedUserId = state.userId;
    const storedPassword = state.password;

    if (state?.userId && state?.password) {
      
      setState({ error: "" });
      setRoleEmail(state?.userId);
      const payloadlogin = {
        username: state?.userId,
        password: state?.password,
        tenantid: __tenantid__,
      };
      const res = await dispatch(actions.ORGANIZATION_SIGIN_IN(payloadlogin));
      //const res = await dispatch(actions.PRACTITIONER_LOGIN(payloadlogin));

      const status = checkError(res.payload);

      if (status.isError) {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: status.errMsg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        setState({
          error: status.errMsg,
        });
      } else {
        setState({ userId: "", password: "", error: "" });
        const roleData = res?.payload?.data?.response?.role_data;
        const permissionData = res?.payload?.data?.response?.permissionData;
        const userInfo = res?.payload?.data?.response?.userinfo;
        const token = res?.payload?.data?.response?.token;
        const jwtToken = res?.payload?.data?.response?.jwttoken;

        if (roleData && permissionData && userInfo && jwtToken) {
          const decodedUserinfo = jwtDecode(userInfo);

          localStorage.setItem("RoleData", roleData);
          localStorage.setItem("PermissionData", permissionData);
          localStorage.setItem("UserInfo", userInfo);
          localStorage.setItem("Token", token);
          localStorage.setItem("JwtToken", jwtToken);
          localStorage.setItem("RoleName", decodedUserinfo.rolename);
          // isFullScreen(true);
          
          auth.RefreshTokenFunc()
          navigate({
            pathname: AppRoutes.dashboard,
          });
        } else if (
          token &&
          !roleData &&
          !permissionData &&
          !userInfo &&
          !jwtToken
        ) {
          handleRoleClick();
          localStorage.setItem("Token", token);
        }
      }
    } else {
      const errorMessage = "Please fill all the mandatory fields !";
      alertContext.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: errorMessage,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      setState({
        error: errorMessage,
      });
    }
    setState({
      userId: storedUserId,
      password: storedPassword,
    });
  };
  const handleRoleClick = () => {
    setRoleOpen(true);
  };
  const handleRoleClose = () => {
    setRoleOpen(false);
  };
  const getPractitionerId = () => {
    // const decodedUserinfo = jwtDecode("userInfo");
    return roleEmail;
  };
  const onNextClick = async (
    location,
    role,
    facilitystate,
    organisation,
    enterprise,
    rolestate,
    practitionerCode,
    org_id
  ) => {
    setLoadingOpen(true);
    setRoleOpen(false);
    if (roleEmail && role && facilitystate&&location) {
      const payloadlogin = {
        username: roleEmail,
        tenantid: __tenantid__,
        roleid: role?.id,
        locationid: location?.id,
        facilityid: facilitystate?.id,
      };
      let token = localStorage.getItem("Token");
      let register = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
        url: urls.practitionerLogin,
        data: payloadlogin,
      })
        .then((res) => {
          setLoadingOpen(false);
          if (res?.data?.statusCode === 201 && !res.data.error) {
            const status = checkError(res);

            if (status.isError) {
              alertContext.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: status.errMsg,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });
            } else {
              const roleData = res?.data?.response?.role_data;
              const permissionData = res?.data?.response?.permissionData;
              const userInfo = res?.data?.response?.userinfo;
              const token = res?.data?.response?.token;
              const jwtToken = res?.data?.response?.jwttoken;
              if (roleData && permissionData && userInfo && token && jwtToken) {
                localStorage.removeItem("Token");
                localStorage.removeItem("RoleData");
                localStorage.removeItem("PermissionData");
                localStorage.removeItem("UserInfo");
                localStorage.removeItem("JwtToken");
                localStorage.removeItem("RoleName");
                const decodedUserinfo = jwtDecode(userInfo);
                localStorage.setItem("RoleData", roleData);
                localStorage.setItem("PermissionData", permissionData);
                localStorage.setItem("UserInfo", userInfo);
                localStorage.setItem("Token", token);
                localStorage.setItem("JwtToken", jwtToken);
                localStorage.setItem("RoleName", decodedUserinfo.rolename);
                isFullScreen(true);
                auth.RefreshTokenFunc()
                navigate({
                  pathname: AppRoutes.dashboard,
                });
                // setRoleOpen(false);
              } else {
                alertContext.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: "Access not found",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.right,
                });
                // setRoleOpen(false);
              }
            }
          } else {
            alertContext.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: res.data.message,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
          }
        })
        .catch((error) => {
          alertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please contact to admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        });
    }
  };

  return (
    <Paper
      key={"0"}
      children="Paper"
      variant="elevation"
      elevation="1"
      style={{
        width: "350px",
        // height: "400px",
        backgroundColor: "#ffffff",
        borderRadius: "16px",
        padding: "32px",
        boxShadow: "0px 8px 69px #0000001A",
        display: "",
      }}
      id={"signIn-Paper"}
    >
      <H6
        key={"0"}
        name="Sign In"
        style={{
          fontSize: "24px",
          fontWeight: "500",
          color: "#101010",
        }}
        inLineStyles={{ fontFamily: "pc_semibold !important" }}
        id={"signIn-H6-Sign-In"}
      />

      <H6
        className="pc_regular"
        key={"1"}
        name="Sign In with your registered user ID"
        style={{
          fontSize: "12px",
          color: "#6F6F6F",
          textAlign: "left",
          marginBottom: "30px",
          marginTop: "10px",
        }}
        id={"signIn-H6-Sign-in-registered"}
      />
      <TextInput
        isRequired={true}
        label="User Id"
        placeholder=""
        labelStyle={classes.labelStyle}
        style={{
          borderRadius: 8,
          borderColor: "#E0E0E0",
          marginBottom: 10,
        }}
        hoverColor={UIColor.primaryColor.color}
        value={state.userId}
        onChange={(e) => changeState("userId", e.target.value)}
        id={"signIn-H6-User-Id-registered"}
      />

      <Div style={{ position: "relative" }}>
        <TextInput
          isRequired={true}
          type="password"
          label="Password"
          placeholder=""
          labelStyle={classes.labelStyle}
          style={{
            borderRadius: 8,
            borderColor: "#E0E0E0",
            marginBottom: 10,
          }}
          hoverColor={UIColor.primaryColor.color}
          value={state.password}
          onChange={(e) => changeState("password", e.target.value)}
          id={"signIn-H6-User-Password-registered"}
        />
        <Div style={{ position: "absolute", right: 0, top: 0 }}>
          <H6
            onClick={() => navigate(AppRoutes.forgotPassword)}
            style={{
              fontSize: "12px",
              marginBottom: "6px",
              fontFamily: `pc_regular !important`,
              color: UIColor.secondaryColor.color,
              cursor: "pointer",
            }}
            id={"signIn-H6-User-forgotPassword"}
          >
            Forgot Password?
          </H6>
        </Div>
      </Div>
      {state.error && (
        <H6
          name={state.error}
          style={{
            fontSize: "12px",
            color: "red",
            textAlign: "left",
          }}
          id={"signIn-H6-error"}
        />
      )}
      <Button
        className="pc_medium"
        key={"3"}
        onClick={() => handlesendOtp()}
        disabled={actions.oraganizationSignin?.loading}
        type="button"
        variant="outlined"
        name={actions.oraganizationSignin?.loading ? "Sign In..." : "Sign In"}
        style={{
          margin: "",
          marginTop: "25px",
          width: "100%",
          backgroundColor: UIColor.primaryColor.color,
          borderColor: UIColor.primaryColor.color,
          borderRadius: "8px",
          fontSize: "14px",
          height: "40px",
        }}
        id={"signIn-button-handlesendOtp"}
      />
      <Dialog
        open={roleOpen}
        // onClose={handleRoleClose}
        // classes={{ paper: classes.RoledialogPaper }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ padding: "0px" }}
      >
        <DialogTitle id="alert-dialog-title" style={{ paddingBottom: "0px" }}>
          Select Location and Role
          <IconButton
            aria-label="close"
            onClick={handleRoleClose}
            style={{ position: "absolute", right: 0, top: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "0px",
            paddingBottom: "10px",
            paddingRight: "20px",
          }}
        >
          <LocationRoles
            practitonerId={getPractitionerId()}
            onNextClick={onNextClick}
          />
        </DialogContent>
      </Dialog>
      <Loading
      loading={loadingOpen}
      style={{
        margin: "auto",
        position: "absolute",
        left: "45%",
        top: "35%",
      }}
    >
      </Loading>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  oraganizationSignin: state?.OrganizationAuthApiSlice?.oraganizationSignin,
  getFacilityByUserid: state?.authSlice?.getFacilityByUserid,
  getIsNurseTriageRequiredByFacility:
    state?.authSlice?.getIsNurseTriageRequiredByFacility,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(WithUnsecurePage(SignIn)));

//export default withAllContexts(Login);
