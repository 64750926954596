import { AppRoutes } from "./routes";
import SignIn from "../screens/authentication/signIn";
import ForgetPassword from "../screens/authentication/forgetPassword";
import ChangePassword from "../screens/authentication/changePassword";
import ResetPassword from "../screens/authentication/resetPassword";
import LinkExpired from "../screens/authentication/linkExpired";
import Dashboard from "../screens/dashboard";
import FinanceRegistration from "../screens/financeRegistration";
import VisitRegistration from "../screens/visitRegistration";
import ConfirmBooking from "../screens/confirmBooking";
import PatientRegistration from "../screens/patientRegistration/patientRegistration";
import PatientDetails, { PatientDetailWithAppBar } from "../screens/patientDetail";
import AppointmentBooking from "../screens/appointmentBooking";
import TestComponent from "../screens/testComponent";
import searchFacility from "../screens/searchFacility";
import PrivateRoute from "./privateRouter";
import PatientDashboard from "../screens/patientDashboard/patientDashboard";
import SearchFacility from "../screens/searchFacility";
import cashierBilling from "../screens/cashierBillingDetails";
import { CashierBillingSummary } from "../screens/cashierSummary";
import TreatmentPlans from "../screens/treatmentPlan";
import ClinicalInfo from "../screens/clinicalInfo";
import ReferralRegistration from "../screens/ReferralRegistration";
import CashierCollectionSummary from "../screens/cashierCollections";
import DashboardLanding from "../screens/dashboardLanding";
import PatientReferral from "../screens/patientReferral";
import IFrames from "../screens/iFrames";
import PaymentCollections from "../screens/paymentCollections";
import AdvancePayment from "../screens/advancePayment";
import eRefEMRscreen from "../screens/eRefferal/eRefEMRscreen";
import ERefEMRscreen from "../screens/eRefferal/eRefEMRscreen";
export let tenantRoutes = {
  "4567": [
    { "path": "/", "component": <SignIn/> },
    { "path": AppRoutes.dashboard, "component": <Dashboard/> },
    { "path": AppRoutes.patientRegistration, "component": <PatientRegistration/> },
    { "path": AppRoutes.financeRegistration, "component": <FinanceRegistration/> },
    { "path": AppRoutes.referralInRegistration, "component": <ReferralRegistration/> },
    { "path": AppRoutes.forgotPassword, "component": <ForgetPassword/> },
    { "path": AppRoutes.changePassword, "component": <ChangePassword/> },
    { "path": AppRoutes.expired, "component": <LinkExpired/> },
    { "path": AppRoutes.resetPassword, "component": <ResetPassword/> },
    { "path": AppRoutes.visitRegistration, "component": <VisitRegistration/> },
    { "path": AppRoutes.PatientDashBoard, "component": <PatientDashboard/> },
    { "path": AppRoutes.confirmBooking, "component": <ConfirmBooking/> },
    { "path": AppRoutes.patientDetail, "component": <PatientDetailWithAppBar/> },
    { "path": AppRoutes.appointmentBooking, "component": <AppointmentBooking/> },
    { "path": AppRoutes.searchFacility, "component": <SearchFacility/> },
    { "path": AppRoutes.billingSummary, "component": <CashierBillingSummary/> },
    { "path": AppRoutes.clinicalInfo, "component": <ClinicalInfo/> },
    { "path": AppRoutes.cashCollection, "component": <CashierCollectionSummary/> },
    { "path": AppRoutes.iframes, "component": <IFrames/> },
    { "path": AppRoutes.paymentCollections, "component": <PaymentCollections/> },
    { "path": AppRoutes.advancePayment, "component": <AdvancePayment/> }
  ],
  "1234": [
    { "path": "/", "component": <SignIn/> },
    { "path": AppRoutes.forgotPassword, "component": <ForgetPassword/> },
    { "path": AppRoutes.changePassword, "component": <ChangePassword/> },
    { "path": AppRoutes.expired, "component": <LinkExpired/> },
    { "path": AppRoutes.resetPassword, "component": <ResetPassword/> },
    { "path": AppRoutes.dashboard, "component": <Dashboard/> },
    { "path": AppRoutes.patientRegistration, "component": <PatientRegistration/> },
    { "path": AppRoutes.financeRegistration, "component": <FinanceRegistration/> },
    { "path": AppRoutes.visitRegistration, "component": <VisitRegistration/> },
    { "path": AppRoutes.PatientDashBoard, "component": <PatientDashboard/> },
    { "path": AppRoutes.confirmBooking, "component": <ConfirmBooking/> },
    { "path": AppRoutes.patientDetail, "component": <PatientDetailWithAppBar/> },
    { "path": AppRoutes.appointmentBooking, "component": <AppointmentBooking/> },
    { "path": AppRoutes.searchFacility, "component": <SearchFacility/> },
    { "path": AppRoutes.billingSummary, "component": <CashierBillingSummary/> },
    { "path": AppRoutes.referralInRegistration, "component": <ERefEMRscreen/> },
    { "path": AppRoutes.cashCollection, "component": <CashierCollectionSummary/> },
    { "path": AppRoutes.iframes, "component": <IFrames/> },
    { "path": AppRoutes.paymentCollections, "component": <PaymentCollections/> },
    { "path": AppRoutes.advancePayment, "component": <AdvancePayment /> }
  ]
};

export let tenantRoutes1={
  "4567": [
    { "path": "/", "component": "authentication/SignIn" },
    { "path": "/forgot-password", "component": "authentication/ForgetPassword" },
    { "path": "/change-password", "component": "authentication/ChangePassword" },
    { "path": "/expired", "component": "authentication/LinkExpired" },
    { "path": "/reset-Password", "component": "authentication/ResetPassword" },
    { "path": "/dashboard", "component": "dashboard/Dashboard" },
    { "path": "/patient-registration", "component": "patientRegistration/PatientRegistration" },
    { "path": "/finance-registration", "component": "financeRegistration/FinanceRegistration" },
    { "path": "/visit-registration", "component": "visitRegistration/VisitRegistration" },
    { "path": "/patient_dashboard", "component": "patientDashboard/PatientDashboard" },
    { "path": "/confirmBooking", "component": "confirmBooking/ConfirmBooking" },
    { "path": "/patientDetail", "component": "patientDetail/PatientDetailWithAppBar" },
    { "path": "/appointmentBooking", "component": "appointmentBooking/AppointmentBooking" },
    { "path": "/searchFacility", "component": "searchFacility/SearchFacility" },
    { "path": "/billingSummary", "component": "cashierBilling/CashierBillingSummary" },
    { "path": "/referral_registration", "component": "referralRegistration/ERefEMRscreen" },
    { "path": "/cashCollection", "component": "cashierCollections/CashierCollectionSummary" },
    { "path": "/iframes", "component": "iFrames/IFrames" },
    { "path": "/paymentCollections", "component": "paymentCollections/PaymentCollections" },
    { "path": "/advancePayment", "component": "advancePayment/AdvancePayment" }
  ],
  "1234": [
    { "path": "/", "component": "authentication/SignIn" },
    { "path": "/forgot-password", "component": "authentication/ForgetPassword" },
    { "path": "/change-password", "component": "authentication/ChangePassword" },
    { "path": "/expired", "component": "authentication/LinkExpired" },
    { "path": "/reset-Password", "component": "authentication/ResetPassword" },
    { "path": "/dashboard", "component": "dashboard/Dashboard" },
    { "path": "/patient-registration", "component": "patientRegistration/PatientRegistration" },
    { "path": "/finance-registration", "component": "financeRegistration/FinanceRegistration" },
    { "path": "/visit-registration", "component": "visitRegistration/VisitRegistration" },
    { "path": "/patient_dashboard", "component": "patientDashboard/PatientDashboard" },
    { "path": "/confirmBooking", "component": "confirmBooking/ConfirmBooking" },
    { "path": "/patientDetail", "component": "patientDetail/PatientDetailWithAppBar" },
    { "path": "/appointmentBooking", "component": "appointmentBooking/AppointmentBooking" },
    { "path": "/searchFacility", "component": "searchFacility/SearchFacility" },
    { "path": "/billingSummary", "component": "cashierBilling/CashierBillingSummary" },
    { "path": "/referral_registration", "component": "referralRegistration/ERefEMRscreen" },
    { "path": "/cashCollection", "component": "cashierCollections/CashierCollectionSummary" },
    { "path": "/iframes", "component": "iFrames/IFrames" },
    { "path": "/paymentCollections", "component": "paymentCollections/PaymentCollections" },
    { "path": "/advancePayment", "component": "advancePayment/AdvancePayment" }
  ]
}