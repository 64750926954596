import React, { useEffect } from "react";
import { Div, Select, Col, Row, Paper, H6 } from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../hoc/withAllContexts";
import actions from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
//import { AlertContext } from '../../contexts'
//import { withRouter } from "react-router-dom";
import { UIColor } from "../../themes/theme";
// import SearchLocationInput from "../../components/location/index"
import { useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";

function RefTo(props) {
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    selectBorderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorselectBorderStyle: {
      borderRadius: 8,
    },
  };
  const dispatch = useDispatch();
  const { refTo, setRefTo } = props;
  const [practitioner_name, setpractitioner_name] = React.useState([]);
  const [practitioner_role, setpractitioner_role] = React.useState([]);
  const [locationdata, setlocationdata] = React.useState([]);
  const [facility_name, setfacility_name] = React.useState([]);
  const location = useLocation();
  const viewMode = location?.state?.isERefView;
  const getEditdependecyDropDownvalues = async () => {
    debugger
    if (refTo?.facilityType?._id) {
      const facility_name = await dispatch(
        actions.GET_FACILITY_NAME({
          orgtype: refTo?.facilityType?._id,
          // extintid: refTo?.from?._id,
        })
      );

      const facility_name_Data = facility_name?.payload?.data?.map((v) => {
        return { value: v?.name, label: v?.name, _id: v?._id };
      });
      setfacility_name(facility_name_Data);
    }

    if (refTo?.facilityName?._id) {
      let datas = await dispatch(
        actions.GET_REFERRAL_LOCATION({ orgid: refTo?.facilityName?._id })
      );
      let ArrLoc = [];
      datas?.payload?.data.forEach((val) => {
        let obj = {
          value: val?.longdesc,
          locationID: val?.locationID,
          _id: val?._id,
        };
        ArrLoc.push(obj);
      });
      setlocationdata(ArrLoc);
    }

    if (refTo?.specialty?._id) {
      const practitioner_type = await dispatch(
        actions.GET_PRACTITIONER_TYPE({ speciality: refTo?.specialty?._id })
      );

      setpractitioner_role(practitioner_type?.payload?.data ?? []);
      
      const practitioner_name_Data = await dispatch(
        actions.GET_PRACTITIONER_NAME({
          speciality: refTo.specialty?._id ?? "",
          roleid: refTo?.practitionerType?._id ?? "",
          locationid: refTo?.location?._id,
        })
      );
      let Arr = [];
      practitioner_name_Data?.payload?.data?.forEach((val) => {
        let obj = {
          value: val?.name,
          _id: val?.id,
        };
        Arr.push(obj);
      });
      setpractitioner_name(Arr);
    }
  };
  useEffect(() => {
    getEditdependecyDropDownvalues();
  }, [refTo?.facilityType?._id]);

  const setDetails = async (name, value) => {
    debugger;
    let todata = JSON.parse(JSON.stringify(refTo));
    if (name === "facilityType") {
      delete todata.facilityName;
      delete todata.location;
      delete todata.specialty;
      delete todata.practitioner_type;
      delete todata.practitioner;

      const facility_name = await dispatch(
        actions.GET_FACILITY_NAME({
          orgtype: value?._id,
          //extintid: todata?.to?._id,
        })
      );
      const facility_name_Data = facility_name?.payload?.data?.map((v) => {
        return { value: v?.name, label: v?.OrgID, _id: v?._id };
      });
      setfacility_name(facility_name_Data);
    }
    if (name === "facilityName") {
      delete todata.location;
      delete todata.specialty;
      delete todata.practitioner_type;
      delete todata.practitioner;

      let datas = await dispatch(
        actions.GET_REFERRAL_LOCATION({ orgid: value?._id })
      );
      let Arr = [];
      datas?.payload?.data.forEach((val) => {
        let obj = {
          value: val?.longdesc,
          locationID: val?.locationID,
          _id: val?._id,
        };
        Arr.push(obj);
      });
      setlocationdata(Arr);
    }
    if (name === "specialty") {
      delete todata.practitionerType;
      delete todata.practitioner;
      const practitioner_type = await dispatch(
        actions.GET_PRACTITIONER_TYPE({ speciality: value?._id })
      );
      setpractitioner_role(practitioner_type?.payload?.data ?? []);
    }
    if (name === "practitionerType") {
      debugger;
      delete todata.practitioner;

      const practitioner_name_Data = await dispatch(
        actions.GET_PRACTITIONER_NAME({
          speciality: todata.specialty?._id,
          roleid: value?._id,
          locationid: todata.location?._id,
        })
      );
      let Arr = [];
      practitioner_name_Data?.payload?.data?.forEach((val) => {
        let obj = {
          value: val?.name,
          _id: val?.id,
        };
        Arr.push(obj);
      });
      setpractitioner_name(Arr);
    }
    if (refTo.error) {
      refTo.error[name] = false;
    }

    setRefTo({
      ...todata,
      [name]: value,
    });
  };

  const FlterOptions3 = (props, type) => {
    let filteredArray = props.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?.display,
        _id: data[index]?._id,
      };

      list.push(datatemp);
    }

    return list;
  };

  const { labelStyle, selectBorderStyle, errorselectBorderStyle } = styles;

  const { parent_id } = props;
  console.log(refTo, "to///");
  return (
    <Div id={`${parent_id}_refto_parent_div`} className="pv-root">
      <Paper id={`${parent_id}_refto_parent_paper`} className="pl-root">
        <Row id={`${parent_id}_refto_parent_row`} className="en-actions-root">
          <Grid
            container
            id={`${parent_id}_refto_parent_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Div id={`${parent_id}_refto_title_h6`}>
              <H6
                id={`${parent_id}_refto_h6`}
                className="en-title space-padding-bottom"
              >
                REFERRED TO
              </H6>
            </Div>
            <Grid
              container
              xs={12}
              style={{ paddingLeft: "12px" }}
              spacing={3}
              id={`${parent_id}_refto_sub_parent_row`}
              className="en-actions-root"
            >
              <Grid
                item
                id={`${parent_id}_refFrom_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_refFrom_from_select`}
                  placeholder="select..."
                  search
                  options={FlterOptions3(props?.allMasters, "EXTINT")}
                  // helperText={
                  // 	from?.error && from?.error["from"] ? "Is required" : ""
                  // }
                  // error={refTo?.error && refTo?.error["to"] ? true : false}
                  value={refTo?.to?.value ?? null}
                  onChangeValue={(e) => setDetails("to", e)}
                  label="To"
                  labelStyle={labelStyle}
                  inLineStyles={
                    // refTo?.error && refTo?.error["to"]
                    //   ? errorselectBorderStyle
                    //   :
                    selectBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  //disabled={viewMode}
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refFrom_partner_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_refFrom_partner_select`}
                  // helperText={
                  // 	from?.error && from?.error["facility_type"]
                  // 		? "Is required"
                  // 		: ""
                  // }
                  // error={
                  // 	refTo?.error && refTo?.error["facilityType"] ? true : false
                  // }
                  search
                  placeholder={
                    refTo.to?.value === "partner" ||
                    refTo.to?.value === "external"
                      ? "Organization"
                      : "Facility type"
                  }
                  options={FlterOptions3(props?.allMasters, "ORGTYPE")}
                  value={refTo?.facilityType?.value}
                  onChangeValue={(e) => setDetails("facilityType", e)}
                  label={
                    refTo?.to?.value === "partner" ||
                    refTo?.to?.value === "external"
                      ? "Organization"
                      : "Facility Type"
                  }
                  labelStyle={labelStyle}
                  inLineStyles={
                    // refTo?.error && refTo?.error["facilityType"] ? errorselectBorderStyle :
                    selectBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  disabled={!refTo?.to?._id}
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refFrom_facility_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_refFrom_facility_select`}
                  // helperText={
                  // 	from?.error && from?.error["facility_name"]
                  // 		? "Is required"
                  // 		: ""
                  // }
                  // error={
                  //   refTo?.error && refTo?.error["facilityName"] ? true : false
                  // }
                  disabled={!refTo?.facilityType?.value}
                  search
                  placeholder="Facility name"
                  options={facility_name}
                  value={refTo?.facilityName?.value}
                  onChangeValue={(e) => setDetails("facilityName", e)}
                  label="Facility Name"
                  labelStyle={labelStyle}
                  inLineStyles={
                    // refTo?.error && refTo?.error["facilityName"]
                    //   ? errorselectBorderStyle
                    //   :
                    selectBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refto_specialty_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_refto_specialty_select`}
                  // helperText={(to?.error && to?.error['specialty']) ? 'Is required' : ''}
                  error={
                    refTo?.error && refTo?.error["specialty"] ? true : false
                  }
                  options={FlterOptions3(props?.allMasters, "SPECIALTY")}
                  placeholder="select..."
                  value={refTo?.specialty?.value}
                  onChangeValue={(e) => setDetails("specialty", e)}
                  label="Specialty"
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={
                    refTo?.error && refTo?.error["specialty"]
                      ? errorselectBorderStyle
                      : selectBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  isRequired={viewMode}
                  // disabled={                    
                  // }
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refto_location_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                {/* <H6 className="default-size pc_regular">Location</H6> */}
                {/* <div style={{
                                fontSize: '14px',
                                width: '100%',
                                fontWeight: 400,
                                textOverflow: 'ellipsis',
                                padding: '7.5px 14px',
                                color: '#aba9a9',
                                border: "1px solid #E0E0E0",
                                borderRadius: 8
                            }}>
                                <SearchLocationInput
                                    isinput={true}
                                    label=''
                                    handleChange={(v) => setDetails('location', v)}
                                    values={{
                                        address: to.location?.value
                                    }}
                                />
                            </div> */}
                <Select
                  id={`${parent_id}_refto_location_select`}
                  placeholder="select..."
                  options={locationdata || []}
                  // error={
                  //   refTo?.error && refTo?.error["location"] ? true : false
                  // }
                  value={refTo?.location?.value}
                  onChangeValue={(e) => setDetails("location", e)}
                  label="Location"
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={
                    // refTo?.error && refTo?.error["location"]
                    //   ? errorselectBorderStyle
                    //   :
                    selectBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  disabled={
                    !refTo?.facilityType?._id || !refTo?.facilityName?._id
                  }
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refto_practitoner_role_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_refto_practitoner_type_select`}
                  //FIXME -  options={FlterOptions3(props?.allMasters, "PRACTROLE")}
                  options={practitioner_role || []}
                  placeholder="select..."
                  value={refTo?.practitionerType?.value}
                  // error={
                  //   refTo?.error && refTo?.error["practitionerType"]
                  //     ? true
                  //     : false
                  // }
                  onChangeValue={(e) => setDetails("practitionerType", e)}
                  label="Practitioner Type"
                  inLineStyles={
                    // refTo?.error && refTo?.error["practitionerType"]
                    //   ? errorselectBorderStyle
                    //   :
                    selectBorderStyle
                  }
                  showArrow
                  labelStyle={labelStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={
                    !refTo?.facilityType?._id ||
                    !refTo?.facilityName?._id ||
                    !refTo?.specialty?._id
                  }
                />
              </Grid>

              <Grid
                item
                id={`${parent_id}_refto_practitoner_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                 
                  id={`${parent_id}_refto_practitoner_select`}
                  options={practitioner_name}
                  placeholder="select..."
                  // error={
                  //   refTo?.error && refTo?.error["practitioner"] ? true : false
                  // }
                  inLineStyles={
                    // refTo?.error && refTo?.error["practitioner"]
                    //   ? errorselectBorderStyle
                    //   :
                    selectBorderStyle
                  }
                  value={refTo?.practitioner?.value}
                  onChangeValue={(e) => setDetails("practitioner", e)}
                  label="Practitioner"
                  showArrow
                  labelStyle={labelStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={
                    !refTo?.facilityType?._id ||
                    !refTo?.facilityName?._id ||
                    !refTo?.specialty?._id ||
                    !refTo?.practitionerType?.value
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Row>
      </Paper>
    </Div>
  );
}

export default withAllContexts(RefTo);
