export const generatereferalresponse = (data) => {
    const getEpochTIme = (date) => {
        const unixMilliSeconds = date * 1000;
        const myDate = new Date(unixMilliSeconds);
        return myDate.toLocaleString();
      };
    
    let Arr = []
   
    let letter = {
        referralDate:getEpochTIme(data?.RefSourceLetDate),
        letterNo:data?.RefSourceLetRef,
        recivedDate:getEpochTIme(data?.RefLetReceivedDate)
    }
    Arr["letter"] = letter
    let from = {
        from:data?.RequestorOrganizationType,
        facility_type:data?.RequestorOrganizationEntityType,
        facility_name:data?.HealthCareServiceRequested,
        specialty:data?.RequestorSpecialty,
        practitioner_type:data?.RequestorPractionerType,
        practitioner:data?.RequestorPractioner
    }
    Arr["from"] = from

    let to = {
        to:data?.receiptOrganizationType,
        facility_type:data?.receiptOrganizationEntityType,
        facility_name:data?.receiptfacility,
        specialty:data?.recipientSpecialty,
        location:data?.recipientLocType,
        practitioner_type:data?.recipientPractType,
        practitioner:data?.recipientPract
    }
    Arr["to"] = to

    let details = {
        intent_type:data?.intent,
        for:data?.subject,
        priority:data?.priority,
        request_reason:data?.reasonReference,
        service_requested:data?.serviceRequested
    }
    Arr["details"] = details

    let note = {
        reason:data?.note?.[0]?.authorreference,
        multiLine:data?.note?.[0]?.text
    }
    Arr["note"] = note

    return Arr
}

