import moment from "moment";

const createDate = (date) => {
  return moment.unix(date).format("DD/MM/YYYY HH:mm");
};

export const displayTable = (data) => {
  let list = [];
  const table = data.map((val, i) => {
    return list.push({
      service: val?.service,
      description: val?.servicedesc,
      orderRef: val?.orderref,
      date: createDate(val?.createddate ?? val?.orderdatetime),
      category: val?.ordcatOrdrugclass,
      qty: val?.qty,
      uom: val?.chargeuom?.UOM,
      uomId: val?.chargeuom?._id,
      rate: val?.rate,
      amt: val?.gross,
      status: val?.chargestatus,
      billed: val?.billstatus,
      checked: false,
      id: val?._id,
      orderdispenseid: val?.orderdispenseid,
      orderrefid: val?.orderrefid,
      chargecodeid: val?.chargeid,
      payerDtls: val?.payerDtls,
      packageorderid: val?.packageorderid,
      packorddesc: val?.packorddesc,
      gross: val?.gross,
      net: val?.netamount,
      discount: val?.discount,
      patientShare: val?.patientshare,
      creditShare: val?.creditshare,
      chargeedit: val?.chargeedit ?? false
    });
  });
  return list;
};
export const displayTable1 = (data) => {
  let list = [];
  const table = data.map((val, i) => {
    return list.push({
      patientid: val?.patientid,
      patmrn: val?.patmrn,
      encounterid:val?.encounterid,
      patientname: val?.patientname,
      billno: val?.billno,
      billdate: createDate(val?.billdate),
      billamount: val?.billamount,
      paidamount: val?.paidamount,
      balanceamount:val?.balanceamount,
      patientshare:val?.patientshare,  
      payershare:val?.payershare, 
      checked: false,
      billed: false,
      CollectionAmount:null,
      id: val?.ID,
      amtineng: val?.amtineng,
      amtinlocal:val?.amtinlocal
    });
  });
  return list;
};
export const displayTable2 = (data) => {
  let list = [];
  const table = data.map((val, i) => {
    return list.push({
      patientid: val?.patientid,
      patmrn: val?.mrn,
      // encounterid:val?.encounterid,
      patientname: val?.name,
      advanceno: val?.advanceno,
      advdate: createDate(val?.advdate),
      advamount: val?.amount,
      remarks: val?.remarks,
      // balanceamount:val?.balanceamount,
      // patientshare:val?.patientshare,  
      // payershare:val?.payershare, 
      checked: false,
      billed: false,
      CollectionAmount:null,
      id: val?.id,
      amtineng: val?.amtineng,
      amtinlocal:val?.amtinlocal
    });
  });
  return list;
};
export const constructNewOrder = (data) => {
  const returnData = {
    // service: data?.chargeCode?.totalValue?.service ?? "---",
    service: data?.chargeCode?.service ?? "---",
    description: data?.chargeCode?.servicedesc ?? "no-desc",
    orderRef: "no-ref",
    date: createDate(new Date(new Date()) / 1000),
    category: "--nocat--",
    qty: data?.qty?.length > 0 ? data?.qty : "1",
    uom: data?.uom?.value ?? "NaN",
    rate: data?.chargeCode?.rate ?? "--",
    amt: "-",
    status: "",
    billed: false,
    checked: true,
    isNew: true,
    chargecodeid: data?.chargeCode?._id,
  };
  return returnData;
};

export const validate = (data, paymentDetails) => {
  if (
    data.billno &&
    data.billdate &&
    data.patientid &&
    data.encounterid &&
    paymentDetails?.settleNow
  ) {
    if (paymentDetails?.paymentType === "") {
      return {
        status: false,
        msg: "Please select the payment type",
      };
    } else {
      if (
        paymentDetails?.paymentType?.value === "Cheque" &&
        !paymentDetails?.docRefNo
      ) {
        return {
          status: false,
          msg: "Please add the Doctor Ref No.",
        };
      } else if (
        (paymentDetails?.paymentType?.value === "Credit Card" ||
          paymentDetails?.paymentType?.value === "Debit Card") &&
        !paymentDetails?.card
      ) {
        return {
          status: false,
          msg: "Please add the Card No.",
        };
      } else if (
        (paymentDetails?.paymentType?.value === "Credit Card" ||
          paymentDetails?.paymentType?.value === "Debit Card") &&
        !paymentDetails?.expiry
      ) {
        return {
          status: false,
          msg: "Please enter the expiry date",
        };
      } else {
        return {
          status: true,
          msg: "Saved",
        };
      }
    }
  } else {
    if (!paymentDetails?.settleNow) {
      return {
        status: false,
        msg: "Please settle the pending amount and then save",
      };
    } else if (!data.billno) {
      return {
        status: false,
        msg: "Bill No. has not been generated",
      };
    }
  }
};
