import React from "react";
//import { actions } from "primarycare-binder";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom";
import { Grid, withStyles } from "@material-ui/core";
import { Col, Row } from "qdm-component-library";
import "./styles.css";
import VitalSigns from "./vitalSigns";
import { Allergy } from "./allergyFlows";
import { Diagnosis } from "./diagnosisFlow";
import { CheifComplaints } from "./cheifComplaintsFlow";
import { Radiology } from "./radiologyFlow";
import LabOrder from "./labOrderFlow/labOrder";
import { MedicationOrderFrom } from "./medicationFlows";
import TreatmentProcedure from "./treatmentProcedureFlow/treatmentProcedure";
import DoccumentationList from "./documentation/index";
import { OrderSets } from "./orderSetFlow";
import { Dialysis } from "./dialysis/dialysis";
import ProgressNotes from "./progressNotes";
import TreatmentPlan from "./treatmentPlan/treatmentPlan";
const materialStyles = (theme) => ({});

const PatientChart = (props) => {
  const [formsList, setformList] = React.useState(null);
  const [patientgetData, setpatientgetData] = React.useState(null);

  const styles = {
    columnStyle: {
      padding: 10,
      backgroundColor: "#fff",
      borderRadius: 5,
    },
    divider: {
      margin: "5px 0px",
      border: "0.5px dashed #EEEEEE",
      borderRadius: 10,
    },
  };

  React.useEffect(() => {
    setformList(props?.formsList);
  }, [props?.formsList]);

  // componentDidMount() {
  //   this.setState({
  //     formsList: this.props.formsList,
  //   });
  // }

  // componentDidUpdate(prevProps) {
  //   if (
  //     !(
  //       this.props.formsList.length === prevProps.formsList.length &&
  //       this.props.formsList.every(
  //         (value, index) => value === prevProps.formsList[index]
  //       )
  //     )
  //   ) {
  //     this.setState({
  //       formsList: this.props.formsList,
  //     });
  //   }
  // }

  const getComponets = () => {
    return JSON.stringify(formsList);
  };

  const onEditChart = (id, type) => {
    props.onEditChart(id, type);
  };
  // const { classes } = this.props;
  const { parent_id } = props;
  return (
    <Row
      id={`${parent_id}_patient_chart_parent_row`}
      inLineStyles={{
        height: "calc(100vh - 180px)",
        overflow: "auto",
      }}
    >
       <Col
        >
           <Grid container >
           <div
        id={`${parent_id}_patient_chart_parent_div`}
        style={{ width: "100%" }}
      >
        <div
          id={`${parent_id}_patient_chart_parent_div`}
          style={{ width: "100%" }}
        >
          {/* {this.getComponets()} */}
          {formsList?.includes("vital_signs") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <VitalSigns Encounterdata={props?.patientgetData}  onRegistration={false}/>{" "}
            </div>
          )}
          {formsList?.includes("dialysis") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <Dialysis
                dialysisData={props?.dialysisData}
                patientgetData={props.patientgetData}
                getDialysisData={props?.getDialysisData}
                orgdetails={props?.orgdetails}
                saveTreatment={props?.saveTreatment}
                setTreatmentplan={props?.setTreatmentplan}
                setOrderlineId={props?.setOrderlineId}
                readDialysisWithEncouterid={props?.readDialysisWithEncouterid}
              />
            </div>
          )}

          {formsList?.includes("progressnotes") && (
            <div style={{ marginBottom: "12px" }}>
              <ProgressNotes
                patientgetData={props?.patientgetData}
                progressNotesData={props?.progressNotesData ?? {}}
              />
            </div>
          )}
          {formsList?.includes("treatmentplan") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <TreatmentPlan
                dialysisData={props?.dialysisData}
                patientgetData={props?.patientgetData}
                getDialysisData={props?.getDialysisData}
                orgdetails={props?.orgdetails}
                treatmentplan={props?.treatmentplan}
                location={props?.locationdata}
                setTreatmentplan={props?.setTreatmentplan}
              />
            </div>
          )}
          {formsList?.includes("allergy") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <Allergy
                parent_id={"patientchart"}
                saveAllergy={props.saveAllergy}
                onEditChart={onEditChart}
                handleClose={props.handleClose}
                Encounterdata={props?.patientgetData}
              />
            </div>
          )}
          {formsList?.includes("diagnosis") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <Diagnosis
                parent_id={"patientchart"}
                saveDiagnosis={props.saveDiagnosis}
                onEditChart={onEditChart}
                handleClose={props.handleClose}
                Encounterdata={props.patientgetData}
              />
            </div>
          )}
          {formsList?.includes("cheif_complaints") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <CheifComplaints
                parent_id={"patientchart"}
                saveCheifComp={props.saveCheifComp}
                onEditChart={onEditChart}
                handleClose={props.handleClose}
                Encounterdata={props?.patientgetData}
              />
            </div>
          )}
          {formsList?.includes("radiology") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <Radiology
                propsData={{ ...props }}
                parent_id={"patientchart"}
                onEditChart={onEditChart}
                saveRadiology={props.saveRadiology}
                patientgetData={props.patientgetData}
                handleClose={props.handleClose}
              />
            </div>
          )}
          {formsList?.includes("lab_order") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <LabOrder
                propsData={{ ...props }}
                parent_id={"patientchart"}
                onEditChart={onEditChart}
                saveLabOrders={props.saveLabOrders}
                patientgetData={props.patientgetData}
                handleClose={props.handleClose}
              />
            </div>
          )}
          {formsList?.includes("medication") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <MedicationOrderFrom
                propsData={{ ...props }}
                parent_id={"patientchart"}
                onEditChart={onEditChart}
                handleClose={props.handleClose}
              />
            </div>
          )}
          {formsList?.includes("orders_sets") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <OrderSets
                setOpen={true}
                handlingClose={props.handleOrderSetClose}
                Encounterdata={props?.patientgetData}
              />
            </div>
          )}
          {formsList?.includes("treatment_procedure") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <TreatmentProcedure
                propsData={{ ...props }}
                parent_id={"patientchart"}
                onEditChart={onEditChart}
                saveTreatment={props?.saveTreatment}
                patientgetData={props.patientgetData}
                handleClose={props.handleClose}
                setTreatmentplan={props?.setTreatmentplan}
              />
            </div>
          )}
          {formsList?.includes("docc_ui") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <DoccumentationList
                parent_id={"patientchart"}
                onEditChart={onEditChart}
                saveDoccumentation={props.saveDoccumentation}
                patientgetData={props.patientgetData}
                handleClose={props.handleClose}
              />
            </div>
          )}
        </div>
      </div>
           </Grid>
          
        </Col>
     
    </Row>
  );
};

// const mapStateToProps = (state) => ({});

export default PatientChart;