import React, { useState } from "react";
import {
  Div,
  H6,
  Col,
  Row,
  Text,
  Avatar,
  Paper,
  Image,
  Icons,
  Modal,
  Divider,
} from "qdm-component-library";
import "./style.css";
import moment from "moment";
import ReferralBannerListView from "./referralViewDetails";
import Type from "../../assets/img/svg/icons8-identification-documents.svg";
import id from "../../assets/img/svg/id.svg";
import Group from "../../assets/img/svg/Group 90503.svg";
import imgUrl from "../../assets/img/svg/icons8-Referral.svg";
import referralicon from "../../assets/img/svg/referral icon.svg";
import { Card, Popover, makeStyles } from "@material-ui/core";
import qdmstyles from "./styles.json";
import viewIcon from "../../assets/img/svg/icons8-eye.svg";
import FileUpload from "../../assets/img/svg/icons8-file.svg";
import { UploadFiles } from "../navBar";
const useStyles = makeStyles((theme) => ({
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    marginTop: 10,
    padding: 10,
  },
}));

function ReferralListView(props) {
  const [openRef, setopenRef] = useState(false);
  const [open, setOpen] = React.useState(null);
  const [image, setimage] = React.useState("");
  const [position, setPosition] = useState({ mouseX: null, mouseY: null });
  const classes = useStyles();
  const openPopover = (e) => {
    // this.setState({
    //   open: e?.currentTarget ?? null,
    // });
    setPosition({
      mouseX: e.clientX - 350,
      mouseY: e.clientY - -40,
    });
    setOpen(true);
  };
  const getAge = (date) => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
      ) + "Yrs"
    );
  };
  const handleopenRef = () => {
    setopenRef(!openRef);
  };
  const closePopover = (e) => {
    setPosition({ mouseX: null, mouseY: null });
    setOpen(null);
  };
  const handleOpenModel = (value) => {
    setOpen(null);
    setimage([value]);
    setopenRef(!openRef);
  };
  const {
    ReferralData,
    letter = ReferralData?.name?.[0],
    noright,
    parent_id,
  } = props;
  return (
    <Card
      id={`${parent_id}_referabanner_parent_paper`}
      className="rv-pl-root"
      style={{ margin: 0, padding: 0, height: "15vh" }}
    >
      <Row id={`${parent_id}_referabanner_parent_row`}>
        <Col
          id={`${parent_id}_referabanner_parent_col`}
          md={12}
          lg={12}
          sm={12}
          xs={12}
          inLineStyles={{ padding: 0, margin: 0, marginLeft: "5px" }}
        >
          <Div
            id={`${parent_id}_referabanner_parent_div`}
            className="flexCenter"
          >
            <Avatar
              id={`${parent_id}_referabanner_letter_image`}
              style={{
                padding: 10,
                margin: "0px 5px 0px 0px",
                width: 48,
                height: 48,
                borderRadius: 8,
                backgroundColor: "#F0F0FA",
              }}
              src={referralicon}
              letter={letter}
              variant="rounded"
            />
            &nbsp;&nbsp;&nbsp;
            <Div
              id={`${parent_id}_referabanner_description_div`}
              className="pl-details-names"
            >
              <H6 id={`${parent_id}_referabanner_referral_id_h6`} style={{margin: "4px 0px 0px 0px"}}>
                {/* {ReferralData?.[0]?.HealthCareServiceRequested?.display} */}
                {ReferralData?.[0]?.HealthCareServiceRequested?.display?.slice(
                  0,
                  10
                )}
                {ReferralData?.[0]?.HealthCareServiceRequested?.display
                  ?.length > 10
                  ? ".."
                  : ""}
                &nbsp;&nbsp;•&nbsp;&nbsp;
                {/* {ReferralData?.[0]?.facilityid?.name} */}
                {ReferralData?.[0]?.facilityid?.name?.slice(0, 10)}
                {ReferralData?.[0]?.facilityid?.name?.length > 10 ? ".." : ""}
              </H6>

              <Div
                id={`${parent_id}_referabanner_document_div`}
                className="flexCenter"
              >
                <Div
                  id={`${parent_id}_referabanner_document_sub_div`}
                  className="pl-secondary-text flexCenter"
                >
                  <Div
                    id={`${parent_id}_referabanner_iden_document_div`}
                    className="p1-secondary-img"
                  >
                    <Image
                      id={`${parent_id}_referabanner_document_image`}
                      className="pl-secondary-text-icons"
                      src={id}
                      alt="id"
                    />
                  </Div>
                  <Div id={`${parent_id}_referabanner_specialty_div`}>
                    <Text id={`${parent_id}_referabanner_specialty_text`}>
                      {/* {ReferralData?.[0]?.RequestorPractioner?.SpecialtyID?.[0] ?.display } */}
                      {ReferralData?.[0]?.RequestorPractioner?.SpecialtyID?.[0]?.display?.slice(
                        0,
                        10
                      )}
                      {ReferralData?.[0]?.RequestorPractioner?.SpecialtyID?.[0]
                        ?.display?.length > 10
                        ? ".."
                        : ""}
                    </Text>
                  </Div>
                </Div>
                <Div
                  id={`${parent_id}_referabanner_group_div`}
                  className="pl-secondary-text flexCenter"
                >
                  <Div
                    id={`${parent_id}_referabanner_group1_div`}
                    className="p1-secondary-img"
                  >
                    <Image
                      id={`${parent_id}_referabanner_group_image`}
                      className="pl-secondary-text-icons"
                      src={Group}
                      alt="group"
                    />
                  </Div>
                  <Text id={`${parent_id}_referabanner_practitioner_text`}>
                    {/* {ReferralData?.[0]?.RequestorPractioner?.name} */}
                    {ReferralData?.[0]?.RequestorPractioner?.name?.slice(0, 10)}
                    {ReferralData?.[0]?.RequestorPractioner?.name?.length > 10
                      ? ".."
                      : ""}
                  </Text>
                </Div>
                <Div
                  id={`${parent_id}_referabanner_openref_div`}
                  style={{
                    position: "absolute",
                    right: "58px",
                    top: "1px",
                    width: "30px",
                    height: "30px",
                    border: "1px solid #E0E0E0",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => openPopover(e)}
                >
                  <Icons
                    id={`${parent_id}_referabanner_eye_icons`}
                    inLineStyles={{
                      color: "black",
                      fontSize: "20px ​!importan",
                    }}
                    fontIcon={"eye"}
                    ariaHidden={"true"}
                    size={"small"}
                  />
                </Div>
                <Popover
                  //   anchorEl={isUpload}
                  open={Boolean(position.mouseX && position.mouseY)}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    position.mouseY !== null && position.mouseX !== null
                      ? { top: position.mouseY, left: position.mouseX }
                      : undefined
                  }
                  onClose={closePopover}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  classes={{ paper: classes.muiPopovers }}
                >
                  <UploadFiles
                    id={`${parent_id}_topnavbar_upload_uploadfiles`}
                    // uploadDocument={uploadDocument}
                    // files={files}
                    viewfiles={props.attachments}
                    isViewMode={true}
                  />
                </Popover>
              </Div>
            </Div>
          </Div>
        </Col>
      </Row>

      {/* <Modal
        id={`${parent_id}_referabanner_modal`}
        inLineStyles={{ borderRadius: 16 }}
        open={openRef}
        onClose={handleopenRef}
        width={1000}
      >
        <ReferralBannerListView
          parent_id={"doctor_listing_referral"}
          ReferralData={ReferralData?.[0]}
          image={image}
        />
      </Modal> */}
    </Card>
  );
}

export default ReferralListView;
