import React, { useEffect } from "react";
import { Div, Select, Col, Row, Paper, H6 } from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../hoc/withAllContexts";
import actions from "../../redux/actions";
import { UIColor } from "../../themes/theme";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

function RefFrom(props) {
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    selectBorderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorselectBorderStyle: {
      borderRadius: 8,
    },
    inputBorderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
      background: "#F0F0F0",
    },
  };
  const { from, setFrom } = props;

  useEffect(() => {
    getEditdependecyDropDownvalues();
  }, [from?.facilityType?._id]);

  const [facility_name, setfacility_name] = React.useState([]);
  const [practitioner_name, setpractitioner_name] = React.useState([]);
  const [practitioner_role, setpractitioner_role] = React.useState([]);
  const [getspeciality, setsetspeciality] = React.useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const viewMode = location?.state?.isERefView;

  const selector = useSelector((state) => {
    return {
      hubOptions: state?.referalslice?.get_hubs?.data || [],
      casemanagerOptions: state?.referalslice?.get_casemanager?.data || [],
    };
  });

  const getEditdependecyDropDownvalues = async () => {
    if (from?.facilityType?._id) {
      const facility_name = await dispatch(
        actions.GET_FACILITY_NAME({
          orgtype: from?.facilityType?._id,
          // extintid: from?.from?._id,
        })
      );
      const facility_name_Data = facility_name?.payload?.data?.map((v) => {
        return { value: v?.name, label: v?.name, _id: v?._id };
      });
      setfacility_name(facility_name_Data);
    }

    if (from?.facilityName?._id) {
      let openLData = await dispatch(
        actions.GET_OPENL_CASE_MANAG_AND_HUB_ID({
          orgId: from?.facilityName?._id,
        })
      );
      openLData = openLData?.payload?.data;
      setFrom({
        ...from,
        casemanagerOpenL: openLData?.casemanagerid || {},
        hubNameOpenL: openLData?.hubid || {},
      });
    }

    if (from?.specialty?._id) {
      const practitioner_type = await dispatch(
        actions.GET_PRACTITIONER_TYPE({ speciality: from?.specialty?._id })
      );

      setpractitioner_role(practitioner_type?.payload?.data ?? []);
      const practitioner_name_Data = await dispatch(
        actions.GET_PRACTITIONER_NAME({
          speciality: from.specialty?._id ?? "",
          roleid: from?.practitionerType?._id ?? "",
          locationid: "",
        })
      );
      let Arr = [];
      practitioner_name_Data?.payload?.data?.forEach((val) => {
        let obj = {
          value: val?.name,
          _id: val?.id,
        };
        Arr.push(obj);
      });
      setpractitioner_name(Arr);
    }
  };

  const setDetails = async (name, value) => {
    debugger;
    let fromData = JSON.parse(JSON.stringify(from));
    if (name === "facilityType") {
      delete fromData.facilityName;
      delete fromData.specialty;
      delete fromData.practitionerType;
      delete fromData.practitioner;
      delete fromData.hubNameOpenL;
      delete fromData.casemanagerOpenL;

      const facility_name = await dispatch(
        actions.GET_FACILITY_NAME({
          orgtype: value?._id,
          //extintid: fromData?.from?._id,
        })
      );
      const facility_name_Data = facility_name?.payload?.data?.map((v) => {
        return { value: v?.name, label: v?.name, _id: v?._id };
      });
      setfacility_name(facility_name_Data);
    }
    if (name === "facilityName") {
      delete fromData.specialty;
      delete fromData.practitionerType;
      delete fromData.practitioner;
      delete fromData.hubNameOpenL;
      delete fromData.casemanagerOpenL;
      // value?.label
      let filteredArray1 = props?.allMasters.filter((obj) =>
        obj.hasOwnProperty(["SPECIALTY"])
      );
      const data_ = filteredArray1[0]?.["SPECIALTY"].Value;
      const speciality_Data = data_?.map((v) => {
        return {
          value: v?.display,
          label: v?.id,
          codeableId: v?._id,
          codingId: v?._id,
        };
      });
      setsetspeciality(speciality_Data);

      let openLData = await dispatch(
        actions.GET_OPENL_CASE_MANAG_AND_HUB_ID({ orgId: value?._id })
      );
      openLData = openLData?.payload?.data;
      fromData["casemanagerOpenL"] = openLData?.casemanagerid || {};
      fromData["hubNameOpenL"] = openLData?.hubid || {};
    }
    if (name === "specialty") {
      delete fromData.practitionerType;
      delete fromData.practitioner;

      const practitioner_type = await dispatch(
        actions.GET_PRACTITIONER_TYPE({ speciality: value?._id })
      );

      setpractitioner_role(practitioner_type?.payload?.data ?? []);
    } else if (name === "practitionerType") {
      debugger;
      delete fromData.practitioner;
      const practitioner_name_Data = await dispatch(
        actions.GET_PRACTITIONER_NAME({
          speciality: fromData.specialty?._id ?? "",
          roleid: value?._id ?? "",
          locationid: "",
        })
      );
      let Arr = [];
      practitioner_name_Data?.payload?.data?.forEach((val) => {
        let obj = {
          value: val?.name,
          _id: val?.id,
        };
        Arr.push(obj);
      });
      setpractitioner_name(Arr);
    }

    if (fromData.error) {
      fromData.error[name] = false;
    }
    setFrom({
      ...fromData,
      [name]: value,
    });

    // if (patientData.from.error) {
    //   patientData.from.error[name] = false;
    // }
    // patientData.setReferralDetails({
    //   ...patientData,
    // });
  };

  const {
    labelStyle,
    selectBorderStyle,
    errorselectBorderStyle,
    // inputBorderStyle
  } = styles;
  // const { walkin } = props
  const { parent_id } = props;
  const FlterOptions3 = (props, type) => {
    let filteredArray = props.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?.display,
        _id: data[index]?._id,
      };

      list.push(datatemp);
    }

    return list;
  };
  console.log(from, "from///");

  return (
    <Div id={`${parent_id}_refFrom_parent_div`} className="pv-root">
      <Paper id={`${parent_id}_refFrom_parent_paper`} className="pl-root">
        <Row id={`${parent_id}_refFrom_parent_row`} className="en-actions-root">
          <Grid
            container
            id={`${parent_id}_refFrom_parent_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Div id={`${parent_id}_refFrom_title_div`}>
              <H6
                id={`${parent_id}_refFrom_label_h6`}
                className="en-title space-padding-bottom"
              >
                REFERRED FROM
              </H6>
            </Div>
            <Grid
              container
              id={`${parent_id}_refFrom_row`}
              className="en-actions-root"
              spacing={3}
              style={{ paddingLeft: "12px" }}
            >
              <Grid
                item
                id={`${parent_id}_refFrom_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_refFrom_from_select`}
                  placeholder="select..."
                  search
                  options={FlterOptions3(props?.allMasters, "EXTINT")}
                  isRequired
                  // helperText={
                  // 	from?.error && from?.error["from"] ? "Is required" : ""
                  // }
                  error={from?.error && from?.error["from"] ? true : false}
                  value={from?.from?.value}
                  onChangeValue={(e) => setDetails("from", e)}
                  label="From"
                  labelStyle={labelStyle}
                  inLineStyles={
                    from?.error && from?.error["from"]
                      ? errorselectBorderStyle
                      : selectBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  disabled={viewMode}
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refFrom_partner_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_refFrom_partner_select`}
                  // helperText={
                  // 	from?.error && from?.error["facility_type"]
                  // 		? "Is required"
                  // 		: ""
                  // }
                  error={
                    from?.error && from?.error["facilityType"] ? true : false
                  }
                  search
                  isRequired
                  placeholder={
                    from.from?.value === "partner" ||
                    from.from?.value === "external"
                      ? "Organization"
                      : "Facility type"
                  }
                  options={FlterOptions3(props?.allMasters, "ORGTYPE")}
                  value={from?.facilityType?.value}
                  onChangeValue={(e) => setDetails("facilityType", e)}
                  label={
                    from.from?.value === "partner" ||
                    from.from?.value === "external"
                      ? "Organization"
                      : "Facility Type"
                  }
                  labelStyle={labelStyle}
                  inLineStyles={
                    from?.error && from?.error["facilityType"]
                      ? errorselectBorderStyle
                      : selectBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  disabled={viewMode || !from?.from?._id}
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refFrom_facility_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_refFrom_facility_select`}
                  isRequired
                  // helperText={
                  // 	from?.error && from?.error["facility_name"]
                  // 		? "Is required"
                  // 		: ""
                  // }
                  error={
                    from?.error && from?.error["facilityName"] ? true : false
                  }
                  disabled={viewMode || !from?.facilityType?.value}
                  search
                  placeholder="Facility name"
                  options={facility_name}
                  value={from?.facilityName?.value}
                  onChangeValue={(e) => setDetails("facilityName", e)}
                  label="Facility Name"
                  labelStyle={labelStyle}
                  inLineStyles={
                    from?.error && from?.error["facilityName"]
                      ? errorselectBorderStyle
                      : selectBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refFrom_specialty_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_refFrom_specialty_select`}
                  // helperText={
                  // 	from?.error && from?.error["specialty"] ? "Is required" : ""
                  // }
                  isRequired
                  error={from?.error && from?.error["specialty"] ? true : false}
                  // options={getspeciality}
                  options={FlterOptions3(props?.allMasters, "SPECIALTY")}
                  //disabled={!props.referralData?.from?.facility_name}
                  placeholder="select..."
                  value={from?.specialty?.value}
                  onChangeValue={(e) => setDetails("specialty", e)}
                  label="Specialty"
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={
                    from?.error && from?.error["specialty"]
                      ? errorselectBorderStyle
                      : selectBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  disabled={
                    viewMode ||
                    !from?.facilityType?._id ||
                    !from?.facilityName?._id
                  }
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refFrom_practioner_type_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_refFrom_practioner_type_select`}
                  //FIXME -
                  // options={FlterOptions3(props?.allMasters, "PRACTROLE")}
                  options={practitioner_role}
                  //disabled={!props.referralData?.from?.specialty}

                  placeholder="select..."
                  isRequired
                  // helperText={
                  // 	from?.error && from?.error["practitioner_type"]
                  // 		? "Is required"
                  // 		: ""
                  // }
                  error={
                    from?.error && from?.error["practitionerType"]
                      ? true
                      : false
                  }
                  value={from?.practitionerType?.value}
                  onChangeValue={(e) => setDetails("practitionerType", e)}
                  label="Practitioner Type"
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={
                    from?.error && from?.error["practitionerType"]
                      ? errorselectBorderStyle
                      : selectBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  disabled={
                    viewMode ||
                    !from?.facilityType?._id ||
                    !from?.facilityName?._id ||
                    !from?.specialty?._id
                  }
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refFrom_practioner_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_refFrom_practioner_select`}
                  options={practitioner_name}
                  placeholder="select..."
                  // helperText={
                  // 	from?.error && from?.error["practitioner"]
                  // 		? "Is required"
                  // 		: ""
                  // }
                  isRequired
                  error={
                    from?.error && from?.error["practitioner"] ? true : false
                  }
                  value={from?.practitioner?.value}
                  onChangeValue={(e) => setDetails("practitioner", e)}
                  label="Practitioner"
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={
                    from?.error && from?.error["practitioner"]
                      ? errorselectBorderStyle
                      : selectBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  disabled={
                    viewMode ||
                    !from?.facilityType?._id ||
                    !from?.facilityName?._id ||
                    !from?.specialty?._id ||
                    !from?.practitionerType?.value
                  }
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_from_practitoner_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  // disabled={!from?.hubNameOpenL?.value}
                  id={`${parent_id}_from_hubNameOpenL_select`}
                  options={selector?.hubOptions}
                  placeholder="select..."
                  error={
                    from?.error && from?.error["hubNameOpenL"] ? true : false
                  }
                  isRequired
                  inLineStyles={
                    from?.error && from?.error["hubNameOpenL"]
                      ? errorselectBorderStyle
                      : selectBorderStyle
                  }
                  value={from?.hubNameOpenL?.value || ""}
                  onChangeValue={(e) => setDetails("hubNameOpenL", e)}
                  label="Hub Name"
                  showArrow
                  labelStyle={labelStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={
                    viewMode ||
                    !from?.facilityType?._id ||
                    !from?.facilityName?._id
                  }
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_from_practitoner_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  //disabled={!from?.casemanagerOpenL?.value}
                  id={`${parent_id}_from_casemanagerOpenL_select`}
                  options={selector?.casemanagerOptions}
                  placeholder="select..."
                  error={
                    from?.error && from?.error["casemanagerOpenL"]
                      ? true
                      : false
                  }
                  isRequired
                  inLineStyles={
                    from?.error && from?.error["casemanagerOpenL"]
                      ? errorselectBorderStyle
                      : selectBorderStyle
                  }
                  value={from?.casemanagerOpenL?.value ?? ""}
                  onChangeValue={(e) => setDetails("casemanagerOpenL", e)}
                  label="Case Manager"
                  showArrow
                  labelStyle={labelStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={
                    viewMode ||
                    !from?.facilityType?._id ||
                    !from?.facilityName?._id
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Row>
      </Paper>
    </Div>
  );
}

export default withAllContexts(RefFrom);
