import React, { useEffect, useState, useContext } from "react";
import {
  Div,
  Select,
  Col,
  Row,
  Paper,
  H6,
  TextInput,
  Divider,
  Checkbox,
  Button,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../../hoc/withAllContexts";
import { UIColor } from "../../../themes/theme";
import CustomInputDatePicker from "../../../components/customDatePicker";
import moment from "moment";
import CalendarKeyIcon from "../../../assets/img/svg/icons8-calendar-grey.svg";
import { AlertContext } from "../../../context";
import { AlertProps } from "../../../utils";
import actions from "../../../redux/actions";
import { useDispatch } from "react-redux";

// const data = [{ value: "data" }]
function CardDetails(props) {
  const context = useContext(AlertContext);
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    selectBorderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    inputBorderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    errorborderStyle: {
      borderRadius: 5,
      borderColor: "red",
    },
    outlinedButton: {
      borderColor: UIColor.primaryColor.color,
      color: UIColor.primaryColor.color,
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontFamily: "pc_medium !important",
      fontSize: 14,
    },
    containedButton: {
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontFamily: "pc_medium !important",
      fontSize: 14,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
  };
  const dispatch = useDispatch();
  const [isRelDisabled, setIsRelDisabled] = useState(false);
  const [financialData, setFinancialData] = useState({
    // // payer: {},
    // payer: {
    //   priority: {},
    //   payer_type: {},
    //   payer: {},
    //   guarantor_type: {},
    //   guarantor_code: {},
    //   membershipId: {},
    //   plan: {},
    // },
    // validity: {},
    // membership: {},
    // eligibility: {},
    // financial: {},
    // // applicable: {},
    // // savedata: [],
    // savedata: [
    //   {
    //     // id: 0,
    //     // identifier: [],
    //     // status: "",
    //     // type: "",
    //     // name: "",
    //     // servicePeriod: "",
    //     // coverage: [
    //     //   {
    //     //     coverage_id: 0,
    //     //     priority: "",
    //     //   },
    //     // ],
    //     // organization_id: 0,
    //     // description: "",
    //     // guarantor: {
    //     //   onHold: false,
    //     //   period: "",
    //     //   relatedperson_id: 0,
    //     //   patient_id: 1114,
    //     //   organization_id: 0,
    //     // },
    //     // account_id: [],
    //     // device_id: [],
    //     payer: {
    //       priority: "",
    //       payer_type: {
    //         value: "Self Pay",
    //       },
    //       payer: "",
    //       guarantor_type: "",
    //       guarantor_code: "",
    //       membershipId: "",
    //       plan: "",
    //     },
    //     validity: {
    //       //   policy_start: 0,
    //       //   policy_expiry: 0,
    //       //   service_start: 0,
    //       //   service_end: 0,
    //     },
    //     membership: {
    //       //   type: {},
    //       //   relationship: {},
    //       //   member_id: "",
    //     },
    //     eligibility: {
    //       //   isverify: false,
    //       //   sponsor_id: "",
    //       //   sponsor_name: "",
    //     },
    //     financial: {
    //       //   class_type: {},
    //       //   class_value: {},
    //     },
    //     // applicable: {
    //     //   encounter: {},
    //     //   encounter_type: "",
    //     //   speciality: {},
    //     // },
    //     // patient_id: 0,
    //   },
    // ],
    // index: null,
    ...props?.financialDatas,
  });
  //This IsChange used for change the edit dropdown value to onchange dropdown
  const { payer, validity, membership, financial, eligibility,payerdetails } =
    props?.financialDatas;
  const [sponserName, setSponserName] = useState([]);
  const Getagaintspayer = (type, list) => {
    for (let index in type) {
      const item = type[index];
      var datatemp = {
        value: item?.payercode || item?.shortdesc,
        _id: item._id,
      };
      if (item?._id === props?.financialDatas?.payerdetails?.payer?._id) {
        return datatemp;
      }
    }
  };
  const setDetails = async (name, value, index, limit,check) => {
    let updatedPatientData = { ...financialData };
    let savedata = updatedPatientData.savedata || [];
    let currentData = savedata[index] || {};
    let payerData = currentData.payer || {};
    let membershipData = currentData.membership || {};
    props?.setAdd(false);
    props?.setError(false);
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let patientData = props?.financialDatas;
    let guarantortype;
    let guarantor;
    let get_Plan;
    if (patientData[index].error) {
      patientData[index].error[name] = false;
    }
    patientData[index][name] = value;
    if (name === "payer") {
      // guarantortype = await Getagaintspayer(
      //   props?.financialDatas?.payer?.guarantor_type
      // );
      // patientData.payerdetails.guarantor_type = guarantortype;
      // guarantor = await Getagaintspayer(
      //   props?.financialDatas?.payer?.guarantor
      // );
      // patientData.payerdetails.guarantor = guarantor;
      get_Plan = await Get_Plan(patientData?.payerdetails?.payer?._id);
      patientData["payer"]["plan"] = get_Plan;
    }
    if (name == "sponsor_name") {
      let data = sponserName.find((v) => v._id === value?._id);
      if (data) {
        patientData["membership"]["relationship"] = {
          value: data?.relatedperson?.display,
          _id: data?.relatedperson?._id,
        };
        setIsRelDisabled(true);
      } else {
        patientData["membership"]["relationship"] = {};
        setIsRelDisabled(false);
      }
    }

    if (name === "payer_type") {
      if (check) {
        patientData.payerdetails.payer = {
          value: "",
          _id: "",
        };
        patientData.payerdetails.guarantor_type = {
          value: "",
          _id: "",
        };
        patientData.payerdetails.guarantor = {
          value: "",
          _id: "",
        };
        patientData.payerdetails.plan = {
          value: "",
          _id: "",
        };
      }
      const payerValue = await PayerRequest(
        patientData?.payer?.payer_type?._id
      );
   if (name === "payer_type") {
        if (value === "Self Pay") {
          // If payer type is "Self Pay", set fields to non-mandatory
          payerData.error = {};
          payerData.priority = {};
          membershipData.error = {};
        } else {
          payerData.error = {
            payer_type: true,
          };
          payerData.priority = {
            value: payerData.priority?.value || "",
          };
          membershipData.error = {
            member_id: true,
          };
        }
      }
      currentData.payer = payerData;
      currentData.membership = membershipData;
      savedata[index] = currentData;

      updatedPatientData.savedata = savedata;
      patientData[index]["payer"] = payerValue;
      // const get_Plan = await Get_Plan(payerValue?.[0]?._id);
      // patientData[index]["plan"] = get_Plan;
      // const guarantor_typeValue = await GuarantorType(
      //   patientData?.payer?.payer_type?._id
      // );
      // patientData["payer"]["guarantor_type"] = guarantor_typeValue;
      // const guarantorValue = await GuarantorId(
      //   patientData?.payer?.payer_type?._id
      // );
      // patientData["payer"]["guarantor"] = guarantorValue;
    }
    const guarantor_typeValue = await GuarantorType(
      patientData?.payer?.payer_type?._id
    );
    patientData["payer"]["guarantor_type"] = guarantor_typeValue;
    const guarantorValue = await GuarantorId(
      patientData?.payer?.payer_type?._id
    );
    patientData["payer"]["guarantor"] = guarantorValue;
    // if (
    //   patientData?.payer?.policy_expiry < patientData?.payer?.policy_start ||
    //   patientData?.validity?.service_start > patientData?.validity?.service_end ||
    //   patientData?.payer?.policy_start > patientData?.validity?.service_start ||
    //   patientData?.payer?.policy_expiry < patientData?.validity?.service_end
    // ) {
    //   return;
    // }


    setFinancialData({ ...patientData });
    // props?.setFinancialDatas({ ...patientData });
    // autoFillLocations();
  };

  //-----------------x----------------
  // const autoFillLocations = async () => {
  //   let stateVal = { ...props?.payerData };
  //   // Get_Plan(payers?.[0]?._id)
  //   props?.setPayerData({
  //     ...stateVal,
  //     priority: financialData?.payer?.priority?._id,
  //     payer_type: financialData?.payer?.payer_type?._id,
  //     payer: payers,
  //     guarantor_type: guarantorType,
  //     guarantor: guarantorId,
  //     plan: plan,
  //     policy_start: financialData?.payer?.policy_start,
  //     policy_expiry: financialData?.payer?.policy_expiry,
  //     sponsor_name: financialData?.payer?.sponsor_name,
  //   });
  //   sendObject();
  //   props?.setErr(true);
  // };

  const FlterOptions = (type) => {
    const { allMasters } = props;
    let filteredArray = allMasters?.filter((obj) => obj.hasOwnProperty([type]));
    var lista = [];
    let data = filteredArray?.[0]?.[type]?.Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?.display,
        _id: data[index]?._id,
      };
      lista.push(datatemp);
    }
    return lista;
  };

  const FlterOptions3 = (props, type) => {
    let filteredArray = props?.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray?.[0]?.[type]?.Value;
    let savedFinance = financialData?.savedata;
    let list2 = [];
    savedFinance?.map((val) => {
      if(val?.payerdetails?.payer?._id !== process.env.REACT_APP_PAYER_SELFPAY){
        list2.push(val?.payer?.priority?.value)}
      });

    for (let index in data) {
      if (!list2?.includes(data[index]?.display)) {
        var datatemp = {
          value: data[index]?.display,
          _id: data[index]?._id,
        };

        list.push(datatemp);
      }
    }

    return list;
  };
  const GetIdAndDisplay = (data) => {
    let list = [];
    for (let index in data) {
      const item = data[index];
      var datatemp = {
        value: item.longdesc,
        _id: item._id,
      };
      list.push(datatemp);
    }
    return list;
  };
  const GetIdAndDisplay3 = (data) => {
    let list = [];
    for (let index in data) {
      const item = data[index];
      var datatemp = {
        value: item.payercode,
        _id: item._id,
      };
      list.push(datatemp);
    }
    return list;
  };
  const GetIdAndDisplay1 = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const isSavedPayerType = props?.financialDatas?.savedata?.some(
        (savedData) => savedData?.payer?.payer_type?._id === item?._id
      );

      // if (isSavedPayerType) {
      //   continue; // Skip to the next iteration if the payer type is already saved
      // }

      const datatemp = {
        value: item.longdesc,
        _id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const GetIdAndDisplay2 = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        value: item.name,
        _id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const {
    labelStyle,
    selectBorderStyle,
    inputBorderStyle,
    containedButton,
    outlinedButton,
    errorborderStyle,
  } = styles;
  const { parent_id } = props;
  const clearFinancialData = () => {
    setFinancialData(() => ({
      // payer: {},
      payer: {
        priority: {},
        payer_type: {},
        payer: {},
        guarantor_type: {},
        guarantor_code: {},
        membershipId: {},
        plan: {},
      },
      validity: {},
      membership: {},
      eligibility: {},
      payerdetails: {},
      financial: {},
      savedata: [
        {
          payer: {
            priority: "",
            payer_type: {},
            payer: "",
            guarantor_type: "",
            guarantor_code: "",
            membershipId: "",
            plan: "",
          },
          validity: {},
          membership: {},
          eligibility: {},
          financial: {},
        },
      ],
      index: null,
    }));
  };
  const handlesubmit = async () => {
    let patientData = props?.financialDatas;
    if (
      // payer?.payer_type?.value !== "Self Pay" &&
      // (
      patientData?.payer?.error?.plan === true ||
      patientData?.payer?.error?.payer === true ||
      patientData?.payer?.error?.payer_type === true ||
      patientData?.payer?.error?.policy_start === true ||
      patientData?.payer?.error?.policy_expiry === true ||
      patientData?.membership?.error?.member_id === true ||
      patientData?.membership?.error?.type === true ||
      patientData?.membership?.error?.relationship === true ||
      patientData?.membership?.error?.prime_member_id === true ||
      patientData?.payer?.error?.priority === true ||
      patientData?.payerdetails?.payer === "" ||
      patientData?.payerdetails?.payer === undefined ||
      patientData?.payerdetails?.plan === "" ||
      patientData?.payerdetails?.plan === undefined 
      // patientData?.payerdetails?.guarantor_type === "" ||
      // patientData?.payerdetails?.guarantor_type === undefined ||
      // patientData?.payerdetails?.guarantor === "" ||
      // patientData?.payerdetails?.guarantor === undefined
      // )
    ) {
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else {
      if (props?.financialDatas?.index !== null) {
        let patientData = props?.financialDatas;
        patientData.savedata[patientData.index] = {
          ...patientData.savedata[patientData.index],
          membership: patientData.membership,
          payer: patientData.payer,
          validity: patientData.validity,
          payerdetails: patientData.payerdetails,
          eligibility: patientData.eligibility,
          financial: patientData.financial,
          applicable: patientData.applicable,
        };
        let list =props?.financialDatas?.savedata?.filter((i)=>i?.payerdetails?.payer?._id !==process.env.REACT_APP_PAYER_SELFPAY).map((s)=> {return s?.payer?.priority?.value});
        let nextValue = 0;
        let priorityList =FlterOptions("PRIORITY");
        let valPriority = priorityList.map((s)=> {return s?.value});
        let maxP = 0;
        if(valPriority.length>0){
          maxP = Math.max(...valPriority);
        }
        if(list.length>0){
          nextValue = Math.max(...list) + 1;
          nextValue = nextValue > maxP ? maxP : nextValue;
        } else{
          nextValue = 1;
        }
        let index = props?.financialDatas?.savedata?.findIndex((i)=>i?.payerdetails?.payer?._id ===process.env.REACT_APP_PAYER_SELFPAY);
        if(index >= 0){
        let p = priorityList?.find((s)=>Number(s?.value)===Number(nextValue));
        patientData.savedata[index].payer.priority= p;
      }

        patientData.membership = {};
        patientData.payer = {};
        patientData.validity = {};
        patientData.eligibility = {};
        patientData.financial = {};
        patientData.payerdetails = {};
        patientData.applicable = {};
        patientData.index = null;
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Updated successsfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        await props?.setFinancialDatas({
          ...patientData,
        });
        clearFinancialData();
        return;
      } else {
        let patientData = props?.financialDatas;
        await patientData.savedata.push({
          membership: patientData.membership,
          payer: patientData.payer,
          validity: patientData.validity,
          payerdetails: patientData.payerdetails,
          eligibility: patientData.eligibility,
          financial: patientData.financial,
          applicable: patientData.applicable,
        });
        let list = patientData?.savedata?.filter((i)=>i?.payerdetails?.payer?._id !==process.env.REACT_APP_PAYER_SELFPAY).map((s)=> {return s?.payer?.priority?.value});
        let nextValue = 0;
        let priorityList =FlterOptions("PRIORITY");
        let valPriority = priorityList.map((s)=> {return s?.value});
        let maxP = 0;
        if(valPriority.length>0){
          maxP = Math.max(...valPriority);
        }
        if(list.length>0){
          nextValue = Math.max(...list) + 1;
          nextValue = nextValue > maxP ? maxP : nextValue;
        } else{
          nextValue = 1;
        }
        let index = patientData?.savedata?.findIndex((i)=>i?.payerdetails?.payer?._id ===process.env.REACT_APP_PAYER_SELFPAY);
        if(index >= 0){
        let p = priorityList?.find((s)=>Number(s?.value)===Number(nextValue));
        patientData.savedata[index].payer.priority= p;
      }

        patientData.membership = {};
        patientData.payer = {};
        patientData.validity = {};
        patientData.payerdetails = {};
        patientData.eligibility = {};
        patientData.financial = {};
        patientData.applicable = {};
        patientData.index = null;
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Added succsssfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        props?.setFinancialDatas({
          ...patientData,
        });
        clearFinancialData();
        props?.setIsOnEdit(false);
      }
    }
  };

  const onSave = (files) => {
    let finform = props?.financialDatas;
   // finform.payer.priority = props?.financialDatas?.savedata?.length + 1
    // Check if the payer type already exists in the entire savedata array
    const payerExists = finform.savedata.some(
      (data) =>
        data?.payerdetails?.payer?._id === finform?.payerdetails?.payer?._id
    );
    const planExists = finform.savedata.some(
      (data) =>
        data?.payerdetails?.plan?._id === finform?.payerdetails?.plan?._id
    )
    const memberExists = finform.savedata.some(
      (data) =>
        data?.membership?.member_id=== finform?.membership?.member_id
    )
    if(finform?.savedata[0] === undefined){
      finform?.savedata?.splice(0, 1);
    } 
    // if (payerExists && props?.isOnEdit === false) {
    //   props?.alert?.setSnack({
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Payer Already Exists",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //     tone: true,
    //   });
    //   return;
    // }
    
    // let payerExists = false;
    // let newpayerExists = finform.payerdetails?.payer?._id ?? "";
    // for (var i = 0; i < finform?.savedata.length; i++) {
    //   if (
    //     // i >= 0 &&
    //     newpayerExists != "" &&
    //     newpayerExists === finform?.savedata[i].payerdetails?.payer?._id
    //   ) {
    //     if (finform?.index === i) {
    //       payerExists = false;
    //       continue;
    //     } else {
    //       payerExists = true;
    //       break;
    //     }
    //   }
    // }
    // let planExists = false;
    // let newplanExists = finform.payerdetails?.plan?._id ?? "";
    // for (var i = 0; i < finform?.savedata.length; i++) {
    //   if (
    //     // i >= 0 &&
    //     newplanExists != "" &&
    //     newplanExists === finform?.savedata[i].payerdetails?.plan?._id
    //   ) {
    //     if (finform?.index === i) {
    //       planExists = false;
    //       continue;
    //     } else {
    //       planExists = true;
    //       break;
    //     }
    //   }
    // }
    let err = false;
    let newMemberId = finform?.membership?.member_id ?? "";
    for (var i = 0; i < finform?.savedata.length; i++) {
      if (
        // i >= 0 &&
        newMemberId != "" &&
        newMemberId === finform?.savedata[i]?.membership?.member_id
      ) {
        if (props?.isOnEdit === true && finform?.index === i) {
          err = false;
          continue;
        } else {
          err = true;
          break;
        }
      }
    }
    if (planExists && payerExists&& props?.isOnEdit === false) {
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Same payer and plan Already Exists",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    } 
    if (memberExists&& props?.isOnEdit === false) {
      context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Member Id Can't be Same as Before",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
      if (!props?.financialDatas?.payer?.payer_type?.value) {
        finform.payer["error"] = {
          payer_type: true,
          // plan: true,
          // payer: true,
          ...finform.payer["error"],
        };
      }
      if (!props?.financialDatas?.payer.policy_start) {
        finform.payer["error"] = {
          policy_start: true,
          ...finform.payer["error"],
        };
      }
      if (!props?.financialDatas?.payer.policy_expiry) {
        finform.payer["error"] = {
          policy_expiry: true,
          ...finform.payer["error"],
        };
      }
      if (!props?.financialDatas?.membership?.member_id) {
        finform.membership["error"] = {
          member_id: true,
          ...finform.membership["error"],
        };
      }
      if (!props?.financialDatas?.payerdetails?.plan?.value) {
        finform.payerdetails["error"] = {
          plan: true,
          ...finform.payerdetails["error"],
        };
      }
      if (!props?.financialDatas?.payerdetails?.payer?.value) {
        finform.payerdetails["error"] = {
          payer: true,
          ...finform.payerdetails["error"],
        };
      }
      if (!props?.financialDatas?.membership?.type?.value) {
        finform.membership["error"] = {
          type: true,
          ...finform.membership["error"],
        };
      }
      if (props?.financialDatas?.membership?.type?.value === "Dependent") {
        if (!props?.financialDatas?.membership?.relationship?.value) {
          finform.membership["error"] = {
            relationship: true,
            ...finform.membership["error"],
          };
        }
      }
      if (props?.financialDatas?.membership?.type?.value === "Dependent") {
        if (!props?.financialDatas?.membership?.prime_member_id) {
          finform.membership["error"] = {
            prime_member_id: true,
            ...finform.membership["error"],
          };
        }
      }
      // if (!props?.financialDatas?.payer.priority) {
      //   finform.payer["error"] = {
      //     priority: true,
      //     ...finform.payer["error"],
      //   };
      // }
      props?.setFinancialDatas({
        ...finform,
      }); 
    //  props?.setdragItems(listData)
      if (
        finform.payer?.payer_type &&
        // finform.payerdetails?.guarantor &&
        // finform.payerdetails?.guarantor_type &&
        finform.payerdetails?.payer?.value &&
        finform.payerdetails?.plan?.value &&
        finform.membership?.member_id &&
        finform.membership?.type &&
        finform.payer?.policy_start &&
        finform.payer?.policy_expiry
        // true
      ) {
        handlesubmit();
        props?.setIsOnEdit(false);
        props?.loadFinanceDetailsDefaultValues(finform?.savedata)
      } else {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill all the mandatory fields",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
    }
  };
  const GetSponserName = async () => {
    const response = await dispatch(
      actions.GET_SPONSER_NAME({ patientId: props?.location?.state?.patientId })
    );
    setSponserName(response?.payload?.data);
  };
  const PayerRequest = async (payerType) => {
    const response = await dispatch(
      actions.GET_PAYER({ payerType: payerType })
    );
    Get_Plan(response?.payload?.data?.[0]?._id);
    return response?.payload?.data;
  };
  const GuarantorType = async (guarantorType) => {
    const response = await dispatch(
      actions.GET_GUARANTOR_TYPE({ payerType: guarantorType })
    );
    return response?.payload?.data;
  };
  const GuarantorId = async (guarantorId) => {
    const response = await dispatch(
      actions.GET_GUARANTOR_ID({ payerType: guarantorId })
    );
    return response?.payload?.data;
  };
  const Get_Plan = async (planValue) => {
    const response = await dispatch(actions.Get_Plan({ payerId: planValue }));
    return response?.payload?.data;
  };
  // useEffect(() => {
  //   if (props?.add) {
  //     PayerRequest(props?.editPayer?.payer?.payer_type?._id);
  //     GuarantorType(props?.editPayer?.payer?.payer_type?._id);
  //     GuarantorId(props?.editPayer?.payer?.payer_type?._id);
  //   }
  // }, [props?.add]);
  const onCancel = () => {
    let patientData = financialData;
    patientData["membership"] = {};
    patientData["payer"] = {};
    patientData["validity"] = {};
    patientData["eligibility"] = {};
    patientData["financial"] = {};
    // patientData["applicable"] = {};
    patientData.index = null;
    setFinancialData({ ...patientData });
    props?.setFinancialDatas({ ...patientData });
  };
  useEffect(() => {
    if (props?.cleared) {
      props?.setFinancialDatas({ ...props?.financialDatas });
      clearFinancialData();
    }
  }, [props?.cleared]);

  useEffect(() => {
    props?.setFinancialDatas({ ...props?.financialDatas });
    setFinancialData({ ...props?.financialDatas });
    GetSponserName();
  }, []);
  useEffect(() => {
    setFinancialData({ ...props?.financialDatas });
    GetSponserName();
  }, [props, props?.location]);

  useEffect(() => {
    if (props?.defaultDataLoad) {
  setDetails("payer_type", props?.defaultPayerTypeLoad, "payer","",false);
    }
  }, [props?.defaultDataLoad]);

  // const setPayerDetails = (name,value,type) =>{

  //   let patientPayerDetails = props?.financialDatas;

  //   patientPayerDetails[type][name] = value

  //   setFinancialData({ ...patientPayerDetails });
  //   props?.setFinancialDatas({ ...patientPayerDetails });

  // }
  useEffect(() => {
    if (props?.isOnEdit) {
      setDetails(
        "payer_type",
        props?.editPayer?.payer?.payer_type ||
          props?.financialDatas?.payer?.payer_type,
        "payer",
        ""
      );
      setDetails(
        "payer",
        props?.editPayer?.payerdetails?.payer ||
          props?.financialDatas?.payerdetails?.payer,
        "payerdetails",
        ""
      );
      // setDetails("plan",props?.financialDatas?.payerdetails?.payer,"payerdetails","")
    }
  }, [props?.financialDatas?.payer?.payer_type, props?.isOnEdit]);
  return (
    <Div
      id={`${parent_id}_parent_div`}
      className="pv-root"
      style={{
        margin: "3px 15px",
      }}
    >
      <Paper id={`${parent_id}_parent_paper`} className="pl-root">
        <Row id={`${parent_id}_payer_parent_row`} className="en-actions-root">
          <Col
            id={`${parent_id}_payer_parent_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Div id={`${parent_id}_payer_title_div`}>
              <H6
                id={`${parent_id}_payer_label_div`}
                className="en-title space-padding-bottom"
              >
                PAYER DETAILS
              </H6>
            </Div>
            <Row id={`${parent_id}_payer_sub_row`} className="en-actions-root">
              {/* <Col
                id={`${parent_id}_payer_priority_div`}
                md={1}
                lg={1}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_payer_priority_select`}
                  // options={[
                  //   { value: "1" },
                  //   { value: "2" },
                  //   { value: "3" },
                  //   { value: "4" },
                  //   { value: "5" },
                  //   { value: "6" },
                  // ]}
                  options={FlterOptions3(props?.allMasters, "PRIORITY")}
                  placeholder="Priority"
                  value={payer?.priority?.value??""}
                  onChangeValue={(e) => setDetails("priority", e, "payer")}
                  label="Priority"
                  showArrow
                  labelStyle={labelStyle}
                  // inLineStyles={selectBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={props?.isViewMode}
                  isRequired
                  inLineStyles={
                    payer?.error && !financialData?.payer?.priority?.value
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                  key={payer?.priority?.value  ?? ""}
                />
              </Col> */}
              <Col
                id={`${parent_id}_payer_Reciver_Type_div`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_payer_Payer_Type_select`}
                  // options={[
                  //   { value: "TPA" },
                  //   { value: "Insurance" },
                  //   { value: "Corporate" },
                  //   // { value: "Self Pay" }
                  // ]}
                  options={GetIdAndDisplay1(props?.payerType)}
                  // isRequired
                  showArrow
                  // helperText={(payer?.error && payer?.error['reciver_type']) ? 'Is required' : ''}
                  error={
                    payer?.error && payer?.error["payer_type"] ? true : false
                  }
                  value={payer?.payer_type?.value??""}
                  //onChangeValue={(e) => setDetails("payer_type", e, "payer")}
                  onChangeValue={(e) => {
                    setDetails("payer_type", e, "payer","",true);
                  }}
                  label="Payer Type"
                  labelStyle={labelStyle}
                  style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  isRequired
                  disabled={props?.isViewMode}
                  key={payer?.payer_type?.value ?? ""}
                />
              </Col>
              <Col
                id={`${parent_id}_payer__div`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_payer_select`}
                  options={GetIdAndDisplay(
                    financialData?.payer?.payer ||
                      props?.financialDatas?.payer?.payer
                  )}
                                    error={
                    payer?.error && payer?.error["payer_type"] ? true : false
                  }
                  // value={
                  //   //  payerdetails?.payer?.value??""
                  //   //   // || props?.financialDatas?.payer?.payer?.[0]?.longdesc
                  //   // }
                  //   payerdetails?.payer !== undefined &&
                  //   payerdetails?.payer !== null
                  //     ? payerdetails?.payer?.value
                  //     : ""
                  // }
                  value={payerdetails?.payer?.value || ""}

                  // onChangeValue={(e) =>
                  //   setDetails(payers?.[0]?._id,"payer", e, "payer")
                  // }
                  onChangeValue={(e) =>
                    setDetails(
                      "payer",
                      e,
                      "payerdetails",
                      //  financialData?.payer?.payer?.[0]?.longdesc
                    )
                  }
                  label="Payer"
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={
                    // payer?.error && !financialData?.payerdetails?.payer?.value
                    financialData?.payerdetails?.error?.payer && !financialData?.payerdetails?.payer?.value
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                  style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  //isRequired
                  isRequired={payer?.payer_type?.value !== "Self Pay"}
                  disabled={props?.isViewMode}
                  key={payerdetails?.payer?.value ?? ""}
                />
              </Col>
              <Col
                id={`${parent_id}_payer_Guarantor_type_div`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_payer_Guarantor_type_select`}
                  // options={[
                  //   { value: "Union Insurance Company" },
                  //   { value: "United Insurance Company" },
                  //   { value: "National General Insurance Company" },
                  //   { value: "Orient Insurance" },
                  //   { value: "Noor Takeful" },
                  // ]}
                  options={GetIdAndDisplay3(
                    financialData?.payer?.guarantor_type
                  )}
                  placeholder="Guarantor Type"
                  // disabled={payer?.payer_type?.value === "TPA" ? false : true}
                  value={
                    financialData?.payerdetails?.guarantor_type?.value??""
                    // || financialData?.payer?.guarantor_type?.[0]?.payercode
                  }
                  onChangeValue={(e) =>
                    setDetails("guarantor_type", e, "payerdetails")
                  }
                  showArrow
                  label="Guarantor type"
                  labelStyle={labelStyle}
                  inLineStyles={selectBorderStyle}
                  // isRequired={payer?.payer_type?.value !== "Self Pay"}
                  style={{ ...inputBorderStyle }}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={props?.isViewMode}
                  key={payerdetails?.guarantor_type?.value?? ""}
                />
              </Col>
              <Col
                id={`${parent_id}_payer_Guarantor_id_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_payer_Guarantor_select`}
                  // options={[
                  //   { value: "EXE-Executive" },
                  //   { value: "SUP-Supervisor" },
                  //   { value: "VIP" },
                  // ]}
                  options={GetIdAndDisplay(
                    financialData?.payer?.guarantor
                  )}
                  placeholder="Guarantor"
                  showArrow
                  // disabled={payer?.payer_type?.value === "TPA" ? false : true}
                  value={
                    payerdetails?.guarantor?.value || ""
                    // || financialData?.payer?.guarantor?.[0]?.longdesc
                  }
                  onChangeValue={(e) =>
                    setDetails("guarantor", e, "payerdetails")
                  }
                  label="Guarantor"
                  labelStyle={labelStyle}
                  style={{ ...inputBorderStyle }}
                  hoverColor={UIColor.primaryColor.color}
                  //disabled={payer?.payer_type?.value==="Self Pay"}
                  disabled={props?.isViewMode}
                  inLineStyles={selectBorderStyle}
                  // isRequired={payer?.payer_type?.value !== "Self Pay"}
                  key={payerdetails?.guarantor?.value ?? ""}
                />
              </Col>
            </Row>
            <Row id={`${parent_id}_payer_sub_row`} className="en-actions-root">
              {/* <Col
                          id={`${parent_id}_payer_Policy_col`}
                          md={2} lg={2} sm={12} xs={12} className="no-padding-left space15-padding-top space-padding-bottom">
                          <Select
                              id={`${parent_id}_payer_Policy_select`}
                              // disabled
                              options={[
                                  { value: "EXE-Executive" },
                                  { value: "SUP-Supervisor" },
                                  { value: "VIP" },
                              ]}
                              placeholder='Policy'
                              showArrow
                              value={payer?.policy?.value}
                              isRequired
                              // helperText={(payer?.error && payer?.error['policy']) ? 'Is required' : ''}
                              error={(payer?.error && payer?.error['policy']) ? true : false}
                              onChangeValue={e => setDetails(financialDatas,"policy", e, "payer")}
                              label="Policy" labelStyle={labelStyle}
                              style={inputBorderStyle} hoverColor={UIColor.primaryColor.color} />
                      </Col> */}
              <Col
                id={`${parent_id}_payer_Plan_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left space15-padding-top space-padding-bottom"
              >
                <Select
                  id={`${parent_id}_payer_Plan_select`}
                  // options={[
                  //   { value: "GN-General" },
                  //   { value: "GC-Comprehensive" },
                  //   { value: "Gold Plan" },
                  //   { value: "Gold(+) Plan" },
                  //   { value: "EN-Enhanced" },
                  // ]}
                  options={GetIdAndDisplay(props?.financialDatas?.payer?.plan)}
                  value={
                    payerdetails?.plan?.value || ""
                    // || financialData?.payer?.plan?.[0]?.longdesc
                  }
                  // helperText={(payer?.error && payer?.error['plan']) ? 'Is required' : ''}
                  // isRequired
                  isRequired={payer?.payer_type?.value !== "Self Pay"}
                  placeholder="Plan"
                  onChangeValue={(e) => setDetails("plan", e, "payerdetails")}
                  label="Policy/Plan"
                  showArrow
                  labelStyle={labelStyle}
                  style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={props?.isViewMode}
                  inLineStyles={
                    financialData?.payerdetails?.error?.plan && !financialData?.payerdetails?.plan?.value
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                  key={payerdetails?.plan?.value?? ""}
                />
              </Col>
              <Col
                id={`${parent_id}_Policy_Start_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left space15-padding-top space-padding-bottom"
              >
                <TextInput
                  id={`${parent_id}_payer_Policy_Start_textinput`}
                  value={payer?.policy_start ? payer?.policy_start : ""}
                  //max={"2023-12-14"}
                  max={moment().format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setDetails("policy_start", e.target.value, "payer")
                  }
                  error={
                    payer?.error && payer?.error["policy_start"] ? true : false
                  }
                  label="Policy Start"
                  type="date"
                  dateIcon={CalendarKeyIcon}
                  labelStyle={labelStyle}
                  // style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  //isRequired
                  isRequired={payer?.payer_type?.value !== "Self Pay"}
                  inLineStyles={
                    payer?.error && payer?.error["policy_start"]
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                  disabled={props?.isViewMode}
                />
                {/* <CustomInputDatePicker
                                  disabled = {false}
                                  placeholderText = "dd / mm / yyyy" 
                                  maxDate = {new Date("9999-12-31")}
                                  handleChange = {(value) => setDetails("policy_start", value ? moment(value).format("YYYY-MM-DD") : null, "validity")}
                                  selectedDate = {validity?.policy_start ? new Date(validity?.policy_start) : ''}
                                  inputField = {
                                      <TextInput
                                          id={`${parent_id}_Policy_Start_textinput`}
                                          label = "Policy Start"
                                          placeholder = "dd / mm / yyyy"
                                          dateIcon="images/icons8-calendar-grey.svg" 
                                          labelStyle={labelStyle} 
                                          style={inputBorderStyle} 
                                          hoverColor={UIColor.primaryColor.color}
                                          value={validity?.policy_start ? moment(new Date(validity?.policy_start)).format("MM/DD/YYYY") : ''}
                                      />
                                  }
                              /> */}
              </Col>
              <Col
                id={`${parent_id}_payer_Policy_Expiry_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left space15-padding-top space-padding-bottom"
              >
                <TextInput
                  id={`${parent_id}_payer_Policy_Expiry_textinput`}
                  error={
                    payer?.error && payer?.error["policy_expiry"] ? true : false
                  }
                  value={payer?.policy_expiry ? payer?.policy_expiry : ""}
                  onChange={(e) =>
                    setDetails("policy_expiry", e.target.value, "payer")
                  }
                  label="Policy Expiry"
                  type="date"
                  //max={"9999-12-31"}
                  min={moment().format("YYYY-MM-DD")}
                  dateIcon={CalendarKeyIcon}
                  labelStyle={labelStyle}
                  // style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  //isRequired
                  isRequired={payer?.payer_type?.value !== "Self Pay"}
                  inLineStyles={
                    payer?.error && payer?.error["policy_expiry"]
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                  disabled={props?.isViewMode}
                />

                {/* <CustomInputDatePicker
                                  disabled = {false}
                                  placeholderText = "dd / mm / yyyy" 
                                  maxDate = {new Date("9999-12-31")}
                                  handleChange = {(value) => setDetails("policy_expiry",value ? moment(value).format("YYYY-MM-DD") : null , "validity")}
                                  selectedDate = {validity?.policy_expiry ? new Date(validity?.policy_expiry) : ''}
                                  inputField = {
                                      <TextInput
                                          id={`${parent_id}_Policy_Expiry_textinput`}
                                          label = "Policy Expiry"
                                          placeholder = "dd / mm / yyyy"
                                          dateIcon="images/icons8-calendar-grey.svg" 
                                          labelStyle={labelStyle} 
                                          style={inputBorderStyle} 
                                          hoverColor={UIColor.primaryColor.color}
                                          value={validity?.policy_expiry ? moment(new Date(validity?.policy_expiry)).format("MM/DD/YYYY") : ''}
                                      />
                                  }
                              /> */}
              </Col>
              {/* <Col
                id={`${parent_id}_membership_Eligibility_Sponsor_ID_col`}
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="no-padding-left space15-padding-top space-padding-bottom"
              >
                <TextInput
                  id={`${parent_id}_membership_Eligibility_Sponsor_ID_textinput`}
                  type="number"
                  placeholder="Sponsor id"
                  value={eligibility?.sponsor_id ? eligibility?.sponsor_id : ""}
                  onChange={(e) =>
                    setDetails(
                      financialDatas,
                      "sponsor_id",
                      e.target.value,
                      "eligibility",
                      60
                    )
                  }
                  label="Sponsor ID"
                  labelStyle={labelStyle}
                  style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col> */}
            </Row>
          </Col>
        </Row>
        <Divider
          id={`${parent_id}_bottom_divider`}
          style={{
            margin: "10px 0px",
            borderColor: "rgba(0, 0, 0, 0.08)",
            backgroundColor: "rgba(0, 0, 0, 0.08)",
          }}
        />
        <Row
          id={`${parent_id}_membership_parent_row`}
          className="en-actions-root space-padding-bottom"
        >
          <Col
            id={`${parent_id}_membership_parent_col`}
            md={6}
            lg={6}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Div id={`${parent_id}_membership_title_div`}>
              <H6
                id={`${parent_id}_membership_label_h6`}
                className="en-title space-padding-bottom"
              >
                MEMBERSHIP DETAILS
              </H6>
            </Div>
            <Row
              id={`${parent_id}_membership_sub_row`}
              className="en-actions-root"
            >
              <Col
                id={`${parent_id}__membership_Membership_Type_col`}
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  autoFocus={true}
                  id={`${parent_id}_membership_Membership_ID_textinput`}
                  placeholder="Membership ID"
                  value={membership?.member_id ? membership.member_id : ""}
                  error={
                    membership?.error && membership?.error["member_id"]
                      ? true
                      : false
                  }
                  onChange={(e) =>
                    setDetails("member_id", e.target.value, "membership", 30)
                  }
                  label="Membership ID"
                  labelStyle={labelStyle}
                  // style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  isRequired={payer?.payer_type?.value !== "Self Pay"}
                  inLineStyles={
                    membership?.error && membership?.error["member_id"]
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                  disabled={props?.isViewMode}
                />
              </Col>
              <Col
                id={`${parent_id}_membership_Membership_Type_col`}
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_membership_Membership_Type_select`}
                  // options={[{ value: "Dependent" }, { value: "self" }]}
                  options={FlterOptions3(props?.allMasters, "MEMBERTYPE")}
                  placeholder="Membership type"
                  value={membership?.type?.value}
                  onChangeValue={(e) => setDetails("type", e, "membership")}
                  label="Membership Type"
                  showArrow
                  error={
                    membership?.error && membership?.error["type"]
                      ? true
                      : false
                  }
                  labelStyle={labelStyle}
                  // inLineStyles={selectBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  //isRequired
                  isRequired={payer?.payer_type?.value !== "Self Pay"}
                  inLineStyles={
                    membership?.error && membership?.error["type"]
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                  disabled={props?.isViewMode}
                />
              </Col>
              {/* <Col
                id={`${parent_id}_membership_Sponsor_name_col`}
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_membership_Sponsor_name_textinput`}
                  placeholder="Sponsor name"
                  value={payer?.sponsor_name ? payer?.sponsor_name : ""}
                  onChange={(e) =>
                    setDetails("sponsor_name", e.target.value, "membership", 60)
                  }
                  label="Sponsor Name"
                  labelStyle={labelStyle}
                  style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col> */}
              <Col
                id={`${parent_id}_membership_Member_Sponsor_name_col`}
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_membership_Member_Sponsor_name_select`}
                  placeholder="Sponsor name"
                  options={GetIdAndDisplay2(sponserName)}
                  error={
                    membership?.error && membership?.error["sponsor_name"]
                      ? true
                      : false
                  }
                  value={membership?.sponsor_name?.value}
                  disabled={
                    membership?.type?.value === "Self" || props?.isViewMode
                  }
                  onChangeValue={(e) =>
                    setDetails("sponsor_name", e, "membership")
                  }
                  label="Sponsor Name"
                  showArrow
                  labelStyle={labelStyle}
                  // inLineStyles={selectBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  inLineStyles={
                    membership?.error && membership?.error["sponsor_name"]
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                  // isRequired={
                  //   membership?.type?.value === "Dependent" ? true : false
                  // }
                />
              </Col>
              <Col
                id={`${parent_id}_membership_Member_Relationship_col`}
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_membership_Member_Relationship_select`}
                  placeholder="Member relationship"
                  // options={FlterOptions("RELATIONSHIP")}
                  options={FlterOptions3(props?.allMasters, "RELATIONSHIP")}
                  error={
                    membership?.error && membership?.error["relationship"]
                      ? true
                      : false
                  }
                  value={membership?.relationship?.value}
                  disabled={
                    membership?.type?.value === "Self" ||
                    props?.isViewMode ||
                    isRelDisabled
                  }
                  onChangeValue={(e) =>
                    setDetails("relationship", e, "membership")
                  }
                  label="Member Relationship"
                  showArrow
                  labelStyle={labelStyle}
                  // inLineStyles={selectBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  inLineStyles={
                    membership?.error && membership?.error["relationship"]
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                  isRequired={
                    membership?.type?.value === "Dependent" ? true : false
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_membership_Prime_Member_ID_col`}
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_membership_prime_Membership_ID_textinput`}
                  placeholder="Prime member id"
                  value={
                    membership?.prime_member_id
                      ? membership?.prime_member_id
                      : ""
                  }
                  error={
                    membership?.error && membership?.error["prime_member_id"]
                      ? true
                      : false
                  }
                  onChange={(e) =>
                    setDetails(
                      "prime_member_id",
                      e.target.value,
                      "membership",
                      20
                    )
                  }
                  disabled={
                    props?.isViewMode || membership?.type?.value === "Dependent"
                      ? false
                      : true
                  }
                  label="Prime Member ID"
                  labelStyle={labelStyle}
                  // style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  isRequired={
                    membership?.type?.value == "Dependent" ? true : false
                  }
                  style={
                    membership?.type?.value == "Dependent" &&
                    membership?.error &&
                    !membership?.prime_member_id
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                />
              </Col>
            </Row>
          </Col>
          {/* <Col
            id={`${parent_id}_membership_Eligibility_parent_col`}
            md={6}
            lg={6}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Div id={`${parent_id}_membership_Eligibility_title_div`}>
              <H6
                id={`${parent_id}_membership_Eligibility_label_h6`}
                className="en-title space-padding-bottom"
              >
                ELIGIBILITY DETAILS
              </H6>
            </Div>
            <Row
              id={`${parent_id}_membership_Eligibility_label_h6`}
              className="en-actions-root"
            >
              <Col
                id={`${parent_id}_membership_Eligibility_Verification_col`}
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <H6
                  id={`${parent_id}_membership_Eligibility_Verification_label_h6`}
                  className="default-size pc_regular"
                >
                  Verification Required
                </H6>
                <Checkbox
                  id={`${parent_id}_membership_Eligibility_Verification_checkbox`}
                  name=""
                  checked={eligibility?.isverify}
                  onChange={(e) =>
                    setDetails(
                      "isverify",
                      !eligibility?.isverify,
                      "eligibility"
                    )
                  }
                  color={UIColor.secondaryButtonColor.color}
                />
              </Col>
            </Row>
          </Col> */}
        </Row>
        <Divider
          id={`${parent_id}_payer_bottom_divider`}
          style={{
            margin: "10px 0px",
            borderColor: "rgba(0, 0, 0, 0.08)",
            backgroundColor: "rgba(0, 0, 0, 0.08)",
          }}
        />
        <Row
          id={`${parent_id}_validity_row`}
          className="en-actions-root space-padding-bottom"
        >
          <Col
            id={`${parent_id}_validity_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Div id={`${parent_id}_validity_title_div`}>
              <H6
                id={`${parent_id}_validity_label_h6`}
                className="en-title space-padding-bottom"
              >
                GUARANTEE LETTER DETAILS
              </H6>
            </Div>
            <Row
              id={`${parent_id}_guarantee_letter_details_row`}
              className="en-actions-root"
            >
              <Col
                id={`${parent_id}_guarantee_letter_details_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_guarantee_letter_details_Gl_Number_textinput`}
                  value={validity?.gl_number ? validity?.gl_number : " "}
                  placeholder="GL Number"
                  type="text"
                  onChange={(e) =>
                    setDetails("gl_number", e.target.value, "validity", 20)
                  }
                  label="GL Number"
                  labelStyle={labelStyle}
                  style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={props?.isViewMode}
                />
                {/* <CustomInputDatePicker
                                  disabled = {false}
                                  placeholderText = "dd / mm / yyyy" 
                                  maxDate = {new Date("9999-12-31")}
                                  handleChange = {(value) => setDetails("policy_start", value ? moment(value).format("YYYY-MM-DD") : null, "validity")}
                                  selectedDate = {validity?.policy_start ? new Date(validity?.policy_start) : ''}
                                  inputField = {
                                      <TextInput
                                          id={`${parent_id}_Policy_Start_textinput`}
                                          label = "Policy Start"
                                          placeholder = "dd / mm / yyyy"
                                          dateIcon="images/icons8-calendar-grey.svg" 
                                          labelStyle={labelStyle} 
                                          style={inputBorderStyle} 
                                          hoverColor={UIColor.primaryColor.color}
                                          value={validity?.policy_start ? moment(new Date(validity?.policy_start)).format("MM/DD/YYYY") : ''}
                                      />
                                  }
                              /> */}
              </Col>
              <Col
                id={`${parent_id}_guarantee_letter_details_gl_issue_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_guarantee_letter_details_gl_issue_textinput`}
                  value={validity?.gl_start ? validity?.gl_start : ""}
                  onChange={(e) =>
                    setDetails("gl_start", e.target.value, "validity")
                  }
                  label="GL Issue"
                  type="date"
                  max={"9999-12-31"}
                  dateIcon={CalendarKeyIcon}
                  labelStyle={labelStyle}
                  style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={props?.isViewMode}
                />

                {/* <CustomInputDatePicker
                                  disabled = {false}
                                  placeholderText = "dd / mm / yyyy" 
                                  maxDate = {new Date("9999-12-31")}
                                  handleChange = {(value) => setDetails("policy_expiry",value ? moment(value).format("YYYY-MM-DD") : null , "validity")}
                                  selectedDate = {validity?.policy_expiry ? new Date(validity?.policy_expiry) : ''}
                                  inputField = {
                                      <TextInput
                                          id={`${parent_id}_Policy_Expiry_textinput`}
                                          label = "Policy Expiry"
                                          placeholder = "dd / mm / yyyy"
                                          dateIcon="images/icons8-calendar-grey.svg" 
                                          labelStyle={labelStyle} 
                                          style={inputBorderStyle} 
                                          hoverColor={UIColor.primaryColor.color}
                                          value={validity?.policy_expiry ? moment(new Date(validity?.policy_expiry)).format("MM/DD/YYYY") : ''}
                                      />
                                  }
                              /> */}
              </Col>
              <Col
                id={`${parent_id}_Service_Start_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_Service_Start_textinput`}
                  value={validity?.service_start ? validity?.service_start : ""}
                  onChange={(e) =>
                    setDetails("service_start", e.target.value, "validity")
                  }
                  label="Service Start"
                  type="date"
                  //max={"9999-12-31"}
                  max={moment().format("YYYY-MM-DD")}
                  dateIcon={CalendarKeyIcon}
                  labelStyle={labelStyle}
                  style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={props?.isViewMode}
                />
                {/* <CustomInputDatePicker
                                  disabled = {false}
                                  placeholderText = "dd / mm / yyyy" 
                                  maxDate = {new Date("9999-12-31")}
                                  handleChange = {(value) => setDetails("service_start", value ? moment(value).format("YYYY-MM-DD") : null, "validity")}
                                  selectedDate = {validity?.service_start ? new Date(validity?.service_start) : ''}
                                  inputField = {
                                      <TextInput
                                          id={`${parent_id}_Service_Start_textinput`}
                                          label = "Service Start"
                                          placeholder = "dd / mm / yyyy"
                                          dateIcon="images/icons8-calendar-grey.svg" 
                                          labelStyle={labelStyle} 
                                          style={inputBorderStyle} 
                                          hoverColor={UIColor.primaryColor.color}
                                          value={validity?.service_start ? moment(new Date(validity?.service_start)).format("MM/DD/YYYY") : ''}
                                      />
                                  }
                              /> */}
              </Col>
              <Col
                id={`${parent_id}_Service_end_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_Service_end_textinput`}
                  value={validity?.service_end ? validity?.service_end : ""}
                  onChange={(e) =>
                    setDetails("service_end", e.target.value, "validity")
                  }
                  label="Service End"
                  type="date"
                  //max={"9999-12-31"}
                  min={moment().format("YYYY-MM-DD")}
                  dateIcon={CalendarKeyIcon}
                  labelStyle={labelStyle}
                  style={inputBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={props?.isViewMode}
                />

                {/* <CustomInputDatePicker
                                  disabled = {false}
                                  placeholderText = "dd / mm / yyyy" 
                                  maxDate = {new Date("9999-12-31")}
                                  handleChange = {(value) => setDetails("service_end", value ? moment(value).format("YYYY-MM-DD") : null , "validity")}
                                  selectedDate = {validity?.service_end ? new Date(validity?.service_end) : ''}
                                  inputField = {
                                      <TextInput
                                          id={`${parent_id}_Service_end_textinput`}
                                          label = "Service End"
                                          placeholder = "dd / mm / yyyy"
                                          dateIcon="images/icons8-calendar-grey.svg" 
                                          labelStyle={labelStyle} 
                                          style={inputBorderStyle} 
                                          hoverColor={UIColor.primaryColor.color}
                                          value={validity?.service_end ? moment(new Date(validity?.service_end)).format("MM/DD/YYYY") : ''}
                                      />
                                  }
                              /> */}
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider
          id={`${parent_id}_membership_bottom_divider`}
          style={{
            margin: "10px 0px",
            borderColor: "rgba(0, 0, 0, 0.08)",
            backgroundColor: "rgba(0, 0, 0, 0.08)",
          }}
        />
        <Row
          id={`${parent_id}_Financial_parent_row`}
          className="en-actions-root"
        >
          <Col
            id={`${parent_id}_Financial_parent_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Div id={`${parent_id}_Financial_title_div`}>
              <H6
                id={`${parent_id}_Financial_label_h6`}
                className="en-title space-padding-bottom"
              >
                FINANCIAL CLASS
              </H6>
            </Div>
            <Row
              id={`${parent_id}_Financial_sub_row`}
              className="en-actions-root"
            >
              <Col
                id={`${parent_id}_Financial_Class_Type_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_Financial_Class_Type_select`}
                  // options={[{ value: "Subsidy class" }]}
                  options={FlterOptions3(
                    props?.allMasters,
                    "FINANCIALCLASSTYPE"
                  )}
                  value={financial?.class_type?.value}
                  placeholder="Financial class type"
                  onChangeValue={(e) =>
                    setDetails("class_type", e, "financial")
                  }
                  label="Financial Class Type"
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={selectBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={props?.isViewMode}
                />
              </Col>
              <Col
                id={`${parent_id}_Financial_Class_value_col`}
                md={3}
                lg={3}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_Financial_Class_value_select`}
                  // options={[
                  //   { value: "STU-Student" },
                  //   { value: "BL-Blood donor" },
                  //   { value: "CPEN-Pensioner(Cancel)" },
                  //   { value: "SPEN-Pensioner(State)" },
                  // ]}
                  options={FlterOptions3(props?.allMasters, "FINANCIALCLASS")}
                  value={financial?.class_value?.value}
                  placeholder="Financial class value"
                  onChangeValue={(e) =>
                    setDetails("class_value", e, "financial")
                  }
                  label="Financial Class Value"
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={selectBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  disabled={props?.isViewMode}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Div
          id={`${parent_id}_Financial_submit_div`}
          className="p-continue-action"
        >
          {/* {!props?.isViewMode && (
          <Button
            id={`${parent_id}_Financial_cancel_buttom`}
            style={{ ...outlinedButton, marginRight: "15px" }}
            onClick={() => onCancel()}
            className="pc_medium"
          >
            Cancel
          </Button>
          )} */}
          {!props?.isViewMode ? (
            <Button
              id={`${parent_id}_Financial_save_buttom`}
              inLineStyles={containedButton}
              onClick={() => onSave()}
            >
              Save
            </Button>
          ) : (
            <Button
              id={`${parent_id}_Financial_save_buttom`}
              inLineStyles={containedButton}
              onClick={() => props?.viewModeNavigateToReferal()}
            >
              Continue to Referral Registration
            </Button>
          )}
        </Div>
      </Paper>
    </Div>
  );
}

export default withAllContexts(CardDetails);
