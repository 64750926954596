import React, { useEffect, useState } from "react";
import {
  Div,
  Paper,
  TextInput,
  H6,
  Col,
  Row,
  Button,
  Select,
} from "qdm-component-library";
import { useDispatch } from "react-redux";
import { AxiosCall, urls, AlertProps } from "../utils";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { UIColor } from "../themes/theme";
import withAllContexts from "../hoc/withAllContexts";
import moment from "moment";

const PatientReviewChart = (props) => {
  let styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    containedButton: {
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontFamily: "pc_medium !important",
      fontSize: 14,
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "#FF0000",
    },
  };
  // const dispatch = useDispatch();
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const [cashierdatas, setCashierdatas] = useState({
    StartDate: moment().format("YYYY-MM-DD"),
    EndDate: moment().format("YYYY-MM-DD"),
    practId:{}
  });

  console.log(moment.unix().format("YYYY-MM-DD"));
  //  const [facilityNameData, setFacilityNameData] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState("");

  const setData = (name, value) => {
    let updatedData = { ...cashierdatas };
    updatedData[name] = value;
    setCashierdatas(updatedData);
  };

  //   const facilityName = async () => {
  //     const response = await dispatch(
  //       actions.Facility_Load_Query_DROPDOWN({
  //         organizationId: decodedUserinfo?.facilityid,
  //       })
  //     );
  //     setFacilityNameData(response?.payload?.data);
  //   };

  const { labelStyle, borderStyle, containedButton } = styles;
  const { parent_id } = props;
  useEffect(() => {
    // facilityName();
    // practitionerName();
  }, []);
  const currentDate = new Date().toISOString().split("T")[0];
  useEffect(() => {
    if (!cashierdatas || Object.keys(cashierdatas).length === 0) {
      setData("date", currentDate);
    }
  }, []);

  function dateToEpoch(dateString) {
    var date = new Date(dateString);
    var epochTime = date.getTime();
    var epochTimeInSeconds = Math.floor(epochTime / 1000);
    return epochTimeInSeconds;
  }

  const generateAndOpenJasperReport = async () => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    if(!(cashierdatas?.practId?._id) || cashierdatas?.StartDate === "" || cashierdatas?.EndDate===""){
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else {
      const payload = {
        reportid: process.env.REACT_APP_PATIENT_REVIEW_CHART_REPORT_ID,
        inputparams: {
          "@practitionerid": cashierdatas?.practId?._id,
          "@facilityid": decodedUserinfo?.facilityid,
          "@startdate": moment
            .unix(dateToEpoch(cashierdatas?.StartDate))
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .unix(),
          "@enddate": moment
            .unix(dateToEpoch(cashierdatas?.EndDate))
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 0,
            })
            .unix(),
        },
        result: [],
      };
      AxiosCall("post", urls.jasperReport, payload, header)
        .then(async (res) => {
          if (res?.downloadUrl) {
            const response = await axios.get(res?.downloadUrl, {
              method: "GET",
              responseType: "blob",
            });
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });
            const pdfDataUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfDataUrl, "_blank");
            // setDownloadUrl(res?.downloadUrl);
            // setFileType("pdf");
          } else {
            props?.alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "There is no report available.",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
          }
        })
        .catch((err) => {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "something went wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        });
      props?.handleModalClose();
    }
  };
  useEffect(() => {
    if (downloadUrl) {
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.target = "_blank";
      downloadLink.download = "report.pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [downloadUrl]);
  const FlterOptions =() => {
    let resp = props?.PractList;
    var lista = []
    for (let index in resp) {
        var datatemp = {
            value: resp[index]?.name,
            _id: resp[index]?.id,
            label:resp[index]?.name,
        };
        lista.push(datatemp);
    }
    return  lista
  };
  return (
    <Div id={`${parent_id}referral_parent_parent_div`} className="pi-root">
      <Paper
        id={`${parent_id}referral_parent_parent_paper`}
        className="pi-paper"
      >
        <Div id={`${parent_id}referral_parent_label_div`}>
          <H6 id={`${parent_id}referral_parent_label_h6`} className="pi-title">
            Patient Review Chart Report
          </H6>
        </Div>
        <Row>
          <Col
            id={`${parent_id}referral_parent_Referring_from_Facility_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            {" "}
             <Select
              id={`${parent_id}_birthdetails_birthgender_select`}
              onChangeValue={(e) => setData("practId", e)}
              options={FlterOptions()}
              value={cashierdatas?.practId?.value}
              // options={genderOptions}
              label="Practitioner"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
              isRequired={true}
            />
          </Col>
        </Row>
        <Row id={`${parent_id}referral_parent_parent_row`}>
          <Col
            id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <TextInput
              id={`${parent_id}referral_parent_Date_of_Referral_In_label_textinput`}
              onChange={(e) => setData("StartDate", e.target.value)}
              value={cashierdatas?.StartDate}
              label="Start Date"
              //   placeholder=""
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor="#0071F2"
              type="date"
              disabled={props?.IsViewMode}
              isRequired={true}
            />
          </Col>
        </Row>
        <Row>
          <Col
            id={`${parent_id}referral_parent_Referring_from_Facility_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            {" "}
            <TextInput
              id={`${parent_id}referral_parent_Date_of_Referral_In_label_textinput`}
              onChange={(e) => setData("EndDate", e.target.value)}
              value={cashierdatas?.EndDate}
              label="End Date"
              placeholder=""
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor="#0071F2"
              type="date"
              disabled={props?.IsViewMode}
              isRequired={true}
            />
          </Col>
        </Row>

       

        <Button
          id={`${parent_id}_Financial_save_buttom`}
          inLineStyles={{
            ...containedButton,
            marginTop: 30,
            marginBottom: 20,
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}
          onClick={() => generateAndOpenJasperReport()}
        >
          Submit
        </Button>
      </Paper>
    </Div>
  );
};

export default withAllContexts(PatientReviewChart);
