import React, { useContext, useEffect, useState } from "react";
import actions from "../../../../../../redux/actions/index";
// import { NamespacesConsumer} from 'react-i18next';
import qdmstyles from "./styles.json";
import CollapseAppoinmentCard from "../collapseAppoinmentCard/collapseAppoinmentCard";
import Tapcomp from "../../../../components/tapComp";
import { DirectConsultation } from "../../../../../../components/svg/components/directconsultation.js";
import { Suggested } from "../../../../../../components/svg/components/suggested";
import { Videoconsultation } from "../../../../../../components/svg/components/videoconsultation";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Container,
  H6,
  Divider,
  Text,
  Div,
  // Icons,
  WeekCalendar,
  Tab,
  Tabpanel,
  Button,
} from "qdm-component-library";
import moment from "moment";
import {
  AlertProps,
  DDMMYYYHHmm_to_epoch,
  MMDDYYYY_sep_by_hyphen_HHmm_to_epoch,
  YYYYMMDD_sep_by_hyphen_HHmm_to_epoch,
  getReccuranceDates,
  getTimeSlot,
  getTimeString,
  utcTOLocal,
} from "../../../../../../utils";
import { useDispatch } from "react-redux";
import { useStyles } from "./style";
import { useSelector } from "react-redux";
import { UIColor } from "../../../../../../themes/theme";
import {
  AlertContext,
  PatientDetailsContext,
  SetPatientDetailsContext,
} from "../../../../../../context";
import jwtDecode from "jwt-decode";

function BookMultipleAppointmentsLists(props) {
  const dispatch = useDispatch();
  let decodedToken = jwtDecode(localStorage.getItem("UserInfo"));
  const alert = useContext(AlertContext);
  const setPatientDetail = useContext(SetPatientDetailsContext);
  const patientDetails = useContext(PatientDetailsContext);

  console.log({ alert });

  const selector = useSelector((state) => {
    return {
      reccurantApp: state?.appointment?.reoccuranceAppointment,
    };
  });
  const classes = useStyles();
  const [state, setState] = useState({
    info: props.info,
    healthCareId: props.info.practData?.healthCareId || [],
    slotObj: props?.multiApppointmentInfo?.slotObj || {},
  });

  useEffect(() => {
    if (props?.info?.appointments?.startsOn)
      setState((prevstate) => ({
        ...prevstate,
        info: props.info,
        healthCareId: props.info.practData?.healthCareId || [],
        slotObj: props?.multiApppointmentInfo?.slotObj || {},
      }));
  }, [props?.info?.appointments?.startsOn]);

  const changeState = (key, value) => {
    setState({
      [key]: value,
    });
  };

  const handleDateChange = async (newDate, oldInfo) => {
    const dates = [...state.info.dates];
    const index = dates?.indexOf(oldInfo.time);
    let newStartDate = MMDDYYYY_sep_by_hyphen_HHmm_to_epoch(newDate, "00:00");
    let isDateIsThere = dates.includes(newStartDate.toFixed());
    if (!isDateIsThere) {
      if (index >= 0) {
        const { practData } = state.info;
        const slotInfo = { ...state.slotObj };
        const datesToBeRemoved = dates?.splice(index, 1);
        datesToBeRemoved.forEach((date) => {
          delete slotInfo[date];
        });

        let payload = {
          startDate: newStartDate,
          endOccurence: 1,
          endDate: 0,
          resourceId: props?.shortcut
            ? decodedToken?.locationid
            : patientDetails?.selectedSlot?.info?.resourcecode ||
              props?.slotInfo?.resourcecode,
          frequencyValue: 1,
          frequencyUom: process.env.REACT_APP_FREQUENCY_UOM_DAYS,
          preferredDay: [],
          preferredWeek: [],
          facilityId: decodedToken?.facilityid,
          isfrontdesk: Boolean(decodedToken?.rolename),
        };
        let data = await dispatch(actions.RECCURANCE_APPOINTMENT(payload));

        const slotObj = {};
        if (data?.payload?.data && Array.isArray(data.payload.data)) {
          data.payload.data.forEach((schedule) => {
            // there is no schedule table
            const slot = {
              morning: [],
              afternoone: [],
              evening: [],
              night: [],
              allSession: [],
              isClinic: !!practData?.isClinic,
              healthCareId: state.healthCareId,
              isSlotAvailable: false,
              isSlotUpdated: false,
              selectedSlotId: 0,
              selectedSlotInfo: {},
              isEmpty: false,
              time: schedule.start,
            };
            filterSlots(
              {
                resourcecode: schedule.resourcecode, //there is no resource value in schedule it is in slot
                resourcerole: schedule.resourcerole, // leaving its as it is im changing that in filter slot function
                resourcetype: schedule.resourcetype,
              },
              schedule.slots,
              slot.morning,
              slot.afternoone,
              slot.evening,
              slot.night,
              slot.allSession,
              slot.isClinic,
              slot.healthCareId,
              slot
            );
            slotObj[schedule.start] = slot;
          });
        }
        // state.info.dates?.forEach((date) => {
        //   if (!(date in slotObj)) {
        //     const slot = {
        //       morning: [],
        //       afternoone: [],
        //       evening: [],
        //       night: [],
        //       allSession: [],
        //       isClinic: !!practData?.isClinic,
        //       healthCareId: state.healthCareId,
        //       isSlotAvailable: false,
        //       isSlotUpdated: false,
        //       selectedSlotId: 0,
        //       selectedSlotInfo: {},
        //       isEmpty: true,
        //       time: date,
        //     };
        //     slotObj[date] = slot;
        //   }
        // });
        let stateData = JSON.parse(JSON.stringify(state));
        stateData["slotObj"] = { ...slotObj, ...slotInfo };
        stateData["info"]["dates"] = [...Object.keys(slotObj), ...dates];
        setState(stateData);

        // getReccuranceDates(
        //   moment(newDate, "MM-DD-YYYY").format("DD-MM-YYYY"),
        //   "NoOfDays",
        //   Number(appointments.occurence.occurrenceValue),
        //   appointments.reccurance.reccuranceType.value,
        //   appointments.reccurance.reccuranceValue,
        //   appointments.preferrence.preferredDays,
        //   appointments.preferrence.preferredWeeks,
        //   dates
        // );
        // setState(
        //   {
        //     ...state,
        //     slotObj: slotInfo,
        //     info: {
        //       ...state.info,
        //       dates,
        //     },
        //   },
        //   () => {
        //     //fetchData();
        //   }
        // );
      }
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: "warning",
        msg: "The date is already there!",
      });
    }
  };

  useEffect(() => {
    //gettingDate();
    if (!(state?.slotObj && Object.keys(state.slotObj).length > 0)) {
      fetchData();
    }
  }, []);

  const gettingDate = () => {
    const { practData, dates } = state.info;
    let start = dates;
    const date = start.map((d) => {
      let startDate = new Date();
      let endDate = new Date();
      startDate = new Date(d * 1000);
      endDate = new Date(d * 1000);
      endDate.setHours(23, 59, 59, 999);

      const getUtcTime = (date) => {
        if (date) {
          return moment.utc(date).unix();
        } else {
          return moment.utc().unix();
        }
      };

      let resultDate = {
        start: getUtcTime(startDate),
        end: getUtcTime(endDate),
      };
      return resultDate;
    });
  };

  async function fetchData() {
    const { practData, dates, appointments } = state.info;

    let payload = {
      startDate: parseInt((appointments?.startsOn?.milli / 1000).toFixed(0)),
      endOccurence:
        appointments?.occurence?.occurrenceHandle === "NoOfDays"
          ? Number(appointments?.occurence?.occurrenceValue)
          : 0,
      endDate:
        appointments?.occurence?.occurrenceHandle === "Calendar"
          ? YYYYMMDD_sep_by_hyphen_HHmm_to_epoch(
              appointments?.occurence?.occurrenceValue,
              "23:59"
            )
          : 0,
      resourceId: props?.shortcut
        ? decodedToken?.locationid
        : patientDetails?.selectedSlot?.info?.resourcecode ||
          props?.slotInfo?.resourcecode,
      frequencyValue: Number(appointments?.reccurance?.reccuranceValue),
      frequencyUom: appointments?.reccurance?.reccuranceType?.value,
      preferredDay: appointments?.preferrence?.preferredDays?.map(
        (v) => v?.value
      ),
      preferredWeek: appointments?.preferrence?.preferredWeeks?.map(
        (v) => v?.value
      ),
      // ...(props?.shortcut && {
      facilityId: decodedToken?.facilityid,
      //}),
      isfrontdesk: Boolean(decodedToken?.rolename),
    };
    let data = await dispatch(actions.RECCURANCE_APPOINTMENT(payload));
    if (data?.payload?.error) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: "error",
        msg: data?.payload?.message,
      });
    }
    const slotObj = {};
    if (data?.payload?.data && Array.isArray(data.payload.data)) {
      data.payload.data.forEach((schedule) => {
        // there is no schedule table
        const slot = {
          morning: [],
          afternoone: [],
          evening: [],
          night: [],
          allSession: [],
          isClinic: !!practData?.isClinic,
          healthCareId: state.healthCareId,
          isSlotAvailable: false,
          isSlotUpdated: false,
          selectedSlotId: 0,
          selectedSlotInfo: {},
          isEmpty: false,
          time: schedule.start,
        };

        filterSlots(
          {
            resourcecode: schedule.resourcecode, //there is no resource value in schedule it is in slot
            resourcerole: schedule.resourcerole, // leaving its as it is im changing that in filter slot function
            resourcetype: schedule.resourcetype,
          },
          schedule.slots,
          slot.morning,
          slot.afternoone,
          slot.evening,
          slot.night,
          slot.allSession,
          slot.isClinic,
          slot.healthCareId,
          slot
        );
        slotObj[schedule.start] = slot;
      });
    }
    // state.info.dates?.forEach((date) => {
    //   if (!(date in slotObj)) {
    //     const slot = {
    //       morning: [],
    //       afternoone: [],
    //       evening: [],
    //       night: [],
    //       allSession: [],
    //       isClinic: !!practData?.isClinic,
    //       healthCareId: state.healthCareId,
    //       isSlotAvailable: false,
    //       isSlotUpdated: false,
    //       selectedSlotId: 0,
    //       selectedSlotInfo: {},
    //       isEmpty: true,
    //       time: date,
    //     };
    //     slotObj[date] = slot;
    //   }
    // });
    setState({
      ...state,
      slotObj,
      info: { ...state.info, dates: Object.keys(slotObj) },
    });
  }

  const filterSlots = (
    resourceInfo,
    slots,
    morning,
    afternoone,
    evening,
    night,
    allSession,
    isClinic,
    healthCareId,
    slotInfo
  ) => {
    slots.forEach((slot) => {
      if (slot) {
        const {
          start,
          _id: slotId,
          status,
          end,
          isdirect = false,
          issuggest = false,
          isvideo = false,
          Maxbooking = 0,
          Maxwaiting = 0,
          booked = 0,
          overbooked,
        } = slot;

        if (
          !(
            status === "CodingMaster/11368" ||
            status === "CodingMaster/11359" ||
            overbooked
          )
        ) {
          //const slotTime = new Date(start * 1000);
          const slotTime = utcTOLocal(start).toDate();
          const selectSlot =
            state?.info?.appointments?.startsOn?.slotSelect?.label;
          const hours = slotTime.getHours();
          let label = getTimeString(hours, slotTime.getMinutes());
          if (isClinic) {
            const endTime = new Date(end * 1000);
            const endHours = endTime.getHours();
            const endTimeLabel = getTimeString(endHours, endTime.getMinutes());
            label = `${label} - ${endTimeLabel}`;
          }
          const iconsArr = [];
          const slotData = {
            value: slotId,
            label,
            date: slotTime.getTime(),
            dateEnd: end,
            dateStart: start,
            status,
            isdirect,
            issuggest,
            isvideo,
            booked: booked,
            maxBooking: Maxbooking,
            maxWaiting: Maxwaiting,
            waiting: booked > Maxbooking ? booked - Maxbooking : Maxwaiting,
            icon: iconsArr,
            healthCareId,
            disabled: status === "closed" || status === "booked" || overbooked,
            resourceInfo: {
              resourcecode: slot.resourcecode,
              resourcerole: slot.resource_id,
              resourcetype: slot.resource_type,
            },
            appointmentType: slot?.appointmentType,
          };
          if (label === selectSlot) {
            slotInfo.isSlotAvailable = true;
            slotInfo.selectedSlotId = slotId;
            slotInfo.selectedSlotInfo = slotData;
          }
          if (isClinic) {
            allSession.push(slotData);
          } else {
            if (slot?.DayType?.display?.toLowerCase() === "morning") {
              morning.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "afternoon") {
              afternoone.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "evening") {
              evening.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "night") {
              night.push(slotData);
            } else {
              morning.push(slotData);
            }
          }
        }
      }
    });
  };

  const confirmAppointment = () => {
    const returnData = [];
    const isSuccess = Object.keys(state.slotObj).every((key) => {
      const obj = {
        _id: state.slotObj[key].selectedSlotId,
        start: state.slotObj[key].selectedSlotInfo.dateStart,
        end: state.slotObj[key].selectedSlotInfo.dateEnd,
        resourceInfo: state.slotObj[key].selectedSlotInfo.resourceInfo,
        appointmentType:
          state?.slotObj?.[key]?.selectedSlotInfo?.appointmentType,
      };
      returnData.push(obj);
      return (
        !state.slotObj[key].isEmpty &&
        state.slotObj[key].selectedSlotId !== 0 &&
        Object.keys(state.slotObj[key].selectedSlotInfo).length > 0
      );
    });
    if (
      props.fromListing ||
      (isSuccess && returnData.length === state.info?.dates?.length)
    ) {
      props.handleMultiAppointmentChange(returnData, "data");
      props.handleMultiAppointmentChange({ ...state.slotObj }, "slotObj");
      props.confirmAppoinment();
      setPatientDetail((prevstate) => ({
        ...prevstate,
        bookFlag: { multiBook: true },
      }));
      //props.setFlag({ multiApppointment: true });
    } else {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Choose Slot For All Days",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };

  const updateSelectedSlot = (date, val) => {
    const states = { ...state };
    if (states.slotObj[date]) {
      states.slotObj[date].selectedSlotId = val.value;
      states.slotObj[date].selectedSlotInfo = val;
      if (
        val.label === states.info?.appointments?.startsOn?.slotSelect?.label
      ) {
        states.slotObj[date].isSlotUpdated = false;
      } else {
        states.slotObj[date].isSlotUpdated = true;
      }
      setState({
        ...states,
      });
    }
  };
  // const app = {
  //   startsOn: {
  //     date: "05-11-2023",
  //     slotSelect: {},
  //     milli: 1699122600000,
  //   },
  //   preferrence: {
  //     preferredDays: [],
  //     preferredWeeks: [],
  //   },
  //   occurence: {
  //     occurrenceHandle: "NoOfDays",
  //     occurrenceValue: 1,
  //   },
  //   reccurance: {
  //     reccuranceValue: 1,
  //     reccuranceType: {
  //       label: "Days",
  //       value: "days",
  //     },
  //   },
  // };
  // const dts = [1699122600, 1699209000];
  const { parent_id } = props;
  const { appointments, dates } = state.info;
  const { slotObj } = state;

  return (
    <Container
      id={`${parent_id}_Multiple_Appointments_parent_container`}
      key={"0"}
      name={"container"}
      fluid={true}
    >
      <H6
        id={`${parent_id}_book_Multiple_Appointments_h6`}
        key={"0"}
        name={"Book Multiple Appointments"}
        style={qdmstyles.agCQ}
        className={"pc_semibold"}
      ></H6>

      <Divider
        id={`${parent_id}_book_Multiple_Appointments_divider`}
        key={"1"}
        inLineStyles={{ margin: "14px 0px", opacity: "0.5" }}
      ></Divider>

      <Text
        id={`${parent_id}_book_Multiple_Appointments_noofdays_text`}
        key={"2"}
        name={`Every ${appointments?.reccurance?.reccuranceValue} ${
          appointments?.reccurance?.reccuranceType?.label
        }, starting on 
              ${`${moment(appointments?.startsOn?.date, "DD-MM-YYYY").format(
                "Do MMM 'YY"
              )} and `}
              ${
                appointments?.occurence?.occurrenceHandle === "NoOfDays"
                  ? "ends after "
                  : "ends by "
              }
              ${
                appointments?.occurence?.occurrenceHandle === "NoOfDays"
                  ? appointments?.occurence?.occurrenceValue
                  : moment(
                      appointments?.occurence?.occurrenceValue,
                      "YYYY-MM-DD"
                    ).format("Do MMM 'YY")
              }
              ${
                appointments?.occurence?.occurrenceHandle === "NoOfDays"
                  ? " occurrences"
                  : ""
              }.`}
        style={qdmstyles.B}
        className={"pc_regular"}
      ></Text>

      <Div
        id={`${parent_id}_book_Multiple_Appointments_dates_div`}
        key={"3"}
        className={"qdm"}
        style={qdmstyles.qGGRc}
      >
        <Div key={"0"}>
          <H6
            id={`${parent_id}_book_Multiple_Appointments_Occurrences_h6`}
            key={"0"}
            name={`${dates?.length} Occurrences`}
            style={{
              color: UIColor.secondaryText.color,
              fontSize: "14px",
              fontWeight: "600",
            }}
            className={"pc_regular"}
          ></H6>
        </Div>

        <Div
          id={`${parent_id}_book_Multiple_Appointments_Change_div`}
          key={"1"}
        >
          <H6
            id={`${parent_id}_book_Multiple_Appointments_Change_h6`}
            key={"0"}
            onClick={props?.onChange}
            name={"Change"}
            style={{
              color: UIColor.secondaryColor.color,
              fontSize: "14px",
              fontWeight: "600",
              cursor: "pointer",
            }}
            className={"pc_regular"}
          ></H6>
        </Div>
      </Div>

      <Divider
        id={`${parent_id}_book_Multiple_Appointments_Change_divider`}
        inLineStyles={{ margin: "14px 0px", opacity: "0.5" }}
      />
      {selector?.reccurantApp?.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        Object.keys(state.slotObj).map((date, i) => {
          return (
            (props.fromListing ? date in state.slotObj : true) && (
              <CollapseAppoinmentCard
                parent_id={"book_appointment_" + i}
                key={i}
                index={i + 1}
                date={date}
                time={
                  slotObj[date]
                    ? slotObj[date].isSlotUpdated
                      ? slotObj[date].selectedSlotInfo?.label
                      : appointments?.startsOn?.slotSelect?.label
                    : appointments?.startsOn?.slotSelect?.label
                }
                slot={
                  slotObj[date]
                    ? slotObj[date].isSlotUpdated
                      ? "Slot Updated"
                      : slotObj[date].isSlotAvailable
                      ? "Slot Available"
                      : slotObj[date].isEmpty
                      ? "Schedule Unavailable"
                      : "Slot Unavailable"
                    : "Schedule Unavailable"
                }
                // showEdit
                showDelete
                deleteFunction={(deletedDate) => {
                  const allDates = [...state.info.dates];
                  if (allDates.length <= 1) {
                    alert?.setSnack({
                      open: true,
                      severity: AlertProps.severity.error,
                      msg: "Atleast one slot should be selected.",
                      vertical: AlertProps.vertical.top,
                      horizontal: AlertProps.horizontal.center,
                      tone: true,
                    });
                    return;
                  }
                  const data = allDates.filter(
                    (v) => Number(v) !== Number(deletedDate)
                  );
                  const slotObj = JSON.parse(JSON.stringify(state.slotObj));
                  delete slotObj[deletedDate];
                  setState({
                    ...state,
                    slotObj,
                    info: {
                      ...state.info,
                      dates: data,
                    },
                  });
                }}
                open={false}
                isError={
                  !(
                    slotObj[date] &&
                    (slotObj[date].isSlotAvailable ||
                      slotObj[date].isSlotUpdated)
                  )
                }
                showEdit
                body={
                  slotObj[date] ? (
                    <div
                      id={`${parent_id}_${i}_book_Multiple_Appointments_week_div`}
                    >
                      <WeekCalendar
                        id={`${parent_id}_${i}_book_Multiple_Appointments_weekcalendar`}
                        activeColor={UIColor.secondaryButtonColor.color}
                        key={"0"}
                        currentDate={moment(new Date(date * 1000)).toDate()}
                        weekStartDate={
                          i === 0
                            ? moment(new Date()).toDate()
                            : moment(new Date(dates[i - 1] * 1000))
                                .add(1, "day")
                                .toDate()
                        }
                        style={{
                          borderWidth: "0px",
                          fontSize: 14,
                          marginBottom: "4%",
                        }}
                        className={classes.weekcalendar}
                        onChange={(dateVal) =>
                          handleDateChange(dateVal, slotObj[date])
                        }
                        dateValue={moment(new Date(date * 1000)).toDate()}
                      />
                      {state.info?.practData?.isClinic ? (
                        <Tab
                          id={`${parent_id}_book_appointment_list_appointment_tab`}
                          key={"0"}
                          align="center"
                          activetabs={0}
                          name=""
                          label=""
                          inLineTabTitleStyles=""
                          fullWidth={true}
                          size="small"
                          backgroundColor="#3f51b5"
                          elevation="0"
                          color=""
                          border={false}
                          variant="withoutBordered"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                          className={"pc_regular"}
                        >
                          <Tabpanel
                            id={`${parent_id}_book_appointment_list_label_morning_tabpanel`}
                            key={"0"}
                            label="ALL SESSION"
                            title="ALL SESSION"
                            subTitle={`${
                              slotObj[date]?.allSession?.length || "No"
                            } slots`}
                            color={
                              slotObj[date]?.allSession?.length
                                ? "#38C20A"
                                : "#EA1601"
                            }
                            style={{
                              maxHeight: "26vh",
                              overflow: "auto",
                              marginBottom: "5%",
                              paddingLeft: 0,
                              paddingRight: 0,
                            }}
                            className={"pc_regular"}
                          >
                            {slotObj[date]?.allSession?.map((val, i) => (
                              <Tapcomp
                                id={`${parent_id}_book_appointment_list_morning_tapcomp`}
                                val={val}
                                disabled={val.disabled}
                                stateVal={slotObj[date]?.selectedSlotId}
                                onChange={() => updateSelectedSlot(date, val)}
                              />
                            ))}
                          </Tabpanel>
                        </Tab>
                      ) : (
                        <Tab
                          id={`${parent_id}_book_appointment_list_appointment_tab`}
                          key={"0"}
                          align="center"
                          name=""
                          label=""
                          inLineTabTitleStyles=""
                          fullWidth={true}
                          size="small"
                          backgroundColor="#3f51b5"
                          elevation="0"
                          color=""
                          border={false}
                          activetabs={
                            slotObj[date].isEmpty
                              ? 0
                              : getTimeSlot(
                                  appointments?.startsOn?.slotSelect?.dateStart,
                                  "unix",
                                  true
                                )
                          }
                          variant="withoutBordered"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                          className={"pc_regular"}
                          activeColor={"#2A60BC"}
                        >
                          <Tabpanel
                            id={`${parent_id}_book_appointment_list_label_morning_tabpanel`}
                            key={"0"}
                            label="MORNING"
                            title="MORNING"
                            subTitle={`${
                              slotObj[date]?.morning?.length || "No"
                            } slots`}
                            color={
                              slotObj[date]?.morning?.length
                                ? "#38C20A"
                                : "#EA1601"
                            }
                            style={{
                              maxHeight: "26vh",
                              overflow: "auto",
                              marginBottom: "5%",
                              paddingLeft: 0,
                              paddingRight: 0,
                            }}
                            className={"pc_regular"}
                          >
                            {slotObj[date]?.morning?.map((val, i) => (
                              <Tapcomp
                                id={`${parent_id}_book_appointment_list_morning_tapcomp`}
                                val={val}
                                disabled={val.disabled}
                                stateVal={slotObj[date]?.selectedSlotId}
                                onChange={() => updateSelectedSlot(date, val)}
                              />
                            ))}
                          </Tabpanel>

                          <Tabpanel
                            id={`${parent_id}_book_appointment_list_label_afternoon_tabpanel`}
                            key={"1"}
                            label="AFTERNOON"
                            title="AFTERNOON"
                            subTitle={`${
                              slotObj[date]?.afternoone?.length || "No"
                            } slots`}
                            color={
                              slotObj[date]?.afternoone?.length
                                ? "#38C20A"
                                : "#EA1601"
                            }
                            style={{
                              maxHeight: "26vh",
                              overflow: "auto",
                              marginBottom: "5%",
                              paddingLeft: 0,
                              paddingRight: 0,
                            }}
                            className={"pc_regular"}
                          >
                            {slotObj[date]?.afternoone?.map((val, i) => (
                              <Tapcomp
                                id={`${parent_id}_book_appointment_list_afternoon_tapcomp`}
                                val={val}
                                disabled={val.disabled}
                                stateVal={slotObj[date]?.selectedSlotId}
                                onChange={() => updateSelectedSlot(date, val)}
                              />
                            ))}
                          </Tabpanel>

                          <Tabpanel
                            id={`${parent_id}_book_appointment_list_label_evening_tabpanel`}
                            key={"2"}
                            label="EVENING"
                            title="EVENING"
                            subTitle={`${
                              slotObj[date]?.evening?.length || "No"
                            } slots`}
                            color={
                              slotObj[date]?.evening?.length
                                ? "#38C20A"
                                : "#EA1601"
                            }
                            style={{
                              maxHeight: "26vh",
                              overflow: "auto",
                              marginBottom: "5%",
                              paddingLeft: 0,
                              paddingRight: 0,
                            }}
                            className={"pc_regular"}
                          >
                            {slotObj[date]?.evening?.map((val, i) => (
                              <Tapcomp
                                id={`${parent_id}_book_appointment_list_evening_tapcomp`}
                                val={val}
                                disabled={val.disabled}
                                stateVal={slotObj[date]?.selectedSlotId}
                                onChange={() => updateSelectedSlot(date, val)}
                              />
                            ))}
                          </Tabpanel>

                          <Tabpanel
                            id={`${parent_id}_book_appointment_list_label_night_tabpanel`}
                            key={"3"}
                            label="NIGHT"
                            title="NIGHT"
                            subTitle={`${
                              slotObj[date]?.night?.length || "No"
                            } slots`}
                            color={
                              slotObj[date]?.night?.length
                                ? "#38C20A"
                                : "#EA1601"
                            }
                            style={{
                              maxHeight: "26vh",
                              overflow: "auto",
                              marginBottom: "5%",
                              paddingLeft: 0,
                              paddingRight: 0,
                            }}
                            className={"pc_regular"}
                          >
                            {slotObj[date]?.night?.map((val, i) => (
                              <Tapcomp
                                id={`${parent_id}_book_appointment_list_night_tapcomp`}
                                val={val}
                                disabled={val.disabled}
                                stateVal={slotObj[date]?.selectedSlotId}
                                onChange={() => updateSelectedSlot(date, val)}
                              />
                            ))}
                          </Tabpanel>
                        </Tab>
                      )}
                      <span
                        id={`${parent_id}_${i}_book_Multiple_Appointments_span`}
                        className={classes.icons}
                      >
                        {/* <span
                            id={`${parent_id}_${i}_book_Multiple_Appointments_Suggested_span`}
                            className={classes.flexCenter}>
                            <Suggested
                              id={`${parent_id}_${i}_book_Multiple_Appointments_Suggested`}
                              color={"#B6B6B6"} />
                            <Text
                              id={`${parent_id}_${i}_book_Multiple_Appointments_Suggested_text`}
                              className={classes.iconText}>Suggested Slot</Text>
                          </span> */}
                        <span
                          id={`${parent_id}_${i}_book_Multiple_Appointments_Videoconsultation_span`}
                          className={classes.flexCenter}
                        >
                          <Videoconsultation
                            id={`${parent_id}_${i}_book_Multiple_Appointments_Videoconsultation`}
                            color={UIColor.tertiaryText.color}
                            size={15}
                          />
                          <Text
                            id={`${parent_id}_${i}_book_Multiple_Appointments_Videoconsultation_text`}
                            className={classes.iconText}
                          >
                            Video Consultation
                          </Text>
                        </span>
                        <span
                          id={`${parent_id}_${i}_book_Multiple_Appointments_Videoconsultation_span`}
                          className={classes.flexCenter}
                        >
                          <DirectConsultation
                            id={`${parent_id}_${i}_book_Multiple_Appointments_Videoconsultation`}
                            color={UIColor.tertiaryText.color}
                            size={15}
                          />
                          <Text
                            id={`${parent_id}_${i}_book_Multiple_Appointments_Videoconsultation_text`}
                            className={classes.iconText}
                          >
                            Direct Consultation
                          </Text>
                        </span>
                      </span>
                    </div>
                  ) : null
                }
              />
            )
          );
        })
      )}

      <Button
        id={`${parent_id}_book_Multiple_Confirm_Appointments_button`}
        key={"6"}
        type={"button"}
        variant={"outlined"}
        name={
          props?.shortcut
            ? props?.loading
              ? "Booking..."
              : "Confirm Appointment"
            : "Confirm Appointment"
        }
        onClick={confirmAppointment}
        disabled={selector?.reccurantApp?.loading}
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          marginTop: "20px",
          marginBottom: "10px",
          backgroundColor: UIColor.secondaryButtonColor.color,
          borderColor: UIColor.secondaryButtonColor.color,
          borderRadius: "10px",
          fontSize: "14px",
          padding: "10px",
        }}
        className={"pc_regular"}
      ></Button>
    </Container>
  );
}

export default BookMultipleAppointmentsLists;
