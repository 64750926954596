import React, { useState, useEffect, useContext } from "react";
import withAppBar from "../../hoc/withAppBar";
import { Div, Row, Col, H6, Paper } from "qdm-component-library";
import { TopNavbar, InfoIcon } from "../../components";
import Loading from "../../components/global/loading";
import {
  PatientListView,
  CardDetails,
  PatientStatusCard,
} from "./components";
import { financialTitle } from "../../utils/routesList";
import { AppRoutes } from "../../router/routes";
// import { withRouter } from "react-router-dom";
// import { actions } from "primarycare-binder";
import {  useDispatch } from "react-redux";
import { AlertContext } from "../../context";
import withAllContexts from "../../hoc/withAllContexts";
import PayerDetailCard from "./components/payerDetailCard";
import moment from "moment";
import axios from "axios";
import {
  AlertProps,
  putOnHold,
  urls, 
  actionButtonClick,
  readFileAsBase64,
  __tenantid__,
} from "../../utils";
import { useNavigate, useLocation } from "react-router-dom";
import actions from "../../redux/actions";
import jwtDecode from "jwt-decode";
import { Grid } from "@material-ui/core";
import { UIColor } from "../../themes/theme";
const FinanceRegistration = (props) => {
  const [btnName, setBtnName] = useState(false);
  const [state, setState] = useState({
    Persondata: [],
    allMasters: [],
    Isopenform: false,
    loading: false,
    btnName: false,
    patientId: "",
  });
  const [financialDatas, setFinancialDatas] = useState({
    payer: {},
    validity: {},
    membership: {},
    payerdetails: {},
    eligibility: {},
    financial: {},
    savedata: [],
    index: null,
  });
  const clearFinancialData = () => {
    setFinancialDatas(() => ({
      payer: {},
      validity: {},
      membership: {},
      eligibility: {},
      financial: {},
      index: null,
    }));
  };

  const [allMasters, setAllMasters] = useState([]);
  const [cleared, setCleared] = useState(false);
  const [add, setAdd] = useState(false);
  const [payerType, setPayerType] = useState([]);
  const [payer, setPayer] = useState({});
  const [guarantorType, setGuarantorType] = useState({});
  const [guarantorId, setGuarantorId] = useState({});
  const [plan, setPlan] = useState({});
  const [editPayer, setEditPayer] = useState({});
  const [error, setError] = useState({});
  const [err, setErr] = useState(false);
  const [isOnEdit, setIsOnEdit] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [actionName, setActionName] = useState("");
  const [cancelbookingreason, setCancelbookingreason] = useState({});
  const [Files, setFiles] = useState();
  const [viewFiles, setViewFiles] = useState();
  const [getReferralData, setGetreferralData] = useState({});
  const [getPatientRegistration, setGetPatientRegistration] = useState({});
  const [defaultDataLoad, setDefaultDataLoad] = useState(false);
  const [defaultPayerTypeLoad, setDefaultPayerTypeLoad] = useState({});
  const [editState, setEditState] = useState({});
  const [payerTypeId,setPayerTypeId] = useState("")
  const [payerId,setPayerId] = useState("")
  const [dragitems, setdragItems] = useState([])
  const [selfpaydata, setselfpaydata] = useState()
  const navigate = useNavigate();
  const alert = useContext(AlertContext);
  const location = useLocation();
  const dispatch = useDispatch();
  function formatDate(inputDate) {
    const parts = inputDate?.split("-");
    if (parts?.length === 3) {
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    } else {
      // Handle invalid input date format
      return "Invalid Date Format";
    }
  }

  let policy_startValue = formatDate(financialDatas?.payer?.policy_start);
  let policy_ExpValue = formatDate(financialDatas?.payer?.policy_expiry);
  let gl_IsssueValue = formatDate(financialDatas?.validity?.gl_start);
  let service_startValue = formatDate(financialDatas?.validity?.service_start);
  let service_EndValue = formatDate(financialDatas?.validity?.service_end);
  function dateToEpoch(dateString) {
    const [day, month, year] = dateString.split("/").map(Number);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return null;
    }

    const date = new Date(year, month - 1, day);
    const epochTime = date.getTime() / 1000;

    return epochTime;
  }
  const policyStr = policy_startValue;
  const policyStrEpochTime = dateToEpoch(policyStr);
  const policyExp = policy_ExpValue;
  const policyExpEpochTime = dateToEpoch(policyExp);
  const glIssueDate = gl_IsssueValue;
  const glIssueDateEpochTime = dateToEpoch(glIssueDate);
  const serviceStart = service_startValue;
  const serviceStartEpochTime = dateToEpoch(serviceStart);
  const serviceEnd = service_EndValue;
  const serviceEndEpochTime = dateToEpoch(serviceEnd);
  const navigateToReferralInRegistration = async (encounterid) => {
    debugger
    //   if(!location?.state?.isModify && !location?.state?.appointmentId){
    //   const element = document.documentElement;
    //   if (element.requestFullscreen) {
    //     element.requestFullscreen();
    //   } else if (element.mozRequestFullScreen) {
    //     element.mozRequestFullScreen();
    //   } else if (element.webkitRequestFullscreen) {
    //     element.webkitRequestFullscreen();
    //   } else if (element.msRequestFullscreen) {
    //     element.msRequestFullscreen();
    //   }
    // }
    //
    if (location?.state?.isVisit) {
      window.location.href = AppRoutes.dashboard;
      // await actionButtonClick(
      //   location?.state?.stateAction,
      //   location?.state?.stateEndPoint,
      //   location?.state?.stateBackEndCall,
      //   location?.state?.stateTicketid
      // )
      //   .then((res) => {
      //     if (!res?.error) {
      //       alert?.setSnack({
      //         open: true,
      //         severity: AlertProps.severity.success,
      //         msg: "Your visit has been Checked-In",
      //         vertical: AlertProps.vertical.top,
      //         horizontal: AlertProps.horizontal.center,
      //         tone: true,
      //       });
      //     } else {
      //       alert?.setSnack({
      //         open: true,
      //         severity: AlertProps.severity.error,
      //         msg: res?.Response?.[0]?.errorMessage,
      //         vertical: AlertProps.vertical.top,
      //         horizontal: AlertProps.horizontal.center,
      //         tone: true,
      //       });
      //     }
      //     setTimeout(() => {
      //       window.location.href = AppRoutes.dashboard;
      //     }, 1000);
      //   })
      //   .catch((error) => {
      //     alert?.setSnack({
      //       open: true,
      //       severity: AlertProps.severity.error,
      //       msg: "Please contact to admin",
      //       vertical: AlertProps.vertical.top,
      //       horizontal: AlertProps.horizontal.center,
      //       tone: true,
      //     });
          // window.location.href = AppRoutes.dashboard;
      //   });
    } else {
      navigate(AppRoutes.referralInRegistration, {
        state: {
          from: location?.state?.from,
          visit: true,
          nextRoute: AppRoutes.referralInRegistration,
          prevRoute: AppRoutes.visitRegistration,
          mobileNo: location?.state?.mobileNumber,
          mobileNumber: location?.state?.mobileNumber,
          appointmentId: location?.state?.appointmentId,
          patientId: location.state.patientId ?? props?.patientId,
          referraldetails:
            location?.state?.referraldetails ||
            location?.state?.data?.referraldetails ||
            getReferralData,
          addtionaldetails: location?.state?.addtionaldetails,
          nextofkin: location?.state?.nextofkin,
          patientdetails: location?.state?.patientdetails,
          isModify: location?.state?.isModify ?? false,
          personid: location?.state?.userId || location?.state?.personId || location?.state?.personid,
          userId: location?.state?.userId || location?.state?.personId || location?.state?.personid,
          appointmentInfo: {},
          stateAction: location?.state?.stateAction,
          stateEndPoint: location?.state?.stateEndPoint,
          stateBackEndCall: location?.state?.stateBackEndCall,
          stateTicketid: location?.state?.stateTicketid,
          data: location?.state?.data,
          backMode: location?.state?.backMode,
          isEditedMode: location?.state?.isEditedMode,
          encounterID: encounterid ||location?.state?.encounterID || "",
          referralId: location?.state?.referraldetails?._id,
          isRefRegister:true
        },
      });
    }
  };

  const saveFinanceDataFun = async () => {
    const userInfo = localStorage?.getItem("UserInfo");
    const decodedUserinfo = userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
    const facilityId = decodedUserinfo?.facilityid ?? "";

    //const userInfo = localStorage.getItem("UserInfo");
    //const decodedUserinfo = jwtDecode(userInfo);
    let attachments = [];
    const promises = Files?.map((val) => readFileAsBase64(val));
    let dragitem = dragitems;
    let filteredArray = allMasters?.filter((obj) => obj.hasOwnProperty(["PRIORITY"]));
    let data = filteredArray?.[0]?.["PRIORITY"]?.Value;
    dragitems?.map((v,i)=>{
      let len = i+1;
      if(len.toString() === data[i]?.display){
        dragitem[i].payer.priority=data[i]?._id;
      }
    })
    if (promises) {
      const base64Results = await Promise.all(promises);
      Files?.map((val, i) => {
        let obj = {
          fileName: val?.name,
          fileid: "",
          filetype: val?.type,
          base64string: base64Results[i],
        };
        attachments.push(obj);
      });
    }
    if (viewFiles === undefined) {
      attachments = attachments ? attachments : [];
    } else {
      attachments =
        viewFiles === undefined ? attachments : attachments.concat(viewFiles);
    }
    let finDatas = financialDatas?.savedata
    // const saveFinanceDatas = finDatas?.map((s) => {
    //   if(s?.payerdetails?.payer?._id == process.env.REACT_APP_PAYER_SELFPAY ){
    //     finDatas.slice(1)
    //   }
    // });
    const saveFinanceData = finDatas?.map((s,i) => {
      if(s?.payerdetails?.payer?._id === process.env.REACT_APP_PAYER_SELFPAY){
        finDatas.splice(i, 1)
      }
      const policyStart = s?.payer?.policy_start;
      const policyStartEpochTime = policyStart
        ? Date.parse(policyStart) / 1000
        : 0;
      const policyExpiry = s?.payer?.policy_expiry;
      const policyEndEpochTime = policyExpiry
        ? Date.parse(policyExpiry) / 1000
        : 0;
      const glissuedates = s?.validity?.gl_start;
      const glissuedateEpochTime = glissuedates
        ? Date.parse(glissuedates) / 1000
        : 0;
      const serviceStart = s?.validity?.service_start;
      const serviceStartEpochTime = serviceStart
        ? Date.parse(serviceStart) / 1000
        : 0;
      const serviceEnd = s?.validity?.service_end;
      const serviceEndEpochTime = serviceEnd
        ? Date.parse(serviceEnd) / 1000
        : 0;
        let priority;
        dragitem.map((v,i)=>{
            if(s?.payerdetails?.payer?._id === v?.payerdetails?.payer?._id && s?.payerdetails?.plan?._id === v?.payerdetails?.plan?._id){
              priority = v?.payer?.priority.toString()
            }
        })
      return {
        patientaccount_id: s?.patientaccount_id ?? "",
        is_deleted: s?.is_deleted,
        tenantid: decodedUserinfo?.tenantid ?? "1234",
        facilityid: decodedUserinfo?.facilityid,
        privilage: "yes",
        patientid: location.state?.patientId,
        priority: priority,
        trantype: location?.state?.appointmentId ? "encounter" : "patient",
        payertype: s?.payer?.payer_type?._id ? s?.payer?.payer_type?._id : "",
        payer: s?.payerdetails?.payer?._id ? s?.payerdetails?.payer?._id : "", //s?.payer?.payer?.[0]?._id
        guarantortype: s?.payerdetails?.guarantor_type?._id
          ? s?.payerdetails?.guarantor_type?._id
          : "", //s?.payer?.guarantor_type?.[0]?._id
        guarantorid: s?.payerdetails?.guarantor?._id
          ? s?.payerdetails?.guarantor?._id
          : "",
        policyid: s?.payerdetails?.plan?._id ? s?.payerdetails?.plan?._id : "",
        policystart:
          policyStartEpochTime == null || policyStartEpochTime == undefined
            ? 0
            : policyStartEpochTime,
        policyexpiry:
          policyEndEpochTime == null || policyEndEpochTime == undefined
            ? 0
            : policyEndEpochTime,
        sponsorname: s?.membership?.sponsor_name?._id,
        membershipid: s?.membership?.member_id ? s?.membership?.member_id : "",
        membertype: s?.membership?.type?._id ? s?.membership?.type?._id : "",
        memberrelid: s?.membership?.relationship?._id
          ? s?.membership?.relationship?._id
          : "",
        primemembershipid: s?.membership?.prime_member_id
          ? s?.membership?.prime_member_id
          : "",
        applencounter: "",
        applencountertype: "",
        applspecialty: "",
        financialclass: [
          {
            classtype: s?.financial?.class_type?._id,
            classval: s?.financial?.class_value?._id,
          },
        ],
        documentref: attachments,
        encounteraccount_id: "",
        encounterid: location?.state?.encounterDetails?.response?.[0]?._id,
        eligiblestatus: "",
        eligverifreqdyn: s?.eligibility?.isverify
          ? s?.eligibility?.isverify
          : false,
        approvallimit: 0,
        copayperc: 0,
        copayamt: 0,
        glnumber: s?.validity?.gl_number ? s?.validity?.gl_number : "",
        glissuedate:
          glissuedateEpochTime == null || glissuedateEpochTime == undefined
            ? 0
            : glissuedateEpochTime,
        servicestart:
          serviceStartEpochTime == null || serviceStartEpochTime == undefined
            ? 0
            : serviceStartEpochTime,
        serviceend:
          serviceEndEpochTime == null || serviceEndEpochTime == undefined
            ? 0
            : serviceEndEpochTime,
        status: "CodingMaster/10191",
        referenceid: [
          {
            refsource: "",
            refidtype: "",
            refid: "",
          },
        ],
      };
    });
    return saveFinanceData;
  };

  const successfun = async () => {
    let patientData = financialDatas;
      setBtnName(true);
      let payload = {
        dbName: process.env.REACT_APP_DB, //"primarycareng",
        metadatadbname: process.env.REACT_APP_METADATA_DBNAME,
        patientid: location.state?.patientId,
        doc: await saveFinanceDataFun(),
      };
      let token = localStorage.getItem("JwtToken");

      let register = await axios({
        method: "POST",
        url: urls.saveFianance,
        headers: {
          "Content-Type": "application/json",
          jwtToken: `${token}`,
        },
        data: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.status === 200 && !res.data.error) {
            if (!location.state?.isVisit)
              props?.alert?.setSnack({
                open: true,
                severity: AlertProps.severity.success,
                msg:
                  location?.state?.backMode ||
                  location?.state?.isEditedMode ||
                  location?.state?.isRevise
                    ? "Updated successfully"
                    : "Added successfully",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
            setBtnName(false);
            // navigate(location?.state?.appointmentId?
            //   AppRoutes.confirmBooking : AppRoutes.PatientDashBoard,{
            //     state: {
            //       from: location?.state?.from,
            //       visit: true,
            //       nextRoute: AppRoutes.dashboard,
            //       prevRoute: AppRoutes.visitRegistration,
            //       mobileNo: location?.state?.mobileNumber,
            //       mobileNumber: location?.state?.mobileNumber,
            //       appointmentId: location?.state?.appointmentId,
            //       patientId:location.state.patientId?? props?.patientId,
            //       userId: location?.state?.personId,
            //       appointmentInfo:{},
            //       stateAction: location?.state?.stateAction,
            //       stateEndPoint: location?.state?.stateEndPoint,
            //       stateBackEndCall: location?.state?.stateBackEndCall,
            //       stateTicketid: location?.state?.stateTicketid
            //     }
            //   }
            //   );
            //isFullScreen();
            // navigate(location?.state?.appointmentId?
            //   AppRoutes.referralInRegistration : AppRoutes.PatientDashBoard,{
            //     state: {
            //       from: location?.state?.from,
            //       visit: true,
            //       nextRoute: AppRoutes.dashboard,
            //       prevRoute: AppRoutes.visitRegistration,
            //       mobileNo: location?.state?.mobileNumber,
            //       mobileNumber: location?.state?.mobileNumber,
            //       appointmentId: location?.state?.appointmentId,
            //       patientId:location.state.patientId?? props?.patientId,
            //       userId: location?.state?.personId||location?.state?.personid,
            //       appointmentInfo:{},
            //       stateAction: location?.state?.stateAction,
            //       stateEndPoint: location?.state?.stateEndPoint,
            //       stateBackEndCall: location?.state?.stateBackEndCall,
            //       stateTicketid: location?.state?.stateTicketid,
            //       personid: location?.state?.personId||location?.state?.personid
            //     }
            //   }
            //   );
            if (location?.state?.isRevise) {
              navigate(AppRoutes.dashboard);
              clearFinancialData();
            } else {
              debugger
              navigateToReferralInRegistration((res?.data?.[0]?.response?.[0]?.encounterid));
              clearFinancialData();
            }
          } else {
            props?.alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: res.data.message,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
            setBtnName(false);
          }
        })
        .catch((error) => {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please contact to admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          setBtnName(false);
        });
    
  };

  const handleSideMenu = () => {
    setIsSideMenuOpen(true);
  };
  const handleSideMenuclose = () => {
    setIsSideMenuOpen(false);
  };

  const handleSelectCancelbooking = (v) => {
    setCancelbookingreason(v);
  };

  const actionsMap = location?.state?.totalData?.action.reduce(
    (map, actionItem) => {
      map[actionItem.action] = {
        actionName: actionItem.action,
        action: actionItem.action,
        apiEndPoint: actionItem.apiendpoint,
        backendCall: actionItem.backEndCall,
      };
      return map;
    },
    {}
  );
  const cancelData = () => {
    if (actionName) {
      let data = {};
      const { action, apiEndPoint, backendCall } = actionsMap[actionName];
      data.action = action;
      data.apiEndPoint = apiEndPoint;
      data.backendCall = backendCall;
      data.ticketId = location?.state?.totalData?.ticketId;
      data.cancelReasonId = cancelbookingreason?._id;
      data.queue =
        location?.state?.totalData?.queueMetaData?.queuename?.queuename ??
        "Referral";

      return data;
    }
  };

  const handlecahngeCancelbooking = async () => {
    const { action, apiEndPoint, backendCall } = actionsMap[actionName];
    let fetchVal = await actionButtonClick(
      action,
      apiEndPoint,
      backendCall,
      location?.state?.totalData?.ticketId,
      cancelbookingreason?._id,
      location?.state?.totalData?.queueMetaData?.queuename?.queuename ??
        "Referral"
    );
    if (!fetchVal.error) {
      setIsSideMenuOpen(false);
      navigate(AppRoutes.dashboard);
    } else if (fetchVal.error) {
      setIsSideMenuOpen(true);
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: fetchVal?.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };

  const onDelete = async (v, i,drgitm) => {
    if (v?._key) {
      const response____ = await props?.SOFT_DELETE_FINANCIAL_PAYER({
        _key: v?._key,
      });
      if (response____?.payload?.data?.Code === 201) {
        let patientData = financialDatas;
        // patientData.savedata.splice(i - 1, 1);
        // setFinancialDatas({
        //   ...patientData,
        // });
        let newData = patientData?.savedata?.map((data, index) => {
          if (index === i) {
            return { ...data, is_deleted: true };
          }
          // else {
          //   return data;
          // }
        });
        patientData?.savedata.splice(i,1);
        setdragItems(patientData?.savedata)
        if (newData) {
          setFinancialDatas({
            ...patientData,
            savedata: newData,
          });
        }
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Deleted successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Something went wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
    } else {
      let patientData = financialDatas;
      let newData =[];
      // patientData.savedata.splice(i, 1);
      // setFinancialDatas({
      //   ...patientData,
      // });
      if(drgitm==="dragitem"){
        let patientDataditem = dragitems;
         newData = patientDataditem?.map((data, index) => {
          if (index === i) {
            return { ...data, is_deleted: true };
          } else {
            return {...data};
          }
      });
      } else {
        let patientDataditem = dragitems;
         newData = patientDataditem?.map((data, index) => {
            if (index === i) {
              return { ...data, is_deleted: true };
            } else {
              return {...data};
            }
        });
      }
      patientData?.savedata?.map((data, index) => {
        if(data?.is_deleted==true){
          newData.push(data);
        }
      })
      if (newData) {
        setFinancialDatas({
          ...patientData,
          savedata: newData,
        });
      } 
      let listData =[];
      newData?.map((vk,index)=>{
         if(!vk?.is_deleted){
          listData.push(vk)
         }
      });
      // listData.splice(i,1)
      setdragItems(listData)

      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Deleted successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  const getAllMasters = async () => {
    const response = await dispatch(actions.GET_ALL_MASTERS(""));
    setAllMasters(response?.payload?.data);
  };

  // const getPatientInfo = async () => {
  //   const data = await dispatch(
  //     actions.PATIENT_INFO({
  //       patientid: location.state.patientId,
  //       tenantid: "",
  //       facilityid: "",
  //       lang: "",
  //     })
  //   );
  //   await saveFinanceData.push({
  //         id: 0,
  //         identifier: [],
  //         status: "",
  //         type: "",
  //         name: "",
  //         servicePeriod: "",
  //         coverage: [
  //           {
  //             coverage_id: 0,
  //             priority: "",
  //           },
  //         ],
  //         organization_id: 0,
  //         description: "",
  //         guarantor: {
  //           onHold: false,
  //           period: "",
  //           relatedperson_id: 0,
  //           patient_id: 1114,
  //           organization_id: 0,
  //         },
  //         account_id: [],
  //         device_id: [],
  //         payer: {
  //           priority: "",
  //           payer_type: {
  //             value: data?.payload?.data?.financialdetails[0].payertypedisplay,
  //           },
  //           payer: "",
  //           guarantor_type: "",
  //           guarantor_code: "",
  //           membershipId: "",
  //           plan: "",
  //         },
  //         validity: {
  //             policy_start: 0,
  //             policy_expiry: 0,
  //             service_start: 0,
  //             service_end: 0,
  //         },
  //         membership: {
  //             type: {},
  //             relationship: {},
  //             member_id: "",
  //         },
  //         eligibility: {
  //             isverify: false,
  //             sponsor_id: "",
  //             sponsor_name: "",
  //         },
  //         financial: {
  //             class_type: {},
  //             class_value: {},
  //         },
  //   })
  //   setFinancialDatas({
  //     ...financialDatas,
  //     savedata: saveFinanceData,
  //   });
  //   setState({
  //     Persondata: data?.payload?.data?.patientdetails,
  //     patientId: data?.payload?.data?._id,
  //     loading: false,
  //   });
  // };
  const getPatientInfo = async () => {
    const data = await dispatch(
      actions.PATIENT_INFO({
        patientid: location.state.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
      })
    );
    const newFinancialData = {
      id: 0,
      identifier: [],
      status: "",
      type: "",
      name: "",
      servicePeriod: "",
      coverage: [
        {
          coverage_id: 0,
          priority: "",
        },
      ],
      organization_id: 0,
      description: "",
      guarantor: {
        onHold: false,
        period: "",
        relatedperson_id: 0,
        patient_id: 1114,
        organization_id: 0,
      },
      account_id: [],
      device_id: [],
      payer: {
        priority: "",
        payer_type: {
          value:
            data?.payload?.data?.financialdetails?.[0]?.payertype?.longdesc,
        },
        payer: "",
        guarantor_type: "",
        guarantor_code: "",
        membershipId: "",
        plan: "",
      },
      validity: {
        policy_start: 0,
        policy_expiry: 0,
        service_start: 0,
        service_end: 0,
      },
      membership: {
        type: {},
        relationship: {},
        member_id: "",
      },
      eligibility: {
        isverify: false,
        sponsor_id: "",
        sponsor_name: "",
      },
      financial: {
        class_type: {},
        class_value: {},
      },
    };

    const updatedFinancialDatas = {
      ...financialDatas,
      // savedata: [...financialDatas.savedata, newFinancialData],
      savedata:
        financialDatas?.savedata?.length === 0
          ? [newFinancialData]
          : [...financialDatas?.savedata, newFinancialData],
    };
    // if (
    //   !(
    //     location?.state?.IsViewMode ||
    //     location?.state?.isEditedMode ||
    //     location?.state?.isModify ||
    //     location?.state?.appointmentId ||
    //     location?.state?.backMode
    //   )
    // ) {
    //   setFinancialDatas(updatedFinancialDatas);
    // }
    setState({
      Persondata: data?.payload?.data?.patientdetails,
      patientId: data?.payload?.data?._id,
      loading: false,
    });
  };
  const loadFinanceDetailsDefaultValues = async (findata_save,finlistdata) => {
    let payload = {
      screenid: process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID,
      tenantid: __tenantid__,
    };
    let token = localStorage.getItem("JwtToken");
    const data = await dispatch(
      actions.PATIENT_INFO({
        patientid: location.state.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
      })
    );
    let register = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      data: JSON.stringify(payload),
    }).then((res) => {
      if (
        res?.data?.response?.screenId ===
        process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID
      ) {
        const defaultValues = res?.data?.response?.defaultValues;
        const stateNames = [
          "policy_sdate",
          "policy_plan",
          "payer_type",
          "membership_type",
          "applicable_criteria",
          "priority",
          "policy_edate",
        ];
        const stateValues = {};
        defaultValues.forEach((s) => {
          if (stateNames.includes(s.stateName)) {
            stateValues[s.stateName] = s.value;
          }
        });
        setDefaultDataLoad(true);
        setDefaultPayerTypeLoad(stateValues?.payer_type);
        // PayerRequest(stateValues?.payer_type?._id)
        // GuarantorType(stateValues?.payer_type?._id);
        // GuarantorId(stateValues?.payer_type?._id)
        let financeDetailss = data?.payload?.data?.financialdetails?.map((s,i) => {
          if(s?.payer?._id === process.env.REACT_APP_PAYER_SELFPAY){
            let a = {
              patientaccount_id: s?._id,
              membership: {
                member_id: s?.membershipid,
                type: {
                  value: s?.membertype?.display,
                  _id: s?.membertype?._id,
                },
                sponsor_name: {
                  value: s?.sponsorname?.name,
                  _id: s?.sponsorname?._id,
                },
                relationship: {
                  value: s?.memberrelid?.display,
                  _id: s?.memberrelid?._id,
                },
                prime_member_id: s?.primemembershipid,
              },
              payer: {
                priority: {
                  value: s?.priority?.display,
                  _id: s?.priority?._id,
                },
                payer_type: {
                  value: s?.payertype?.longdesc,
                  _id: s?.payertype?._id,
                },
                payer: [
                  {
                    _id: s?.payer?._id,
                    value: s?.payer?.longdesc,
                    //shortdesc: s?.payer?.longdesc,
                  },
                ],
                plan: [
                  {
                    id: s?.policyid?._id,
                    value: s?.policyid?.longdesc,
                    //shortdesc: s?.policyid?.longdesc,
                  },
                ],
                guarantor_type: [
                  {
                    _id: s?.guarantortype?._id,
                    payercode: s?.guarantortype?.payercode,
                  },
                ],
                guarantor: [
                  {
                    _id: s?.guarantorid?._id,
                    value: s?.guarantorid?.longdesc,
                    //shortdesc: s?.guarantorid?.longdesc,
                  },
                ],
                policy_expiry: epochToDate(s?.policyexpiry),
                policy_start: epochToDate(s?.policystart),
              },
              validity: {
                gl_number: " ",
                gl_start: "",
                service_start: "",
                service_end: "",
              },
              eligibility: {},
              financial: {
                class_type: {
                  value: s?.financialclass?.classtype?.display,
                  _id: s?.financialclass?.classtype?._id,
                },
                class_value: {
                  value: s?.financialclass?.classval?.display,
                  _id: s?.financialclass?.classval?._id,
                },
              },
              payerdetails: {
                payer: {
                  _id: s?.payer?._id,
                  value: s?.payer?.longdesc,
                },
                guarantor_type: {
                  _id: s?.guarantortype?._id,
                  value: s?.guarantortype?.payercode,
                },
                guarantor: {
                  _id: s?.guarantorid?._id,
                  value: s?.guarantorid?.longdesc,
                },
                plan: {
                  _id: s?.policyid?._id,
                  value: s?.policyid?.longdesc,
                },
              },
              documentref: s?.documentref,
              id:i+1
            };
            setselfpaydata(a)
            return;
          } else {
            return {
              patientaccount_id: s?._id,
              membership: {
                member_id: s?.membershipid,
                type: {
                  value: s?.membertype?.display,
                  _id: s?.membertype?._id,
                },
                sponsor_name: {
                  value: s?.sponsorname?.name,
                  _id: s?.sponsorname?._id,
                },
                relationship: {
                  value: s?.memberrelid?.display,
                  _id: s?.memberrelid?._id,
                },
                prime_member_id: s?.primemembershipid,
              },
              payer: {
                priority: {
                  value: s?.priority?.display,
                  _id: s?.priority?._id,
                },
                payer_type: {
                  value: s?.payertype?.longdesc,
                  _id: s?.payertype?._id,
                },
                payer: [
                  {
                    _id: s?.payer?._id,
                    value: s?.payer?.longdesc,
                    //shortdesc: s?.payer?.longdesc,
                  },
                ],
                plan: [
                  {
                    id: s?.policyid?._id,
                    value: s?.policyid?.longdesc,
                    //shortdesc: s?.policyid?.longdesc,
                  },
                ],
                guarantor_type: [
                  {
                    _id: s?.guarantortype?._id,
                    payercode: s?.guarantortype?.payercode,
                  },
                ],
                guarantor: [
                  {
                    _id: s?.guarantorid?._id,
                    value: s?.guarantorid?.longdesc,
                    //shortdesc: s?.guarantorid?.longdesc,
                  },
                ],
                policy_expiry: epochToDate(s?.policyexpiry),
                policy_start: epochToDate(s?.policystart),
              },
              validity: {
                gl_number: " ",
                gl_start: "",
                service_start: "",
                service_end: "",
              },
              eligibility: {},
              financial: {
                class_type: {
                  value: s?.financialclass?.classtype?.display,
                  _id: s?.financialclass?.classtype?._id,
                },
                class_value: {
                  value: s?.financialclass?.classval?.display,
                  _id: s?.financialclass?.classval?._id,
                },
              },
              payerdetails: {
                payer: {
                  _id: s?.payer?._id,
                  value: s?.payer?.longdesc,
                },
                guarantor_type: {
                  _id: s?.guarantortype?._id,
                  value: s?.guarantortype?.payercode,
                },
                guarantor: {
                  _id: s?.guarantorid?._id,
                  value: s?.guarantorid?.longdesc,
                },
                plan: {
                  _id: s?.policyid?._id,
                  value: s?.policyid?.longdesc,
                },
              },
              documentref: s?.documentref,
              id:i+1
            };
          }
        });
        financeDetailss?.map((v,i)=>{
          if(v === undefined){
            financeDetailss?.splice(i, 1);
          } 
        })
          financeDetailss?.map((v, i) => {
            if (v?.documentref?.length > 0) {
              setViewFiles(v?.documentref);
            }
          });
          setFinancialDatas({
            payer: {
              //payer_type:stateValues?.payer_type,
              policy_start: epochToDate(stateValues?.policy_sdate),
              policy_expiry: epochToDate(stateValues?.policy_edate),
            },
            membership: {
              type: {
                value: stateValues?.membership_type?.value,
                _id: stateValues?.membership_type?._id,
              },
            },
            payerdetails: {
            },
            eligibility: {},
            financial: {},
            savedata: findata_save ? findata_save : financeDetailss,
            index: null,
          });
          let listData =[];
          findata_save?.map((v,i)=>{
            if(!v?.is_deleted){
              listData?.push(v);
            }
          })
          //if(finlistdata===null){
           // setdragItems(findata_save ? findata_save : financeDetailss[0] === undefined ? [] :financeDetailss);
            setdragItems(listData ? listData : financeDetailss[0] === undefined ? [] :financeDetailss);

          // } else {
          //   setdragItems(finlistdata)
          // }
          
        
      }
    }) .catch((error) => {
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please contact to admin",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    });
  };
  // const loadFinanceDetailsDefaultValues = async (findata_save) => {
  //   let payload = {
  //     screenid: process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID,
  //     tenantid: __tenantid__,
  //   };
  //   let token = localStorage.getItem("JwtToken");
  //   const data = await dispatch(
  //     actions.PATIENT_INFO({
  //       patientid: location.state.patientId,
  //       tenantid: "",
  //       facilityid: "",
  //       lang: "",
  //     })
  //   );
  //   let register = await axios({
  //     method: "POST",
  //     url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
  //     headers: {
  //       "Content-Type": "application/json",
  //       jwtToken: `${token}`,
  //     },
  //     data: JSON.stringify(payload),
  //   }).then((res) => {
  //     if (
  //       res?.data?.response?.screenId ===
  //       process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID
  //     ) {
  //       const defaultValues = res?.data?.response?.defaultValues;
  //       const stateNames = [
  //         "policy_sdate",
  //         "policy_plan",
  //         "payer_type",
  //         "membership_type",
  //         "applicable_criteria",
  //         "priority",
  //         "policy_edate",
  //       ];
  //       const stateValues = {};
  //       defaultValues.forEach((s) => {
  //         if (stateNames.includes(s.stateName)) {
  //           stateValues[s.stateName] = s.value;
  //         }
  //       });
  //       setDefaultDataLoad(true);
  //       setDefaultPayerTypeLoad(stateValues?.payer_type);
  //       // PayerRequest(stateValues?.payer_type?._id)
  //       // GuarantorType(stateValues?.payer_type?._id);
  //       // GuarantorId(stateValues?.payer_type?._id)
  //       let financeDetailss = data?.payload?.data?.financialdetails?.map((s) => {
  //         return {
  //           patientaccount_id: s?._id,
  //           membership: {
  //             member_id: s?.membershipid,
  //             type: {
  //               value: s?.membertype?.display,
  //               _id: s?.membertype?._id,
  //             },
  //             sponsor_name: {
  //               value: s?.sponsorname?.name,
  //               _id: s?.sponsorname?._id,
  //             },
  //             relationship: {
  //               value: s?.memberrelid?.display,
  //               _id: s?.memberrelid?._id,
  //             },
  //             prime_member_id: s?.primemembershipid,
  //           },
  //           payer: {
  //             priority: {
  //               value: s?.priority?.display,
  //               _id: s?.priority?._id,
  //             },
  //             payer_type: {
  //               value: s?.payertype?.longdesc,
  //               _id: s?.payertype?._id,
  //             },
  //             payer: [
  //               {
  //                 _id: s?.payer?._id,
  //                 value: s?.payer?.longdesc,
  //                 //shortdesc: s?.payer?.longdesc,
  //               },
  //             ],
  //             plan: [
  //               {
  //                 id: s?.policyid?._id,
  //                 value: s?.policyid?.longdesc,
  //                 //shortdesc: s?.policyid?.longdesc,
  //               },
  //             ],
  //             guarantor_type: [
  //               {
  //                 _id: s?.guarantortype?._id,
  //                 payercode: s?.guarantortype?.payercode,
  //               },
  //             ],
  //             guarantor: [
  //               {
  //                 _id: s?.guarantorid?._id,
  //                 value: s?.guarantorid?.longdesc,
  //                 //shortdesc: s?.guarantorid?.longdesc,
  //               },
  //             ],
  //             policy_expiry: epochToDate(s?.policyexpiry),
  //             policy_start: epochToDate(s?.policystart),
  //           },
  //           validity: {
  //             gl_number: " ",
  //             gl_start: "",
  //             service_start: "",
  //             service_end: "",
  //           },
  //           eligibility: {},
  //           financial: {
  //             class_type: {
  //               value: s?.financialclass?.classtype?.display,
  //               _id: s?.financialclass?.classtype?._id,
  //             },
  //             class_value: {
  //               value: s?.financialclass?.classval?.display,
  //               _id: s?.financialclass?.classval?._id,
  //             },
  //           },
  //           payerdetails: {
  //             payer: {
  //               _id: s?.payer?._id,
  //               value: s?.payer?.longdesc,
  //             },
  //             guarantor_type: {
  //               _id: s?.guarantortype?._id,
  //               value: s?.guarantortype?.payercode,
  //             },
  //             guarantor: {
  //               _id: s?.guarantorid?._id,
  //               value: s?.guarantorid?.longdesc,
  //             },
  //             plan: {
  //               _id: s?.policyid?._id,
  //               value: s?.policyid?.longdesc,
  //             },
  //           },
  //           documentref: s?.documentref,
  //         };

  //       });
  //       financeDetailss?.map((v, i) => {
  //         if (v?.documentref?.length > 0) {
  //           setViewFiles(v?.documentref);
  //         }
  //       });
  //       setFinancialDatas({
  //         payer: {
  //           payer_type:stateValues?.payer_type,
  //           policy_start: epochToDate(stateValues?.policy_sdate),
  //           policy_expiry: epochToDate(stateValues?.policy_edate),
  //           priority: stateValues?.priority,
  //         },
  //         membership: {
  //           type: {
  //             value: stateValues?.membership_type?.value,
  //             _id: stateValues?.membership_type?._id,
  //           },
  //         },
  //         payerdetails: {
  //           // plan: {
  //           //   value: stateValues?.policy_plan?.value,
  //           //   _id: stateValues?.policy_plan?._id,
  //           // },
  //         },
  //         eligibility: {},
  //         financial: {},
  //         savedata: findata_save?.savedata ? findata_save?.savedata : financeDetailss,
  //         index: null,
  //       });
  //     }
  //   });
  // };

  const PayerTypeRequest = async () => {
    const response = await dispatch(actions.PAYER_TYPE(""));
    setPayerType(response?.payload?.data);
  };
  function epochToDate(epochTimestamp) {
    const milliseconds = epochTimestamp * 1000;
    const dateObject = new Date(milliseconds);
    const year = dateObject.getUTCFullYear();
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getUTCDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
//   const viewData = async () => {
//     let payload = {
//       screenid: process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID,
//       tenantid: __tenantid__,
//     };
//     let token = localStorage.getItem("JwtToken");
//     let register = await axios({
//       method: "POST",
//       url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
//       headers: {
//         "Content-Type": "application/json",
//         jwtToken: `${token}`,
//       },
//       data: JSON.stringify(payload),
//     }).then((res) => {
//       if (
//         res?.data?.response?.screenId ===
//         process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID
//       ) {
//         const defaultValues = res?.data?.response?.defaultValues;
//         const stateNames = [
//           "policy_sdate",
//           "policy_plan",
//           "payer_type",
//           "membership_type",
//           "applicable_criteria",
//           "priority",
//           "policy_edate",
//         ];
//         const stateValues = {};
//         defaultValues.forEach((s) => {
//           if (stateNames.includes(s.stateName)) {
//             stateValues[s.stateName] = s.value;
//           }
//         });
//         setDefaultDataLoad(true);
//         setDefaultPayerTypeLoad(stateValues?.payer_type);
//         // PayerRequest(stateValues?.payer_type?._id)
//         // GuarantorType(stateValues?.payer_type?._id);
//         // GuarantorId(stateValues?.payer_type?._id)
  
//         let financeDetailss = location?.state?.data?.financialdetails.map((s) => {
//           // setPayerTypeId(
//           //   {
//           //     value: s?.payertype?.longdesc,
//           //     _id: s?.payertype?._id,
//           //   }
//           // )
//           // setPayerId({
//           //   _id: s?.payer?._id,
//           //   longdesc: s?.payer?.longdesc,
//           //   shortdesc: s?.payer?.longdesc,
//           //   value: s?.payer?.longdesc,
//           // })
//           return {
//             patientaccount_id: s?._id,
//             membership: {
//               member_id: s?.membershipid,
//               type: {
//                 value: s?.membertype?.display,
//                 _id: s?.membertype?._id,
//               },
//               sponsor_name: {
//                 value: s?.sponsorname?.name,
//                 _id: s?.sponsorname?._id,
//               },
//               relationship: {
//                 value: s?.memberrelid?.display,
//                 _id: s?.memberrelid?._id,
//               },
//               prime_member_id: s?.primemembershipid,
//             },
//             payer: {
//               priority: {
//                 value: s?.priority?.display,
//                 _id: s?.priority?._id,
//               },
//               payer_type: {
//                 value: s?.payertype?.longdesc,
//                 _id: s?.payertype?._id,
//               },
//               // payer: [
//               //   {
//               //     _id: s?.payer?._id,
//               //     longdesc: s?.payer?.longdesc,
//               //     shortdesc: s?.payer?.longdesc,
//               //   },
//               // ],
//               plan: [
//                 {
//                   id: s?.policyid?._id,
//                   longdesc: s?.policyid?.longdesc,
//                   shortdesc: s?.policyid?.longdesc,
//                 },
//               ],
//               guarantor_type: [
//                 {
//                   _id: s?.guarantortype?._id,
//                   payercode: s?.guarantortype?.payercode,
//                 },
//               ],
//               guarantor: [
//                 {
//                   _id: s?.guarantorid?._id,
//                   longdesc: s?.guarantorid?.longdesc,
//                   shortdesc: s?.guarantorid?.longdesc,
//                 },
//               ],
//               policy_expiry: epochToDate(s?.policyexpiry),
//               policy_start: epochToDate(s?.policystart),
//             },
//             validity: {
//               gl_number: " ",
//               gl_start: "",
//               service_start: "",
//               service_end: "",
//             },
//             eligibility: {},
//             financial: {
//               class_type: {
//                 value: s?.financialclass?.classtype?.display,
//                 _id: s?.financialclass?.classtype?._id,
//               },
//               class_value: {
//                 value: s?.financialclass?.classval?.display,
//                 _id: s?.financialclass?.classval?._id,
//               },
//             },
//             payerdetails: {
//               payer: {
//                 _id: s?.payer?._id,
//                 value: s?.payer?.longdesc,
//               },
//               guarantor_type: {
//                 _id: s?.guarantortype?._id,
//                 value: s?.guarantortype?.payercode,
//                 payercode: s?.guarantortype?.payercode,
    
//               },
//               guarantor: {
//                 _id: s?.guarantorid?._id,
//                 value: s?.guarantorid?.longdesc,
//                 shortdesc: s?.guarantorid?.longdesc,
    
//               },
//               plan: {
//                 _id: s?.policyid?._id,
//                 value: s?.policyid?.longdesc,
//                 shortdesc: s?.policyid?.longdesc,
//               },
//             },
//             documentref: s?.documentref,
//           };
//         });
//         financeDetailss?.map((v, i) => {
//           if (v?.documentref?.length > 0) {
//             setViewFiles(v?.documentref);
//           }
//         });
//     setFinancialDatas((prevData) => {
//       return {
//         ...prevData,
//         payer: {
//           // payer_type:stateValues?.payer_type,
//           policy_start: epochToDate(stateValues?.policy_sdate),
//           policy_expiry: epochToDate(stateValues?.policy_edate),
//           priority: stateValues?.priority,
//         },
//         membership: {
//           type: {
//             value: stateValues?.membership_type?.value,
//             _id: stateValues?.membership_type?._id,
//           },
//         },
//         // payerdetails: {
//         //   plan: {
//         //     value: stateValues?.policy_plan?.value,
//         //     _id: stateValues?.policy_plan?._id,
//         //   },
//         // },
//         eligibility: {},
//         financial: {},
//         savedata: financeDetailss,
//       };
//     });
//   }
// }).catch((error) => {
//   props?.alert?.setSnack({
//     open: true,
//     severity: AlertProps.severity.error,
//     msg: "Please contact to admin",
//     vertical: AlertProps.vertical.top,
//     horizontal: AlertProps.horizontal.center,
//     tone: true,
//   });
// });
//   };
  //below functions used for back from referral get the finance detail using patient Id
  
  const viewData = () => {
    let financeDetailss = location?.state?.data?.financialdetails.map((s,i) => {
      if(s?.payer?._id === process.env.REACT_APP_PAYER_SELFPAY){
        let a= {
          patientaccount_id: s?._id,
          membership: {
            member_id: s?.membershipid,
            type: {
              value: s?.membertype?.display,
              _id: s?.membertype?._id,
            },
            sponsor_name: {
              value: s?.sponsorname?.name,
              _id: s?.sponsorname?._id,
            },
            relationship: {
              value: s?.memberrelid?.display,
              _id: s?.memberrelid?._id,
            },
            prime_member_id: s?.primemembershipid,
          },
          payer: {
            priority: {
              value: s?.priority?.display,
              _id: s?.priority?._id,
            },
            payer_type: {
              value: s?.payertype?.longdesc,
              _id: s?.payertype?._id,
            },
            // payer: [
            //   {
            //     _id: s?.payer?._id,
            //     longdesc: s?.payer?.longdesc,
            //     shortdesc: s?.payer?.longdesc,
            //   },
            // ],
            plan: [
              {
                id: s?.policyid?._id,
                longdesc: s?.policyid?.longdesc,
                shortdesc: s?.policyid?.longdesc,
              },
            ],
            guarantor_type: [
              {
                _id: s?.guarantortype?._id,
                payercode: s?.guarantortype?.payercode,
              },
            ],
            guarantor: [
              {
                _id: s?.guarantorid?._id,
                longdesc: s?.guarantorid?.longdesc,
                shortdesc: s?.guarantorid?.longdesc,
              },
            ],
            policy_expiry: epochToDate(s?.policyexpiry),
            policy_start: epochToDate(s?.policystart),
          },
          validity: {
            gl_number: " ",
            gl_start: "",
            service_start: "",
            service_end: "",
          },
          eligibility: {},
          financial: {
            class_type: {
              value: s?.financialclass?.classtype?.display,
              _id: s?.financialclass?.classtype?._id,
            },
            class_value: {
              value: s?.financialclass?.classval?.display,
              _id: s?.financialclass?.classval?._id,
            },
          },
          payerdetails: {
            payer: {
              _id: s?.payer?._id,
              value: s?.payer?.longdesc,
            },
            guarantor_type: {
              _id: s?.guarantortype?._id,
              value: s?.guarantortype?.payercode,
              payercode: s?.guarantortype?.payercode,
  
            },
            guarantor: {
              _id: s?.guarantorid?._id,
              value: s?.guarantorid?.longdesc,
              shortdesc: s?.guarantorid?.longdesc,
  
            },
            plan: {
              _id: s?.policyid?._id,
              value: s?.policyid?.longdesc,
              shortdesc: s?.policyid?.longdesc,
            },
          },
          documentref: s?.documentref,
          id:i+1
        };
        setselfpaydata(a)
        return;
      }else{
        return {
          patientaccount_id: s?._id,
          membership: {
            member_id: s?.membershipid,
            type: {
              value: s?.membertype?.display,
              _id: s?.membertype?._id,
            },
            sponsor_name: {
              value: s?.sponsorname?.name,
              _id: s?.sponsorname?._id,
            },
            relationship: {
              value: s?.memberrelid?.display,
              _id: s?.memberrelid?._id,
            },
            prime_member_id: s?.primemembershipid,
          },
          payer: {
            priority: {
              value: s?.priority?.display,
              _id: s?.priority?._id,
            },
            payer_type: {
              value: s?.payertype?.longdesc,
              _id: s?.payertype?._id,
            },
            // payer: [
            //   {
            //     _id: s?.payer?._id,
            //     longdesc: s?.payer?.longdesc,
            //     shortdesc: s?.payer?.longdesc,
            //   },
            // ],
            plan: [
              {
                id: s?.policyid?._id,
                longdesc: s?.policyid?.longdesc,
                shortdesc: s?.policyid?.longdesc,
              },
            ],
            guarantor_type: [
              {
                _id: s?.guarantortype?._id,
                payercode: s?.guarantortype?.payercode,
              },
            ],
            guarantor: [
              {
                _id: s?.guarantorid?._id,
                longdesc: s?.guarantorid?.longdesc,
                shortdesc: s?.guarantorid?.longdesc,
              },
            ],
            policy_expiry: epochToDate(s?.policyexpiry),
            policy_start: epochToDate(s?.policystart),
          },
          validity: {
            gl_number: " ",
            gl_start: "",
            service_start: "",
            service_end: "",
          },
          eligibility: {},
          financial: {
            class_type: {
              value: s?.financialclass?.classtype?.display,
              _id: s?.financialclass?.classtype?._id,
            },
            class_value: {
              value: s?.financialclass?.classval?.display,
              _id: s?.financialclass?.classval?._id,
            },
          },
          payerdetails: {
            payer: {
              _id: s?.payer?._id,
              value: s?.payer?.longdesc,
            },
            guarantor_type: {
              _id: s?.guarantortype?._id,
              value: s?.guarantortype?.payercode,
              payercode: s?.guarantortype?.payercode,
  
            },
            guarantor: {
              _id: s?.guarantorid?._id,
              value: s?.guarantorid?.longdesc,
              shortdesc: s?.guarantorid?.longdesc,
  
            },
            plan: {
              _id: s?.policyid?._id,
              value: s?.policyid?.longdesc,
              shortdesc: s?.policyid?.longdesc,
            },
          },
          documentref: s?.documentref,
          id:i+1
        };
      }
      // setPayerTypeId(
      //   {
      //     value: s?.payertype?.longdesc,
      //     _id: s?.payertype?._id,
      //   }
      // )
      // setPayerId({
      //   _id: s?.payer?._id,
      //   longdesc: s?.payer?.longdesc,
      //   shortdesc: s?.payer?.longdesc,
      //   value: s?.payer?.longdesc,
      // })
    });
    financeDetailss?.map((v,i)=>{
      if(v === undefined){
        financeDetailss?.splice(i, 1);
      } 
    })
      financeDetailss?.map((v, i) => {
        if (v?.documentref?.length > 0) {
          setViewFiles(v?.documentref);
        }
      });
  
      setFinancialDatas((prevData) => {
        return {
          ...prevData,
          savedata: financeDetailss,
        };
      });
      setdragItems(financeDetailss);
  };
//below functions used for back from referral get the finance detail using patient Id
  const viewData1 = async () => {
        const data = await dispatch(
      actions.PATIENT_INFO({
        patientid: location.state.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
      })
    );
    setGetPatientRegistration(data?.payload?.data);
    setGetreferralData(data?.payload?.data?.referraldetails);
    let financeDetailss = data?.payload?.data?.financialdetails?.map((s,i) => {
      if(s?.payer?._id === process.env.REACT_APP_PAYER_SELFPAY){
        let a = {
          patientaccount_id: s?._id,
          membership: {
            member_id: s?.membershipid,
            type: {
              value: s?.membertype?.display,
              _id: s?.membertype?._id,
            },
            sponsor_name: {
              value: s?.sponsorname?.name,
              _id: s?.sponsorname?._id,
            },
            relationship: {
              value: s?.memberrelid?.display,
              _id: s?.memberrelid?._id,
            },
            prime_member_id: s?.primemembershipid,
          },
          payer: {
            priority: {
              value: s?.priority?.display,
              _id: s?.priority?._id,
            },
            payer_type: {
              value: s?.payertype?.longdesc,
              _id: s?.payertype?._id,
            },
            // payer:editState?.payer,
            //  [
            //   {
            //     _id: s?.payer?._id,
            //     value: s?.payer?.longdesc,
            //     shortdesc: s?.payer?.longdesc,
            //   },
            // ],
            plan: [
              {
                _id: s?.policyid?._id,
                value: s?.policyid?.longdesc,
                shortdesc: s?.policyid?.longdesc,
              },
            ],
            guarantor_type: [
              {
                _id: s?.guarantortype?._id,
                payercode: s?.guarantortype?.payercode,
              },
            ],
            guarantor: [
              {
                _id: s?.guarantorid?._id,
                value: s?.guarantorid?.longdesc,
                shortdesc: s?.guarantorid?.longdesc,
              },
            ],
            policy_expiry: epochToDate(s?.policyexpiry),
            policy_start: epochToDate(s?.policystart),
          },
          validity: {
            gl_number: " ",
            gl_start: "",
            service_start: "",
            service_end: "",
          },
          eligibility: {},
          financial: {
            class_type: {
              value: s?.financialclass?.classtype?.display,
              _id: s?.financialclass?.classtype?._id,
            },
            class_value: {
              value: s?.financialclass?.classval?.display,
              _id: s?.financialclass?.classval?._id,
            },
          },
          payerdetails: {
            payer: {
              _id: s?.payer?._id,
              value: s?.payer?.longdesc,
            },
            guarantor_type: {
              _id: s?.guarantortype?._id,
              value: s?.guarantortype?.payercode,
              payercode: s?.guarantortype?.payercode,
            },
            guarantor: {
              _id: s?.guarantorid?._id,
              value: s?.guarantorid?.longdesc,
              shortdesc: s?.guarantorid?.longdesc,
            },
            plan: {
              _id: s?.policyid?._id,
              value: s?.policyid?.longdesc,
              shortdesc: s?.policyid?.longdesc,
            },
          },
          documentref: s?.documentref,
          id:i+1
        };
        setselfpaydata(a)
        return;
      } else {
        setPayerTypeId(
          {
          value: s?.payertype?.longdesc,
          _id: s?.payertype?._id,
        }
        )
        setPayerId({
          _id: s?.payer?._id,
          longdesc: s?.payer?.longdesc,
          shortdesc: s?.payer?.longdesc,
          value: s?.payer?.longdesc,
        })
        return {
          patientaccount_id: s?._id,
          membership: {
            member_id: s?.membershipid,
            type: {
              value: s?.membertype?.display,
              _id: s?.membertype?._id,
            },
            sponsor_name: {
              value: s?.sponsorname?.name,
              _id: s?.sponsorname?._id,
            },
            relationship: {
              value: s?.memberrelid?.display,
              _id: s?.memberrelid?._id,
            },
            prime_member_id: s?.primemembershipid,
          },
          payer: {
            priority: {
              value: s?.priority?.display,
              _id: s?.priority?._id,
            },
            payer_type: {
              value: s?.payertype?.longdesc,
              _id: s?.payertype?._id,
            },
            // payer:editState?.payer,
            //  [
            //   {
            //     _id: s?.payer?._id,
            //     value: s?.payer?.longdesc,
            //     shortdesc: s?.payer?.longdesc,
            //   },
            // ],
            plan: [
              {
                _id: s?.policyid?._id,
                value: s?.policyid?.longdesc,
                shortdesc: s?.policyid?.longdesc,
              },
            ],
            guarantor_type: [
              {
                _id: s?.guarantortype?._id,
                payercode: s?.guarantortype?.payercode,
              },
            ],
            guarantor: [
              {
                _id: s?.guarantorid?._id,
                value: s?.guarantorid?.longdesc,
                shortdesc: s?.guarantorid?.longdesc,
              },
            ],
            policy_expiry: epochToDate(s?.policyexpiry),
            policy_start: epochToDate(s?.policystart),
          },
          validity: {
            gl_number: " ",
            gl_start: "",
            service_start: "",
            service_end: "",
          },
          eligibility: {},
          financial: {
            class_type: {
              value: s?.financialclass?.classtype?.display,
              _id: s?.financialclass?.classtype?._id,
            },
            class_value: {
              value: s?.financialclass?.classval?.display,
              _id: s?.financialclass?.classval?._id,
            },
          },
          payerdetails: {
            payer: {
              _id: s?.payer?._id,
              value: s?.payer?.longdesc,
            },
            guarantor_type: {
              _id: s?.guarantortype?._id,
              value: s?.guarantortype?.payercode,
              payercode: s?.guarantortype?.payercode,
            },
            guarantor: {
              _id: s?.guarantorid?._id,
              value: s?.guarantorid?.longdesc,
              shortdesc: s?.guarantorid?.longdesc,
            },
            plan: {
              _id: s?.policyid?._id,
              value: s?.policyid?.longdesc,
              shortdesc: s?.policyid?.longdesc,
            },
          },
          documentref: s?.documentref,
          id:i+1
        };
      }
    });
    financeDetailss?.map((v,i)=>{
      if(v === undefined){
        financeDetailss?.splice(i, 1);
      } 
    })
      financeDetailss?.map((v, i) => {
        if (v?.documentref?.length > 0) {
          setViewFiles(v?.documentref);
        }
      });
      setFinancialDatas((prevData) => {
        return {
          ...prevData,
          savedata: financeDetailss,
        };
      });
      setdragItems(financeDetailss);
  };
//     const viewData1 = async () => {
//     let payload = {
//       screenid: process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID,
//       tenantid: __tenantid__,
//     };
//     let token = localStorage.getItem("JwtToken");
//     const data = await dispatch(
//       actions.PATIENT_INFO({
//         patientid: location.state.patientId,
//         tenantid: "",
//         facilityid: "",
//         lang: "",
//       })
//     );
//     setGetPatientRegistration(data?.payload?.data);
//     setGetreferralData(data?.payload?.data?.referraldetails);
//     let register = await axios({
//       method: "POST",
//       url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
//       headers: {
//         "Content-Type": "application/json",
//         jwtToken: `${token}`,
//       },
//       data: JSON.stringify(payload),
//     }).then((res) => {
//       if (
//         res?.data?.response?.screenId ===
//         process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID
//       ) {
//         const defaultValues = res?.data?.response?.defaultValues;
//         const stateNames = [
//           "policy_sdate",
//           "policy_plan",
//           "payer_type",
//           "membership_type",
//           "applicable_criteria",
//           "priority",
//           "policy_edate",
//         ];
//         const stateValues = {};
//         defaultValues.forEach((s) => {
//           if (stateNames.includes(s.stateName)) {
//             stateValues[s.stateName] = s.value;
//           }
//         });
//         setDefaultDataLoad(true);
//         setDefaultPayerTypeLoad(stateValues?.payer_type);
//         // PayerRequest(stateValues?.payer_type?._id)
//         // GuarantorType(stateValues?.payer_type?._id);
//         // GuarantorId(stateValues?.payer_type?._id)
  
//     let financeDetailss = data?.payload?.data?.financialdetails.map((s) => {
//       setPayerTypeId(
//         {
//         value: s?.payertype?.longdesc,
//         _id: s?.payertype?._id,
//       }
//       )
//       setPayerId({
//         _id: s?.payer?._id,
//         longdesc: s?.payer?.longdesc,
//         shortdesc: s?.payer?.longdesc,
//         value: s?.payer?.longdesc,
//       })
//       return {
//         patientaccount_id: s?._id,
//         membership: {
//           member_id: s?.membershipid,
//           type: {
//             value: s?.membertype?.display,
//             _id: s?.membertype?._id,
//           },
//           sponsor_name: {
//             value: s?.sponsorname?.name,
//             _id: s?.sponsorname?._id,
//           },
//           relationship: {
//             value: s?.memberrelid?.display,
//             _id: s?.memberrelid?._id,
//           },
//           prime_member_id: s?.primemembershipid,
//         },
//         payer: {
//           priority: {
//             value: s?.priority?.display,
//             _id: s?.priority?._id,
//           },
//           payer_type: {
//             value: s?.payertype?.longdesc,
//             _id: s?.payertype?._id,
//           },
//           // payer:editState?.payer,
//           //  [
//           //   {
//           //     _id: s?.payer?._id,
//           //     value: s?.payer?.longdesc,
//           //     shortdesc: s?.payer?.longdesc,
//           //   },
//           // ],
//           plan: [
//             {
//               _id: s?.policyid?._id,
//               value: s?.policyid?.longdesc,
//               shortdesc: s?.policyid?.longdesc,
//             },
//           ],
//           guarantor_type: [
//             {
//               _id: s?.guarantortype?._id,
//               payercode: s?.guarantortype?.payercode,
//             },
//           ],
//           guarantor: [
//             {
//               _id: s?.guarantorid?._id,
//               value: s?.guarantorid?.longdesc,
//               shortdesc: s?.guarantorid?.longdesc,
//             },
//           ],
//           policy_expiry: epochToDate(s?.policyexpiry),
//           policy_start: epochToDate(s?.policystart),
//         },
//         validity: {
//           gl_number: " ",
//           gl_start: "",
//           service_start: "",
//           service_end: "",
//         },
//         eligibility: {},
//         financial: {
//           class_type: {
//             value: s?.financialclass?.classtype?.display,
//             _id: s?.financialclass?.classtype?._id,
//           },
//           class_value: {
//             value: s?.financialclass?.classval?.display,
//             _id: s?.financialclass?.classval?._id,
//           },
//         },
//         payerdetails: {
//           payer: {
//             _id: s?.payer?._id,
//             value: s?.payer?.longdesc,
//           },
//           guarantor_type: {
//             _id: s?.guarantortype?._id,
//             value: s?.guarantortype?.payercode,
//             payercode: s?.guarantortype?.payercode,
//           },
//           guarantor: {
//             _id: s?.guarantorid?._id,
//             value: s?.guarantorid?.longdesc,
//             shortdesc: s?.guarantorid?.longdesc,
//           },
//           plan: {
//             _id: s?.policyid?._id,
//             value: s?.policyid?.longdesc,
//             shortdesc: s?.policyid?.longdesc,
//           },
//         },
//         documentref: s?.documentref,
//       };

//     });
//     financeDetailss?.map((v, i) => {
//       if (v?.documentref?.length > 0) {
//         setViewFiles(v?.documentref);
//       }
//     });
//     setFinancialDatas((prevData) => {
//       return {
//         ...prevData,
//         payer: {
//           // payer_type:stateValues?.payer_type,
//           policy_start: epochToDate(stateValues?.policy_sdate),
//           policy_expiry: epochToDate(stateValues?.policy_edate),
//           priority: stateValues?.priority,
//         },
//         membership: {
//           type: {
//             value: stateValues?.membership_type?.value,
//             _id: stateValues?.membership_type?._id,
//           },
//         },
//         // payerdetails: {
//         //   plan: {
//         //     value: stateValues?.policy_plan?.value,
//         //     _id: stateValues?.policy_plan?._id,
//         //   },
//         // },
//         eligibility: {},
//         financial: {},
//         savedata: financeDetailss,
//       };
//     });
//   }
// }).catch((error) => {
//   props?.alert?.setSnack({
//     open: true,
//     severity: AlertProps.severity.error,
//     msg: "Please contact to admin",
//     vertical: AlertProps.vertical.top,
//     horizontal: AlertProps.horizontal.center,
//     tone: true,
//   });
// });
//   };
  const navigatefromVisitRegister = async () => {
    let payload = {
      screenid: process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID,
      tenantid: __tenantid__,
    };
    let token = localStorage.getItem("JwtToken");
    const patientInfo = await dispatch(
      actions.PATIENT_INFO({
        patientid: location?.state?.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
      })
    );
    let register = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      data: JSON.stringify(payload),
    }).then((res) => {
      if (
        res?.data?.response?.screenId ===
        process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID
      ) {
        const defaultValues = res?.data?.response?.defaultValues;
        const stateNames = [
          "policy_sdate",
          "policy_plan",
          "payer_type",
          "membership_type",
          "applicable_criteria",
          // "priority",
          "policy_edate",
        ];
        const stateValues = {};
        defaultValues.forEach((s) => {
          if (stateNames.includes(s.stateName)) {
            stateValues[s.stateName] = s.value;
          }
        });
        setDefaultDataLoad(true);
        setDefaultPayerTypeLoad(stateValues?.payer_type);
        // PayerRequest(stateValues?.payer_type?._id)
        // GuarantorType(stateValues?.payer_type?._id);
        // GuarantorId(stateValues?.payer_type?._id)
  
    let financeDetailss = patientInfo?.payload?.data?.financialdetails.map(
      (s,i) => {
        if(s?.payer?._id === process.env.REACT_APP_PAYER_SELFPAY){
          let a = {
            patientaccount_id: s?._id,
            membership: {
              member_id: s?.membershipid,
              type: {
                value: s?.membertype?.display,
                _id: s?.membertype?._id,
              },
              sponsor_name: {
                value: s?.sponsorname?.name,
                _id: s?.sponsorname?._id,
              },
              relationship: {
                value: s?.memberrelid?.display,
                _id: s?.memberrelid?._id,
              },
              prime_member_id: s?.primemembershipid,
            },
            payer: {
              priority: {
                value: s?.priority?.display,
                _id: s?.priority?._id,
              },
              payer_type: {
                value: s?.payertype?.longdesc,
                _id: s?.payertype?._id,
              },
              // payer:editState?.payer,
              //  [
              //   {
              //     _id: s?.payer?._id,
              //     value: s?.payer?.longdesc,
              //     shortdesc: s?.payer?.longdesc,
              //   },
              // ],
              plan: [
                {
                  _id: s?.policyid?._id,
                  value: s?.policyid?.longdesc,
                  shortdesc: s?.policyid?.longdesc,
                },
              ],
              guarantor_type: [
                {
                  _id: s?.guarantortype?._id,
                  payercode: s?.guarantortype?.payercode,
                },
              ],
              guarantor: [
                {
                  _id: s?.guarantorid?._id,
                  value: s?.guarantorid?.longdesc,
                  shortdesc: s?.guarantorid?.longdesc,
                },
              ],
              policy_expiry: epochToDate(s?.policyexpiry),
              policy_start: epochToDate(s?.policystart),
            },
            validity: {
              gl_number: " ",
              gl_start: "",
              service_start: "",
              service_end: "",
            },
            eligibility: {},
            financial: {
              class_type: {
                value: s?.financialclass?.classtype?.display,
                _id: s?.financialclass?.classtype?._id,
              },
              class_value: {
                value: s?.financialclass?.classval?.display,
                _id: s?.financialclass?.classval?._id,
              },
            },
            payerdetails: {
              payer: {
                _id: s?.payer?._id,
                value: s?.payer?.longdesc,
              },
              guarantor_type: {
                _id: s?.guarantortype?._id,
                value: s?.guarantortype?.payercode,
                payercode: s?.guarantortype?.payercode,
              },
              guarantor: {
                _id: s?.guarantorid?._id,
                value: s?.guarantorid?.longdesc,
                shortdesc: s?.guarantorid?.longdesc,
              },
              plan: {
                _id: s?.policyid?._id,
                value: s?.policyid?.longdesc,
                shortdesc: s?.policyid?.longdesc,
              },
            },
            documentref: s?.documentref,
            id:i+1
          };
          setselfpaydata(a)
          return;
        } else {
          setPayerTypeId(
            {
            value: s?.payertype?.longdesc,
            _id: s?.payertype?._id,
          }
          )
          setPayerId({
            _id: s?.payer?._id,
            longdesc: s?.payer?.longdesc,
            shortdesc: s?.payer?.longdesc,
            value: s?.payer?.longdesc,
          })
          return {
            patientaccount_id: s?._id,
            membership: {
              member_id: s?.membershipid,
              type: {
                value: s?.membertype?.display,
                _id: s?.membertype?._id,
              },
              sponsor_name: {
                value: s?.sponsorname?.name,
                _id: s?.sponsorname?._id,
              },
              relationship: {
                value: s?.memberrelid?.display,
                _id: s?.memberrelid?._id,
              },
              prime_member_id: s?.primemembershipid,
            },
            payer: {
              priority: {
                value: s?.priority?.display,
                _id: s?.priority?._id,
              },
              payer_type: {
                value: s?.payertype?.longdesc,
                _id: s?.payertype?._id,
              },
              // payer:editState?.payer,
              //  [
              //   {
              //     _id: s?.payer?._id,
              //     value: s?.payer?.longdesc,
              //     shortdesc: s?.payer?.longdesc,
              //   },
              // ],
              plan: [
                {
                  _id: s?.policyid?._id,
                  value: s?.policyid?.longdesc,
                  shortdesc: s?.policyid?.longdesc,
                },
              ],
              guarantor_type: [
                {
                  _id: s?.guarantortype?._id,
                  payercode: s?.guarantortype?.payercode,
                },
              ],
              guarantor: [
                {
                  _id: s?.guarantorid?._id,
                  value: s?.guarantorid?.longdesc,
                  shortdesc: s?.guarantorid?.longdesc,
                },
              ],
              policy_expiry: epochToDate(s?.policyexpiry),
              policy_start: epochToDate(s?.policystart),
            },
            validity: {
              gl_number: " ",
              gl_start: "",
              service_start: "",
              service_end: "",
            },
            eligibility: {},
            financial: {
              class_type: {
                value: s?.financialclass?.classtype?.display,
                _id: s?.financialclass?.classtype?._id,
              },
              class_value: {
                value: s?.financialclass?.classval?.display,
                _id: s?.financialclass?.classval?._id,
              },
            },
            payerdetails: {
              payer: {
                _id: s?.payer?._id,
                value: s?.payer?.longdesc,
              },
              guarantor_type: {
                _id: s?.guarantortype?._id,
                value: s?.guarantortype?.payercode,
                payercode: s?.guarantortype?.payercode,
              },
              guarantor: {
                _id: s?.guarantorid?._id,
                value: s?.guarantorid?.longdesc,
                shortdesc: s?.guarantorid?.longdesc,
              },
              plan: {
                _id: s?.policyid?._id,
                value: s?.policyid?.longdesc,
                shortdesc: s?.policyid?.longdesc,
              },
            },
            documentref: s?.documentref,
            id:i+1
          };
        }
      }
    );
    financeDetailss?.map((v,i)=>{
      if(v === undefined){
        financeDetailss?.splice(i, 1);
      } 
    })
      financeDetailss?.map((v, i) => {
        if (v?.documentref?.length > 0) {
          setViewFiles(v?.documentref);
        }
      });
      setFinancialDatas((prevData) => {
        return {
          ...prevData,
          payer: {
          // payer_type:stateValues?.payer_type,
          policy_start: epochToDate(stateValues?.policy_sdate),
          policy_expiry: epochToDate(stateValues?.policy_edate),
          priority: stateValues?.priority,
        },
        membership: {
          type: {
            value: stateValues?.membership_type?.value,
            _id: stateValues?.membership_type?._id,
          },
        },
        // payerdetails: {
        //   plan: {
        //     value: stateValues?.policy_plan?.value,
        //     _id: stateValues?.policy_plan?._id,
        //   },
        // },
        eligibility: {},
        financial: {},
        savedata: financeDetailss,
        };
      });
      setdragItems(financeDetailss);
  }
}).catch((error) => {
  props?.alert?.setSnack({
    open: true,
    severity: AlertProps.severity.error,
    msg: "Please contact to admin",
    vertical: AlertProps.vertical.top,
    horizontal: AlertProps.horizontal.center,
    tone: true,
  });
});
  };
  useEffect(() => {
    
    if (location?.state?.backMode) {
      viewData1();
    }
    getAllMasters();
    getPatientInfo();
    PayerTypeRequest();
    if (location?.state?.isModify) {
      viewData();
    } else if (location?.state?.IsViewMode) {
      viewData();
    } else if (location?.state?.isEditedMode) {
      viewData();
    } else if (location?.state?.appointmentId) {
      navigatefromVisitRegister();
    }
  }, []);

  useEffect(() => {
    if (
      !(
        location?.state?.IsViewMode ||
        location?.state?.isEditedMode ||
        location?.state?.isModify ||
        location?.state?.backMode ||
        location?.state?.appointmentId||
        isOnEdit
      )
    ) {
      loadFinanceDetailsDefaultValues();
    }
  }, [])
  // useEffect(() => {
  //     loadFinanceDetailsDefaultValues();
  // }, [financialDatas?.savedata])
  // useEffect(() => {
  //   if (
  //     !(
  //       location?.state?.IsViewMode ||
  //       location?.state?.isEditedMode ||
  //       location?.state?.isModify ||
  //       location?.state?.backMode ||
  //       location?.state?.appointmentId||
  //       isOnEdit
  //     )
  //   ) {
  //     loadFinanceDetailsDefaultValues();
  //   }
  // }, [props]);

  const onEdit = async (v, i) => {
    let patientData = { ...financialDatas };
    setEditPayer(patientData);
    patientData["membership"] = v?.membership??{};
    patientData["payer"] = v?.payer??{};
    patientData["payerdetails"] = v?.payerdetails??{};
    patientData["validity"] = v?.validity??{};
    patientData["eligibility"] = v?.eligibility??{};
    patientData["financial"] = v?.financial??{};
    patientData["applicable"] = v?.applicable??{};
    // patientData.index = i;
    patientData.index = patientData?.savedata?.findIndex(
      (i) =>
        i?.payerdetails?.payer?._id === patientData?.payerdetails?.payer?._id
    );
    await setFinancialDatas({ ...patientData });
    setAdd(true);
    setIsOnEdit(true);
  };

  const alertHold = () => {};

  let AVCMeeting = {
    RoomName: "",
    component: {
      key: null,
      props: { children: "Hai" },
      ref: null,
      _owner: null,
      _store: { validated: false },
    },
    counter: undefined,
    direction: "right",
    domain: "",
    dragging: false,
    isLarge: null,
    jwt: "",
    onClose: () => {},
    open: false,
    pos: { top: 250, left: 150 },
    rel: null,
    setMeetingDrawer: async (props) => {},
    user_name: "",
    variant: "temporary",
    widthAdjState: undefined,
  };
  const goBackFn = () => {
    if (location?.state?.isRevise) {
      navigate(AppRoutes.visitRegistration, {
        state: {
          personId: location?.state?.personId,
          from: 1,
          patientId: location?.state?.patientId,
          appointmentId: location?.state?.appointmentId,
          Ispatient: true,
          isedit: true, //action?.action == "VISIT REGISTER"? false : true,
          isRevise: true, //action?.action == "REVISE VISIT"? true : false,
          patientData: "", //mobileNo, allPatientDetails.patientdetails.telecom
          stateAction: "REVISE VISIT", //action?.action,
          stateEndPoint: "", //action?.apiendpoint,
          stateBackEndCall: "", //action?.backEndCall,
          stateTicketid: "",
        },
      });
    } else {
      navigate(location?.state?.appointmentId
        ? AppRoutes.visitRegistration
        :AppRoutes.patientRegistration, {
          state: {
          from: location?.state?.from,
          visit: true,
          nextRoute: AppRoutes.referralInRegistration,
          prevRoute: AppRoutes.visitRegistration,
          mobileNo: location?.state?.mobileNumber,
          mobileNumber: location?.state?.mobileNumber,
          appointmentId: location?.state?.appointmentId,
          isViewMode: location?.state?.IsViewMode,
          patientId: location?.state?.data?._id ?? location?.state?.patientId,
          data: location?.state?.data || getPatientRegistration,
          patientdetails: location?.state?.patientdetails,
          addtionaldetails: location?.state?.addtionaldetails,
          nextofkin: location?.state?.nextofkin,
          totalData: location?.state?.totalData,
          isModify: location?.state?.isModify,
          backMode: location?.state?.backMode || "true",
          stateAction: location?.state?.stateAction,
          stateEndPoint: location?.state?.stateEndPoint,
          stateBackEndCall: location?.state?.stateBackEndCall,
          stateTicketid: location?.state?.stateTicketid,
          visitData: location?.state?.visitData,
          isedited: location?.state?.appointmentId ? true : false,
          isFinanceBack: location?.state?.appointmentId ? true : false,
        },
      });
    }
  };

  const getLanguage = (val) => {};
  const EncounterDetails = location?.state?.encounterDetails?.response?.[0];

  const EncounterHeader = props.encounterHeader?.data?.[0];
  const { practitioner } = props.location?.state?.visitData || {};
  const viewModeNavigateToReferal = (patientid) => {
    navigate(AppRoutes.referralInRegistration, {
      state: {
        // patientdetails: patientdetails1,
        // nextofkin: nextOfKind1,
        // addtionaldetails: additionalDetails1,
        // allMasters: allMasters,
        IsViewMode: location?.state?.IsViewMode,
        isEditedMode: location?.state?.isEditedMode,
        patientId: location?.state?.data?._id ?? "",
        data: location?.state?.data,
        referraldetails: location?.state?.data?.referraldetails,
        totalData: location?.state?.totalData,
      },
    });
  };
  const successfun2 = async () => {
    const userInfo = localStorage?.getItem("UserInfo");
    const decodedUserinfo = userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
    const facilityId = decodedUserinfo?.facilityid ?? "";

    //const userInfo = localStorage.getItem("UserInfo");
    //const decodedUserinfo = jwtDecode(userInfo);
    // if (!patientData?.length > 0) {
    //   props?.alert?.setSnack({
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Please save the details",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //     tone: true,
    //   });
    // } else {
    setBtnName(true);
    let payload = {
      ticketId: location?.state?.totalData?.ticketId ?? "",

      userAction: process.env.REACT_APP_REFERRAL_APPROVE,
      data: {
        ticketId: location?.state?.totalData?.ticketId ?? "",
        practitionerId: decodedUserinfo?.practitionerid,
        practitionerRoleId: decodedUserinfo?.roleid,
      },
    };
    let token = localStorage.getItem("JwtToken");
    let register = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      url: process.env.REACT_APP_PERFORMUSERACTION_API,
      data: payload,
    })
      .then((res) => {
        if (res.status === 200 && !res.data.error) {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Approved successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          setBtnName(false);
          navigate(AppRoutes.dashboard);
        } else {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: res.data.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          setBtnName(false);
        }
      })
      .catch((error) => {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please contact to admin",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        setBtnName(false);
      });
    // }
  };
  const FlterOptions = (type) => {
    // const { allMasters } = props;
    let filteredArray = allMasters?.filter((obj) => obj.hasOwnProperty([type]));
    var lista = [];
    let data = filteredArray?.[0]?.[type]?.Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?.display,
        _id: data[index]?._id,
      };
      lista.push(datatemp);
    }
    return lista;
  };
  let priorityList = FlterOptions("PRIORITY");
  let valPriority = priorityList.map((s) => s?.value);
  const handleDragEnd = (dragIndex, dragY) => {
    const destinationIndex = Math.round(dragY / 55  );
    const newDestinationIndex = Math.min(Math.max(destinationIndex, 0), dragitems.length - 1);
    if (newDestinationIndex !== dragIndex) {
      const newItems = [...dragitems];
      const draggedItem = newItems.splice(dragIndex, 1)[0];
      newItems.splice(newDestinationIndex, 0, draggedItem);
      let getdragdata =[]
      newItems?.map((data, index) => {
        if(data?.is_deleted !== true){
          getdragdata.push(data);
        }
      })
      setdragItems(getdragdata);
      let isdeldata = newItems;
      financialDatas?.savedata?.map((data, index) => {
        if(data?.is_deleted==true){
          isdeldata.push(data);
        }
      })
      setFinancialDatas({
        ...financialDatas,
        savedata: isdeldata,
      });
    }    
    //setdragItems(newItems);
  };
  return (
    <Div
      id="finance_registration_parent_div"
      className="light-background-color"
    >
      <TopNavbar
        goBack={goBackFn}
        parent_id={"Financial_Registration"}
        alertHold={alertHold}
        putOnHold={() =>
          putOnHold("undefined", props.location?.state?.appointmentId)
        }
        newUser={props.location?.state?.newUser}
        successfun={successfun}
        navBarArr={[]}
        title={financialTitle}
        btn={
          location?.state?.IsViewMode
            ? "Continue"
            : btnName
            ? "Registering"
            : "Register & continue"
        }
        isAVC={AVCMeeting?.RoomName}
        parent_View_id={location?.state?.IsViewMode}
        isViewMode={location?.state?.IsViewMode}
        navigateToNextScreen={viewModeNavigateToReferal}
        approveStatus={successfun2}
        isSideMenuOpen={isSideMenuOpen}
        handleSideMenu={handleSideMenu}
        handleSideMenuclose={handleSideMenuclose}
        setActionName={setActionName}
        actionName={actionName}
        handlecahngeCancelbooking={handlecahngeCancelbooking}
        handleSelectCancelbooking={handleSelectCancelbooking}
        cancelbookingreason={cancelbookingreason}
        totalData={location?.state?.totalData}
        cancelData={cancelData()}
        setFiles={setFiles}
        setviewFiles={setViewFiles}
        initialFiles={viewFiles}
        isModify={location?.state?.isModify}
        isBackMode={
          location?.state?.isModify ||
          location?.state?.backMode ||
          location?.state?.isEditedMode
        }
      />
      <div
        style={{
          height: "calc(100vh - 128px)", // 128px = 72px + 56px // 72 - topheader , 56 - topnav
          overflow: "auto",
          display: state.loading ? "flex" : "block",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading loading={location?.state.loading ? true : false}>
          <Div id="financial_parent_sub_div" className="rv-list-view">
            {
              //(props.location?.state?.guest || location?.state?.Ispatient) &&
              // EncounterDetails !== "undefined" ? (
              //EncounterDetails?.length > 0 ? (
              //true ? (
              EncounterDetails?._id ? (
                <Row id="financial_patientlist_row">
                  <Col
                    id="financial_patientlist_col"
                    lg={6}
                    md={6}
                    inLineStyles={{ padding: 5 }}
                  >
                    <PatientListView
                      parent_id={"Financial_Registration"}
                      noright={true}
                      // Persondata={state.Persondata?.[0]}
                      Persondata={state.Persondata}
                      // patientId={state.patientId}
                      patientId={state?.Persondata?.identifier?.[0]?.value}
                      IsViewMode={location?.state?.IsViewMode}
                    />
                  </Col>
                  <Col
                    id="financial_patientstatus_col"
                    lg={6}
                    md={6}
                    inLineStyles={{ padding: 5 }}
                  >
                    <PatientStatusCard
                      parent_id={"Financial_Registration"}
                      EncounterDetails={EncounterDetails}
                      pic={
                        "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                      }
                      // titles={[
                      //   `${EncounterHeader?._id ?? ""}`,
                      //   `${EncounterHeader?.type?.[0] ?? ""}`,
                      //   `${EncounterHeader?.visit_type ?? ""}`,
                      //   `${practitioner?.speciality?.value ?? ""}`,
                      // ]}
                      titles={[
                        `${EncounterDetails?.encounter_no ?? ""}`,
                        `${EncounterDetails?.typevalue ?? ""}`,
                        `${EncounterDetails?.visit_typevalue ?? ""}`,
                        `${
                          EncounterDetails?.participants?.[0]
                            ?.specialitydisplay ?? ""
                        }`, 
                      ]}
                      date={moment
                        .unix(EncounterDetails?.encounterdate)
                        .format("MMMM Do YYYY, h:mm a")}
                      languages={getLanguage(EncounterHeader)}
                      padding
                      IsViewMode={location?.state?.IsViewMode}
                    />
                  </Col>
                </Row>
              ) : (
                <Row id="financial_patientist_row_one">
                  {" "}
                  <Col
                    id="financial_patientist_col_one"
                    lg={12}
                    md={12}
                    inLineStyles={{ padding: 5 }}
                  >
                    {/* <PatientListView
                    parent_id={"Financial_Registration"}
                    noright={true}
                    Persondata={state.Persondata?.[0]}
                  /> */}
                    <PatientListView
                      parent_id={"Financial_Registration"}
                      noright={true}
                      // Persondata={state.Persondata?.[0]}
                      Persondata={state.Persondata}
                      // patientId={state.patientId}
                      patientId={state?.Persondata?.identifier?.[0]?.value}
                      IsViewMode={location?.state?.IsViewMode}
                    />
                  </Col>
                </Row>
              )
            }
          </Div>
          {financialDatas?.savedata?.length > 0 && (
            <Div
              className="pv-root"
              style={{
                margin: "3px 15px",
                display:"flex",
                flexDirection:"column",
                gap:"10px"
              }}
            >
              <Paper id="financial_applicable_paper" className="pl-root" >
                <H6
                  id="financial_applicable_label_h6"
                  className="en-title space-padding-bottom"
                >
                  APPLICABLE CRITERIA
                </H6>
                <Grid container style={{alignItems:"center", justifyContent:"flex-start"}}>
                <InfoIcon
                 width={12}
                 style={{ marginRight: 6 }}
                 color={UIColor.primaryColor.color}
            
                />
                <span style={{fontSize:"12px"}}  color={UIColor.primaryText.color}>
                Use drag and drop to change the priority
                </span>
                </Grid>
              
                {/* {financialDatas?.savedata?.sort((s, k) => s?.payer?.priority?.value - k?.payer?.priority?.value).map((v, i) => {                  
                  if (v?.is_deleted) {
                    return null;
                  }
                  return (
                    <Div
                      id="financial_applicable_div"
                      style={{ marginBottom: 16 }}
                    >
                      <PayerDetailCard
                        parent_id={"financial_showlist"}
                        onedit={() => onEdit(v, i)}
                        ondelete={() => onDelete(v, i)}
                        isDelete={
                          v?.payer?.payer_type?.value === "Self Pay"
                            ? false
                            : true
                        }
                        isEdit={
                          v?.payer?.payer_type?.value === "Self Pay"
                            ? false
                            : true
                        }
                        data={v}
                        index={i?.payer?.priority?.value}
                        cleared={cleared}
                        IsViewMode={location?.state?.IsViewMode}
                      />
                    </Div>
                  );
                })} */}
                {dragitems?.map((v, i) => (
                    <PayerDetailCard
                      key={v?.id} // You might need to set a unique key for each card
                      parent_id={"financial_showlist"}
                      onedit={() => onEdit(v, i)}
                      ondelete={() => onDelete(v, i)}
                      isDelete={
                          v?.payer?.payer_type?.value === "Self Pay"
                          ? false
                          : true
                      }
                      isEdit={
                          v?.payer?.payer_type?.value === "Self Pay"
                          ? false
                          : true
                      }
                      data={v}
                      index={i}
                      cleared={cleared}
                      IsViewMode={location?.state?.IsViewMode}
                      keys={v} 
                      values={v}
                      priority={i+1}
                      handleDragEnd={handleDragEnd}
                      // newpriority={i+ parseInt(v?.payer?.priority?.value)}
                    />
                  ))}
               
              </Paper>
            </Div>
          )}
          <Div
              className="pv-root"
              style={{
                margin: "3px 15px",
                display:"flex",
                flexDirection:"column",
                gap:"10px"
              }}
            >
              <Paper id="financial_applicable_paper" className="pl-root" >
              <PayerDetailCard
                key={`${1}`} // You might need to set a unique key for each card
                parent_id={"financial_showlist"}
                isDelete={
                false
                }
                isEdit={
                  false
                }
                data={selfpaydata}
                cleared={cleared}
                IsViewMode={location?.state?.IsViewMode}
                keys={selfpaydata} 
                values={selfpaydata}
                istitle={true}
                priority={dragitems?.length + 1}//selfpaydata?.payer?.priority?.value
              />
              </Paper>
            </Div>

          <CardDetails
            parent_id={"financial_carddetails"}
            allMasters={allMasters}
            // onSave={() => handlesubmit()}
            // onCancel={() => onCancel()}
            setFinancialDatas={setFinancialDatas}
            setdragItems={setdragItems}
            financialDatas={financialDatas}
            cleared={cleared}
            payerType={payerType}
            payerValue={payer}
            guarantorType={guarantorType}
            guarantorId={guarantorId}
            plan={plan}
            editPayer={editPayer}
            add={add}
            setAdd={setAdd}
            errorNames={error}
            setError={setError}
            setErr={setErr}
            isOnEdit={isOnEdit}
            setIsOnEdit={setIsOnEdit}
            location={location}
            isViewMode={location?.state?.IsViewMode}
            viewModeNavigateToReferal={viewModeNavigateToReferal}
            defaultDataLoad={defaultDataLoad}
            defaultPayerTypeLoad={defaultPayerTypeLoad}
            editState={editState}
            setEditState={setEditState}
            payerId={payerId}
            payerTypeId={payerTypeId}
            loadFinanceDetailsDefaultValues={loadFinanceDetailsDefaultValues}
            />
          {/* <ApplicableCriteria
            parent_id={"financial_applicable_criteria"}
            allMasters={state?.allMasters}
            setApplicable={setApplicable}
            applicable={applicable}
          /> */}
          <Div id="financial_continue_div" className="p-continue-action"></Div>
        </Loading>
      </div>
    </Div>
  );
};

export default withAppBar(withAllContexts(FinanceRegistration));