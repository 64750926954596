import React from "react";
import {
  Div,
  Col,
  Row,
  Paper,
  H6,
  TextInput,
  Image,
  Text,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../hoc/withAllContexts";
import moment from "moment";
import { UIColor } from "../../themes/theme";
import CustomInputDatePicker from "../customDatePicker/inputDatePicker";
import { useLocation } from "react-router-dom";
import uploadIcon from "../../assets/img/svg/icons8-upload.svg";
import { Grid, Popover } from "@material-ui/core";
import { UploadFiles } from "../navBar";
import FileIcon from '../../assets/img/svg/icons8-file.svg';
import useStyles from "./styles";

function RefLetter(props) {
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    inputBorderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    errorinputBorderStyle: {
      borderRadius: 8,
    },
  };
  const { refletter, setRefletter } = props;

  const setDetails = (name, value, limit) => {
    if (limit) {
      if (limit < value?.length) {
        return false;
      }
    }
    setRefletter((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (refletter.error) {
      refletter.error[name] = false;
    }
    setRefletter({
      ...refletter,
      [name]: value,
    });
  };
  const [isUpload, setIsUpload] = React.useState(null);
  const uploadFun = (e) => {
    setIsUpload(e?.currentTarget);
  };
  const uploadDocument = (files, viewfiles) => {
    
    setRefletter({
      ...refletter,
      viewFile: viewfiles,
      refLetterFile: files || [],
    });
    // setRefletter((prevRefletter) => ({
    //   ...prevRefletter, // Preserve existing properties
    //   viewFile: viewfiles,
    //   refLetterFile: [
    //     // Concatenate existing files with newly uploaded ones
    //     ...prevRefletter.refLetterFile,
    //     ...files,
    //   ],
    // }));
   
  };

  const { labelStyle, inputBorderStyle, errorinputBorderStyle } = styles;
  const { parent_id } = props;
  const location = useLocation();
  const viewMode = location?.state?.isERefView;
  const classes = useStyles();
  console.log(refletter, "refletter");
  return (
    <Div id={`${parent_id}_refletter_parent_div`} className="pv-root">
      <Paper id={`${parent_id}_refletter_parent_paper`} className="pl-root">
        <Row
          id={`${parent_id}_refletter_parent_row`}
          className="en-actions-root"
        >
          <Col
            id={`${parent_id}_refletter_parent_col`}
            md={8}
            lg={8}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Div id={`${parent_id}_refletter_title_label_col`}>
              <H6
                id={`${parent_id}_refletter_title_label_h6`}
                className="en-title space-padding-bottom"
              >
                REFERRAL LETTER
              </H6>
            </Div>
            <Grid
              container
              xs={12}
              style={{
                paddingLeft: "10px",
                alignItems: "center",
                justifyItems: "center",
              }}
              spacing={3}
            >
              <Grid
                item
                id={`${parent_id}_refletter_sub_parent_col`}
                md={3}
                lg={3}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_refletter_no_label_textinput`}
                  value={refletter?.letterNo || ""}
                  onChange={(e) => setDetails("letterNo", e.target.value, 20)}
                  label="Referral letter No."
                  isRequired
                  placeholder="Referral letter no"
                  // helperText={
                  // 	letter?.error && letter?.error["letterNo"]
                  // 		? "Is required"
                  // 		: ""
                  // }
                  error={
                    refletter?.error && refletter?.error["letterNo"]
                      ? true
                      : false
                  }
                  labelStyle={labelStyle}
                  style={
                    refletter?.error && refletter?.error["letterNo"]
                      ? errorinputBorderStyle
                      : inputBorderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  disabled={viewMode}
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refletter_calender_col`}
                md={3}
                lg={3}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <CustomInputDatePicker
                  disabled={false}
                  placeholderText="dd/mm/yyyy"
                  minDate = {new Date(moment().subtract(2, 'months').format('YYYY-MM-DD'))}
									maxDate = {new Date()}
                  handleChange={(value) =>
                    setDetails(
                      "referralDate",
                      value ? moment(value).format("YYYY-MM-DD") : null,
                      "validity"
                    )
                  }
                  selectedDate={
                    refletter?.referralDate
                      ? new Date(refletter?.referralDate)
                      : ""
                  }
                  inputField={
                    <TextInput
                      id={`${parent_id}_refletter_referral_date_textinput`}
                      label="Referral Date"
                      placeholder="dd/mm/yyyy"
                      dateIcon="images/icons8-calendar-grey.svg"
                      labelStyle={labelStyle}
                      isRequired
                      error={
                        refletter?.error && refletter?.error["referralDate"]
                          ? true
                          : false
                      }
                      style={
                        refletter?.error && refletter?.error["referralDate"]
                          ? errorinputBorderStyle
                          : inputBorderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                      value={
                        refletter?.referralDate
                          ? moment(new Date(refletter?.referralDate)).format(
                              "DD/MM/YYYY"
                            )
                          : ""
                      }
                      disabled={viewMode}
                    />
                  }
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refletter_reciveddate_col`}
                md={3}
                lg={3}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                {/* <TextInput
									id={`${parent_id}_refletter_reciveddate_textinput`}
									isRequired
									// helperText={
									// 	letter?.error && letter?.error["recivedDate"]
									// 		? "Is required"
									// 		: ""
									// }
									error={
										letter?.error && letter?.error["recivedDate"] ? true : false
									}
									//max={moment(new Date()).format("YYYY-MM-DD")}
									min={moment(letter.referralDate).format("YYYY-MM-DD")}
									value={letter.recivedDate}
									onChange={(e) => setDetails("recivedDate", e.target.value)}
									label="Received Date"
									type="date"
									dateIcon="images/icons8-calendar-grey.svg"
									labelStyle={labelStyle}
									style={letter?.error && letter?.error["recivedDate"] ? errorinputBorderStyle : inputBorderStyle}
									hoverColor={UIColor.primaryColor.color}
								/> */}
                <CustomInputDatePicker
                  disabled={false}
                  placeholderText="dd/mm/yyyy"
                  minDate = {new Date(moment().subtract(2, 'months').format('YYYY-MM-DD'))}
									maxDate = {new Date()}                 
                  handleChange={(value) =>
                    setDetails(
                      "recivedDate",
                      value ? moment(value).format("YYYY-MM-DD") : null,
                      "validity"
                    )
                  }
                  selectedDate={
                    refletter?.recivedDate
                      ? new Date(refletter?.recivedDate)
                      : new Date()
                  }
                  inputField={
                    <TextInput
                      id={`${parent_id}_refletter_reciveddate_textinput`}
                      label="Received Date"
                      placeholder="dd/mm/yyyy"
                      dateIcon="images/icons8-calendar-grey.svg"
                      labelStyle={labelStyle}
                      isRequired
                      // error={
                      // 	letter?.error && letter?.error["recivedDate"] ? true : false
                      // }
                      style={
                        refletter?.error && refletter?.error["recivedDate"]
                          ? errorinputBorderStyle
                          : inputBorderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                      value={
                        refletter?.recivedDate
                          ? moment(new Date(refletter?.recivedDate)).format(
                              "DD/MM/YYYY"
                            )
                          : moment(new Date()).format("DD/MM/YYYY")
                      }
                      disabled={viewMode}
                    />
                  }
                />
              </Grid>
              <Grid
                item
                id={`${parent_id}_refletter_reciveddate_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
                style={{ marginLeft: "20px" }}
              >
                <Text
                  style={{
                    color: "#6F6F6F",
                    fontSize: "14px",
                    fontFamily: "pc_regular",
                  }}
                >
                  Upload Referral Letter
                </Text>
              </Grid>
              <Grid item>
                <Div
                  id="Referral_Registration_referral_parent_div"
                  className="uploadIcon"
                >
                  <Image
                    src={viewMode ? FileIcon : uploadIcon}
                    alt="upload"
                    onClick={(e) => uploadFun(e)}
                  />
                </Div>
              </Grid>
            </Grid>
          </Col>
          <Col
            id={`${parent_id}_refletter_referralid_col`}
            lg={4}
            md={4}
            sm={4}
            xs={4}
            className="no-padding-left no-padding-right"
          >
            {/* <Row
              id={`${parent_id}_refletter_referralid_row`}
              className="en-encounter-right"
            >
              <Col
                id={`${parent_id}_refletter_referralid_sub_col`}
                md={6}
                lg={6}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_refletter_referralid_textinput`}
                  value={letter.referralId}
                  onChange={(e) => setDetails("referralId", e.target.value)}
                  disabled
                  label="Referral ID"
                  placeholder="Referral id"
                  labelStyle={labelStyle}
                  style={{ ...inputBorderStyle, background: "#F0F0F0" }}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              <Col
                id={`${parent_id}_refletter_status_col`}
                md={6}
                lg={6}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_refletter_status_textinput`}
                  // value={letter.status}
                  // value={Persondata?.[0]?.active ? 'Active' : 'Inactive'}
                  value={"Active"}
                  onChange={(e) => setDetails("status", e.target.value)}
                  disabled
                  label="Status"
                  placeholder="Status"
                  labelStyle={labelStyle}
                  style={{ ...inputBorderStyle, background: "#F0F0F0" }}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Paper>
      <Popover
        anchorEl={isUpload}
        open={Boolean(isUpload)}
        onClose={() => uploadFun(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ zIndex: 99999 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.muiPopovers }}
      >
        <UploadFiles
          uploadDocument={uploadDocument}
          files={refletter?.refLetterFile}
          viewfiles={refletter?.viewFile}
          isViewMode={props.isViewMode || viewMode}
		  
        />
      </Popover>
    </Div>
  );
}

export default withAllContexts(RefLetter);
