import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  TextareaAutosize,
  Typography,
  makeStyles,
  Divider
} from "@material-ui/core";
import { TextInput } from "qdm-component-library";
import { UIColor } from "../../../themes/theme";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import { AlertContext } from "../../../context";
import { AlertProps, axiosCall } from "../../../utils";
import actions from "../../../redux/actions";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { treatmentPlan } from "../vitalsJson";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import moment from "moment";
const styles = {
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "red",
  },
  inputStyle: {
    padding: 5,
    marginBottom: 20,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  actionButton: {
    backgroundColor: "#FFFFFF",
    border: "solid 1px #DEDEDE",
    borderRadius: 6,
    color: "black",
    marginBottom: 8,
    boxShadow: "none",
    "&:hover": {},
    "&:first-child": {
      backgroundColor: "#FF4D4A",
      borderColor: "#FF4D4A",
      color: "#ffffff",
      fontWeight: 600,
    },
  },
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
}));

const TreatmentPlanForm = (props) => {
  console.log("TreatmentPlanForm", props);
  const { setSnack } = useContext(AlertContext);
  const {
    data,
    // handlesave,
    statekey,
    handlecancel,
    handleEdit,
    edit,
    editstate,
  } = props;
  const [state, setState] = useState({});
  const dispatch = useDispatch();
  const [errorsField, setErrorsFields] = React.useState({});
  const [errorField, setErrorFields] = React.useState({});
  const [hdMachineNo, setHdMachineNo] = React.useState([]);
  const [heparinDropDown, setHeparinDropDown] = React.useState([]);
  const [shiftTypeDropDown, setShiftTypeDropDown] = React.useState([]);
  const [practionerName, setPractionerName] = React.useState([]);
  const [datas, setDatas] = React.useState(false);
  const [isErr, setisErr] = React.useState(false);
  const [isErrs, setisErrs] = React.useState(false);

  const alert = useContext(AlertContext);
  const classes = useStyles();
  React.useEffect(() => {
    if (edit) {
      setState({
        TVA: {
          obsinternalid: "VitalsCodes/10008",
          obscode: "TVA",
          obsvalue: editstate?.vascularaccess,
          shortdesc: "Vascular Access",
          isabnormal: true,
          abnormalmsg: '"Min"',
          type: "text",
          obsuom: "VitalsMeasureCodes/10007",
          time: 1705570640883,
          groupname: "Common Field",
          mandatory: true,
          validation_max: 200,
          validation_min: 10,
          default_unit: "",
          error: false,
        },
        TargetDryWeight: {
          obsinternalid: "VitalsCodes/10012",
          obscode: "TargetDryWeight",
          obsvalue: editstate?.dryweight,
          shortdesc: "Dry Weight",
          isabnormal: true,
          abnormalmsg: '"Min"',
          type: "number",
          obsuom: "VitalsMeasureCodes/10011",
          time: 1705570640883,
          groupname: "Common Field",
          mandatory: true,
          validation_max: 250,
          validation_min: 0,
          default_unit: "Kg",
          error: false,
        },
        AVFneedlesize: {
          obsinternalid: "VitalsCodes/10022",
          obscode: "AVFneedlesize",
          obsvalue: editstate?.needlesize,
          shortdesc: "Needle size",
          isabnormal: true,
          abnormalmsg: '"Min"',
          type: "autocomplete",
          obsuom: "VitalsMeasureCodes/10021",
          time: 1705570640883,
          groupname: "Flow Rate",
          mandatory: true,
          validation_max: 200,
          validation_min: 20,
          default_unit: "",
          error: false,
        },
        "Duration": {
          obsinternalid: "VitalsCodes/10019",
          obscode: "Duration",
          obsvalue: editstate?.hdduration,
          shortdesc: "Duration (hrs)",
          isabnormal: true,
          abnormalmsg: '"Min"',
          type: "number",
          obsuom: "VitalsMeasureCodes/10018",
          time: 1705570640883,
          groupname: "Hd Duration",
          mandatory: true,
          validation_max: 12,
          validation_min: 0,
          default_unit: "Hrs",
          error: false,
        },
        "Frequency": {
          obsinternalid: "VitalsCodes/10018",
          obscode: "Frequency",
          obsvalue: editstate?.frequency,
          shortdesc: "Frequency (/week)",
          isabnormal: true,
          abnormalmsg: '"Min"',
          type: "select",
          obsuom: "VitalsMeasureCodes/10017",
          time: 1705570640883,
          dropdownvalues: ["shift 1a", "shift 2a", "shift 3a"],
          groupname: "Hd Duration",
          mandatory: true,
          validation_max: 200,
          validation_min: 20,
          default_unit: "/week",
          error: false,
        },
        Shift: {
          obsinternalid: "VitalsCodes/10017",
          obscode: "Shift",
          obsvalue: editstate?.shift,
          shortdesc: "Shift",
          isabnormal: true,
          abnormalmsg: '"Min"',
          type: "autocomplete",
          obsuom: "VitalsMeasureCodes/10016",
          time: 1705570640883,
          dropdownvalues: ["Afternoon","Evening","Morning","Night"],
          groupname: "Hd Duration",
          mandatory: true,
          validation_max: 200,
          validation_min: 20,
          default_unit: "",
          error: false,
        },
        QB: {
          obsinternalid: "VitalsCodes/10008",
          obscode: "QB",
          obsvalue: editstate?.qb,
          shortdesc: "Qb - mL/min",
          isabnormal: true,
          abnormalmsg: '"Min"',
          type: "select",
          obsuom: "VitalsMeasureCodes/10009",
          time: 1705570640883,
          groupname: "Hourly Monitoring",
          mandatory: true,
          validation_max: 500,
          validation_min: 1,
          default_unit: "mL/min",
          error: false,
        },
        QD: {
          obsinternalid: "VitalsCodes/10008",
          obscode: "QD",
          obsvalue: editstate?.qd,
          shortdesc: "Qd - mL/min",
          isabnormal: true,
          abnormalmsg: '"Min"',
          type: "select",
          obsuom: "VitalsMeasureCodes/10009",
          time: 1705570640883,
          groupname: "Hourly Monitoring",
          mandatory: true,
          validation_min: 0,
          default_unit: "mL/min",
          error: false,
        },
        Heparin: {
          obsinternalid: "VitalsCodes/10000",
          obscode: "Heparin",
          obsvalue: editstate?.heparin,
          shortdesc: "Heparin",
          isabnormal: true,
          abnormalmsg: '"Min"',
          type: "select",
          obsuom: "VitalsMeasureCodes/10000",
          time: 1705570640883,
          groupname: "Hourly Monitoring",
          mandatory: true,
          validation_max: 200,
          validation_min: 20,
          default_unit: "",
          error: false,
        },
        DialyzerType: {
          obsinternalid: "VitalsCodes/10023",
          obscode: "DialyzerType",
          obsvalue: editstate?.dialyzertype,
          shortdesc: "Dialyzer",
          isabnormal: true,
          abnormalmsg: '"Min"',
          type: "autocomplete",
          obsuom: "VitalsMeasureCodes/10022",
          time: 1705570640883,
          groupname: "Dialyzer",
          mandatory: true,
          validation_max: 200,
          validation_min: 20,
          default_unit: "",
          error: false,
        },
        "DialysateCa2": {
          obsinternalid: "VitalsCodes/10025",
          obscode: "DialysateCa2",
          obsvalue: editstate?.dialysate,
          shortdesc: "Dialysate",
          isabnormal: true,
          abnormalmsg: '"Min"',
          type: "autocomplete",
          obsuom: "VitalsMeasureCodes/10024",
          time: 1705570640883,
          groupname: "Dialysate",
          mandatory: true,
          validation_max: 200,
          validation_min: 20,
          default_unit: "",
          error: false,
        },
        CONDUCTIVITY: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "CONDUCTIVITY",
          obsvalue: editstate?.buffer,
          shortdesc: "Buffer",
          isabnormal: true,
          abnormalmsg: '"Min"',
          type: "text",
          obsuom: "VitalsMeasureCodes/10002",
          time: 1705570640883,
          groupname: "Hourly Monitoring",
          mandatory: true,
          validation_max: 100,
          validation_min: 0,
          default_unit: "",
          error: false,
        },
        // medicationHistory: editstate?.medicationhistory,
        // medicalHistory: editstate?.medicalhistory,
        // bloodResult: editstate?.bloodresult,
        plan: editstate?.plan,
        examinations: editstate?.examinations,
        // currentMedication: editstate?.currentmedication,
        // hdStartDate: moment.unix(editstate?.hdstartdate).format("YYYY-MM-DD") ,
        hdStartDate: editstate?.hdstartdate 
        ? moment.unix(editstate?.hdstartdate).format("YYYY-MM-DD") 
        : "",
        hdStartDateEpoch: editstate?.hdstartdate,
      });
    } else {
      setState(data);
    }
  }, [edit]);

  const handlesaved = async (statekey, value) => {
    let error = false;
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        const obs = value[key];
        if (obs?.obsvalue === "" && obs?.mandatory) {
          errorField[obs?.obscode] = { error: true };
          error = true;
          setisErrs(true);
          setErrorFields({ ...errorField });
          setDatas(true);
        }
      }
    }
    if (error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the mandatory field",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    } else {
      let doc = {
        _id: props?.treatmentPlanData?.[0]?._id || "",
        tenantid: decodedUserinfo?.tenantid,
        facilityid: decodedUserinfo?.facilityid,
        patientid: props?.patientid ?? props?.ids?.patientgetData?.patient_id,
        encounterid: props?.encounterid ?? props?.ids?.patientgetData?._id,
        treatplandate: 1704793218,
        vascularaccess: state?.TVA?.obsvalue,
        qb: parseInt(state?.QB?.obsvalue),
        qd: parseInt(state?.QD?.obsvalue),
        hddate: 1704863005,
        dialyzertype: state?.DialyzerType?.obsvalue?._id,
        dryweight: parseInt(state?.TargetDryWeight?.obsvalue),
        dialysate: state["DialysateCa2"]?.obsvalue?._id,
        needlesize: state["AVFneedlesize"]?.obsvalue?._id,
        shift: state["Shift"]?.obsvalue?._id,
        heparin: state["Heparin"]?.obsvalue?._id,
        buffer: state["CONDUCTIVITY"]?.obsvalue,
        ticketId: "",
        practitionerId: decodedUserinfo?.practitionerid,
        practitionerRoleId: decodedUserinfo?.roleid,
        hdduration: parseInt(state["Duration"]?.obsvalue),
        frequency: state["Frequency"]?.obsvalue?._id,
        // medicalhistory: state?.medicalHistory,
        // medicationhistory: state?.medicationHistory,
        plan: state?.plan,
        examinations: state?.examinations,
        // currentmedication: state?.currentMedication,
        hdstartdate:
          state?.hdStartDateEpoch !== null &&
          state?.hdStartDateEpoch !== undefined
            ? Number((state?.hdStartDateEpoch / 1000).toFixed(0))
            : 0,
        // bloodresult: state?.bloodResult,
      };
      const value = await dispatch(actions.TRAEMENT_PLAN_SAVE({ doc: doc }));
      const data = await dispatch(
        actions.TRAEMENT_PLAN_READ({
          patientid:
            props?.patientId ??
            props?.patientid ??
            props?.ids?.patientgetData?.patient_id,
        })
      );

      if (!value?.payload?.error) {
        if (props?.ticketId) {
          let JwtToken = localStorage.getItem("JwtToken");
          let header = { JwtToken: JwtToken };
          let payloadList = {
            ticketId: props?.ticketId,
            userAction: process.env.REACT_APP_REFERRAL_UPDATETREATMENTPLAN,
            data: {
              ticketId: props?.ticketId,
              practitionerId: decodedUserinfo?.practitionerid,
              practitionerRoleId: decodedUserinfo?.roleid,
            },
          };
          const performUserActionData = await axiosCall(
            payloadList,
            process.env.REACT_APP_PERFORMUSERACTION_API,
            header
          );
        }
      }
      const alertmsg = edit ? " Treatment Plan Updated successfully!" : " Treatment Plan Saved successfully!";
      setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: alertmsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });


      //  props?.setTreatmentplan(data?.payload?.data?.[0]);
      props?.setTreatmentPlanDatas(data?.payload?.data?.[0]);
      // console.log(data, "787878");
    }
    props?.setOpen(false);
  };
  const HeparinDropDown = async () => {
    const response = await dispatch(actions.HeparinDropDown(""));
    setHeparinDropDown(response?.payload?.data);
  };
  const ShiftTypeDropDown = async () => {
    const response = await dispatch(actions.ShiftTypeDropDown(""));
    setShiftTypeDropDown(response?.payload?.data);
  };
  const Practioner_Name = async () => {
    const response = await dispatch(
      actions.Practioner_Name({ PractitionerId: props?.practid })
    );
    handlechange("predialysis", "StaffID", response?.payload?.data?.[0]?.name);
    setPractionerName(response?.payload?.data);
  };
  const FlterOptions3 = (props, type) => {
    let filteredArray = props.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        display: data?.[index]?.display,
        _id: data?.[index]?._id,
      };

      list.push(datatemp);
    }

    return list;
  };
  const handleEdited = (statekey, value) => {
    let error = false;

    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        const obs = value[key];
        if (obs.obsvalue === "" && obs.mandatory) {
          errorField[obs.obscode] = { error: true };
          error = true;
          setisErrs(true);
          setErrorFields({ ...errorField });
        }
      }
    }
    if (error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Mandatory Fields cannot be empty and out of range",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }
    handleEdit(statekey, state);
  };
  const handlechange = (statekey, key, value, min_range, max_range) => {
    try {
      let obj = {};
      if (key === "StaffID" && !edit) {
        obj = JSON.parse(JSON.stringify(data));
      } else {
        obj = JSON.parse(JSON.stringify(state));
      }
      obj[key].obsvalue = value;
      if (value < min_range || value > max_range) {
        obj[key].error = true;
        setErrorsFields((prevErrors) => ({
          ...prevErrors,
          [key]: { error: true },
        }));
        setisErr(true);
      } else {
        obj[key].error = false;
        setErrorsFields((prevErrors) => ({
          ...prevErrors,
          [key]: { error: false },
        }));
        setisErr(false);
        errorField[obj[key].obscode] = { error: false };
        setErrorFields({ ...errorField });
      }

      // obj[key].practitionerName = practitionerName?.[0]?.name;
      // obj[key].LastVisitDate = new Date().toISOString().split("T")[0];
      setState(obj);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    HeparinDropDown();
  }, []);
  useEffect(() => {
    ShiftTypeDropDown();
  }, []);
  useEffect(() => {
    gettreatmentdata();
  }, []);
  const gettreatmentdata = async () => {
    const data = await dispatch(
      actions.TRAEMENT_PLAN_READ({
        patientid: props?.patientId ?? props?.ids?.patientgetData?.patient_id,
      })
    );

    // props?.setTreatmentPlanData(data?.payload?.data);
  };

  // useEffect(() => {
  //   let value = Object.keys(data).filter((li) => li === "StaffID");

  //   if (value?.length > 0) {
  //     Practioner_Name();
  //   }
  // }, []);

  const simplehandlechange = (key, value, date) => {
    if (date) {
      setState({
        ...state,
        [key + "Epoch"]: value?.target?.valueAsNumber,
        [key]: value?.target?.value,
      });
    } else {
      setState({ ...state, [key]: value });
    }
  };

  return (
    <div style={{margin:"10px 0px"}}>
    <Grid container justifyContent="space-between" alignItems="center" style={{padding:"5px 25px"}}>
    <Grid item>
    <Typography component="h2" variant="h6" style={{fontWeight:"600",color:"#01205C"}} >Treatment Plan</Typography>
    </Grid>
    <Grid item>
      <IconButton onClick={handlecancel}>
        <CloseIcon />
      </IconButton>
    </Grid>
  </Grid>
  <Grid>
    <Divider variant="fullWidth" />
  </Grid>
      <Grid container alignItems="center" spacing={2} xs={12} style={{padding:" 25px 25px  0px 25px"}}>
        {console.log(state, "vascular")}
        {Object.keys(data)?.map((v, i) => {
          const groupname = data[v]?.groupname;
          if (groupname === "Common Field") {
            return (
              <>
                <Grid item key={`${v}-${i}`}>
                  {data?.[v]?.type === "text" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      isRequired={data?.[v]?.mandatory}
                      size={"small"}
                      onChange={(e) => {
                        handlechange("treatmentplan", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      // value={editstate?.vascularaccess}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      iconText={data?.[v]?.default_unit}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      hoverColor={UIColor.lineBorderFill.color}
                    />
                  )}
                  {data?.[v]?.type === "number" && (
                    <>
                      <TextInput
                        label={data?.[v]?.shortdesc}
                        isRequired={data?.[v]?.mandatory}
                        size={"small"}
                        type={"number"}
                        iconText={data?.[v]?.default_unit}
                        onChange={(e) => {
                          handlechange(
                            "predialysis",
                            v,
                            e.target.value,
                            data?.[v]?.validation_min,
                            data?.[v]?.validation_max
                          );
                        }}
                        value={state?.[v]?.obsvalue ?? ""}
                        labelStyle={styles.labelStyle}
                        placeholder={""}
                        // style={styles.inputFiledStyle}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? styles.errorborderStyle
                            : styles.inputFiledStyle
                        }
                        //hoverColor="#0071F2"
                        hoverColor={UIColor.lineBorderFill.color}
                        // error={
                        //   isErr && errorsField[data?.[v]?.obscode]?.error === true
                        //     ? true
                        //     : false
                        // }
                      />
                      <div
                        style={{
                          // marginTop: "3%",
                          position: "absolute",
                          // bottom: 20,
                          marginBottom: "10px",
                        }}
                      >
                        {isErr &&
                        errorsField[data?.[v]?.obscode]?.error === true ? (
                          <span style={{ color: "red", fontSize: 12 }}>
                            {"Value should be " +
                              data?.[v]?.validation_min +
                              " to " +
                              data?.[v]?.validation_max +
                              "."}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                </Grid>
              </>
            );
          } else if (groupname === "Flow Rate") {
            return (
              <>
                <Grid item>
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A7888",
                          fontSize: "12px",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        // options={data?.[v]?.dropdownvalues ?? []}
                        options={FlterOptions3(
                          heparinDropDown,
                          "FLOWRATEAVFNEEDLESIZE"
                        )}
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option?.display ?? ""}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option._id}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option?.display ?? ""}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                </Grid>
              </>
            );
          } else if (groupname === "Hd Duration") {
            return (
              <>
                <Grid item>
                  {data?.[v]?.type === "text" && (
                    <>
                    <p
                    style={{
                      fontSize: "50px",
                      fontSWeight: "800",
                      margin: "0",
                      color: "#6A7888",
                      fontSize: "12px",
                      marginBottom: "6px",
                    }}
                  >
                    {data?.[v]?.shortdesc}
                    <span
                      style={{
                        color: "red",
                        fontSWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      *
                    </span>
                  </p>
                    <TextInput
                      size={"small"}
                      type={"text"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      iconText={data?.[v]?.default_unit}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                      isRequired={data?.[v]?.mandatory}
                    />
                    </>
                  )}
                  {data?.[v]?.type === "number" && (
                    <>
                      <TextInput
                        label={data?.[v]?.shortdesc}
                        isRequired={data?.[v]?.mandatory}
                        size={"small"}
                        type={"number"}
                        onChange={(e) => {
                          handlechange(
                            "predialysis",
                            v,
                            e.target.value,
                            data?.[v]?.validation_min,
                            data?.[v]?.validation_max
                          );
                        }}
                        value={state?.[v]?.obsvalue ?? ""}
                        labelStyle={styles.labelStyle}
                        placeholder={""}
                        // style={styles.inputFiledStyle}
                        iconText={data?.[v]?.default_unit}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? styles.errorborderStyle
                            : styles.inputFiledStyle
                        }
                        //hoverColor="#0071F2"
                        hoverColor={UIColor.lineBorderFill.color}
                        // error={
                        //   isErr && errorsField[data?.[v]?.obscode]
                        //     ? true
                        //     : false
                        // }
                      />
                      <div
                        style={{
                          // marginTop: "3%",
                          position: "absolute",
                          // bottom: 20,
                        }}
                      >
                        {isErr &&
                        errorsField[data?.[v]?.obscode]?.error === true ? (
                          <span style={{ color: "red", fontSize: 12 }}>
                            {"Value should be " +
                              data?.[v]?.validation_min +
                              " to " +
                              data?.[v]?.validation_max +
                              "."}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                  {data?.[v]?.type === "select" && (
                    <>
                      <>
                      <p
                    style={{
                      fontSize: "50px",
                      fontSWeight: "800",
                      margin: "0",
                      color: "#6A7888",
                      fontSize: "12px",
                      marginBottom: "6px",
                    }}
                  >
                    {data?.[v]?.shortdesc}
                    <span
                      style={{
                        color: "red",
                        fontSWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      *
                    </span>
                  </p>
                        {/* <Autocomplete
                          // style={{ width: 190 }}
                          style={
                            isErrs &&
                            errorField[data?.[v]?.obscode]?.error === true
                              ? {
                                  width: 190,
                                  border: "1px solid red",
                                  borderRadius: 8,
                                }
                              : { width: 190 }
                          }
                          className={classes.autocomplete}
                          id="size-small-standard"
                          size="small"
                          isRequired={true}
                          options={
                            data?.[v]?.shortdesc === "Frequency (/week)"
                              ? data?.[v]?.dropdownvalues ?? []//Array.from({ length: 53 }, (_, i) => i)
                              : data?.[v]?.dropdownvalues ?? []
                          }
                          popupIcon={
                            <ExpandMoreIcon
                              id={`${v}_allergy_type_expandmoreicon`}
                              fontSize="small"
                              style={{ color: "#b4aeae" }}
                            />
                          }
                          disableClearable
                          getOptionLabel={(option) => {
                            if (option == null) {
                              return "";
                            }
                            return String(option);
                          }}
                          renderInput={(params) => (
                            <TextField
                              id={`${v}_allergy_type_textfield`}
                              {...params}
                              variant="outlined"
                              iconText={data?.[v]?.default_unit}
                            />
                          )}
                          renderOption={(option) => (
                            <Typography
                              id={`${v}_allergy_${option?.shortdesc}_typography`}
                              style={{ width: "100%" }}
                              noWrap
                            >
                              {option}
                            </Typography>
                          )}
                          classes={{ popper: "muiPopperDropdown" }}
                          value={state?.[v]?.obsvalue ?? ""}
                          // onChange={(e, newValue) => {
                          //   handlechange("predialysis", v, newValue);
                          // }}
                          // onChange={(e, newValue) => {
                          //   if (data?.[v]?.shortdesc === "Frequency (/week)") {
                          //     newValue = newValue === undefined || isNaN(newValue) ? 0 : Number(newValue);
                          //   }
                          //   handlechange("predialysis", v, newValue);
                          // }}
                          onChange={(e, newValue) => {
                            // if (data?.[v]?.shortdesc === "Frequency (/week)") {
                            //   newValue = String(newValue);
                            // }
                            handlechange("predialysis", v, newValue);
                          }}
                        /> */}
                         <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        // options={data?.[v]?.dropdownvalues ?? []}
                        options={FlterOptions3(shiftTypeDropDown, "SHIFTTYPE")}
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option?.display}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option?.value}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option?.display}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                       
                      />
                      
                      </>
                    </>
                  )}
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A7888",
                          fontSize: "12px",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        // options={data?.[v]?.dropdownvalues ?? []}
                        options={FlterOptions3(shiftTypeDropDown, "DAYTYPE")}
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option?.display}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option?.value}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option?.display}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                </Grid>
              </>
            );
          } else if (groupname === "Dialyzer") {
            return (
              <>
                <Grid item>
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A7888",
                          fontSize: "12px",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        // options={data?.[v]?.dropdownvalues ?? []}
                        options={FlterOptions3(
                          shiftTypeDropDown,
                          "DIALYZERHIGHFLUX"
                        )}
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option?.display}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option?.value}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option?.display}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                </Grid>
              </>
            );
          } else if (groupname === "Dialysate") {
            return (
              <>
                <Grid item>
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A7888",
                          fontSize: "12px",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        // options={data?.[v]?.dropdownvalues ?? []}
                        options={FlterOptions3(shiftTypeDropDown, "DIALYSATE")}
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option?.display}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option?.value}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option?.display}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                </Grid>
              </>
            );
          } else if (groupname === "Hourly Monitoring") {
            return (
              <>
                <Grid item>
                  {data?.[v]?.type === "text" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      size={"small"}
                      type={"text"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                      isRequired={data?.[v]?.mandatory}
                    />
                  )}
                  {data?.[v]?.type === "number" && (
                    <>
                      <TextInput
                        label={data?.[v]?.shortdesc}
                        isRequired={data?.[v]?.mandatory}
                        size={"small"}
                        type={"number"}
                        onChange={(e) => {
                          handlechange(
                            "predialysis",
                            v,
                            e.target.value,
                            data?.[v]?.validation_min,
                            data?.[v]?.validation_max
                          );
                        }}
                        value={state?.[v]?.obsvalue ?? ""}
                        labelStyle={styles.labelStyle}
                        placeholder={""}
                        // style={styles.inputFiledStyle}
                        iconText={data?.[v]?.default_unit}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? styles.errorborderStyle
                            : styles.inputFiledStyle
                        }
                        //hoverColor="#0071F2"
                        hoverColor={UIColor.lineBorderFill.color}
                        // error={
                        //   isErr && errorsField[data?.[v]?.obscode]
                        //     ? true
                        //     : false
                        // }
                      />
                      <div
                        style={{
                          // marginTop: "3%",
                          position: "absolute",
                          // bottom: 20,
                        }}
                      >
                        {isErr &&
                        errorsField[data?.[v]?.obscode]?.error === true ? (
                          <span style={{ color: "red", fontSize: 12 }}>
                            {"Value should be " +
                              data?.[v]?.validation_min +
                              " to " +
                              data?.[v]?.validation_max +
                              "."}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                  {data?.[v]?.type === "select" && (
                    <>
                    <p
                    style={{
                      fontSize: "50px",
                      fontSWeight: "800",
                      margin: "0",
                      color: "#6A7888",
                      fontSize: "12px",
                      marginBottom: "6px",
                    }}
                  >
                    {data?.[v]?.shortdesc}
                    <span
                      style={{
                        color: "red",
                        fontSWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      *
                    </span>
                  </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        options={
                          data?.[v]?.shortdesc === "Qb - mL/min" 
                            ? Array.from(
                                { length: 11 },
                                (_, i) => 150 + i * 25
                              ).map(String)
                            : data?.[v]?.shortdesc === "Heparin"
                            ? Array.from(
                                { length: 10 },
                                (_, i) => 500 + i * 500
                              ).map(String)
                            : data?.[v]?.shortdesc === "Qd - mL/min" 
                            ? Array.from(
                                { length: 15 },
                                (_, i) => 150 + i * 25
                              ).map(String) : data?.[v]?.dropdownvalues ?? []
                        }
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => {
                          if (option == null) {
                            return "";
                          }
                          return String(option);
                        }}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        // onChange={(e, newValue) => {
                        //   handlechange("predialysis", v, newValue);
                        // }}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A7888",
                          fontSize: "12px",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        // options={data?.[v]?.dropdownvalues ?? []}
                        options={FlterOptions3(
                          shiftTypeDropDown,
                          "HEPARINTYPE"
                        )}
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option?.display ?? ""}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option._id}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option?.display ?? ""}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                </Grid>
              </>
            );
          }
        })}

        <Grid item xs={7}>
        <p
        style={{
          fontSize: "50px",
          fontSWeight: "800",
          margin: "0",
          color: "#6A7888",
          fontSize: "12px",
          marginBottom: "6px",
        }}
      >
       HD Start Date
      </p>
          <input
            style={{
              width: "190px",
              height: "35px",
              padding: "15px",
              outline: "1px solid rgb(222, 229, 236) ",
              border: "0px",
              borderRadius: "8px",
              fontFamily: "pc_regular",
              fontSize: "14px",
              fontWeight: "400",
            }}
            type="date"
          
            value={state?.hdStartDate ?? ""}
            onChange={(e) => {
              simplehandlechange("hdStartDate", e, true);
              // simplehandlechange("hdStartDateEpoch", e.target.valueAsNumber);
            }}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <p
            style={{
              color: "rgb(106, 120, 136)",
              fontSize: "12px",
              fontWeight: "500",
              margin: "0px 0px 6px",
            }}
          >
            Medical History{" "}
          </p>
          <TextareaAutosize
            maxRows={8}
            style={{
              width: "100%",
              height: "50px",
              outline: "1px solid rgb(222, 229, 236) ",
              border: "0px",
              borderRadius: "8px",
              padding: "5px",
            }}
            value={state?.medicalHistory ?? ""}
            onChange={(e) =>
              simplehandlechange("medicalHistory", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <p
            style={{
              color: "rgb(106, 120, 136)",
              fontSize: "12px",
              fontWeight: "500",
              margin: "0px 0px 6px",
            }}
          >
            Medication History{" "}
          </p>
          <TextareaAutosize
            maxRows={8}
            style={{
              width: "100%",
              height: "50px",
              outline: "1px solid rgb(222, 229, 236) ",
              border: "0px",
              borderRadius: "8px",
              padding: "5px",
            }}
            value={state?.medicationHistory ?? ""}
            onChange={(e) =>
              simplehandlechange("medicationHistory", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <p
            style={{
              color: "rgb(106, 120, 136)",
              fontSize: "12px",
              fontWeight: "500",
              margin: "0px 0px 6px",
            }}
          >
            Blood Result{" "}
          </p>
          <TextareaAutosize
            maxRows={8}
            style={{
              width: "100%",
              height: "50px",
              outline: "1px solid rgb(222, 229, 236) ",
              border: "0px",
              borderRadius: "8px",
              padding: "5px",
            }}
            value={state?.bloodResult ?? ""}
            onChange={(e) => simplehandlechange("bloodResult", e.target.value)}
          />
        </Grid> */}
        <Grid item xs={12}>
        
        <p
        style={{
          fontSize: "50px",
          fontSWeight: "800",
          margin: "0",
          color: "#6A7888",
          fontSize: "12px",
          marginBottom: "6px",
        }}
      >
       Plan
      </p>
          <TextareaAutosize
            maxRows={8}
            style={{
              width: "100%",
              height: "50px",
              outline: "1px solid rgb(222, 229, 236) ",
              border: "0px",
              borderRadius: "8px",
              padding: "5px",
            }}
            value={state?.plan ?? ""}
            onChange={(e) => simplehandlechange("plan", e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
        <p
        style={{
          fontSize: "50px",
          fontSWeight: "800",
          margin: "0",
          color: "#6A7888",
          fontSize: "12px",
          marginBottom: "6px",
        }}
      >
      Examinations
      </p>
          <TextareaAutosize
            maxRows={8}
            style={{
              width: "100%",
              height: "50px",
              outline: "1px solid rgb(222, 229, 236) ",
              border: "0px",
              borderRadius: "8px",
              padding: "5px",
            }}
            value={state?.examinations ?? ""}
            onChange={(e) => simplehandlechange("examinations", e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <p
            style={{
              color: "rgb(106, 120, 136)",
              fontSize: "12px",
              fontWeight: "500",
              margin: "0px 0px 6px",
            }}
          >
            Current Medication{" "}
          </p>
          <TextareaAutosize
            maxRows={8}
            style={{
              width: "100%",
              height: "50px",
              outline: "1px solid rgb(222, 229, 236) ",
              border: "0px",
              borderRadius: "8px",
              padding: "5px",
            }}
            value={state?.currentMedication ?? ""}
            onChange={(e) =>
              simplehandlechange("currentMedication", e.target.value)
            }
          />
        </Grid> */}
      </Grid>
      <div style={{ display: "flex", justifyContent: "flex-end",padding:"10px 30px" }} >
      <span style={{ display: "flex",gap:"20px" }}>
          <Button
            onClick={handlecancel}
            color="primary"
            fullWidth
            className={styles.actionButton}
          >
            cancel
          </Button>{" "}
          <Button
            variant="contained"
            onClick={() => {
              handlesaved(statekey, state);
            }}
            color="primary"
            fullWidth
            className={styles.actionButton}
          >
            {edit ? "UPDATE" : "SAVE"}
          </Button>
        </span>
      </div>
    </div>
  );
};

export default TreatmentPlanForm;
