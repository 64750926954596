import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InfoDisplay from "../infoDisplay";
import { PractitionerInfoCard } from "../practitionerInfoCard/index";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import actions from "../../../../../../redux/actions";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useDividerStyles } from "./styles";
import Calender from "../../../../../../assets/img/svg/calender";
import Clock from "../../../../../../assets/img/svg/ClockIcon";
import DocumentBlack from "../../../../../../assets/img/svg/DocumentBlack";
import PillDark from "../../../../../../assets/img/svg/pillDark";
import Pills from "../../../../../../assets/img/svg/pills";
import StethoscopeIcon from "../../../../../../assets/img/svg/StethoscopeIcon";
import TaskIcon from "../../../../../../assets/img/svg/taskIcon";
import { BasicTable } from "../drugItem/components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "24px 24px 16px 24px",
    display: "grid",
    gridTemplateAreas: `
        "infoRow1"
        "infoRow2"
        "infoRow3"
        `,
    gridTemplateColumns: "1fr",
    gridGap: 40,
  },
  infoRow1: {
    gridArea: "infoRow1",
    display: "grid",
    gridTemplateColumns: "1fr 3fr 1fr 1fr",
  },
  infoRow2: {
    gridArea: "infoRow2",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 2fr",
  },
  infoRow3: {
    gridArea: "infoRow3",
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr 2fr",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  practitionerInfoRoot: {
    padding: "30px 24px 16px 24px",
  },
  practInfoCompRoot: {
    paddingTop: 20,
  },
  dispenseRoot: {
    padding: "30px 24px 16px 24px",
  },
  dispenseHeader: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    // color: "#101010",
  },
  dispenseInfoRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dispenseInfoRowLeft: {
    display: "flex",
    gap: 30,
    alignItems: "center",
  },
  statusLog: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  statusLogHeading: {
    fontSize: 12,
  },
  dispenseItem: {
    padding: "20px 0px",
  },
}));

const getText = (value) => {
  return value ? value : "";
};

// const getFormattedDate = (date) => moment.unix(date).format("DD-MM-YYYY HH:mm");

const { DISPENSE_HISTORY_READ } = actions;

function DrugOrderDetails({
  id,
  showPractitionerEditButton,
  handleEditClick = () => {},
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dividerStyles = useDividerStyles();
  const theme = useTheme();

  // Action States
  const dispenseHistory = useSelector(
    (state) => state?.phramcyFrameworkApiSlice?.dispenseHistoryRead
  );
  const data = dispenseHistory?.data?.[0];

  useEffect(() => {
    dispatch(DISPENSE_HISTORY_READ({ id: id }));
  }, [id]);

  const FreqHeader = (type) => {
    switch (type) {
      case "DBA":
        return ["Day", "Base schedule", "Dosage", "Remarks"];
      case "UDE":
        return ["Base schedule", "From Day", "To Day", "Dosage", "Remarks"];
      case "DET":
        return ["Time", "Dosage", "Remarks"];
      case "INT":
      case "SEQ":
        return ["Base schedule", "Dosage", "Remarks"];
      default:
        return [
          "Day",
          "Time",
          "Base schedule",
          "Start date",
          "End date",
          "Dosage",
          "Remarks",
        ];
    }
  };

  const FreqBody = (FreqDetls) => {
    let data = FreqDetls?.map((v) => {
      return {
        Remarks: v?.adminDosageInstruction
          ? v?.adminDosageInstruction
          : v?.remarks ?? "-",
        Day: v?.day ? v?.day : "-",
        Time: v?.time ? v?.time : "-",
        "Base schedule": v?.baseFreq ?? "-",
        "Start date":
          v?.fromDate === 0
            ? "-"
            : moment(v?.fromDate * 1000).format("DD-MM-YYYY"),

        "End date":
          v?.toDate === 0 ? "-" : moment(v?.toDate * 1000).format("DD-MM-YYYY"),
        Dosage: v?.dose ? v?.dose : "-",
        "From Day": v?.fromDay,
        "To Day": v?.toDay,
      };
    });
    return data;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div className={classes.infoRow1}>
          <InfoDisplay
            icon={<PillDark color={theme.palette.primary.main} />}
            label="Drug Type"
            value={getText(data?.drugType?.longdesc ?? "")}
          />
          <InfoDisplay
            icon={<TaskIcon color={theme.palette.primary.main} />}
            label="Order Description"
            value={getText(data?.orderdescription ?? "")}
          />
          <InfoDisplay
            icon={<Pills color={theme.palette.primary.main} />}
            label="Dosage"
            value={`${
              getText(data?.dosageValue ?? "")
                ? getText(data?.dosageValue ?? "") + "-"
                : ""
            }${getText(data?.dosageUOM ?? "")}`}
          />
          <InfoDisplay
            icon={<PillDark color={theme.palette.primary.main} />}
            label="Drug Category"
            value={getText(data?.drug_category?.longdesc ?? "")}
          />
        </div>
        <div className={classes.infoRow2}>
          <InfoDisplay
            icon={<DocumentBlack color={theme.palette.primary.main} />}
            label="Mode"
            value={getText(data?.presMode?.display ?? "")}
          />
          <InfoDisplay
            icon={<Pills color={theme.palette.primary.main} />}
            label="Route"
            value={getText(data?.routeCode ?? "")}
          />
          <InfoDisplay
            icon={<DocumentBlack color={theme.palette.primary.main} />}
            label="Form"
            value={getText(data?.formCode?.longdesc ?? "")}
          />
          <InfoDisplay
            icon={<DocumentBlack color={theme.palette.primary.main} />}
            label="Nature"
            value={getText(data?.presNature?.display ?? "")}
          />
          <InfoDisplay
            icon={<DocumentBlack color={theme.palette.primary.main} />}
            label="Generic"
            value={getText(data?.GenericDrug?.[0]?.longdesc ?? "")}
          />
        </div>
        <div className={classes.infoRow3}>
          <InfoDisplay
            icon={<Clock color={theme.palette.primary.main} />}
            label="Duration"
            value={`${
              getText(data?.dosageDurationValue ?? "")
                ? getText(data?.dosageDurationValue ?? "") + ""
                : ""
            } ${getText(data?.dosageDurationUOM ?? "")}`}
          />
          {data?.occurStartDateTime !== 0 && (
            <InfoDisplay
              icon={<Calender color={theme.palette.primary.main} />}
              label="Start Date"
              value={moment
                .unix(data?.occurStartDateTime)
                .format("DD-MM-YYYY hh:mm")}
              // valueComponent={
              //     <TimeComponent
              //         dateTime={data?.occurStartDateTime}
              //         formatType={"datetime"}
              //     />
              // }
            />
          )}
          {data?.occurEndDateTime !== 0 && (
            <InfoDisplay
              icon={<Calender color={theme.palette.primary.main} />}
              label="End Date"
              value={moment
                .unix(data?.occurEndDateTime)
                .format("DD-MM-YYYY hh:mm")}
              // value={getFormattedDate(data?.occurEndDateTime)}
              // valueComponent={
              //     <TimeComponent
              //         dateTime={data?.occurEndDateTime}
              //         formatType={"datetime"}
              //     />
              // }
            />
          )}
          <InfoDisplay
            icon={<StethoscopeIcon color={theme.palette.primary.main} />}
            label="Diagnosis"
            value={getText(data?.drugType?.longdesc ?? "")}
          />
        </div>
        {/* ------------------ Frequency Details Section Starts ------------------------- */}
        <BasicTable
          header={FreqHeader(data?.dosageFreqCodetype)}
          dataTabs={FreqBody(data?.FreqDetls)}
          dosageFreqCode={data?.dosageFreqCode}
        />
        {/* /////////////////////// Frequency Details Section Ends ////////////////////////////// */}
      </div>

      {/* ------------------ Practitioner Info UI Starts ----------------------------- */}

      <div className={classes.practitionerInfoRoot}>
        <Typography className={classes.dispenseHeader}>
          Practitioner Info
        </Typography>
        <Divider
          orientation="horizontal"
          flexItem
          classes={dividerStyles}
          // style={{ margin: theme.spacing(0, 1) }}
        />
        <div className={classes.practInfoCompRoot}>
          <PractitionerInfoCard
            data={data?.requestor?.[0]?.practId?.[0]}
            clinicName={
              data?.encounter_Id?.[0]?.practitioner_details?.[0]?.clinic
            }
            specialityName={
              data?.encounter_Id?.[0]?.practitioner_details?.[0]
                ?.speciality?.[0]?.coding?.[0]?.display
            }
            showEditButton={false}
            handleEditClick={handleEditClick}
          />
        </div>
      </div>
    </div>
  );
}

export default DrugOrderDetails;
