import { Button, Container, Div, Divider, Text, TextArea } from 'qdm-component-library'
import React from 'react'
import { UIColor } from '../../themes/theme';
import { AlertProps } from '../../utils';
import { AlertContext } from '../../context';
import { useContext } from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import actions from "../../redux/actions";


const ServiceCompleted = (props) => {
  const { setSnack } = useContext(AlertContext);
  const dispatch = useDispatch();
   
    const { parent_id } = props;
    const handleReasonChange = (e, type) => {
    
        props?.setFeedBack((prevState) => ({
          ...prevState,
          [type]: e
        }));
      }
      console.log(props?.data, "service")
      const onSubmit = async () => {
        try {
          debugger
            let token = jwtDecode(localStorage.getItem("UserInfo"));
            console.log(token, "token");
            let payload = {
              ticketId: props?.data?.totalData?.ticketId,
              userAction: props?.data?.l?.action,
              data: {
                userAction: props?.data?.l?.action,
                ticketId: props?.data?.totalData?.ticketId,
                practitionerId: token?.practitionerid,
                practitionerRoleId: token?.idmroleid,
                feedback: props?.feedback?.feedback || ""
              },
            };
            let data = await dispatch(actions.PERFORM_USER_ACTION(payload));
            console.log(data, "payload");
            props?.setServicePopOver((prev) => {
              return { ...prev, openSideMenu:false};
            });
            if (data?.payload?.data?.error) {
               setSnack({
                open: true,
                severity: AlertProps.severity.warning,
                msg:
                  data?.payload?.data?.Response?.[0]?.errorMessage ||
                  "something went wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
            } 
            
           
          }  catch (error) {
            setSnack({
              open: true,
              severity: AlertProps.severity.warning,
              msg: error?.message || "something went wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
          }
        }

  return (
    <div>
      <Container
        id={`${parent_id}_cancelbook_parent_container`}
        key={"0"}
        name="container"
        fluid={false}
        style={{
          padding: "20px",
        }}
      >
        <Text
          id={`${parent_id}_cancelbook_appointment_text`}
          key={"0"}
          className="pc_semibold"
          style={{ fontSize: 20, color: "#101010" }}
          name={`SERVICE COMPLETED`}
        ></Text>
        <Divider
          id={`${parent_id}_cancelbook_bottom_divider`}
          key={"1"}
          textWidth={"50"}
          textColor={"#000"}
          borderColor={"#f0f0f0"}
          borderWidth={"1"}
          orientation={"horizontal"}
          variant={"middle"}
          style={{ marginLeft: 0, marginRight: 0 }}
        ></Divider>
        <Text
          id={`${parent_id}_cancelbook_reason_text`}
          className="pc_regular"
          name={`Please Give your Feedback here`}
          style={{ fontSize: "14px" ,color: "#6F6F6F"}}
        />
 
        <Div
          id={`${parent_id}_cancelbook_select_div`}
          key={"2"}
        >
         
              <TextArea
                id={`${parent_id}_cancelbook_reason_manually_textarea`}
                placeholder="Enter your feedback here"
                value={props?.feedback?.feedback} 
                onChange={(e)=>  handleReasonChange(e.target.value, "feedback")} 
                style={{
                  marginTop: "20px",
                  padding: 14,
                  resize: "none",
                  height: "14vh",
              }}
              maxLength={3000}
              />
        </Div>
        <Button
          id={`${parent_id}_cancelbook_buttom`}
          key={"1"}
          type="button"
          className="pc_medium"
          variant="outlined"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "20px",
            fontSize: "14px",
            padding: "8px 16px",
            marginBottom: "10px",
            backgroundColor: UIColor?.primaryColor?.color,
            border: "none",
            borderRadius: 8,
          }}
          onClick={async () => {
            if (!props?.feedback?.feedback) {
                setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please enter feedback",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              return;
            }
            else {
             onSubmit();
            }
          }} 
        >
         Submit
        </Button>
      </Container>
    </div>
  )
}

export default ServiceCompleted