import React, { useEffect, useState } from "react";
import actions from "../../redux/actions";
//import { withRouter } from 'react-router-dom'
import Verification from "../../assets/img/svg/verification.svg";
import { TextField ,FormControlLabel,RadioGroup,FormControl,FormLabel,Radio} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Container,
  Div,
  //   Icons,
  Text,
  Divider,
  // Card,
  H6,
  // ListItem,
  Avatar,
  //   Dropdown,
  TextArea,
  Button,
  OtpInput,
  Select
} from "qdm-component-library";
import {
  checkError,
  AlertProps,
  convertTime,
  getUserInfo,
  makeName,
  utcTOLocal,
  getImgUrl,
} from "../../utils";
import { UIColor } from "../../themes/theme";
import { AlertContext } from "../../context";
import { Selection } from "../../components";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import calendar from "../../assets/img/svg/calendar.svg";
import withAllContexts from "../../hoc/withAllContexts";
import LinearProgress from "@material-ui/core/LinearProgress";
const casbinjs = require("casbin.js");
 
const styles = (theme) => ({
    labelStyle: {
        color: "#6F6F6F",
        fontSize: "12px",
        marginBottom: "6px",
        fontFamily: `pc_regular !important`,
      },
      borderStyle: {
        borderRadius: 30,
        borderColor: "#E0E0E0",
      },
  select: {
    marginTop: 16,
    "& fieldset": {
      borderColor: "#E0E0E0",
      borderRadius: 8,
    },
    "& input": {
      height: 19,
    },
  },
});
 
const CancelBookingPage = (props) => {
  const { labelStyle, borderStyle } = styles;
  const dispatch = useDispatch();
  const [state, setState] = useState({
    resend: "",
    appointmentInfo: {},
    otp: 0,
    callcen: false,
    cancelDropValue: [],
    reasonForApptCancelModify: [],
    reasonForNoShow: [],
    reasonForReject:[],
    reasonForOnHold:[]

  });
  const onChangeState = (v,key) => {
    props.handleSelectCancelbooking(v,key);
    setState((prevState) => ({
      ...prevState,
      appointmentInfo: {
        ...state?.appointmentInfo,
        [key]: v,
      },
    }));
  };
  const handleReasonChange = (e, type) => {
    
    props?.setNoShow((prevState) => ({
      ...prevState,
      [type]: e
    }));
  }


  useEffect(() => {
    onLoad();
  }, []);
  const onLoad = async () => {
    fetchInfo();
    const cancelReasonList = await dispatch(actions.GET_REASON_MASTERS({ type: ["ORDERCANCELREASON", "NOSHOWREASON", "CANCELREASON",, "ERREFQREJECTREASON", "ERREFQONHOLDREASON"] }));
    
    setState({
      ...state, reasonForApptCancelModify: cancelReasonList?.payload?.data?.CANCELREASON,
      reasonForNoShow: cancelReasonList?.payload?.data?.NOSHOWREASON,
      reasonForReject: cancelReasonList?.payload?.data?.ERREFQREJECTREASON,
      reasonForOnHold: cancelReasonList?.payload?.data?.ERREFQONHOLDREASON
    })
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const roleName = decodedUserinfo.rolename;

    let roleData = localStorage.getItem("RoleData");
    let decodedRoleData = jwtDecode(roleData);
    // const accessObj = decodedRoleData?.[roleName.toLowerCase()]?.access || [];
    const accessObj = decodedRoleData?.access || [];

    const permission = { callcenter: accessObj };
    const authorizer = new casbinjs.Authorizer("manual", permission);
    authorizer.setPermission(permission);
    const result = await authorizer.can("callcenter", "4");
    let cancelDropVal = [];
    cancelReasonList?.payload?.data.map((val) => {
      let cancelDropValObj = {
        value: val?.title,
        _id: val?._id
      }
      cancelDropVal.push(cancelDropValObj);
    })

    setState((prevState) => ({
      ...prevState,
      callcen: result,
      cancelDropValue: cancelDropVal,
    }));
  };

  const fetchInfo = async () => {
    const data = await dispatch(
      actions.APPOINTMENT_READ({
        appointmentid: props.appointmentId,
        tenantid: "",
        facilityid: "",
      })
    );
    setState((prevState) => ({
      ...prevState,
      appointmentInfo: data?.payload?.data || {},
    }));
  };
  
  const getSelectedReasons = (props) => {
    const action = props?.cancelData?.action;
    if (action === process.env.REACT_APP_DIALYSIS_NO_SHOW) {
      return state.reasonForNoShow;
    } else if (action === process.env.REACT_APP_E_REFERRAL_REJECT) {
      return state.reasonForReject;
    } else if (action === process.env.REACT_APP_E_REFERRAL_ON_HOLD) {
      return state.reasonForOnHold; 
    } else {
      return [];
    }
  };
  const { cancelbookings, cancelbookingreason, parent_id, classes } = props;

  return (
    <div id={`${parent_id}_cancelbook_parent_div`}>
      <Container
        id={`${parent_id}_cancelbook_parent_container`}
        key={"0"}
        name="container"
        fluid={false}
        style={{
          padding: "20px",
        }}
      >
        <Text
          id={`${parent_id}_cancelbook_appointment_text`}
          key={"0"}
          className="pc_semibold"
          style={{ fontSize: 20, color: "#101010" }}
          name={`${props?.cancelData?.action?.charAt(0)?.toUpperCase() + props?.cancelData?.action?.substr(1)?.toLowerCase()} ${props?.cancelData?.queue?.replace(/ .*/,'')}`}
        ></Text>
        <Divider
          id={`${parent_id}_cancelbook_bottom_divider`}
          key={"1"}
          textWidth={"50"}
          textColor={"#000"}
          borderColor={"#f0f0f0"}
          borderWidth={"1"}
          orientation={"horizontal"}
          variant={"middle"}
          style={{ marginLeft: 0, marginRight: 0 }}
          //   style={qdmstyles.BKv}
        ></Divider>
         {props?.cancelData?.queue == "Appointment Queue" &&
        <Div
          id={`${parent_id}_cancelbook_side_div`}
          style={{
            marginTop: "10px",
            boxShadow: "none",
            borderTop: "1px solid #F0F0F0",
            borderBottom: "1px solid #F0F0F0",
            display: "flex",
            paddingBottom: "14px",
            paddingTop: "16px",
            justifyContent: "space-around",
          }}
        >
          <Div
            id={`${parent_id}_cancelbook_side_sub_div`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              // borderRight: "1px solid #F0F0F0",
              paddingRight: "13px",
            }}
          >
            <Div id={`${parent_id}_cancelbook_image_div`}>
              {/* <img
                  id={`${parent_id}_cancelbook_image`}
                  alt='img'
                  width='40px'
                  height='40px'
                  style={{ borderRadius: '50%', marginRight: '10px' }}
                  src='https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg'
                /> */}
 
              <Avatar
                id={`${parent_id}_cancelbook_logo_image`}
                key={"0"}
                variant="circle"
                letter="Logo"
                //src='https://d27zlipt1pllog.cloudfront.net/pub/static/version1608753130/frontend/Apollo/mobile/en_US/images/new-img/logo_2.png'
                alt="Image"
                style={{
                  // backgroundColor: '#F9F9F9',
                  paddingTop: "0px",
                  width: "40px",
                  height: "40px",
                  margin: "0px",
                  marginRight: "10px",
                }}
              >
                <Avatar
                  src={
                    getImgUrl(
                      state?.appointmentInfo?.resourcecode?.[0]?.photo?.[0]
                        ?.fileid
                    ) || undefined
                  }
                  id={`${parent_id}_cancelbook_image`}
                  alt="img"
                  width="40px"
                  height="40px"
                  style={{ borderRadius: "50%", marginRight: "10px" }}
                >
                  {
                    (state.appointmentInfo?.resourcecode?.[0]?.name?.[0]?.fulname
                      ? state.appointmentInfo?.resourcecode?.[0]?.name?.[0]?.fulname
                      : ""
                    )
                      ?.replaceAll("Mrs", "")
                      ?.replaceAll("Mr", "")
                      ?.replaceAll(".", "")[0]
                  }
                </Avatar>
              </Avatar>
            </Div>
            <Div id={`${parent_id}_cancelbook_appointmentinfo_div`}>
              <H6
                id={`${parent_id}_cancelbook_appointmentinfo_h6`}
                className="pc_regular"
                style={{
                  fontSize: "14px",
                  color: "#101010",
                }}
              >
                {state.appointmentInfo?.resourcecode?.[0]?.name?.[0]?.fulname ||
                  ""}
              </H6>
              <Text
                id={`${parent_id}_cancelbook_appointmentinfo_text`}
                className="pc_regular"
                style={{
                  fontSize: "10px",
                  letterSpacing: ".4px",
                  color: "#6f6f6f",
                }}
              >
                {state.appointmentInfo?.orgid?.[0]?.name || ""}
              </Text>
            </Div>
          </Div>
          <Div
            id={`${parent_id}_cancelbook_description_div`}
            style={{ width: 1, height: 42, backgroundColor: "#E0E0E0" }}
          />
          <Div
            id={`${parent_id}_cancelbook_description_sub_div`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "6px",
            }}
          >
            <Div id={`${parent_id}_cancelbook_calendar_div`}>
              <Avatar
                id={`${parent_id}_cancelbook_logo_image`}
                key={"0"}
                variant="circle"
                letter="Logo"
                src="https://d27zlipt1pllog.cloudfront.net/pub/static/version1608753130/frontend/Apollo/mobile/en_US/images/new-img/logo_2.png"
                alt="Image"
                style={{
                  backgroundColor: "#F9F9F9",
                  paddingTop: "0px",
                  width: "40px",
                  height: "40px",
                  margin: "0px",
                  marginRight: "10px",
                }}
              >
                <Avatar
                  id={`${parent_id}_cancelbook_calendar_image`}
                  //src='/static/media/calendar.374dbd8e.svg'
                  src={calendar}
                  alt="Image"
                  style={{
                    backgroundColor: "transparent",
                    height: "auto",
                    width: "16px",
                  }}
                  variant="square"
                ></Avatar>
              </Avatar>
            </Div>
            <Div>
              <H6
                id={`${parent_id}_cancelbook_convert_h6`}
                className="pc_regular"
                style={{
                  fontSize: "14px",
                  color: "#101010",
                }}
              >
                {utcTOLocal(
                  state.appointmentInfo?.slots?.[0]?.start,
                  "DD MMM YY | hh:mm a"
                )}
              </H6>
              <Text
                id={`${parent_id}_cancelbook_consultation_text`}
                className="pc_regular"
                style={{
                  fontSize: "10px",
                  letterSpacing: ".4px",
                  color: "#6f6f6f",
                }}
              >
                {state.appointmentInfo?.consultationModedisplay}
              </Text>
            </Div>
          </Div>
        </Div>
         }
        {/* {cancelbookings===4? null:(
        <Div
          id={`${parent_id}_cancelbook_subtitle_div`}
          style={{
            padding: "10px",
            border: "1px solid #E0E0E0",
            background: "#F9F9F9",
            margin: "16px 0px",
            borderRadius: "6px",
            // height: '12vh'
          }}
        >
          <Div
            id={`${parent_id}_cancelbook_subtitle_sub_div`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "9px",
            }}
          >
            <Text
              id={`${parent_id}_cancelbook_description_text`}
              className="pc_medium"
              style={{
                fontSize: "12px",
                color: "#6F6F6F",
              }}
            >
              DESCRIPTION
            </Text>
            <Text
              id={`${parent_id}_cancelbook_total_text`}
              className="pc_medium"
              style={{
                fontSize: "12px",
                color: "#6F6F6F",
              }}
            >
              TOTAL
            </Text>
          </Div>
          <Div
            id={`${parent_id}_cancelbook_cancellation_div`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "6px",
              borderTop: "1px solid #F0F0F0",
            }}
          >
            <Text
              id={`${parent_id}_cancelbook_cancellation_text`}
              className="pc_regular"
              style={{
                fontSize: "14px",
                color: "#101010",
              }}
            >
              Cancellation charge ( Nil )
            </Text>
            <Text
              id={`${parent_id}_cancelbook_amount_text`}
              className="pc_regular"
              style={{
                fontSize: "14px",
                color: "#101010",
              }}
            >
              ₹ 0.00
            </Text>
          </Div>
        </Div>
        )} */}
         { props?.uploading && ( <Div style={{paddingTop:"10px", paddingBottom:"10px"}}>
        <LinearProgress variant="determinate" value={props?.progress}/>
        </Div>)}
        {props?.cancelData?.action === process.env.REACT_APP_DIALYSIS_CANCEL&&
          <Div>
          <FormControl component="fieldset">
            <FormLabel component="legend">Cancellation Type</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" value={state?.cacellationType} onChange={(e) => onChangeState(e.target.value,"cacellationType")}>
              <FormControlLabel value={process.env.REACT_APP_CANCEL_APPMNT_TYPE_SINGLE} control={<Radio />} label="Single" />
              <FormControlLabel value={process.env.REACT_APP_CANCEL_APPMNT_TYPE_MULTIPLE} control={<Radio />} label="Multiple" />
            </RadioGroup>
          </FormControl>
        </Div>}
        <Text
          id={`${parent_id}_cancelbook_reason_text`}
          className="pc_regular"
          name={`Reason for ${props?.cancelData?.action.charAt(0).toUpperCase() + props?.cancelData?.action.substr(1).toLowerCase()}`}
            // <span
            //   id={`${parent_id}_cancelbook_reason_span`}
            //   style={{ fontSize: "14px", color: "#6F6F6F" }}
            // >
              
            //   <label
            //     id={`${parent_id}_cancelbook_star_label`}
            //     style={{ color: "red", marginLeft: 4 }}
            //   >
            //     *
            //   </label>
            // </span>
          
          style={{ fontSize: "14px" ,color: "#6F6F6F"}}
        />
 
        <Div
          id={`${parent_id}_cancelbook_select_div`}
          key={"2"}
          className={classes.select}
        >
          {props?.cancelData?.action === process.env.REACT_APP_DIALYSIS_NO_SHOW  || props?.cancelData?.action === process.env.REACT_APP_E_REFERRAL_REJECT || props?.cancelData?.action === process.env.REACT_APP_E_REFERRAL_ON_HOLD ?
            (
              <Selection
                showArrow
                // options={paymentDropDown?.data ?? []}
                options={getSelectedReasons(props)}
                // onChangeValue={(e) => updatevalue("reason", e.target.value)}
                onChange={(e, v) => handleReasonChange(v, "reason")}
                value={props?.noshow?.reason}
                placeholder="select..."
                labelStyle={styles.labelStyle}
                inLineStyles={{ ...styles.borderStyle }}
                hoverColor={UIColor.primaryColor.color}
              />

            ) : (
              <>
              <Selection
                id={`${parent_id}_patient_name_use_label_select`}
                value={cancelbookingreason}
                options={state?.reasonForApptCancelModify || []}
                showArrow
                labelStyle={labelStyle}
                style={borderStyle}
                hoverColor={UIColor.primaryColor.color}
                onChange={(e, v) => onChangeState(v,"cancelbookingreason")}
                placeholder={false}
                />
                </>
            )}

          {(cancelbookingreason?.value === process.env.REACT_APP_CANCELLATION_REASON_TYPE_OTHER ||
            props?.noshow?.reason?.value === process.env.REACT_APP_NOSHOW_REASON_TYPE_OTHER  || props?.noshow?.reason?.value === process.env.REACT_APP_E_REFERRAL_REJECT_REASON_TYPE_OTHER)
            &&
            (
              <TextArea
                id={`${parent_id}_cancelbook_reason_manually_textarea`}
                placeholder="Enter your reason manually"
                value={props?.noshow?.comments} // for both cancell and no show 
                onChange={(e) => handleReasonChange(e.target.value, "comments")}
                style={{
                  marginTop: "20px",
                  padding: 14,
                  resize: "none",
                  height: "14vh",
              }}
              maxLength={3000}
              />)}
        </Div>
        <Button
          id={`${parent_id}_cancelbook_buttom`}
          key={"1"}
          type="button"
          disabled={props?.signIn?.loading || props?.cancelAppointment?.loading}
          className="pc_medium"
          variant="outlined"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "20px",
            fontSize: "14px",
            padding: "8px 16px",
            marginBottom: "10px",
            backgroundColor: UIColor?.primaryColor?.color,
            border: "none",
            borderRadius: 8,
          }}
          onClick={async () => {
            if (!cancelbookingreason && !props?.noshow.reason) {
              props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please select reason for cancellation",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              return;
            }
            else {
              props?.handlecahngeCancelbooking();
              props?.timer()
            }
          }}
        >
         Submit
        </Button>
      </Container>
    </div>
  );
};
 
export default withStyles(styles)(withAllContexts(CancelBookingPage));