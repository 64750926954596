import React, { useEffect, useState } from "react";
import withAllContexts from "../../hoc/withAllContexts";
import {
  Col,
  Div,
  Paper,
  Row,
  Select,
  TextArea,
  TextInput,
  TextInputProps,
} from "qdm-component-library";
import { UIColor } from "../../themes/theme";
import CustomInputDatePicker from "../../components/customDatePicker/inputDatePicker";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { useContext } from "react";
import {
  AlertContext,
  BackdropContext,
  QueueManagementContext,
} from "../../context";
import { AlertProps, isObjectEmpty, GetQueueData, getTenantId } from "../../utils";
import axios from "axios";
const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  inputBorderStyle: {
    borderRadius: 8,
    border: "1px solid #E0E0E0",
  },
  errorinputBorderStyle: {
    borderRadius: 8,
  },
  selectBorderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
};

export function TransferCase(props) {
  console.log(props, "propstransfer");
  const backdrop = useContext(BackdropContext);
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const { setSnack } = useContext(AlertContext);
  const {
    setAllData,
    setDisplayData,
    setPreviousData,
    setpreviousQueue,
    setloader,
  } = useContext(QueueManagementContext);
  const selector = useSelector((state) => {
    console.log(state);
    return {
      hubdetails: state?.TransferandSchedule?.hubdetails?.data || [],
      transferreason:
        state?.TransferandSchedule?.transferreason.data?.ERREFQTRANSFERREASON ||
        [],
      casemanagername: state?.TransferandSchedule?.caseManagerName?.data || [],
      hubto: state?.TransferandSchedule?.hubto?.data || [],
    };
  });
  const [err, setErr] = useState({});
  const [state, setState] = useState({
    nameofthehubfrom: {},
    nameofcasemanagerfrom: {},
    nameofthehubto: {},
    transfereddateandtime: "",
    transferreason: {},
    remark: "",
  });
  const {
    labelStyle,
    selectBorderStyle,
    errorinputBorderStyle,
    inputBorderStyle,
  } = styles;
  const handleChange = (key, value) => {
    setState({ ...state, [key]: value });
    console.log(key, value, "key");
  };
  function dateToEpoch(dateString) {
    var date = new Date(dateString);
    var epochTime = date.getTime();
    var epochTimeInSeconds = Math.floor(epochTime / 1000);
    return epochTimeInSeconds;
  }

  const validation = () => {
    let data = {
      nameofthehubfrom: {},
      nameofcasemanagerfrom: {},
      nameofthehubto: {},
      transfereddateandtime: "",
      transferreason: {},
      remark: "",
    };
    let validation = {
      error: false,
    };
    Object.keys(data).forEach((v) => {
      if (state[v]) {
        if (v === "transfereddateandtime") {
          //for date obj
          if (state[v]?.getTime()?.toString()?.length >= 10) {
            validation.error = false;
            validation[v] = false;
          } else {
            validation.error = true;
            validation[v] = true;
          }
        } else {
          validation.error = true;
          validation[v] = true;
        }
      }
      // if (typeof data[v] === "string") {
      //   if (!state?.[v]?.length > 0) {
      //     validation.error = true
      //     validation[v] = true
      //   } else {
      //     validation.error = false
      //     validation[v] = false

      //   }

      // }
      if (
        v === "remark" &&
        state?.transferreason?._id ===
          process.env.REACT_APP_E_REFERRAL_TRANSFER_REASON_TYPE_OTHER
      ) {
        if (typeof data[v] === "string") {
          if (!state?.[v]?.length > 0) {
            validation.error = true;
            validation[v] = true;
          } else {
            validation.error = false;
            validation[v] = false;
          }
        }
      } else if (typeof data[v] === "object") {
        console.log(isObjectEmpty(state?.[v]), "obj//");
        if (isObjectEmpty(state?.[v])) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation.error = false;
          validation[v] = false;
        }
      }
    });
    setErr(validation);
    return validation;
  };

  const onSubmit = async () => {
    try {
      let valid = validation();
      if (!valid.error) {
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: true,
        //   message: "transfering...",
        // });
        let token = jwtDecode(localStorage.getItem("UserInfo"));
        let payload = {
          ticketId: props?.state?.totalData?.ticketId,
          userAction: props?.state?.l?.action || props?.state?.action?.[4]?.action,
          data: {
            ticketId: props?.state?.totalData?.ticketId,
            practitionerId: token?.practitionerid,
            practitionerRoleId: token?.idmroleid,
            fromhub: state?.nameofthehubfrom?._id,
            fromcasemanager: state?.nameofcasemanagerfrom?._id,
            tohub: state?.nameofthehubto?._id,
            tocasemanager: "",
            transdate: dateToEpoch(state?.transfereddateandtime),
            reason: [
              {
                reasonid: state?.transferreason?._id,
                reasontxt: state?.remark,
                reasontype: process.env.REACT_APP_E_REFERRAL_TRANSFER_REASON_TYPE
              },
            ],
          },
        };
        setloader(true);
        let data = await dispatch(actions.TRANSFER_ACTION(payload));
        setloader(false);
        console.log(payload, "payload");
        props?.setDialog((prev) => {
          return { ...prev, openTransfer: false };
        });
        if (data?.payload?.data?.error) {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.warning,
            msg:
              data?.payload?.data?.Response?.[0]?.errorMessage ||
              "something went wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        } else {
          
          let data = await GetQueueData();
          setAllData(data.everyData);
          setDisplayData(data.getDisplayData);
          setPreviousData(data.getPreviousData);
          setpreviousQueue(props?.state?.queueId);
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Transfered Succesfully!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.warning,
          msg: "Please fill mandatory fields",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
      return;
    } catch (error) {
      setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: error?.message || "something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  const getData = async () => {
    dispatch(actions.HUB_DETAILS());
    dispatch(actions.CASEMANAGER_NAME());
    dispatch(actions.TRANSFER_REASON());
    dispatch(actions.HUB_TO());
  };
  const loadDefaultValues = async () => {
    
      let res = await dispatch(actions.GET_SCREEN_DEFAULT_VALUES({
        screenid: "41fb6a14-c1ed-4fe4-b458-61ea3d35d2ee",
        tenantid: getTenantId(),
        filtervalues: {
          referraltrxid: props?.state?.totalData?.inputdoc?.referraltrxid
          }
      })); 
      console.log(res,"defaultdata")
      const defaultValues = res?.payload?.data?.response?.defaultValues;
      const stateNames = ["transfer_datetime", "case_manager", "from_hub"];
      const stateValues = {};
      defaultValues.forEach((s) => {
        console.log(s,"sss")
        if (stateNames.includes(s.stateName)) {
          stateValues[s.stateName] = s.value;
        }
        console.log(stateValues,"statevalue")
      });
      let epochDate = stateValues?.transfer_datetime * 1000;
      let defaultDate = new Date(epochDate);
      setState({
        nameofthehubfrom:{ 
          value: stateValues?.from_hub?.value,
          _id:   stateValues?.from_hub?._id
        },
        nameofcasemanagerfrom: {
          value: stateValues?.case_manager?.value,
          _id:   stateValues?.case_manager?._id
        },
        nameofthehubto: {},
        transfereddateandtime: defaultDate,
        transferreason: {},
        remark: "",
      });
  };
  useEffect(() => {
    getData();
    loadDefaultValues();
  }, []);
  const helperTextMsg = "This field is required";
  return (
    <Div className="pv-root" style={{overflowY:"auto"}}>
      <Grid container style={{ display: "flex", justifyContent: "space-between" , paddingLeft: "12px"}}>
        <Typography variant="h6" style={{ textTransform: "capitalize" }}>
          Transfer Case
        </Typography>
        <IconButton
          onClick={() =>
            props?.setDialog((prev) => {
              return { ...prev, openTransfer: false };
            })
          }
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Paper className="pl-root" style={{ height: "60vh" }}>
      <Grid container spacing={3} xs={12} lg={12} style={{paddingLeft:"12px"}}>
          <Grid item md={12} lg={4} sm={12} xs={12} className="no-padding-left">
             <Select
                    label="Name Of Hub From"
                    labelStyle={labelStyle}
                    isRequired
                    showArrow
                    inLineStyles={selectBorderStyle}
                    placeholder="Name Of Hub From"
                    hoverColor={UIColor.primaryColor.color}
                    options={selector?.hubdetails}
                    value={state?.nameofthehubfrom?.value || ""}
                    onChangeValue={(e) => {
                      handleChange("nameofthehubfrom", e);
                    }}
                    error={err?.nameofthehubfrom}
                    helperText={helperTextMsg}
                    helperTextType={"error"}
                  />
                  </Grid>
                <Grid item md={12} lg={4} sm={12} xs={12} className="no-padding-left">
                  <Select
                    label="Name Of Case Manager From"
                    labelStyle={labelStyle}
                    inLineStyles={selectBorderStyle}
                    showArrow
                    isRequired
                    placeholder="Name Of Case Manager From"
                    hoverColor={UIColor.primaryColor.color}
                    options={selector?.casemanagername}
                    value={state?.nameofcasemanagerfrom?.value || ""}
                    onChangeValue={(e) => {
                      handleChange("nameofcasemanagerfrom", e);
                    }}
                    error={err?.nameofcasemanagerfrom}
                    helperText={helperTextMsg}
                    helperTextType={"error"}
                  />
                </Grid>

                <Grid  item md={12} lg={4} sm={12} xs={12} className="no-padding-left">
                  <Select
                    isRequired
                    search
                    placeholder="Select"
                    label="Name of the Hub To"
                    labelStyle={labelStyle}
                    inLineStyles={selectBorderStyle}
                    hoverColor={UIColor.primaryColor.color}
                    options={selector?.hubto}
                    value={state?.nameofthehubto?.value || ""}
                    onChangeValue={(e) => {
                      handleChange("nameofthehubto", e);
                    }}
                    error={err?.nameofthehubto}
                    helperText={helperTextMsg}
                    helperTextType={"error"}
                  />
                </Grid>
                <Grid item md={12} lg={4} sm={12} xs={12} className="no-padding-left">
                 <CustomInputDatePicker
                      disabled={false}
                      placeholderText="dd/mm/yyyy"
                      inLineStyles={selectBorderStyle}
                      maxDate={new Date()}
                      handleChange={(e) => {
                        handleChange("transfereddateandtime", e);
                      }}
                      helperText={helperTextMsg}
                      helperTextType={"error"}
                      inputField={
                        <TextInput
                          label="Transferred Date/Time"
                          placeholder="dd/mm/yyyy"
                          dateIcon="images/icons8-calendar-grey.svg"
                          labelStyle={labelStyle}
                          isRequired
                          style={selectBorderStyle}
                          hoverColor={UIColor.primaryColor.color}
                          value={
                            state?.transfereddateandtime
                              ? moment(
                                  new Date(state?.transfereddateandtime)
                                ).format("DD/MM/YYYY")
                              : ""
                          }
                          error={err?.transfereddateandtime}
                        />
                      }
                    />
                  </Grid>
                  <Grid item
                    md={2}
                    lg={6}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                  >
                    <Select
                      isRequired
                      search
                      placeholder="Select"
                      label="Transfer Reason"
                      labelStyle={labelStyle}
                      inLineStyles={selectBorderStyle}
                      options={selector?.transferreason}
                      value={state?.transferreason?.value || ""}
                      onChangeValue={(e) => {
                        handleChange("transferreason", e);
                      }}
                      error={err?.transferreason}
                      helperText={helperTextMsg}
                      helperTextType={"error"}
                    />
                  </Grid>
                  {state?.transferreason?._id ===
                  process.env
                    .REACT_APP_E_REFERRAL_TRANSFER_REASON_TYPE_OTHER && (
                  <Grid item
                    md={2}
                    lg={6}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                  >
                
                    <TextArea
                      label="Reason"
                      placeholder="Enter your reason manually"
                      value={state?.remark || ""}
                      hoverColor={UIColor.primaryColor.color}
                      labelStyle={labelStyle}
                      isRequired
                      error={err?.remark}
                      helperText={helperTextMsg}
                      helperTextType={"error"}
                      onChange={(e) => handleChange("remark", e.target.value)}
                      style={{
                        padding: 14,
                        resize: "none",
                        height: "14vh",
                        borderRadius: 8,
                        borderColor: "#E0E0E0",
                      }}
                      maxLength={3000}
                    />
                  </Grid>
                )}
                </Grid>
               
                {/* <div style={{ height: "2vh" }}></div> */}
                
             
            
          
        </Paper>
     

      <Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
          }}
        >
          <span style={{ display: "flex", gap: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={styles.actionButton}
              onClick={onSubmit}
            >
              {"TRANSFER"}
            </Button>
          </span>
        </div>
      </Grid>
    </Div>
  );
}

export default withAllContexts(TransferCase);
