import React, { useState, useContext, useEffect,Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AppRoutes } from "./routes";
import SignIn from "../screens/authentication/signIn";
import ForgetPassword from "../screens/authentication/forgetPassword";
import ChangePassword from "../screens/authentication/changePassword";
import ResetPassword from "../screens/authentication/resetPassword";
import LinkExpired from "../screens/authentication/linkExpired";
import Dashboard from "../screens/dashboard";
import FinanceRegistration from "../screens/financeRegistration";
import VisitRegistration from "../screens/visitRegistration";
import ConfirmBooking from "../screens/confirmBooking";
import PatientRegistration from "../screens/patientRegistration/patientRegistration";
import PatientDetails, { PatientDetailWithAppBar } from "../screens/patientDetail";
import AppointmentBooking from "../screens/appointmentBooking";
import TestComponent from "../screens/testComponent";
import searchFacility from "../screens/searchFacility";
import PrivateRoute from "./privateRouter";
import PatientDashboard from "../screens/patientDashboard/patientDashboard";
import SearchFacility from "../screens/searchFacility";
import cashierBilling from "../screens/cashierBillingDetails";
import { CashierBillingSummary } from "../screens/cashierSummary";
import TreatmentPlans from "../screens/treatmentPlan";
import ClinicalInfo from "../screens/clinicalInfo";
import ReferralRegistration from "../screens/ReferralRegistration";
import CashierCollectionSummary from "../screens/cashierCollections";
import DashboardLanding from "../screens/dashboardLanding";
import PatientReferral from "../screens/patientReferral";
import IFrames from "../screens/iFrames";
import PaymentCollections from "../screens/paymentCollections";
import AdvancePayment from "../screens/advancePayment";
import { tenantRoutes, tenantRoutes1 } from "./tenantRoutes";
import { getTenantId } from "../utils";
import { AuthContext } from "../context";

const AppRouter = () => {
  const tokendata = useContext(AuthContext)
  console.log({ tokendata });
  let tenantId = tokendata?.tokenData?.tenantid || "4567"
  console.log({ tenantId });

    return (
    <Router>
      <Routes>
          <Route exact key={"/"} path={"/"} element={<SignIn />} />
          {tenantRoutes[tenantId].map(({ path, component }) => (
            <Route key={path} path={path} element={component} />  //need to implement private router
          ))} 
      </Routes>
    </Router>
  );
};

export default AppRouter;

/* 
  {tenantRoutes1[tenantId].map(async ({ path, component }) => {
  let data=await import(`../screens/${component}`)
  return (
    <Route exact key={"/"} path={"/"} element={
      <Suspense fallback={<div>Loading...</div>}>
        {data}
      </Suspense>
    }
    />)
  })}*/
