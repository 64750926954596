import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import {
  Autocomplete,
  FieldLabel,
  InputBase,
  FieldContainer,
  CustomButton,
  StyledPopover,
} from "./components";
// import Autocomplete from '@mui/material/Autocomplete';
import RequestorDetailsForm from "./requestorDetailsForm";
import { DateTimePicker } from "./components/common/smartForm/component";
import update from "immutability-helper";
import {
  getFilteredData,
  getDurationWithGranularity,
  AlertProps,
  getFormatForDateTimePicker,
} from "../../../../utils";

import moment from "moment";
import ScheduleLine from "./scheduleLine";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../../../redux/actions";
import copy from "fast-copy";
import { format, fromUnixTime, getUnixTime, add } from "date-fns";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
  bindHover,
} from "material-ui-popup-state/hooks";
import RemarksForm from "./remarks";
import withAllContexts from "../../../../hoc/withAllContexts.js";

import { createJson, doseRangeCheck, getResponseGenerate } from "./createJson";
import { Paper, Switch } from "@material-ui/core";
import Additive from "./additive";
import { AlertDialog } from "./components";

import FreqIcon from "../../../../assets/img/svg/req.js";
import AddMultiOrder from "./addMultiOrder";
import AddMultiOrderBanner from "./AddMultiOrderBanner";
import "./index.css";
import DocumentBlack from "../../../../assets/img/svg/DocumentBlack.js";
import { UIColor } from "../../../../themes/theme.js";
import TextfieldWithSelect from "../medicationInfoForm/components/inputs/textfieldWithSelect";
import { AlertContext, BackdropContext } from "../../../../context/index.js";

// const dateFormat = "DD-MM-YYYY,hh:mm A";
const dateFormat = "MM/dd/yyyy hh:mm aaa";

const scheduleLineStyles = {
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cancelBtn: {
    padding: "8px",
    fontSize: 14,
    marginRight: 10,
    borderColor: UIColor.lineBorderFill.color,
    color: UIColor.primaryColor.color,
    borderRadius: 8,
  },
  filledBtn: {
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    padding: "8px",
    fontSize: 14,
    borderRadius: 8,
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "4px",
    fontFamily: `pc_regular !important`,
  },
  labelStyleScheduleLine: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "12px",
    fontFamily: `pc_regular !important`,
  },
  labelStyle_: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "4px",
    fontFamily: `pc_regular !important`,
    margin: "6px 5px",
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: UIColor.error.color,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  colPadding: {
    padding: "7px",
  },
  label: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px !important",
    fontFamily: "pc_regular!important",
    marginBottom: "6px",
    fontWeight: 400,
  },
  required: {
    color: UIColor.error.color,
    marginLeft: 5,
  },
  switch: {
    "& .App1-Mui-checked": {
      color: "#fff",
    },
    "& .App1-Mui-checked + .App1-MuiSwitch-track": {
      backgroundColor: UIColor.primaryColor.color,
      opacity: 1,
    },
  },
  dateWrapper: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    padding: 5,
    display: "flex",
    alignItems: "center",
    minHeight: 40,
    "&:hover": {
      borderColor: UIColor.lineBorderFill.color,
    },
  },
  calenderText: {
    marginLeft: 5,
    cursor: "pointer",
    display: "flex",
  },
  dateText: {
    fontSize: 14,
    color: UIColor.secondaryText.color,
    flex: 1,
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  iconWrapper: {
    borderRadius: 8,
    width: 40,
    height: 40,
    backgroundColor: UIColor.lineBorderFill.color,
    margin: 0,
    position: "absolute",
    marginTop: 22,
  },
  patientInstructionDiv: {
    height: "82px",
    padding: "5px",
    border: "1px solid #c3c3c3",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
  },
};

const useStyles = makeStyles((theme) => ({
  dropdown: {
    background: `${theme.palette.background.table} !important`,
    "&:input": {
      color: theme.palette.text.primary,
    },
  },
  patientInstruction: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    width: "100%",
  },
  frequencyContainer: {
    display: "flex",
    gap: 6,
  },
  prnPopoverPaper: {
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
    minWidth: 320,
    borderRadius: 8,
  },
  ctaWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  ctaContainer: {
    display: "flex",
    alignItems: "center",
    gap: 6,
  },
  addNewMedicationPaperDividers: {
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 2,
    // backgroundColor: theme.palette.background.common,
    // borderColor: "rgba(222, 229, 236, 1)",
  },
  disabledColor: {
    backgroundColor: "#F1F6FC !important",
    "& .Mui-disabled": {
      backgroundColor: "#F1F6FC !important",
    },
  },
}));

function MedicationInfoForm(props) {
  const {
    DRUGMASTER_MASTER,
    //GET_ORDER_DATE,
    SAVE_MEDICATIONS,
    DIAGNOSISAGAINSTENCOUNTER_MASTER,
    SINGLE_READ_EDIT_INFO,
    DRUGSTOREDISPLOC_MASTER,
    GET_ALLERGY_INTERACTION,
    GET_ALERT_CODES,
    GET_DRUG_DUPLICATION,

    // Needed for the clmm-master package to run independently.
    FREQUENCYMASTER_MASTER,
    DURATIONTYPE_MASTER,
    INFUSIONRATEUOM_MASTER,
    ORGANIZATION_MASTER,
    PRACTITIONERROLE_MASTER,
    PRACTITIONER_MASTER,
    DRUGTYPE_MASTER,
    DOSAGEVOLUMEUOM_MASTER,
    DRUGROUTES_MASTER,
    STRENGTHUOM_MASTER,
    //STRENGTHVOLUMNUOM_MASTER,
    PRIORITY_MASTER,
    DRUGCATEGORY_MASTER,
    //ORDERNATURE_MASTER,
    ORDERMODE_MASTER,
    //REASONCODE_MASTER,
    PATIENT_INSTRUCTIONS_MASTER,
    //ORDER_PRESCRIPTION_MASTER,
    EditNewFrequency_orgbase_frequency_dropdown,
    INFUSIONTIMEUOM_MASTER,
  } = actions;

  const classes = useStyles();
  // const dialogStyles = useDialogStyles();
  // const dialogContentStyles = useDialogContentStyles();
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  const { ORDER_TYPE_REQ_DETAILS_ROLE, ORDER_TYPE_REQ_DETAILS_LAB } = actions;
  const options = useSelector((state) => state?.MedicationMastersSlice);
  const options1 = useSelector((state) => state?.medicationsSlice);
  const singleReadData = useSelector(
    (state) => state?.MedicationMastersSlice?.single_read_edit_info
  );
  const personData = useSelector(
    (state) => state?.signInSlice?.searchPractioner?.data
  );

  const initState = {
    drugType: null,
    drugCodeInputValue: "",
    drugCode: {}, // Not On IV Form
    orderDescription: "", // IV Form Only
    dosageValue: "", // Not On IV Form
    dosageUOM: null, // Not On IV Form
    route: {},
    baseSolution: {}, // IV Form Only
    baseSolutionInputValue: "", // IV Form Only
    ivStrength: "", // IV Form Only
    ivVolumeValue: "", // IV Form Only
    ivVolumeUOM: null, // IV Form Only
    infusionRateValue: "", // IV Form Only
    infusionRateUOM: null, // IV Form Only
    ivInfusionTime: "", // IV Form Only
    ivInfusionTimeUom: null, // IV Form Only
    totalVolumeValue: "", // IV Form Only
    totalVolumeUOM: null, // IV Form Only
    dosageForm: "", // Not On IV Form
    strengthValue: "", // Not On IV Form
    strengthUOM: null, // Not On IV Form
    prn: false,
    remarks: "",
    frequency: null,
    isFrequencyScheduleLineOpen: false,
    durationValue: "",
    durationUOM: null,
    startDate: null,
    endDate: null,
    priority: null,
    drugCategory: null,
    nature: {},
    mode: {},
    dispensingPharmacy: null,
    selectDiagnosis: [],
    indication: null,
    patientInstruction: null,
    patientInstructionValue: "",
    notes: "",
    prescriptionType: null,

    isFormTypeIntravenous: false,

    requestorDetail: {
      organization: null,
      practitionerRole: null,
      orderBy: null,
      practitionerId: "",
    },
    isfirststatorder: false,
    additiveItems: [],
    SlidingScaleDose_prn: false,
    SlidingScaleDose: "",
    practitionerCode: null,
  };

  const [state, setState] = useState({
    ...initState,
  });
  const [allmasters, setAllMasters] = useState([]);
  const [alertData, setAlertData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [error, setError] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [multiOrderData, setMultiOrderData] = useState([]);
  const [orderIndex, setOrderIndex] = useState(null);
  const [openDose, setOpenDoseRangeCheck] = useState({
    open: false,
    alertData: [],
  });

  const popupStateHoverScale = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  const popupStateHoverPRN = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  const popupStateSlide = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  const changeState = (key, value) => {
    // if (key === "durationValue") {
    //   state.durationValue = "";
    //   state.startDate = "";
    //   state.endDate = "";
    //   state.durationUOM = "";
    // }

    if (props?.isAdd) {
      if (key === "drugCode" || (key === "drugCode" && !value)) {
        getReedQery();
        initState.requestorDetail = state.requestorDetail;
        initState.dispensingPharmacy = state.dispensingPharmacy;
        initState.practitionerCode = state.practitionerCode;
        initState.mode =
          localStorage.getItem("role_name") &&
          localStorage.getItem("role_name")?.toLowerCase() === "doctor"
            ? state.mode
            : {};
        setState({ ...initState });
        setOrderIndex(null);
        setEditData(null);
        setAlertData([]);
        setOpenDoseRangeCheck({
          open: false,
          alertData: [],
        });
      }
    }

    setState((prev) => ({
      ...prev,
      // ...state,
      [key]: value,
    }));
  };

  const calculateTotalVolume = () => {
    if (state.infusionRateValue && state.ivInfusionTime) {
      let totalVolumevalue = state.infusionRateValue * state.ivInfusionTime;
      setState((prev) => ({
        ...prev,
        totalVolumeValue: totalVolumevalue,
      }));
    }
  };

  const FlterOptions3 = (type) => {
    let allMasters = allmasters;
    let filteredArray = allMasters.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?._id,
        label: data[index]?.display,
      };
      list.push(datatemp);
    }
    return list;
  };

  const FlterOptions2 = (type) => {
    let allMasters = allmasters;
    let filteredArray = allMasters.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        label: data[index]?._id,
        value: data[index]?.display,
      };
      list.push(datatemp);
    }
    return list;
  };
  const getAllMaster = async () => {
    const response = await dispatch(actions.GET_ALL_MASTERS(""));
    setAllMasters(response?.payload?.data);
  };

  React.useEffect(() => {
    calculateTotalVolume();
    getAllMaster();
  }, [state.infusionRateValue, state.ivInfusionTime]);

  //Alert Dialoge open
  const handleOpenAlertDialog = async (newValue) => {
    // Get drug duplication

    let DrugCode = parseInt(
      newValue?.result?.aliasDtls?.find(
        (val) => val?.aliasType?.code === "FDBRTGEN"
      )?.aliasCode
    );

    //Get Alert Code
    const Alert_Codes = await dispatch(
      GET_ALERT_CODES({ patientId: props?.patientID })
    );

    let payloadDrugDuplication = {
      drugCode: [DrugCode],
      profileDrugs: Alert_Codes?.payload?.data?.ProfileDrugCodes ?? [],
    };

    let DrugDup;
    if (DrugCode && Alert_Codes?.payload?.data?.ProfileDrugCodes?.length > 0) {
      DrugDup = await dispatch(
        GET_DRUG_DUPLICATION({ list: payloadDrugDuplication })
      );
    }

    if (
      (Alert_Codes?.payload?.data?.ProfileDrugCodes?.length === 0 &&
        Alert_Codes?.payload?.data?.DiseaseCodes?.length === 0 &&
        Alert_Codes?.payload?.data?.AllergyCodes?.length === 0) ||
      (Alert_Codes?.payload?.data?.ProfileDrugCodes?.includes(DrugCode) &&
        !DrugDup?.payload.data?.length > 0)
    ) {
      return;
    }

    let payload = {
      DrugCodes: [DrugCode],
      ...Alert_Codes?.payload?.data,
    };

    // let payload = {
    //   DrugCodes: [1048580],
    //   ProfileDrugCodes: [
    //     1049997, 1048649, 1052518, 1052598, 1050231, 1066660, 1049125, 1050215,
    //     1054917, 1051477,
    //   ],
    //   DiseaseCodes: ["346", "G43.909", "D64.3", "1052614"],
    //   AllergyCodes: [
    // 2755, 281, 306, 476, 543, 568, 571, 572, 590, 592, 593, 2715, 2755,
    //     2755
    //   ],
    //   Allergies:[
    //     {
    //       Code: 2755,
    //       Descriptions: 'Dis'
    //     }
    //   ]
    // };

    let GET_ALLERY;
    if (DrugCode) {
      GET_ALLERY = await dispatch(GET_ALLERGY_INTERACTION({ payload }));
    }

    if (
      !GET_ALLERY?.payload?.data?.error &&
      ValidateAlert(GET_ALLERY?.payload?.data)
    ) {
      setAlertDialogOpen(true);
    }

    if (GET_ALLERY?.payload?.data?.error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Unable to Fetch Alert and Interaction",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  //validate alert
  const ValidateAlert = (data) => {
    let ActualData =
      Array.isArray(data) &&
      data?.filter((value) => value?.[Object.keys(value)?.[0]]?.length > 0);
    if (ActualData?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  //Alert Dialoge close
  const handleCloseAlertDialog = (Data, save) => {
    setAlertDialogOpen(false);
    setAlertData(Data);
    setOpenDoseRangeCheck({
      open: false,
      alertData: [],
    });
  };

  const handleChangeDose = (Data, save) => {
    setOpenDoseRangeCheck({
      open: false,
      alertData: [],
    });
  };

  useEffect(() => {
    //FIXME -  handleDurationUOMSideEffect(state?.durationUOM);
  }, [state?.durationUOM]);

  const setOrderDefaultData = (data) => {
    // Unit Dose Only
    let statePayload = {
      frequency: options?.FrequencyMaster_masters?.data?.filter(
        (item) => item?.value === data?.freqcode?._id
      )?.[0],
      durationValue: data?.durationcode,
      durationUOM: options?.DurationType_masters?.data?.filter(
        (item) => item?.value === data?.durnUOM?._id
      )?.[0],
      infusionRateValue: data?.InfuseRateval,
      infusionRateUOM: options?.InfusionRateUoM_masters?.data?.filter(
        (item) => item?.value === data?.InfuseRateUom?._id
      )?.[0],
      ivInfusionTime: data?.InfuseOverDurval,
      ivInfusionTimeUom: options?.InfusionTimeUoM_masters?.data?.filter(
        (item) => item?.value === data?.InfuseOverDurType?._id
      )?.[0],
    };

    setState((prev) => ({
      ...prev,
      ...statePayload,
    }));
  };

  const handleDrugCodeChange = async (newValue) => {
    if (newValue) {
      //open alert dialoag if any
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Validating Alerts Interactions...",
      });
      await handleOpenAlertDialog(newValue);
      //loading true
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
    }
    changeState("drugCode", newValue);

    if (newValue?.drugType === "DRGTYP-002") {
      //If the drug is IV
      handleBaseSolutionChange(newValue);
    }

    // const orderDefaults = await dispatch(
    //   actions.GET_ORDER_DEFAULT({
    //     // encounterId: "Encounter/11143",
    //     // drugMasterId: "DrugMaster/143241961",
    //     encounterId: props?.encounterID,
    //     drugMasterId: newValue?.value,
    //   })
    // );

    //const orderDefaultData = orderDefaults?.payload?.data?.Response;

    // if (orderDefaultData) {
    //   setOrderDefaultData(orderDefaultData);
    //   // handleFrequencyChange(
    //   //   "frequency",
    //   //   options?.FrequencyMaster_masters?.data?.filter(
    //   //     (item) => item?.value === orderDefaultData?.freqcode
    //   //   )?.[0]
    //   // );
    // }

    const result = copy(newValue?.result);

    let filterDrug = [];
    const pres = options?.order_prescription_list?.data?.map((v) => {
      if (
        result?.drug_category?.shortdesc?.toLowerCase() ===
        v?.label?.toLowerCase()
      )
        return filterDrug.push({
          value: v?.prescriptionType?._id ?? v?.value ?? "",
          label: v?.prescriptionType?.display ?? v?.label ?? "",
          id: v?.prescriptionType?.id ?? v?.id ?? "",
        });
    });

    const payload = {
      drugType: {
        label: result?.DrugType?.shortdesc ?? "",
        value: result?.DrugType?._id ?? "",
      },
      drugCategory: getFilteredData(
        "DrugCategory",
        "DrugCategory_masters",
        result,
        options
      ),
      dosageForm: result?.FormCode?.shortdesc ?? result?.FormCode,

      dosageValue:
        result?.StrengthValuesPossible?.[0]?.OrderUOMAllowed?.[0]
          ?.AllowedOrderDoseVal,
      dosageUOM: {
        ...result?.StrengthValuesPossible?.[0]?.OrderUOMAllowed?.[0]
          ?.AllowedOrderDoseUOM,
        label:
          result?.StrengthValuesPossible?.[0]?.OrderUOMAllowed?.[0]
            ?.AllowedOrderDoseUOM?.UOM,
        value:
          result?.StrengthValuesPossible?.[0]?.OrderUOMAllowed?.[0]
            ?.AllowedOrderDoseUOM?._id,
      },

      strengthValue: result?.StrengthValuesPossible?.[0]?.StrengthValue,
      strengthUOM: {
        ...result?.StrengthValuesPossible?.[0]?.StrengthUOM,
        label: result?.StrengthValuesPossible?.[0]?.StrengthUOM?.UOM,
        value: result?.StrengthValuesPossible?.[0]?.StrengthUOM?._id,
      },

      route: getFilteredData(
        "DefaultRouteCode",
        "DrugRoutes_masters",
        result,
        options
      ),
    };

    if (filterDrug?.length > 0) {
      payload.prescriptionType = filterDrug?.[0] ?? {};
    }
    setState((prev) => ({
      ...prev,
      ...payload,
      isFormTypeIntravenous: newValue?.drugTypeShort === "IV",
    }));
  };

  const searchDrugMaster = async (newValue) => {
    if (newValue.length > 3) {
      await dispatch(DRUGMASTER_MASTER({ searchText: newValue }));
    }
  };

  useEffect(() => {
    //FIXME -   handleDurationUOMSideEffectForIV(state?.durationUOM);
  }, [state?.baseSolution]);

  const handleDurationUOMSideEffectForIV = async (value) => {
    // 2. If Frequency and duration value is not present then don't fetch data
    if (state.frequency === null && state.durationValue === "") return;

    // 2. Fetch the order Start and end Date, iff Frequency & duration value is present
    const granularity =
      value?.label === "Doses"
        ? "days"
        : value?.label?.toString()?.toLowerCase();
    const start = new Date();
    const end = ""; //FIXME - add(start, { [granularity]: parseInt(state.durationValue) });

    const orderDateData = await fetchOrderDate(start, end, value?.label); //state?.durationUOM?.label
    const orderStartDate = orderDateData?.orderStartDate;
    const orderEndDate = orderDateData?.orderEndDate;

    // 3. Set the state
    //FIXME -
    // orderStartDate &&
    //   changeState(
    //     "startDate",
    //     format(fromUnixTime(orderStartDate), dateFormat)
    //   );
    // orderEndDate &&
    //   changeState("endDate", format(fromUnixTime(orderEndDate), dateFormat));

    const ressDurationValue = Math.ceil(
      Math.abs(
        getDurationWithGranularity(
          moment.duration(
            moment.unix(orderStartDate).diff(moment.unix(orderEndDate))
          ),
          value?.label?.toLowerCase()
        )
      )
    ).toString();

    if (
      state.durationUOM?.label?.toLowerCase() !== "doses" &&
      state.durationUOM?.label?.toLowerCase() !== "hours" &&
      state.durationUOM?.label?.toLowerCase() !== "sec" &&
      ressDurationValue &&
      state.durationValue &&
      parseFloat(ressDurationValue) !== parseFloat(state.durationValue)
    ) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Order duration is not matching with the schedule duration.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }

    // moment.unix(orderEndDate).format("MM/dd/yyyy h:mm aa")
    // changeState(
    //   "durationValue",
    //   Math.ceil(
    //     Math.abs(
    //       getDurationWithGranularity(
    //         moment.duration(
    //           moment.unix(orderStartDate).diff(moment.unix(orderEndDate))
    //         ),
    //         value?.label?.toLowerCase()
    //       )
    //     )
    //   ).toString()
    // );
  };

  const setOrderDefaultDataForIV = (data) => {
    let statePayload = {
      frequency: options?.FrequencyMaster_masters?.data?.filter(
        (item) => item?.value === data?.freqcode?._id
      )?.[0],
      durationValue: data?.durationcode,
      durationUOM: options?.DurationType_masters?.data?.filter(
        (item) => item?.value === data?.durnUOM?._id
      )?.[0],
      infusionRateValue: data?.InfuseRateval,
      infusionRateUOM: options1?.InfusionRateUoM_masters?.data?.filter(
        (item) => item?.value === data?.InfuseRateUom?._id
      )?.[0],
      ivInfusionTime: data?.InfuseOverDurval,
      ivInfusionTimeUom: options1?.InfusionTimeUoM_masters?.data?.filter(
        (item) => item?.value === data?.InfuseOverDurType?._id
      )?.[0],
    };

    setState((prev) => ({
      ...prev,
      ...statePayload,
    }));
  };

  const handleBaseSolutionChange = async (newValue) => {
    if (newValue?.drugType === "DRGTYP-002") {
      changeState("baseSolution", newValue);
    } else {
      initState.requestorDetail = state.requestorDetail;
      initState.dispensingPharmacy = state.dispensingPharmacy;
      initState.practitionerCode = state.practitionerCode;
      setState({ ...initState });
      handleDrugCodeChange(newValue);
    }

    // await handleOpenAlertDialog(newValue);

    // const orderDefaults = await dispatch(
    //   actions.GET_ORDER_DEFAULT({
    //     // encounterId: "Encounter/11143",
    //     // drugMasterId: "DrugMaster/143241961",
    //     encounterId: props?.encounterID,
    //     drugMasterId: newValue?.value,
    //   })
    // );

    // const orderDefaultData = orderDefaults?.payload?.data?.Response;

    // if (orderDefaultData) {
    //   setOrderDefaultDataForIV(orderDefaultData);
    // }

    const result = copy(newValue?.result);

    let filterDrug = [];
    // const pres = options?.order_prescription_list?.data?.map((v) => {
    const pres = FlterOptions3("PRESCRIPTIONTYPE")?.data?.map((v) => {
      if (
        result?.drug_category?.shortdesc?.toLowerCase() ===
        v?.label?.toLowerCase()
      )
        return filterDrug.push({
          value: v?.prescriptionType?._id ?? v?.value ?? "",
          label: v?.prescriptionType?.display ?? v?.label ?? "",
          id: v?.prescriptionType?.id ?? v?.id ?? "",
        });
    });

    const payload = {
      ivStrength:
        (newValue?.StrengthValues || "") +
        " " +
        (result?.StrengthValuesPossible?.[0]?.StrengthUOM?.UOM ||
          newValue?.StrengthUOM ||
          ""),
      ivVolumeValue: newValue?.StrengthVolume,
      ivVolumeUOM:
        result?.StrengthValuesPossible?.[0]?.StrengthVolumeUoM?.UOM ||
        newValue?.StrengthVolumeUoM ||
        "",
      route: getFilteredData(
        "DefaultRouteCode",
        "DrugRoutes_masters",
        result,
        options
      ),
      orderDescription: result?.LongDesc ?? "",
      totalVolumeValue: result?.StrengthValuesPossible?.TotalVolContent,
      totalVolumeUOM: result?.StrengthValuesPossible?.TotalVolContentUoM,
    };

    if (filterDrug?.length > 0) {
      payload.prescriptionType = filterDrug?.[0] ?? {};
    }

    setState((prev) => ({
      ...prev,
      ...payload,
      //   isFormTypeIntravenous: newValue?.shortdesc === "IV",
    }));
  };

  const searchBaseSolution = async (newValue) => {
    await dispatch(DRUGMASTER_MASTER({ searchText: newValue }));
  };

  const handleRequestorDetailsSave = (key, value) => {
    // setState({
    //   ...state,
    //   requestorDetail: key,
    // });
    // return;
    if (
      key === "organization" &&
      value?.id
      //  &&
      // value?.id !== state?.organization?.id
    ) {
      dispatch(
        ORDER_TYPE_REQ_DETAILS_ROLE({
          id: value?.id,
        })
      );
      state.requestorDetail = {
        ...state.requestorDetail,
        [key]: value,
        practitionerRole: null,
        orderBy: null,
        practitionerId: "",
      };
      setState({
        ...state,
      });
    }
    if (key === "practitionerRole") {
      dispatch(
        ORDER_TYPE_REQ_DETAILS_LAB({
          id: value?.id,
          _id: value?._id,
          orgId: state?.requestorDetail?.organization?.id,
        })
      );

      state.requestorDetail = {
        ...state.requestorDetail,
        [key]: value,
        orderBy: null,
        practitionerId: "",
      };
      setState({
        ...state,
      });
    }
    if (key === "orderBy") {
      state.requestorDetail = {
        ...state.requestorDetail,
        [key]: value,
        practitionerId: value?.practitioner_id,
      };

      setState({
        ...state,
      });

      // Must return to avoid state update clash
    }
  };

  const handleFrequencyChange = (key, value) => {
    let statePayload = {};

    if (value?.freqcode === "STAT") {
      statePayload = {
        durationValue: value?.fixeddurationVal,
        durationUOM: value?.fixeddurUOM,
        startDate: new Date(),
      };
    }
    if (key === "frequency") {
      state.durationValue = "";
      state.startDate = "";
      state.endDate = "";
      state.durationUOM = "";
    }

    setState((prev) => ({
      ...prev,
      [key]: value,
      ...statePayload,
      // ...state
    }));
  };

  const isFrequencyCodeSTAT = () => {
    return state?.frequency?.freqcode === "STAT";
  };

  const tapperDurationCalculation = () => {
    let DurationValues = state?.frequency?.frequency?.flatMap((val) => [
      val.fromDay,
      val.toDay,
    ]);
    let max = Math.max(...DurationValues);
    if (max) {
      let statePayload = {
        durationValue: max,
        durationUOM: options?.DurationType_masters?.data?.find(
          (val) => val.label?.toLowerCase() === "days"
        ),
        startDate: new Date(),
      };
      setState((prev) => ({
        ...prev,
        ...statePayload,
        // ...statelet
      }));
    }
  };

  useEffect(() => {
    if (state?.frequency?.label?.toLowerCase() === "taper") {
      //FIXME -   handleDurationUOMSideEffect(state?.durationUOM);
    }
  }, [state.durationValue]);

  useEffect(() => {
    if (state?.frequency?.label?.toLowerCase() === "taper") {
      tapperDurationCalculation();
    }
  }, [state?.frequency]);

  const fetchOrderDate = async (startDate, endDate, durationType) => {
    if (
      (!state?.frequency?.label &&
        !state.durationValue &&
        !state?.durationUOM) ||
      (state?.frequency?.label && !state.durationValue && !state?.durationUOM)
    ) {
      return;
    }

    const payload = {
      FrequencyType: state?.frequency?.label,
      DurationType: durationType,
      DurationValue: parseInt(state?.durationValue),
      //FIXME -
      // OrderStartDate: startDate
      //   ? getUnixTime(startDate)
      //   : getUnixTime(new Date()),
      // OrderEndDate: endDate ? getUnixTime(endDate) : getUnixTime(new Date()),
    };

    //const response = await dispatch(GET_ORDER_DATE(payload));

    // orderStartDate
    // orderEndDate
    //return response?.payload?.data;
    return "";
  };

  const handleDurationUOMSideEffect = async (value) => {
    // 2. If Frequency and duration value is not present then don't fetch data
    if (state.frequency === null && state.durationValue === "") return;

    // 2. Fetch the order Start and end Date, iff Frequency & duration value is present
    const granularity =
      value?.label === "Doses"
        ? "days"
        : value?.label?.toString()?.toLowerCase();
    const start = new Date();
    const end = ""; //FIXME - add(start, { [granularity]: parseInt(state.durationValue) });

    const orderDateData = await fetchOrderDate(start, end, value?.label); //state?.durationUOM?.label
    const orderStartDate = orderDateData?.orderStartDate;
    const orderEndDate = orderDateData?.orderEndDate;

    // 3. Set the state
    //FIXME -
    // orderStartDate &&
    //   changeState(
    //     "startDate",
    //     format(fromUnixTime(orderStartDate), dateFormat)
    //   );
    // orderEndDate &&
    //   changeState("endDate", format(fromUnixTime(orderEndDate), dateFormat));
    const ressDurationValue = Math.ceil(
      Math.abs(
        getDurationWithGranularity(
          moment.duration(
            moment.unix(orderStartDate).diff(moment.unix(orderEndDate))
          ),
          value?.label?.toLowerCase()
        )
      )
    ).toString();
    if (
      state.durationUOM?.label?.toLowerCase() !== "doses" &&
      state.durationUOM?.label?.toLowerCase() !== "hours" &&
      state.durationUOM?.label?.toLowerCase() !== "sec" &&
      ressDurationValue &&
      state.durationValue &&
      parseFloat(ressDurationValue) !== parseFloat(state.durationValue)
    ) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Order duration is not matching with the schedule duration.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
    // moment.unix(orderEndDate).format("MM/dd/yyyy h:mm aa")
    // changeState(
    //   "durationValue",
    //   Math.ceil(
    //     Math.abs(
    //       getDurationWithGranularity(
    //         moment.duration(
    //           moment.unix(orderStartDate).diff(moment.unix(orderEndDate))
    //         ),
    //         value?.label?.toLowerCase()
    //       )
    //     )
    //   ).toString()
    // );
  };

  const handleDurationUOMChange = (key, value) => {
    changeState(key, value);
  };

  const handleStartDateChange = async (key, value) => {
    // 1. Set state
    changeState(key, value);

    // 2. If Frequency and duration value is not present then don't fetch data
    if (state.frequency === null && state.durationValue === "") return;

    // 2. Fetch the order Start and end Date, iff Frequency & duration value is present
    const granularity =
      state.durationUOM?.label === "Doses"
        ? "days"
        : state.durationUOM?.label?.toString()?.toLowerCase();
    const start = value;
    const end = ""; //FIXME -  add(start, { [granularity]: parseInt(state.durationValue) });

    const orderDateData = await fetchOrderDate(
      start,
      end,
      state?.durationUOM?.label
    );
    const orderStartDate = orderDateData?.orderStartDate;
    const orderEndDate = orderDateData?.orderEndDate;
    //FIXME -
    // 3. Set the state
    // orderStartDate &&
    //   changeState(
    //     "startDate",
    //     format(fromUnixTime(orderStartDate), dateFormat)
    //   );
    // orderEndDate &&
    //   changeState("endDate", format(fromUnixTime(orderEndDate), dateFormat));
    // changeState(
    //   "durationValue",
    //   Math.ceil(
    //     Math.abs(
    //       getDurationWithGranularity(
    //         moment.duration(
    //           moment.unix(orderStartDate).diff(moment.unix(orderEndDate))
    //         ),
    //         state?.durationUOM?.label?.toLowerCase()
    //       )
    //     )
    //   ).toString()
    // );
    const ressDurationValue = Math.ceil(
      Math.abs(
        getDurationWithGranularity(
          moment.duration(
            moment.unix(orderStartDate).diff(moment.unix(orderEndDate))
          ),
          value?.label?.toLowerCase()
        )
      )
    ).toString();
    if (
      state.durationUOM?.label?.toLowerCase() !== "doses" &&
      state.durationUOM?.label?.toLowerCase() !== "hours" &&
      state.durationUOM?.label?.toLowerCase() !== "sec" &&
      ressDurationValue &&
      state.durationValue &&
      parseFloat(ressDurationValue) !== parseFloat(state.durationValue)
    ) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Order duration is not matching with the schedule duration.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  const handleEndDateChange = async (key, value) => {
    // 0. Temporarily disabled the end date change
    return;

    // 1. Set state
    changeState(key, value);

    // 2. If Frequency and duration value is not present then don't fetch data
    if (state.frequency === null && state.durationValue === "") return;

    // 2. Fetch the order Start and end Date, iff Frequency & duration value is present
    const granularity =
      state.durationUOM?.label === "Doses"
        ? "days"
        : state.durationUOM?.label?.toString()?.toLowerCase();
    const end = moment(value);
    const start = end
      .clone()
      .subtract(parseInt(state.durationValue), granularity);

    const orderDateData = await fetchOrderDate(start, end);
    const orderStartDate = orderDateData?.orderStartDate;
    const orderEndDate = orderDateData?.orderEndDate;

    // 3. Set the state
    changeState("startDate", orderStartDate);
    changeState("endDate", orderEndDate);
    changeState(
      "durationValue",
      Math.ceil(
        Math.abs(
          getDurationWithGranularity(
            moment.duration(
              moment.unix(orderStartDate).diff(moment.unix(orderEndDate))
            ),
            state?.durationUOM?.label?.toLowerCase()
          )
        )
      ).toString()
    );
  };

  const handleScheduleDelete = (i) => {
    const stateState = JSON.parse(JSON.stringify(state));
    const index = stateState?.frequency?.frequency?.indexOf(i);
    // if (index > -1) {
    stateState.frequency.frequency.splice(index, 1);
    // };
    // delete stateState?.frequency?.frequency[index];
    setState({
      ...stateState,
    });
  };

  const handleScheduleLineChange = (name, value, index) => {
    const stateState = JSON.parse(JSON.stringify(state));

    if (name === "addNew") {
      // if (stateState.frequency.frequency?.length > 0) {
      //   stateState.frequency.frequency.push(stateState.frequency.frequency?.[0])
      // } else {
      //   stateState.frequency.frequency.push({})
      // }
      stateState.frequency.frequency.push({});
      setState({ ...stateState });
    } else {
      const newState = update(state, {
        frequency: {
          frequency: {
            [index]: {
              [name]: {
                $set: value,
              },
            },
          },
        },
      });
      //to get Scheduled doses
      let CustomDose = false;
      if (
        name === "dose" &&
        (stateState?.frequency?.type === "User Defined" ||
          stateState?.frequency?.type === "Detail")
      ) {
        let Doses = [];
        newState.frequency.frequency.forEach((val) => {
          if (val.dose) {
            Doses.push(parseInt(val.dose));
          }
        });
        CustomDose =
          Doses.filter((val) => val !== state?.dosageValue)?.length > 0
            ? true
            : false;
      }
      setState((prev) => ({
        ...prev,
        frequency: newState?.frequency,
        dosageValue: CustomDose ? 0 : prev?.dosageValue,
      }));
    }
  };

  const getErrorValidation = () => {
    let iserror = false;
    if (state.isFormTypeIntravenous) {
      if (!state?.baseSolution || !state?.route) {
        iserror = true;
      }
      // if (!state?.ivForm?.volume?.unit || !state?.ivForm?.volume?.quantity) {
      //   iserror = true;
      // }
      // if (
      //   !state?.ivForm?.duration?.unit ||
      //   !state?.ivForm?.duration?.quantity
      // ) {
      //   iserror = true;
      // }
    } else {
      if (!state.route) {
        iserror = true;
      }
      if (!state?.dosageValue || !state?.dosageUOM) {
        iserror = true;
      }
    }

    if (!state?.requestorDetail) {
      iserror = true;
    }

    if (!state?.frequency) {
      iserror = true;
    }
    if (!state?.durationValue || !state?.durationUOM) {
      iserror = true;
    }
    if (!state?.startDate) {
      iserror = true;
    }
    if (
      // !state?.priority ||
      !state?.drugCategory ||
      !state?.nature ||
      !state?.mode
    ) {
      iserror = true;
    }

    if (!state?.startDate || !state?.indication?.value) {
      iserror = true;
    }

    if (
      !state?.requestorDetail?.organization ||
      !state?.requestorDetail?.practitionerRole ||
      !state?.requestorDetail?.orderBy ||
      !state?.requestorDetail?.practitionerId
    ) {
      iserror = true;
    }
    if (!state.prescriptionType) {
      iserror = true;
    }
    return iserror;
  };

  const getRead = async () => {
    const ismulti = JSON.parse(JSON.stringify(multiOrderData));
    const indexRemove = ismulti[orderIndex];
    const res = await dispatch(
      SINGLE_READ_EDIT_INFO({ key: indexRemove?._id ?? "" })
    );
    const response = res.payload?.data?.[0] ?? {};
    // setEditData(response);
    return response;
  };

  const UpdateMedication = async (load) => {
    let { iserror, msg } = getErrorValidation();
    if (iserror) {
      setError(true);
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: msg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Updating....",
    });

    let isEdit;
    if (load) {
      isEdit = await getRead();
    }

    let payload = createJson(
      state,
      state?.isFormTypeIntravenous,
      editData || isEdit
    );

    // let statusHistory = await getStatusHistory(
    //   state.statusData,
    //   editData,
    //   personData?.[0]?._id,
    //   state
    // );

    let res = await dispatch(
      SAVE_MEDICATIONS({
        ...payload,
        statushistory: [],
        // statushistory: statusHistory,
        orgId:
          (editData || isEdit)?.org_Id?._id ?? props?.organizationId ?? "0",
        patientid: (editData || isEdit)?.patient_Id?._id ?? props?.patientID,
        confirmedby: personData?.[0]?._id ?? "",
        encountertype:
          (editData || isEdit)?.encountertype ?? "CodingMaster/10264",
        createdby: (editData || isEdit)?.createdby?.[0]?._id,
        updatedby: personData?.[0]?._id,
        encounterid: (editData || isEdit)?.encounter_Id?._id ?? "",
        prescripionType: state?.prescriptionType?.value ?? "",
        status: (editData || isEdit)?.status?._id ?? "",
        isEdit: true,
        alertData,
      })
    );

    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });

    if (res?.payload?.data) {
      if (load) {
        await getReedQery();
        initState.requestorDetail = state.requestorDetail;
        initState.dispensingPharmacy = state.dispensingPharmacy;
        initState.practitionerCode = state.practitionerCode;
        setState({ ...initState });
        setOrderIndex(null);
        setEditData(null);
        setAlertData([]);
        setOpenDoseRangeCheck({
          open: false,
          alertData: [],
        });
      } else {
        props.handleMedicationClose();
        props.handleRefreshButtonClick();
      }
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "The Medication cannot be updated",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  const handleSaveAndAnother = (MedicationSlice) => {
    AddMedicationAnother_Backup(MedicationSlice);
  };

  const AddMedicationAnother = async () => {
    setOpenDoseRangeCheck({
      open: false,
      alertData: [],
    });

    let { iserror, msg } = getErrorValidation();

    if (iserror) {
      setError(true);
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: msg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }

    if (!props?.encountertype) {
      setError(true);
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Encounter Type not available for the encounter!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }

    let does_check = doseRangeCheck(
      state,
      props?.patientID,
      props?.encounterID
    );

    let res_dose_check = await dispatch(
      actions.DOSE_RANGE_CHECK({
        json: does_check,
      })
    );

    if (res_dose_check?.payload?.data?.["ALERT-MESSAGE"]?.length > 0) {
      setOpenDoseRangeCheck({
        open: !openDose.openDoseRangeCheck,
        alertData: res_dose_check?.payload?.data ?? [],
      });
      return;
    } else {
      AddMedicationAnother_Backup();
    }

    return;
  };

  const AddMedicationAnother_Backup = async (MedicationSlice) => {
    setOpenDoseRangeCheck({
      open: false,
      alertData: [],
    });

    let { iserror, msg } = getErrorValidation();

    if (iserror) {
      setError(true);
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: msg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }

    if (!props?.encountertype) {
      setError(true);
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Encounter Type not available for the encounter!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }

    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Adding....",
    });

    let payload = await createJson(state, state?.isFormTypeIntravenous);

    delete payload.editId;

    if (MedicationSlice?.length > 0) {
      alertData.push(MedicationSlice?.[0]);
    }

    let res = await dispatch(
      actions.SAVE_MEDICATIONS({
        ...payload,
        orgId: props?.organizationId ?? "0",
        patientid: props?.patientID,
        encounterid: props?.encounterID,
        encountertype: props?.encountertype ?? "CodingMaster/10264",
        status_id: props.statusID,
        isAdd: true,
        prescripionType: state?.prescriptionType?.value ?? "",
        alertData: alertData,
      })
    );

    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });

    if (res?.payload?.data && !res?.payload?.data?.error) {
      getReedQery();
      initState.requestorDetail = state.requestorDetail;
      initState.dispensingPharmacy = state.dispensingPharmacy;
      initState.practitionerCode = state.practitionerCode;
      setState({ ...initState });
      setOrderIndex(null);
      setEditData(null);
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Added the new order!.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "The Medication cannot be save/updated",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
  };

  const setInitialData = async () => {
    Promise.resolve(dispatch(SINGLE_READ_EDIT_INFO({ key: props?.orderId })))
      .then(async (res) => {
        const response = res.payload?.data ?? [];
        setEditData(response?.[0]);
        let data =
          getResponseGenerate(response, options, dateFormat, props)?.[0] ?? {};
        setState(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "fetching data....",
    });
    Promise.resolve(
      dispatch(
        DIAGNOSISAGAINSTENCOUNTER_MASTER({ encounterid: props.encounterID })
      )
      // dispatch(DIAGNOSISAGAINSTENCOUNTER_MASTER({ input_text: "" }))
    );
    // .then((res) => console.log(res))
    // .catch((err) => console.error(err));

    Promise.resolve(
      dispatch(
        DRUGSTOREDISPLOC_MASTER({ encounterTypeId: props.encountertype })
      )
    );
    // .then((res) => console.log(res))
    // .catch((err) => console.error(err));

    if (!props.isAdd) {
      setInitialData();

      if (props?.isUpdateRequstorDetails) {
        if (props?.organization) {
          dispatch(
            ORDER_TYPE_REQ_DETAILS_ROLE({
              id: props?.organization?.id,
            })
          );
        }
        if (props?.practitionerRoleId) {
          dispatch(
            ORDER_TYPE_REQ_DETAILS_LAB({
              id: props?.practitionerRoleId?.id,
              _id: props?.practitionerRoleId?.value,
              orgId: props?.organization?.id,
            })
          );
        }

        //   const states = state.requestorDetail;
        //   states.organization = props?.organization ?? {};

        //   states.practitionerRole = props?.practitionerRoleId ?? {};
        //   states.orderBy = props?.orderBy ?? {}
        //   states.practitionerId = props?.practitionerId ?? "";

        //   state.dispensingPharmacy = props?.locationData ?? {};
        //   state.practitionerCode = props?.practitionerCode ?? null;
        //   state.requestorDetail = states;
        //   setState({
        //     ...state,
        //   });
      }
    } else {
      setState({
        ...initState,
      });
      setOrderIndex(null);
      setAlertData([]);
      setOpenDoseRangeCheck({
        open: false,
        alertData: [],
      });
      getReedQery();

      if (props?.organization) {
        dispatch(
          ORDER_TYPE_REQ_DETAILS_ROLE({
            id: props?.organization?.id,
          })
        );
      }
      if (props?.practitionerRoleId) {
        dispatch(
          ORDER_TYPE_REQ_DETAILS_LAB({
            id: props?.practitionerRoleId?.id,
            _id: props?.practitionerRoleId?.value,
            orgId: props?.organization?.id,
          })
        );
      }

      const states = state.requestorDetail;
      states.organization = props?.organization ?? {};

      states.practitionerRole = props?.practitionerRoleId ?? {};
      states.orderBy = props?.orderBy ?? {};
      states.practitionerId = props?.practitionerId ?? "";

      state.dispensingPharmacy = props?.locationData ?? {};
      state.practitionerCode = props?.practitionerCode ?? null;
      state.requestorDetail = states;

      state.mode =
        localStorage.getItem("role_name") &&
        localStorage.getItem("role_name")?.toLowerCase() === "doctor"
          ? options?.OrderMode_masters?.data?.map((v) => {
              if (
                v?._id === "CodingMaster/10301" ||
                v?.value === "CodingMaster/10301"
              ) {
                return {
                  value: v?._id ?? v?.value ?? "",
                  label:
                    v?.display || v?.coding?.[0]?.display || v?.label || "",
                  id: v?.id,
                };
              }
            })?.[0]
          : {};

      setState({
        ...state,
      });
    }
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });
  }, []);

  const handleSaveAdditiveItem = (item) => {
    const newAdditiveItems = [...state?.additiveItems];
    newAdditiveItems.push(item);
    setState({ ...state, additiveItems: newAdditiveItems });
  };

  const handleUpdateAdditiveItem = (item, index) => {
    const newAdditiveItems = [...state?.additiveItems];
    newAdditiveItems[index] = item;
    setState({ ...state, additiveItems: newAdditiveItems });
  };

  const handleDeleteAdditiveItem = (index) => {
    const newAdditiveItems = [...state?.additiveItems];
    newAdditiveItems.splice(index, 1);
    setState({ ...state, additiveItems: newAdditiveItems });
  };

  const handleAddOrder = () => {
    initState.requestorDetail = state.requestorDetail;
    initState.dispensingPharmacy = state.dispensingPharmacy;
    initState.practitionerCode = state.practitionerCode;
    setState({
      ...initState,
    });
    setOrderIndex(null);
    setAlertData([]);
    setOpenDoseRangeCheck({
      open: false,
      alertData: [],
    });
  };

  const handleDeleteOrder = async (index) => {
    const ismulti = JSON.parse(JSON.stringify(multiOrderData));
    const indexRemove = ismulti[index];

    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Deleting....",
    });

    await dispatch(
      actions.DELETE_NEW_ORDER_PENDING_STATUS({
        // id: 'PH_OrderLine/11230'
        id: indexRemove?._id ?? "",
      })
    );
    getReedQery();
    initState.requestorDetail = state.requestorDetail;
    initState.dispensingPharmacy = state.dispensingPharmacy;
    initState.practitionerCode = state.practitionerCode;
    setState({ ...initState });
    setOrderIndex(null);
    setEditData(null);
    setAlertData([]);
    setOpenDoseRangeCheck({
      open: false,
      alertData: [],
    });
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });
  };

  const handleEditOrder = (data, index) => {
    setOrderIndex(index);
    setState({
      ...data,
    });
  };

  const savebulk = async () => {
    if (!multiOrderData?.length > 0) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please save the atleast one order.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }

    // const IDS = multiOrderData?.map((v) => Object.values(v)?.[0]);
    // const res = await dispatch(
    //   actions.SAVE_NEW_ORDER_PENDING_STATUS({
    //     ids: IDS ?? [],
    //   })
    // );

    let res;
    const IDS = multiOrderData?.map(async (v) => {
      await dispatch(
        (res = actions.BULK_SAVE_MEDICATIONS({
          _key: v?._key ?? "",
          drugCode: v?.drugCode?.value ?? "",
        }))
      );
    });

    if (res?.payload?.data?.error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "ERROR",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "The orders has been added successfully.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      props.handleMedicationClose();
      props.handleRefreshButtonClick();
    }
  };

  const getReedQery = async () => {
    const res = await dispatch(
      actions.READ_PENDING_MEDICATIONS({ pId: props?.patientID ?? "" })
    );
    const response = res?.payload?.data;
    setMultiOrderData(
      (await getResponseGenerate(response, options, dateFormat)) ?? []
    );
  };

  // const d_pract = props?.encounterData?.Practitioner_id?.[0]?.name[0];

  const handleRemark = (k, v, b, p) => {
    // const stateState = JSON.parse(JSON.stringify(state));
    // stateState[k] = v;
    // stateState[b] = false;
    setState({
      ...state,
      [k]: v,
    });
  };

  useEffect(() => {
    dispatch(FREQUENCYMASTER_MASTER());
    dispatch(DURATIONTYPE_MASTER());
    dispatch(INFUSIONRATEUOM_MASTER());
    dispatch(ORGANIZATION_MASTER());
    dispatch(PRACTITIONERROLE_MASTER());
    dispatch(PRACTITIONER_MASTER());
    dispatch(DRUGTYPE_MASTER());
    dispatch(DOSAGEVOLUMEUOM_MASTER());
    dispatch(DRUGROUTES_MASTER());
    dispatch(STRENGTHUOM_MASTER());
    //dispatch(STRENGTHVOLUMNUOM_MASTER());
    dispatch(PRIORITY_MASTER());
    dispatch(DRUGCATEGORY_MASTER());
    // dispatch(ORDERNATURE_MASTER());
    dispatch(ORDERMODE_MASTER());
    //dispatch(REASONCODE_MASTER());
    dispatch(PATIENT_INSTRUCTIONS_MASTER());
    //dispatch(ORDER_PRESCRIPTION_MASTER());
    dispatch(EditNewFrequency_orgbase_frequency_dropdown());
    dispatch(INFUSIONTIMEUOM_MASTER());
  }, []);

  return (
    <div>
      <AddMultiOrderBanner
        patientInfoData={props?.patientInfoData}
        encounterInfoData={props?.encounterInfoData}
        close={props?.close}
      />

      <div className={classes.addNewMedicationPaperDividers}>
        <Grid container spacing={1}>
          {multiOrderData?.length > 0 && (
            <Grid item xs={12}>
              <AddMultiOrder
                data={multiOrderData ?? []}
                handleEdit={handleEditOrder}
                handleDelete={handleDeleteOrder}
                handleAdd={handleAddOrder}
                practitionerId={props?.practitionerId ?? ""}
                // pract={d_pract?.prefix?.[0]?.display + ' ' + d_pract?.text + ' (You)'}
              />
              <br />
            </Grid>
          )}

          {/* Drug Type */}
          <Grid item xs={2}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Drug Type
              </FieldLabel>
              <Autocomplete
                PaperComponent={({ children }) => (
                  <Paper className={classes.dropdown}>{children}</Paper>
                )}
                id={"drugType"}
                getOptionLabel={(option) => option?.["label"] ?? ""}
                options={options1?.drugType_masters?.data ?? []}
                size={"small"}
                placeholder=""
                //disabled
                value={state?.drugType ?? {}}
                onChange={(event, newValue) => {
                  changeState("drugType", newValue);
                  changeState(
                    "isFormTypeIntravenous",
                    newValue?.label === "IV"
                  );
                }}
                className={`${classes.disabledColor}`}
              />
            </FieldContainer>
          </Grid>

          {state?.isFormTypeIntravenous && (
            <Grid item xs={3}>
              <FieldContainer>
                <FieldLabel variant={"body1"} required>
                  Order Description
                </FieldLabel>
                <InputBase
                  id={"orderDescription"}
                  label={""}
                  placeholder={""}
                  type={"text"}
                  variant={"outlined"}
                  size={"small"}
                  fullWidth={true}
                  value={state.orderDescription}
                  onChange={(event) =>
                    changeState("orderDescription", event.target.value)
                  }
                ></InputBase>
              </FieldContainer>
            </Grid>
          )}

          {/* Drug Code & Description */}
          {!state?.isFormTypeIntravenous && (
            <Grid item xs={4}>
              <FieldContainer>
                <FieldLabel variant={"body1"} required>
                  Drug Code & Description
                </FieldLabel>
                <Autocomplete
                  className={classes.dropdown}
                  PaperComponent={({ children }) => (
                    <Paper className={classes.dropdown}>{children}</Paper>
                  )}
                  id={"drugCode"}
                  // onLoad={() =>
                  //   changeState("drugCode", this.state?.drugCode ?? "")
                  // }
                  getOptionLabel={(option) => option?.["label"] ?? ""}
                  options={options?.drugMaster_masters?.data ?? []}
                  size={"small"}
                  placeholder=""
                  value={state?.drugCode ?? {}}
                  onChange={(event, newValue) => handleDrugCodeChange(newValue)}
                  inputValue={state?.drugCodeInputValue ?? ""}
                  onInputChange={(event, newInputValue) => {
                    changeState("drugCodeInputValue", newInputValue);
                    searchDrugMaster(newInputValue);
                  }}
                />
              </FieldContainer>
            </Grid>
          )}

          {/* Dosage Form */}
          {!state.isFormTypeIntravenous && (
            <Grid item xs={2}>
              <FieldContainer>
                <FieldLabel variant={"body1"}>Dosage Form</FieldLabel>
                <InputBase
                  className={`${classes.disabledColor} ${classes.dropdown}`}
                  id={"dosageForm"}
                  label={""}
                  placeholder={""}
                  type={"text"}
                  variant={"outlined"}
                  size={"small"}
                  fullWidth={true}
                  disabled
                  value={state.dosageForm}
                  onChange={(event) =>
                    changeState("dosageForm", event.target.value)
                  }
                ></InputBase>
              </FieldContainer>
            </Grid>
          )}

          {/* Strength - NOT FOR IV */}
          {!state.isFormTypeIntravenous && (
            <Grid item xs={2}>
              <FieldContainer>
                <FieldLabel variant={"body1"}>Strength</FieldLabel>
                <TextfieldWithSelect
                  InputProps={{
                    className: classes.disabledColor,
                    id: `strengthValue`,
                    type: "number",
                    inputProps: {
                      maxlength: 10,
                      min: 0,
                      max: 9999999999,
                    },
                    fullWidth: true,
                    disabled: true,
                    value: state?.strengthValue,
                    onChange: (event) => {
                      changeState("strengthValue", event.target.value);
                    },
                  }}
                  AutocompleteProps={{
                    className: classes.disabledColor,
                    id: `strengthUOM`,
                    onLoad: () => {},
                    getOptionLabel: (option) => option?.["label"] ?? "",
                    options: options1?.DosageVolumeUom_masters?.data ?? [],
                    disabled: false,
                    value: state?.strengthUOM ?? {},
                    onChange: (event, newValue) => {
                      changeState("strengthUOM", newValue);
                    },
                  }}
                />
              </FieldContainer>
            </Grid>
          )}

          {/* PRN */}
          <Grid item xs={1}>
            <FieldContainer style={{ width: "52px" }}>
              <FieldLabel variant={"inherit"}>PRN</FieldLabel>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <Switch
                  //FIXME -
                  disabled
                  checked={state?.prn}
                  onChange={(event) => {
                    changeState(
                      "prn",
                      state?.remarks ? true : event.target.checked
                    );
                  }}
                  {...bindTrigger(popupState)}
                />
                {state?.remarks && (
                  <div
                    {...bindHover(popupStateHoverPRN)}
                    style={{
                      display: "flex",
                      background: "#dee5ec",
                      padding: 6,
                      borderRadius: 6,
                    }}
                  >
                    {" "}
                    <DocumentBlack color={"#01205C"} />
                  </div>
                )}
                <StyledPopover
                  renderHoverPopover
                  {...bindPopover(popupStateHoverPRN)}
                >
                  <div style={{ padding: 20, minHeight: 40 }}>
                    {state?.remarks ?? ""}
                  </div>
                </StyledPopover>
              </div>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                classes={{
                  paper: classes.prnPopoverPaper,
                }}
                onClose={() => null}
              >
                <RemarksForm
                  value={state?.remarks}
                  setValue={(v) => {
                    setState({ ...state, prn: true });
                    handleRemark("remarks", v, "prn");
                    popupState.close();
                  }}
                  handleClose={() => {
                    setState({ ...state, prn: false, remarks: "" });
                    popupState.close();
                  }}
                />
              </Popover>
            </FieldContainer>
          </Grid>

          {/* SSD */}
          <Grid item xs={1}>
            <FieldContainer style={{ width: "52px" }}>
              <FieldLabel variant={"inherit"}>Sliding&nbsp;Scale</FieldLabel>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <Switch
                  //FIXME -
                  disabled
                  checked={state?.SlidingScaleDose_prn}
                  onChange={(event) => {
                    changeState(
                      "SlidingScaleDose_prn",
                      state?.SlidingScaleDose ? true : event.target.checked
                    );
                  }}
                  {...bindTrigger(popupStateSlide)}
                />
                {state?.SlidingScaleDose && (
                  <div
                    {...bindHover(popupStateHoverScale)}
                    style={{
                      display: "flex",
                      background: "#dee5ec",
                      padding: 6,
                      borderRadius: 6,
                    }}
                  >
                    <DocumentBlack color={"#01205C"} />
                  </div>
                )}
                <StyledPopover
                  renderHoverPopover
                  {...bindPopover(popupStateHoverScale)}
                >
                  <div style={{ padding: 20, minHeight: 40 }}>
                    {state?.SlidingScaleDose ?? ""}
                  </div>
                </StyledPopover>
              </div>
              <Popover
                {...bindPopover(popupStateSlide)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                classes={{
                  paper: classes.prnPopoverPaper,
                }}
                onClose={() => null}
              >
                <RemarksForm
                  name={"Sliding Scale Dose"}
                  value={state?.SlidingScaleDose}
                  setValue={(v) => {
                    setState({ ...state, SlidingScaleDose_prn: true });
                    handleRemark("SlidingScaleDose", v, "SlidingScaleDose_prn");
                    popupStateSlide.close();
                  }}
                  handleClose={() => {
                    setState({
                      ...state,
                      SlidingScaleDose_prn: false,
                      SlidingScaleDose: "",
                    });
                    popupStateSlide.close();
                  }}
                />
              </Popover>
            </FieldContainer>
          </Grid>

          {/* ----------------------------------------------------------------------------------------- */}
          {/* Route */}

          <Grid item xs={2}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Route
              </FieldLabel>
              <Autocomplete
                //FIXME -
                disabled
                className={classes.dropdown}
                PaperComponent={({ children }) => (
                  <Paper className={classes.dropdown}>{children}</Paper>
                )}
                id={"route"}
                // onLoad={() =>
                //   changeState("drugType", this.state?.drugType ?? "")
                // }
                getOptionLabel={(option) => option?.["label"] ?? ""}
                options={options1?.DrugRoutes_masters?.data ?? []}
                size={"small"}
                placeholder=""
                value={state?.route ?? {}}
                onChange={(event, newValue) => changeState("route", newValue)}
              />
            </FieldContainer>
          </Grid>

          {/* Dosage */}
          {!state.isFormTypeIntravenous && (
            <Grid item xs={2}>
              <FieldContainer>
                <FieldLabel variant={"body1"} required>
                  Dosage
                </FieldLabel>
                <TextfieldWithSelect
                  InputProps={{
                    id: `dosageValue`,
                    type: "number",
                    inputProps: {
                      maxlength: 10,
                      min: 0,
                      max: 9999999999,
                    },
                    // fullWidth: true,
                    value: state?.dosageValue,
                    onChange: (event) => {
                      changeState("dosageValue", event.target.value);
                    },
                  }}
                  AutocompleteProps={{
                    id: `dosageUOM`,
                    onLoad: () => {},
                    getOptionLabel: (option) => option?.["label"] ?? "",
                    options: options1?.DosageVolumeUom_masters?.data ?? [],
                    value: state?.dosageUOM ?? {},
                    onChange: (event, newValue) => {
                      changeState("dosageUOM", newValue);
                    },
                  }}
                />
              </FieldContainer>
            </Grid>
          )}

          {/* Base Solution - IV ONLY */}
          {state?.isFormTypeIntravenous && (
            <Grid item xs={3}>
              <FieldContainer>
                <FieldLabel variant={"body1"} required>
                  Base Solution
                </FieldLabel>
                <Autocomplete
                  PaperComponent={({ children }) => (
                    <Paper className={classes.dropdown}>{children}</Paper>
                  )}
                  id={"baseSolution"}
                  // onLoad={() =>
                  //   changeState("baseSolution", this.state?.baseSolution ?? "")
                  // }
                  getOptionLabel={(option) => option?.["label"] ?? ""}
                  options={options?.drugMaster_masters?.data ?? []}
                  size={"small"}
                  placeholder=""
                  value={state?.baseSolution ?? {}}
                  onChange={(event, newValue) =>
                    handleBaseSolutionChange(newValue)
                  }
                  inputValue={state?.baseSolutionInputValue || ""}
                  onInputChange={(event, newInputValue) => {
                    changeState("baseSolutionInputValue", newInputValue);
                    searchBaseSolution(newInputValue);
                  }}
                />
              </FieldContainer>
            </Grid>
          )}

          {/* Strength - IV ONLY */}
          {state?.isFormTypeIntravenous && (
            <Grid item xs={2}>
              <FieldContainer>
                <FieldLabel variant={"body1"}>Strength</FieldLabel>
                <InputBase
                  id={"ivStrength"}
                  label={""}
                  placeholder={""}
                  type={"text"}
                  variant={"outlined"}
                  size={"small"}
                  fullWidth={true}
                  disabled
                  className={classes.disabledColor}
                  value={state.ivStrength}
                  onChange={(event) =>
                    changeState("ivStrength", event.target.value)
                  }
                ></InputBase>
              </FieldContainer>
            </Grid>
          )}

          {/* Volume - IV ONLY */}
          {state?.isFormTypeIntravenous && (
            <Grid item xs={2}>
              <FieldContainer>
                <FieldLabel variant={"body1"} required>
                  Volume
                </FieldLabel>
                <TextfieldWithSelect
                  InputProps={{
                    id: `ivVolumeValue`,
                    type: "number",
                    inputProps: {
                      maxlength: 10,
                      min: 0,
                      max: 9999999999,
                    },
                    fullWidth: true,
                    value: state?.ivVolumeValue,
                    onChange: (event) => {
                      changeState("ivVolumeValue", event.target.value);
                    },
                  }}
                  AutocompleteProps={{
                    id: `ivVolumeUOM`,
                    onLoad: () => {},
                    getOptionLabel: (option) => option?.["label"] ?? "",
                    options: options?.strengthUom_masters?.data ?? [],
                    value: state?.ivVolumeUOM ?? {},
                    onChange: (event, newValue) => {
                      changeState("ivVolumeUOM", newValue);
                    },
                  }}
                />
              </FieldContainer>
            </Grid>
          )}

          {/* Infusion Rate - IV ONLY */}
          {state?.isFormTypeIntravenous && (
            <Grid item xs={2}>
              <FieldContainer>
                <FieldLabel variant={"body1"} required>
                  Infusion Rate
                </FieldLabel>
                <TextfieldWithSelect
                  InputProps={{
                    id: `infusionRateValue`,
                    type: "number",
                    inputProps: {
                      maxlength: 10,
                      min: 0,
                      max: 9999999999,
                    },
                    fullWidth: true,
                    value: state?.infusionRateValue,
                    onChange: (event) => {
                      changeState("infusionRateValue", event.target.value);
                    },
                  }}
                  AutocompleteProps={{
                    id: `infusionRateUOM`,
                    onLoad: () => {},
                    getOptionLabel: (option) => option?.["label"] ?? "",
                    options: options?.InfusionRateUoM_masters?.data ?? [],
                    value: state?.infusionRateUOM ?? {},
                    onChange: (event, newValue) => {
                      changeState("infusionRateUOM", newValue);
                    },
                  }}
                />
              </FieldContainer>
            </Grid>
          )}

          {/* Duration - IV ONLY */}
          {state?.isFormTypeIntravenous && (
            <Grid item xs={2}>
              <FieldContainer>
                <FieldLabel variant={"body1"} required>
                  Infuse Over Duration
                </FieldLabel>
                <TextfieldWithSelect
                  InputProps={{
                    id: `ivInfusionTime`,
                    type: "number",
                    inputProps: {
                      maxlength: 10,
                      min: 0,
                      max: 9999999999,
                    },
                    fullWidth: true,
                    value: state?.ivInfusionTime,
                    onChange: (event) => {
                      changeState("ivInfusionTime", event.target.value);
                    },
                  }}
                  AutocompleteProps={{
                    id: `ivInfusionTimeUom`,
                    onLoad: () => {},
                    getOptionLabel: (option) => option?.["label"] ?? "",
                    options: options?.InfusionTimeUoM_masters?.data ?? [],
                    value: state?.ivInfusionTimeUom ?? {},
                    onChange: (event, newValue) => {
                      changeState("ivInfusionTimeUom", newValue);
                    },
                  }}
                />
              </FieldContainer>
            </Grid>
          )}

          {/* Total Volume - IV ONLY */}
          {state?.isFormTypeIntravenous && (
            <Grid item xs={2}>
              <FieldContainer>
                <FieldLabel variant={"body1"} required>
                  Total Volume
                </FieldLabel>
                <TextfieldWithSelect
                  InputProps={{
                    id: `totalVolumeValue`,
                    type: "number",
                    inputProps: {
                      maxlength: 10,
                      min: 0,
                      max: 9999999999,
                    },
                    fullWidth: true,
                    value: state?.totalVolumeValue,
                    onChange: (event) => {
                      changeState("totalVolumeValue", event.target.value);
                    },
                  }}
                  AutocompleteProps={{
                    id: `totalVolumeUOM`,
                    onLoad: () => {},
                    getOptionLabel: (option) => option?.["label"] ?? "",
                    // options: options?.strengthVolumnUom_masters?.data ?? [],
                    options: options?.DosageVolumeUom_masters?.data ?? [],
                    value: state?.totalVolumeUOM ?? {},
                    onChange: (event, newValue) => {
                      changeState("totalVolumeUOM", newValue);
                    },
                  }}
                />
              </FieldContainer>
            </Grid>
          )}

          {/* Frequency */}
          <Grid item xs={2}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Frequency
              </FieldLabel>
              <div
                className={state?.frequency ? classes.frequencyContainer : ""}
              >
                <Autocomplete
                  className={classes.dropdown}
                  style={{ width: "100%" }}
                  PaperComponent={({ children }) => (
                    <Paper className={classes.dropdown}>{children}</Paper>
                  )}
                  id={"frequency"}
                  // onLoad={() =>
                  //   changeState("drugType", this.state?.drugType ?? "")
                  // }
                  getOptionLabel={(option) => option?.["label"] ?? ""}
                  options={options?.FrequencyMaster_masters?.data ?? []}
                  size={"small"}
                  placeholder=""
                  value={state?.frequency ?? {}}
                  onChange={(event, newValue) =>
                    handleFrequencyChange("frequency", newValue)
                  }
                />
                {state?.frequency && (
                  <IconButton
                    size="small"
                    // style={{ minWidth: "40px" }}
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        isFrequencyScheduleLineOpen:
                          !prev.isFrequencyScheduleLineOpen,
                      }))
                    }
                  >
                    {/* <InfoOutlinedIcon fontSize="small" /> */}
                    {/* <img
                      style={{ width: "30px", margin: "-3px" }}
                      src={FreqIcon}
                    /> */}
                    <FreqIcon
                      style={{
                        width: "28px",
                        height: "auto",
                      }}
                    />
                  </IconButton>
                )}
              </div>
            </FieldContainer>
          </Grid>

          {/* Additives - IV ONLY */}
          {state?.isFormTypeIntravenous && (
            <Grid item xs={12}>
              <Additive
                additive={state?.additive}
                onInputChange={(event, newInputValue) => {
                  changeState("additive", newInputValue);
                  searchBaseSolution(newInputValue);
                }}
                additiveItems={state?.additiveItems}
                handleSaveAdditiveItem={handleSaveAdditiveItem}
                handleUpdateAdditiveItem={handleUpdateAdditiveItem}
                handleDeleteAdditiveItem={handleDeleteAdditiveItem}
              />
            </Grid>
          )}

          {/* Duration */}
          <Grid item xs={2}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Duration
              </FieldLabel>
              <TextfieldWithSelect
                InputProps={{
                  id: `durationValue`,
                  type: "number",
                  inputProps: {
                    maxlength: 10,
                    min: 0,
                    max: 9999999999,
                  },
                  // fullWidth: true,
                  value: state?.durationValue,
                  onChange: (event) => {
                    changeState("durationValue", event.target.value);
                  },
                  disabled: isFrequencyCodeSTAT(),
                }}
                AutocompleteProps={{
                  id: `durationUOM`,
                  onLoad: () => {},
                  getOptionLabel: (option) => option?.["label"] ?? "",
                  options: options?.DurationType_masters?.data ?? [],
                  value: state?.durationUOM ?? {},
                  onChange: (event, newValue) => {
                    handleDurationUOMChange("durationUOM", newValue);
                  },
                  disabled: isFrequencyCodeSTAT(),
                }}
              />
            </FieldContainer>
          </Grid>

          {/* Start Date */}
          <Grid item xs={2}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Start Date
              </FieldLabel>
              <DateTimePicker
                //FIXME -
                disabled
                required={true}
                label={""}
                placeholder={getFormatForDateTimePicker("date")}
                //dateFormat={dateFormat}
                onChange={(value) => {
                  handleStartDateChange("startDate", value);
                }}
                value={state?.startDate ?? null}
                className={classes.disabledColor}

                //FIXME - disabled={isFrequencyCodeSTAT()}
              />
            </FieldContainer>
          </Grid>

          {/* End Date */}
          <Grid item xs={2}>
            <FieldContainer>
              <FieldLabel variant={"body1"}>End Date</FieldLabel>
              <DateTimePicker
                required={true}
                label={""}
                placeholder={getFormatForDateTimePicker("date")}
                // dateFormat={dateFormat}
                onChange={(value) => {
                  handleEndDateChange("endDate", value);
                }}
                className={classes.disabledColor}
                disabled
                value={state?.endDate ?? null}
                // maxDate={state?.efftodate ?? null}
              />
            </FieldContainer>
          </Grid>

          {/* ----------------------------------------------------------------------------------------- */}
          {state?.frequency && state?.isFrequencyScheduleLineOpen && (
            // <Grid item xs={12}>
            <Popover
              open={state?.isFrequencyScheduleLineOpen}
              onClose={() =>
                setState((prev) => ({
                  ...prev,
                  isFrequencyScheduleLineOpen:
                    !prev.isFrequencyScheduleLineOpen,
                }))
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              classes={{
                paper: classes.prnPopoverPaper,
              }}
            >
              <ScheduleLine
                handleScheduleLineChange={handleScheduleLineChange}
                handleScheduleDelete={handleScheduleDelete}
                type={state?.frequency?.type}
                data={state?.frequency}
                styles={scheduleLineStyles}
              />
            </Popover>
            // </Grid>
          )}

          {/* ----------------------------------------------------------------------------------------- */}

          {/* Priority */}
          {/* <Grid item xs={2}>
          <FieldContainer>
            <FieldLabel variant={"body1"} required>
              Priority
            </FieldLabel>
            <Autocomplete
              className={classes.dropdown}
              PaperComponent={({ children }) => (
                <Paper className={classes.dropdown}>{children}</Paper>
              )}
              id={"priority"}
              // onLoad={() =>
              //   changeState("drugType", this.state?.drugType ?? "")
              // }
              getOptionLabel={(option) => option?.["label"]|| ""}
              options={options?.Priority_masters?.data ?? []}
              size={"small"}
              placeholder=""
              value={state?.priority ?? null}
              onChange={(event, newValue) => changeState("priority", newValue)}
            />
          </FieldContainer>
        </Grid> */}

          {/* Drug Category */}
          <Grid item xs={2}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Drug Category
              </FieldLabel>
              <Autocomplete
                className={`${classes.disabledColor}`}
                PaperComponent={({ children }) => (
                  <Paper className={classes.dropdown}>{children}</Paper>
                )}
                disabled
                id={"drugCategory"}
                // onLoad={() =>
                //   changeState("drugType", this.state?.drugType ?? "")
                // }
                getOptionLabel={(option) => option?.["label"] ?? ""}
                options={options?.DrugCategory_masters?.data ?? []}
                size={"small"}
                placeholder=""
                value={state?.drugCategory ?? {}}
                onChange={(event, newValue) =>
                  changeState("drugCategory", newValue)
                }
              />
            </FieldContainer>
          </Grid>

          {/* Dispensing Pharmacy */}
          <Grid
            item
            xs={
              // state?.isFormTypeIntravenous ? 3 :
              4
            }
          >
            <FieldContainer>
              <FieldLabel variant={"body1"}>Dispensing Pharmacy</FieldLabel>
              <Autocomplete
                className={classes.dropdown}
                //FIXME -
                disabled
                PaperComponent={({ children }) => (
                  <Paper className={classes.dropdown}>{children}</Paper>
                )}
                id={"dispensingPharmacy"}
                // onLoad={() =>
                //   changeState("drugType", this.state?.drugType ?? "")
                // }
                getOptionLabel={(option) => option?.["label"] ?? ""}
                options={
                  Array.isArray(options?.DrugStoreDispLoc_masters?.data)
                    ? options?.DrugStoreDispLoc_masters?.data
                    : []
                }
                size={"small"}
                placeholder=""
                value={state?.dispensingPharmacy ?? {}}
                onChange={(event, newValue) =>
                  changeState("dispensingPharmacy", newValue)
                }
              />
            </FieldContainer>
          </Grid>

          {/* Notes */}
          <Grid item xs={3}>
            <FieldContainer>
              <FieldLabel variant={"inherit"}>Notes</FieldLabel>
              <InputBase
                disabled
                className={classes.dropdown}
                id={"notes"}
                label={""}
                placeholder={""}
                type={"text"}
                variant={"outlined"}
                size={"small"}
                fullWidth={true}
                value={state.notes}
                onChange={(event) => changeState("notes", event.target.value)}
              ></InputBase>
            </FieldContainer>
          </Grid>

          {/* Indication (Reason) */}
          <Grid item xs={3}>
            <FieldContainer>
              <FieldLabel variant={"body1"}>
                Indication (Reason)&nbsp;<span style={{ color: "red" }}>*</span>
              </FieldLabel>
              <Autocomplete
                className={classes.dropdown}
                PaperComponent={({ children }) => (
                  <Paper className={classes.dropdown}>{children}</Paper>
                )}
                id={"indication"}
                // onLoad={() =>
                //   changeState("drugType", this.state?.drugType ?? "")
                // }
                getOptionLabel={(option) => option?.["label"] ?? ""}
                //options={options?.Reasoncode_masters?.data ?? []}
                options={FlterOptions3("INDICATIONREASON") ?? []}
                size={"small"}
                placeholder=""
                value={state?.indication ?? {}}
                disabled
                onChange={(event, newValue) =>
                  changeState("indication", newValue)
                }
              />
            </FieldContainer>
          </Grid>

          {/* Nature */}
          <Grid item xs={state?.isFormTypeIntravenous ? 3 : 2}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Nature
              </FieldLabel>
              <Autocomplete
                disabled
                className={classes.dropdown}
                PaperComponent={({ children }) => (
                  <Paper className={classes.dropdown}>{children}</Paper>
                )}
                id={"nature"}
                // onLoad={() =>
                //   changeState("drugType", this.state?.drugType ?? "")
                // }
                getOptionLabel={(option) => option?.["label"] ?? ""}
                // options={options?.OrderNature_masters?.data ?? []}
                options={FlterOptions3("ORDERNATURE") ?? []}
                size={"small"}
                placeholder=""
                value={state?.nature ?? {}}
                onChange={(event, newValue) => changeState("nature", newValue)}
              />
            </FieldContainer>
          </Grid>

          {/* Mode */}
          <Grid item xs={state?.isFormTypeIntravenous ? 3 : 2}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Mode
              </FieldLabel>
              <Autocomplete
                disabled
                className={classes.dropdown}
                PaperComponent={({ children }) => (
                  <Paper className={classes.dropdown}>{children}</Paper>
                )}
                id={"mode"}
                // onLoad={() =>
                //   changeState("drugType", this.state?.drugType ?? "")
                // }
                getOptionLabel={(option) => option?.["label"] ?? ""}
                options={options?.OrderMode_masters?.data ?? []}
                size={"small"}
                placeholder=""
                value={state?.mode ?? {}}
                onChange={(event, newValue) => changeState("mode", newValue)}
              />
            </FieldContainer>
          </Grid>

          {/* ----------------------------------------------------------------------------------------- */}
          {/* Prescription Type */}
          <Grid item xs={3}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Prescription Type
              </FieldLabel>
              <Autocomplete
                //FIXME -
                disabled
                className={classes.dropdown}
                PaperComponent={({ children }) => (
                  <Paper className={classes.dropdown}>{children}</Paper>
                )}
                id={"prescriptionType"}
                // onLoad={() =>
                //   changeState("drugType", this.state?.drugType ?? "")
                // }
                getOptionLabel={(option) => option?.["label"] ?? ""}
                //options={options?.order_prescription_list?.data ?? []}
                options={FlterOptions3("PRESCRIPTIONTYPE") ?? []}
                size={"small"}
                placeholder=""
                value={state?.prescriptionType ?? {}}
                onChange={(event, newValue) =>
                  changeState("prescriptionType", newValue)
                }
              />
            </FieldContainer>
          </Grid>

          {/* ----------------------------------------------------------------------------------------- */}
          {/* Select Diagnosis */}
          <Grid item xs={5}>
            <FieldContainer>
              <FieldLabel variant={"body1"}>Select Diagnosis</FieldLabel>
              {/* 
            <Autocomplete
              PaperComponent={({ children }) => (
                <Paper className={classes.dropdown}>{children}</Paper>
              )}
              // Controllers
              getOptionLabel={(option) => option?.["label"]|| ""}
              options={options?.DiagnosisAgainstEncounter_masters?.data ?? []}
              multiple
            //   className={classNames(
            //     classes.removeGutterRight,
            //     classes.multiAutoComplete
            //   )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option?.label}
                    deleteIcon={<CloseIcon htmlColor="#6A7888" />}
                    {...getTagProps({ index })}
                  />
                ))
              }
              value={statevalue?.alloweddisptype}
              onLoad={() =>
                setFun(
                  stateName,
                  "alloweddisptype",
                  statevalue?.alloweddisptype
                )
              }
              onChange={(event, newValue) =>
                setFun(stateName, "alloweddisptype", newValue)
              }
            />
            <Autocomplete
              PaperComponent={({ children }) => (
                <Paper className={classes.dropdown}>{children}</Paper>
              )}
              placeholder={"Select diagnosis"}
              getOptionLabel={(option) => option?.["name"]}
              options={options?.DiagnosisAgainstEncounter_masters?.data ?? []}
              multiple
              limitTags={2}
              size="small"
              required
              value={state?.selectDiagnosis ?? null}
              onChange={(event, newValue) => {
                changeState("selectDiagnosis", newValue);
              }}
            /> */}
              <Autocomplete
                //FIXME -
                disabled
                className={classes.dropdown}
                PaperComponent={({ children }) => (
                  <Paper className={classes.dropdown}>{children}</Paper>
                )}
                id={"selectDiagnosis"}
                // onLoad={() =>
                //   changeState("drugType", this.state?.drugType ?? "")
                // }
                getOptionLabel={(option) => option?.["label"] ?? ""}
                options={options?.DiagnosisAgainstEncounter_masters?.data ?? []}
                size={"small"}
                placeholder=""
                multiple
                value={state?.selectDiagnosis ?? {}}
                onChange={(event, newValue) =>
                  changeState("selectDiagnosis", newValue)
                }
              />
            </FieldContainer>
          </Grid>

          {/* ----------------------------------------------------------------------------------------- */}
          {/* Patient Instructions */}
          <Grid item xs={state?.isFormTypeIntravenous ? 4 : 5}>
            <FieldContainer>
              <FieldLabel variant={"body1"}>Patient Instructions</FieldLabel>
              <div className={classes.patientInstruction}>
                <Autocomplete
                  //FIXME -
                  disabled
                  className={classes.dropdown}
                  PaperComponent={({ children }) => (
                    <Paper className={classes.dropdown}>{children}</Paper>
                  )}
                  id={"patientInstruction"}
                  // onLoad={() =>
                  //   changeState("drugType", this.state?.drugType ?? "")
                  // }
                  getOptionLabel={(option) => option?.["label"] ?? ""}
                  options={options?.Patient_Instruction?.data ?? []}
                  size={"small"}
                  placeholder=""
                  value={state?.patientInstruction ?? {}}
                  onChange={(event, newValue) =>
                    changeState("patientInstruction", newValue)
                  }
                />
                {
                  //state?.patientInstruction
                  false && (
                    <InputBase
                      className={classes.dropdown}
                      id={"patientInstructionValue"}
                      label={""}
                      placeholder={""}
                      type={"text"}
                      variant={"outlined"}
                      size={"small"}
                      fullWidth={true}
                      maxRows={4}
                      multiline
                      value={state.patientInstructionValue}
                      onChange={(event) =>
                        changeState(
                          "patientInstructionValue",
                          event.target.value
                        )
                      }
                    ></InputBase>
                  )
                }
              </div>
            </FieldContainer>
          </Grid>

          {/* <Grid item xs={2}>
          <FieldLabel variant={"body1"}> First Stat Order</FieldLabel>
         
          <StyledSwitch
            checked={state?.isfirststatorder}
            onChange={(event) =>
              changeState("isfirststatorder", event.target.checked)
            }
          />
        </Grid> */}

          {/* ----------------------------------------------------------------------------------------- */}
          <Grid item xs={12}>
            {
              // (state?.requestorDetail?.organization || state?.requestorDetail?.practitionerRole || state?.requestorDetail?.orderBy || state?.requestorDetail?.practitionerId)
              // &&
              <RequestorDetailsForm
                isAdd={props.isAdd}
                ismulti={multiOrderData}
                requestorDetail={state.requestorDetail}
                onSave={handleRequestorDetailsSave}
                updateState={state}
                updatesetState={setState}
                practitionerCode={state?.practitionerCode}
              />
            }
          </Grid>

          {/* ----------------------------------------------------------------------------------------- */}
          <Grid item xs={12}>
            <div className={classes.ctaWrapper}>
              <div className={classes.ctaContainer}>
                {orderIndex >= 0 && orderIndex !== null && (
                  <CustomButton
                    variant={"outlined"}
                    color="#01205c"
                    onClick={() => {
                      getReedQery();
                      initState.requestorDetail = state.requestorDetail;
                      initState.dispensingPharmacy = state.dispensingPharmacy;
                      initState.practitionerCode = state.practitionerCode;
                      setState({
                        ...initState,
                      });
                      setOrderIndex(null);
                      setEditData(null);
                    }}
                  >
                    Cancel
                  </CustomButton>
                )}
                <CustomButton
                  variant={"contained"}
                  color="#FFFFFF"
                  backgroundColor="#01205c"
                  onClick={() => {
                    props?.close();
                  }}
                >
                  Cancel
                </CustomButton>
                {orderIndex === null && props.isAdd && (
                  <CustomButton
                    variant={"contained"}
                    // color="#FFFFFF"
                    // backgroundColor="#01205c"

                    color="#01205c"
                    backgroundColor="#DEE5EC"
                    onClick={() => AddMedicationAnother()}
                  >
                    Save & Add Another
                  </CustomButton>
                )}

                {props.isAdd && (
                  <CustomButton
                    variant={"contained"}
                    color="#FFFFFF"
                    backgroundColor="#01205c"
                    onClick={
                      orderIndex >= 0 && orderIndex !== null
                        ? () => UpdateMedication(true)
                        : props?.isAdd
                        ? () => savebulk()
                        : () => UpdateMedication()
                    }
                  >
                    {orderIndex >= 0 && orderIndex !== null ? "Update" : "Save"}
                  </CustomButton>
                )}

                {!props.isAdd && (
                  <CustomButton
                    variant={"contained"}
                    color="#FFFFFF"
                    backgroundColor="#01205c"
                    onClick={() => UpdateMedication()}
                  >
                    Update
                  </CustomButton>
                )}
              </div>
            </div>
          </Grid>
        </Grid>

        {/* Drug interaction and alert dialoge */}
        <AlertDialog
          alertDialogOpen={alertDialogOpen || openDose.open}
          handleCloseAlertDialog={handleCloseAlertDialog}
          isHide={false}
          selectedState={true}
          alertData={openDose?.alertData ?? []}
          handleChangeDose={handleChangeDose}
          handleSaveAndAnother={handleSaveAndAnother}
        />
      </div>
    </div>
  );
}

export default withAllContexts(MedicationInfoForm);
