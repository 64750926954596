import React, { useEffect } from "react";
import {
  makeStyles,
  Typography,
  Card,
  CardContent,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch } from "react-redux";
// import { actions } from "frequencyscreen_v_dbinder";
import useStyles from "./styles";
import actions from "../../redux/actions";

const initialOptions = {
  enterprisesList: [],
  organisationList: [],
  facilityList: [],
  locationList: [],
};
const initialState = {
  enterprise: null,
  organisation: null,
  facility: null,
  location: null,
};

const LocationRoles = (props) => {
  const classes = useStyles();
  // const { practitonerDetails } = props;
  const [practitionerCode, setPractitionerCode] = React.useState(null);
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = React.useState(null);
  const [optionsData, setOptionsData] = React.useState(initialOptions);
  const [state, setState] = React.useState(initialState);
  const [enterprisesList, setEnterprisesList] = React.useState([]);
  const [organisationList, setOrganisationList] = React.useState([]);
  const [facilityList, setFacilityList] = React.useState([]);
  const [locationLists, setLocationLists] = React.useState([]);
  const [roleLists, setRoleLists] = React.useState([]);
  const [practitonerDetails, setPractitonerDetails] = React.useState([]);
  const [practitioner_id, setPractitioner_id] = React.useState("");
  const [org_id, setOrg_id] = React.useState("");
  const [indexNo, setIndexNo] = React.useState(0);

// change this function to redux below
  // const fetchOptions = async (dropdownType, id, queryType) => {
  //   const enterpriseList = await dispatch(
  //     actions.GET_ORGANIZATION_NAME({
  //       organizationId: id,
  //     })
  //   );
  //   let mappedData = enterpriseList?.payload?.data?.map((item) => {
  //     return {
  //       _id: item._id,
  //       orgName: item.orgname,
  //       orgType: item.OrgType,
  //       parentOrg: {
  //         orgName: item.ParentOrgID.orgname,
  //         orgType: item.ParentOrgID.OrgType,
  //       },
  //       parentOfParent: item.Parentofparent,
  //     };
  //   });
  //   if (dropdownType === "enterprise") {
  //     setEnterprisesList(mappedData?.[0]?.parentOfParent);
  //     setOrg_id(mappedData?.[0]?._id);
  //   } else if (dropdownType === "organisation") {
  //     // if (enterprisesList) {
  //     setOrganisationList(mappedData?.[0]?.parentOrg);
  //     // }
  //   } else if (dropdownType === "facility") {
  //     setFacilityList(mappedData?.[0]?.orgType);
  //   } else if (dropdownType === "location") {
  //     const locationListres = await dispatch(
  //       actions.GET_LOCATIONS_NAME({
  //         organizationId: practitioner_id || id || mappedData?.[0]?._id,
  //       })
  //     );
  //     setLocationLists(locationListres?.payload?.data);
  //   }
  // };

  const handleDropdown = (data, dropdownType, statics) => {
    if (!data) {
      let stateData = state;
      let options = optionsData;
      statics.forEach((item) => {
        stateData[item] = null;
        options[item] = [];
      });
      setState(stateData);
      setOptionsData(options);
      // }
    } else {
      //before role base facility now this not need
      // if (dropdownType === "organisation") {
      //   fetchOptions("facility", data.id);
      //   // } else if (dropdownType === "facility") {
      //   fetchOptions("location", data.id);
      // }
      if (dropdownType === "facility") {
        setRoleLists([])
        setEnterprisesList([]);
        setOrg_id([]);
        setOrganisationList([]);
        setLocationLists([])
        getOrg(data.id);
        getRolelist(practitioner_id, data.id);
        getlocationlist(data.id)
      }
    }

    setState({
      ...state,
      [dropdownType]: data,
    });
  };

  const getRole = async () => {
    const response = await dispatch(
      actions.GET_PRACTITIONER_ROLE({ userId: props?.practitonerId })
    );
    setPractitonerDetails(response?.payload?.data?.[0]?.practitioner_role);
  };

  //get organization
  const getOrg = async (facilityId) => {
    const enterpriseList = await dispatch(
      actions.GET_ORGANIZATION_NAME({
        organizationId: facilityId,
      })
    );
    let mappedData = enterpriseList?.payload?.data?.map((item) => {
      return {
        _id: item._id,
        orgName: item.orgname,
        orgType: item.OrgType,
        parentOrg: {
          orgName: item.ParentOrgID.orgname,
          orgType: item.ParentOrgID.OrgType,
        },
        parentOfParent: item.Parentofparent,
      };
    });
    setEnterprisesList(mappedData?.[0]?.parentOfParent);
    setOrg_id(mappedData?.[0]?._id);
    setOrganisationList(mappedData?.[0]?.parentOrg);
  };

  //get role
  const getRolelist = async (practid, orgid) => {
    const roleListres = await dispatch(
      actions.GET_ROLE_NAME({
        practid: practid,
        orgid: orgid,
      })
    );
    setRoleLists(roleListres?.payload?.data);
  };
//get facility
  const getfacilitylist = async (emailId) => {
    const facilityListres = await dispatch(
      actions.GET_USER_FACILITY({
        emailId: emailId,
      })
    );
    setFacilityList(facilityListres?.payload?.data?.[0]?.org);
    setPractitioner_id(facilityListres?.payload?.data?.[0]?.practid);
  };
  // get locations
  const getlocationlist = async (Id) => {
      const locationListres = await dispatch(
        actions.GET_LOCATIONS_NAME({
          organizationId: Id,
        })
      );
      setLocationLists(locationListres?.payload?.data);
    }
  React.useEffect(() => {
    if (
      indexNo !== selectedRole &&
      practitonerDetails &&
      practitonerDetails.length > 0
    ) {
      handleRole(0);
    }
  }, [practitonerDetails, indexNo, selectedRole]);
  React.useEffect(() => {
    // getRole();
    getfacilitylist(props?.practitonerId);
  }, [props?.practitonerId]);

  const handleRole = (index) => {
    let practitionerId = practitonerDetails?.[index]?.entityname?._id;
    // setPractitioner_id(practitionerId);
    // fetchOptions("enterprise", practitionerId);
    // fetchOptions("organisation", practitionerId, 1);
    // fetchOptions("location", practitionerId);
    // fetchOptions("facility", practitionerId, indexNo);
    // setState(initialState);
    // setOptionsData(initialOptions);
    setSelectedRole(index);
  };

  const GetIdAndDisplay2 = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        name: item?.orgname,
        //name: item?.OrgType?.display,
        id: item?.OrgType?._id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const filterOptions = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        name: item?.locationname, //item.OrgType?.display
        id: item?.id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const GetIdAndDisplay = (data) => {
    return [
      {
        name: data?.orgName,
        //name: data?.orgType?.display,
        id: data?.orgType?._id,
      },
    ];
  };
  const GetIdAndDisplay1 = (data) => {
    return [
      {
        name: data?.display,
        id: data?._id,
      },
    ];
  };
  const filterOption = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        name: item.display,
        id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };
  // useEffect(() => {
  //   const defaultValues = {
  //     enterprise: enterprisesList && enterprisesList.length > 0
  //       ? GetIdAndDisplay2(enterprisesList)[0]
  //       : null,
  //     organisation: GetIdAndDisplay(organisationList)[0],
  //     facility: GetIdAndDisplay1(facilityList)[0],
  //     location: locationList && locationList.length > 0
  //       ? filterOptions(locationList)[0]
  //       : null,
  //   };

  //   Object.entries(defaultValues).forEach(([dropdownName, value]) => {
  //     if (value !== null && value !== undefined) {
  //       handleDropdown(value, dropdownName);
  //     }
  //   });
  // }, [enterprisesList, organisationList, facilityList, locationList]);
  // useEffect(() => {
  //   const defaultValues = {
  //     enterprise:GetIdAndDisplay2(enterprisesList)[0],
  //     organisation: GetIdAndDisplay(organisationList)[0],
  //     facility: GetIdAndDisplay1(facilityList)[0],
  //     location: filterOptions(locationLists)[0]
  //   };
  //   Object.entries(defaultValues).forEach(([dropdownName, value]) => {
  //     if (value !== null && value !== undefined) {
  //       if (!(Array.isArray(value)) || value.length !== 0) {
  //         handleDropdown(value, dropdownName , " ");
  //       }
  //     }
  //   });
  // }, [enterprisesList.length>0,organisationList.length>0,facilityList.length>0,locationLists.length>0]);

  useEffect(() => {
    const defaultValues = {
      enterprise: GetIdAndDisplay2(enterprisesList)[0],
      organisation: GetIdAndDisplay(organisationList)[0],
      location: filterOptions(locationLists)[0],
    };
    let newState = {};
  
    Object.entries(defaultValues).forEach(([dropdownName, value]) => {
      if (value !== null && value !== undefined) {
        if (!Array.isArray(value) || value?.length !== 0) {
          newState[dropdownName] = value;
        }
      }
    });
  
    setState(prevState => {
      return { ...prevState, ...newState };
    });
  }, [
    enterprisesList?.length > 0,
    organisationList?.length > 0,
    facilityList?.length > 0,
    locationLists?.length > 0,
  ]);
  const clearState = () => {
    setEnterprisesList([]);
    setOrganisationList([]);
    setFacilityList([]);
    setLocationLists([]);
    setPractitioner_id([]);
    setRoleLists([]);
  };
  return (
    <div style={{ minWidth: 240, minHeight: 300 }}>
      {/* <div style={{ marginTop: "20px", marginLeft: "23px" }}>
        <Typography
          style={{ fontSize: "12px", fontWeight: 500, marginBottom: "10px" }}
        >
          ROLE :
        </Typography>
        <div style={{ display: "flex", marginTop: "15px" }}>
          {practitonerDetails?.map((data1, index) => {
            let data = data1?.role;
            return (
              <div>
                <Card
                  onClick={() => {
                    handleRole(index);
                    clearState();
                    setIndexNo(index);
                  }}
                  className={
                    selectedRole === index ? classes.selected : classes.normal
                  }
                >
                  <CardContent
                    style={{
                      paddingBottom: "12px",
                      paddingTop: "12px",
                      paddingRight: "5px",
                      paddingLeft: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      {data?.display}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            );
          })}
        </div>
      </div> */}
      {/* ENTERPRISE START */}
      {/* {practitionerCode === "OT001" && ( */}
      <Grid style={{ marginTop: "19px", marginLeft: "23px" }}>
        <Typography
          style={{ fontSize: "12px", fontWeight: 500, marginBottom: "10px" }}
        >
          Facility
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          size={"small"}
          options={filterOption(facilityList) ?? []}
          value={state?.facility}
          onChange={(e, newValue) =>
            handleDropdown(newValue, "facility", ["facility", "location"])
          }
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Grid>
         {/* ORGANIZATION START */}
      {/* {(practitionerCode === "OT001" || practitionerCode === "OT002") && ( */}
      <Grid className={classes.align}>
        <Typography className={classes.heading}>Organization</Typography>
        <Autocomplete
          id="combo-box-demo"
          size={"small"}
          options={GetIdAndDisplay(organisationList) ?? []}
          value={state?.organisation || GetIdAndDisplay(organisationList)[0]}
          onChange={(e, newValue) =>
            handleDropdown(newValue, "organisation", [
              "organisation",
              "facility",
              "location",
            ])
          }
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          disabled
        />
      </Grid>
      {/* )} */}
      {/* ORGANIZATION END */}
      <Grid style={{ marginTop: "19px", marginLeft: "23px" }}>
        <Typography
          style={{ fontSize: "12px", fontWeight: 500, marginBottom: "10px" }}
        >
          Enterprise
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          size={"small"}
          options={GetIdAndDisplay2(enterprisesList ?? [])}
          // value={state?.enterprise}
          value={
            (state?.enterprise &&
              GetIdAndDisplay2(enterprisesList ?? []).find(
                (option) => option.id === state.enterprise.id
              )) ||
            (enterprisesList && enterprisesList.length > 0
              ? GetIdAndDisplay2(enterprisesList)[0]
              : null)
          }
          onChange={(e, newValue) =>
            handleDropdown(newValue, "enterprise", [
              "enterprise",
              "organisation",
              "facility",
              "location",
            ])
          }
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          disabled
        />
      </Grid>
      <Grid className={classes.align}>
        <Typography className={classes.heading}>Role</Typography>
        <Autocomplete
          id="combo-box-demo"
          size={"small"}
          options={filterOption(roleLists)}
          value={state?.role}
          onChange={(e, newValue) => handleDropdown(newValue, "role", ["role"])}
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Grid>
      <Grid className={classes.align}>
        <Typography className={classes.heading}>Location</Typography>
        <Autocomplete
          id="combo-box-demo"
          size={"small"}
          options={filterOptions(locationLists)}
          value={state?.location}
          onChange={(e, newValue) =>
            handleDropdown(newValue, "location", ["location"])
          }
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          disabled
        />
      </Grid> 
      <Button
        onClick={() => {
          props?.onNextClick(
            state.location,
            state.role,
            state.facility,
            state.organisation,
            state.enterprise,
            practitonerDetails[selectedRole],
            practitionerCode,
            org_id,
            props?.practitonerId,
            props?.person_id,
            props?.practitioner
          );
          clearState();
        }}
        // disabled={!validate(practitionerCode)}
        color="primary"
        style={{ float: "right", marginTop: "20px" }}
      >
        Submit
      </Button>
      {/* )} */}
    </div>
  );
};

export default LocationRoles;
