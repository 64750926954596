import React, { useEffect, useState } from "react";
import { Div, Avatar, H6, Text, Image, Card } from "qdm-component-library";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { UIColor } from "../../../themes/theme";
import privacyPolicyIcon from "../../../assets/img/svg/icons8-privacy-policy.svg";
import expiryDateIcon from "../../../assets/img/svg/expirydateicon.svg";
import arrowIcon from "../../../assets/img/svg/Group 3873.svg";
import dollorIcon from "../../../assets/img/svg/Group 90507.svg"
import policyPlanIcon from "../../../assets/img/svg/policyPlanIcon.svg";
import moment from "moment";
import cardIcon from "../../../assets/img/svg/Group 90506.svg"


const useStyles = makeStyles((theme) => ({

    avatarCls: {
      "& img": {
        width: "30px !important",
        height: "30px !important",
        objectFit: "contain !important",
      },
    },
    headerFonts: {
      fontSize: 14,
      color: UIColor.primaryColor.color,
      margin: 0,
      fontWeight: 600,
      fontFamily: "pc_semibold !important",
    },
    dot: {
      padding: "2px",
      backgroundColor: UIColor.tertiaryText.color,
      borderRadius: "50%",
    },
    alignItemsSpaceBetween: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    alignItems: {
      display: "flex",
      alignItems: "center",
    },
    subText: {
      fontSize: 12,
      color: UIColor.tertiaryText.color,
      fontFamily: "pc_regular !important",
    },
    circle: {
      border: `1px solid ${UIColor.tertiaryText.color}`,
      borderRadius: "50%",
      width: "21px",
      height: "21px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    arrow: {
      fontSize: "15px",
      color: UIColor.tertiaryText.color,
      cursor: "pointer",
    },
  }));
const EncounterShowingCard = (props) => {
const classes = useStyles();
const [encounterDataList, setEncounterDataList] = useState([]);

  const [open, setOpen] = React.useState(null);
  const styles = {
    cardStyle: {
      boxShadow: "none",
      borderRadius: 8,
      marginLeft: "5px",
    },
    avatar: {
      margin: 0,
      marginRight: 15,
      width: 48,
      height: 48,
      borderRadius: 8,
      backgroundColor: "#F0F0FA",
    },
    h61: {
        fontSize: 14,
        color: UIColor.secondaryButtonColor.color,
        margin: 0,
      },
  };
  const makeDate = (date) => {
    return moment.unix(date).format("DD MMM YY HH:mm");
  };
  return (
    <div>
    <Card
      id={`${props.parent_id}_patientstatus_parent_card`}
      style={styles.cardStyle}
    >
      <div
        id={`${props.parent_id}_patientstatus_parent_div`}
        className={classes.alignItemsSpaceBetween}
        style={{ alignItems: "start", marginRight: "10px" }}
      >
        <div className={classes.alignItems}>
          <Avatar
            id={`${props.parent_id}_patientstatus_${props.name?.[0]}_avatar`}
            src={cardIcon}
            variant="rounded"
            className={classes.avatarCls}
            inLineStyles={styles.avatar}
          />
          <div>
            <div className={classes.alignItemsSpaceBetween}>
              <div className={classes.alignItems} style={{ gap: "8px" }}>
                <Typography className={classes.headerFonts}>
                  {props?.encounterName}
                </Typography>
                <div className={classes.dot}></div>
                <Typography className={classes.headerFonts}>
                  {props?.type}
                </Typography>
                <div className={classes.dot}></div>
                <Typography className={classes.headerFonts}>
                  {props?.speciality}
                </Typography>
                &nbsp;
                    {props.noOfIteams === 1 ? '' : (!props.moreopen) ? null : (
                      <H6
                        id={`${props.parent_id}_selectcard_${props.noOfIteams}_h6`.replaceAll(" ","_")}
                        className="pc_semibold" style={styles.h61}>
                        {props.noOfIteams ? (
                          `& ${props.noOfIteams - 1}+ more`
                        ) : (
                          <div />
                        )}
                      </H6>
                    )}
              </div>
            </div>
            <div className={classes.alignItems} style={{ marginTop: "10px" }}>
              <Typography className={classes.subText}>
                On{" "}
                {makeDate(props?.encounterStart)}{" "}
                -{" "}
                {makeDate(props?.encounterEnd)}
              </Typography>
              <div className={classes.dot} style={{ margin: "0px 6px" }}></div>
              <div>
                <Typography className={classes.subText}>
                  {props?.doctorName}
                </Typography>
              </div>
              <div>
                <Typography className={classes.subText }style={{ margin: "0px 6px" ,color:props?.encstatus?._id === process.env.REACT_APP_ENC_COMPLETE || props?.encstatus?._id === process.env.REACT_APP_ENC_DISCHARGED || props?.encstatus?._id === process.env.REACT_APP_ENC_BILLED? "#3BB213" : props?.encstatus?._id === process.env.REACT_APP_ENC_PENDING ? "#EC6A49" : "#2A60BC"}}>
                  {props?.encstatus?.display}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
    
  </div>
  );
};

EncounterShowingCard.defaultProps = {
  pic: "",
  title_name: "",
  care_type: "",
  showUpArrow: false,
  showDivider: false,
};

export default EncounterShowingCard;
