import React, { useEffect, useState, useContext } from "react";
import {
  Div,
  Paper,
  Select,
  TextInput,
  H6,
  Col,
  Row,
  Button,
  SideMenu,
  Divider,
  Text,
} from "qdm-component-library";
import "./style.css";
import { useDispatch } from "react-redux";
import { UIColor } from "../../themes/theme";
import {
  Card,
  CardHeader,
  Dialog,
  Grid,
  IconButton,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import FileViewer from "react-file-viewer";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import {
  AxiosCall,
  ToBase64,
  readFileAsBase64,
  urls,
  AlertProps,
} from "../../utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import withAllContexts from "../../hoc/withAllContexts";
import actions from "../../redux/actions";
import { getSize } from "../patientRegistration/components/handlers";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { AlertContext, BackdropContext } from "../../context";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: "transparent",
  },
  dialogRoot: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },

    zIndex: "999999 !important",
  },
  chips: {
    "& .MuiChip-root": {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 8,
      },
    },
  },
  autocompleteBorder: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
        borderRadius: 8,
      },
    },
  },
}));

const PicReviewSheet = (props) => {
  let styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    containedButton: {
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontFamily: "pc_medium !important",
      fontSize: 14,
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "#FF0000",
    },
  };
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const [picReviewDetails, setPicReviewDetails] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    Practitioner:{}
  });
  // const [facilityNameData, setFacilityNameData] = useState([]);
  const [practitionerNameData, setPractitionerNameData] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState("");
  const setData = (name, value) => {
    let updatedData = { ...picReviewDetails };
    updatedData[name] = value;
    setPicReviewDetails(updatedData);
  };

  const GetIdAndDisplay1 = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        value: item.name,
        _id: item.id,
      };

      list.push(datatemp);
    }
    return list;
  };
  // const facilityName = async () => {
  //   const response = await dispatch(
  //     actions.Pic_Review_Sheet_Facility_DropDown({ //Facility_Load_Query_DROPDOWN
  //       organizationId: decodedUserinfo?.facilityid,
  //     })
  //   );
  //   setFacilityNameData(response?.payload?.data);
  // };
  const practitionerName = async () => {
    const response = await dispatch(
      actions.Pic_Review_Sheet_Practitioner_DropDown({
        organizationId: decodedUserinfo?.facilityid,
      })
    );
    setPractitionerNameData(response?.payload?.data);
  };
  const { labelStyle, borderStyle, containedButton, errorborderStyle } = styles;
  const { parent_id } = props;
  useEffect(() => {
    // facilityName();
    practitionerName();
  }, []);
  //const currentDate = new Date().toISOString().split("T")[0];
  // useEffect(() => {
  //   if (!picReviewDetails || Object.keys(picReviewDetails).length === 0) {
  //     setData("date", currentDate);
  //   }
  // }, []);

  function dateToEpoch(dateString) {
    var date = new Date(dateString);
    var epochTime = date.getTime();
    var epochTimeInSeconds = Math.floor(epochTime / 1000);
    return epochTimeInSeconds;
  }

  // var startDateEpoch = dateToEpoch(picReviewDetails?.startDate);
  // var enddDateEpoch = dateToEpoch(picReviewDetails?.endDate);

  const generateAndOpenJasperReport = async () => {
    console.log();
    if(!(picReviewDetails?.Practitioner?._id) || picReviewDetails?.startDate === null || picReviewDetails?.endDate=== null || picReviewDetails?.startDate === "" || picReviewDetails?.endDate=== ""){
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
    else{
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading .. Please wait",
      });
      let JwtToken = localStorage.getItem("JwtToken");
      let header = { JwtToken: JwtToken };
      const payload = {
        reportid: "fca764a3-d0a7-4fcc-863f-e179d0d3d60c",
        inputparams: {
          "@practitionerid": picReviewDetails?.Practitioner?._id ?? "",
          "@startdate": moment
          .unix(dateToEpoch(picReviewDetails?.startDate))
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .unix(),
          "@enddate": moment
          .unix(dateToEpoch(picReviewDetails?.endDate))
          .set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 0,
          })
          .unix(),
        },
  
        result: [],
      };
      AxiosCall("post", urls.jasperReport, payload, header).then(async (res) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        if (res?.downloadUrl) {
          const response = await axios.get(res?.downloadUrl, {
            method: "GET",
            responseType: "blob",
          });
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfDataUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfDataUrl, "_blank");
        } else {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "There is no report available.",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }
      });
      props?.handleModalClose();
    }
   
   
  };
  useEffect(() => {
    if (downloadUrl) {
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.target = "_blank";
      downloadLink.download = "report.pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [downloadUrl]);
  return (
    <Div id={`${parent_id}referral_parent_parent_div`} className="pi-root">
      <Paper
        id={`${parent_id}referral_parent_parent_paper`}
        className="pi-paper"
        style={{ width: '280px' }}
      >
        <Div id={`${parent_id}referral_parent_label_div`}style={{ display: "flex", justifyContent: "center"}}>
          <H6 id={`${parent_id}referral_parent_label_h6`} className="pi-title">
           PIC Review Sheet Report
          </H6>
        </Div>
        {/* <Row>
          <Col
            id={`${parent_id}referral_parent_Referring_from_Facility_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}referral_parent_Referring_from_Facility_select`}
              options={GetIdAndDisplay1(facilityNameData)}
              onChangeValue={(e) => setData("facility", e)}
              value={picReviewDetails?.facility?.value}
              label="Facility"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.IsViewMode}
              isRequired={true}
            />
          </Col>
        </Row> */}
        <Row>
          <Col
            id={`${parent_id}referral_parent_Referring_from_Facility_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}referral_parent_Referring_from_Facility_select`}
              options={GetIdAndDisplay1(practitionerNameData)}
              onChangeValue={(e) => setData("Practitioner", e)}
              value={picReviewDetails?.Practitioner?.value}
              label="Practitioner"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.IsViewMode}
              isRequired={true}
            />
          </Col>
        </Row>
        <Row id={`${parent_id}referral_parent_parent_row`}>
          <Col
            id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <TextInput
              id={`${parent_id}referral_parent_Date_of_Referral_In_label_textinput`}
              onChange={(e) => setData("startDate", e.target.value)}
              value={picReviewDetails?.startDate ?? ""}
              label="Start Date"
              //   placeholder=""
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor="#0071F2"
              type="date"
              disabled={props?.IsViewMode}
              isRequired={true}
            />
          </Col>
        </Row>
        <Row id={`${parent_id}referral_parent_parent_row`}>
          <Col
            id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <TextInput
              id={`${parent_id}referral_parent_Date_of_Referral_In_label_textinput`}
              onChange={(e) => setData("endDate", e.target.value)}
              value={picReviewDetails?.endDate ?? ""}
              label="End Date"
              //   placeholder=""
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor="#0071F2"
              type="date"
              disabled={props?.IsViewMode}
              isRequired={true}
            />
          </Col>
        </Row>
        
        <Button
          id={`${parent_id}_Financial_save_buttom`}
          inLineStyles={{
            ...containedButton,
            marginTop: 30,
            marginBottom: 20,
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}
          onClick={() => generateAndOpenJasperReport()}
        >
          Submit
        </Button>
      </Paper>
    </Div>
  );
};

export default withAllContexts(PicReviewSheet);
