import React from "react";
import {
  Box,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import "./style.css";
import { StyledDialog } from "../commonComponents";
import { useDialogContentStyles, useDialogStyles } from "./style";
import { useSelector } from "react-redux";
import { AlertProps } from "../../../../../../utils";
import withAllContexts from "../../../../../../hoc/withAllContexts";
import ReasonMaster from "../reasonMaster";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10px",
    boxShadow: "none",
    "& .App1-MuiCardHeader-root": {
      backgroundColor: "#DEE5EC",
      "& .App1-MuiCardHeader-content": {
        "& .App1-MuiTypography-h5": {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
  },
  contentGrid: {
    border: "1px solid #DEE5EC",
    borderRadius: "8px",
  },
  action: {
    position: "absolute",
    bottom: 0,
    zIndex: 1000000,
    width: " 95%",
    background: "white",
  },
  reasonFieldLabel: {
    fontWeight: "bold",
    margin: "16px 0px 6px",
  },
}));

const AlertUI = ({
  messageRead,
  dataIndex,
  message,
  html,
  index,
  isAlert,
  selectedTabName,
  handleChange = () => null,
  handleContinue = () => null,
  handleCancel = () => null,
  handleChangeReason = () => null,
  isDup,
  reasonData,
  isHide,
  selectedState,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Grid className={classes?.contentGrid}>
        <CardHeader title={selectedTabName} />
        {isAlert ? (
          <CardContent>
            <Typography gutterBottom variant="h6" component="h6">
              {index + 1}. Alert
            </Typography>
            <Typography variant="body1" color="textSecondary" component="p">
              {message}
            </Typography>
            {html && (
              <>
                <Typography gutterBottom variant="h6" component="h6">
                  Report :
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </>
            )}

            {isDup && (
              <>
                <Typography gutterBottom variant="h6" component="h6">
                  Message :
                </Typography>
                {isDup}
              </>
            )}

            <Typography className={classes.reasonFieldLabel}>
              Suggested Reason&nbsp;<span style={{ color: "red" }}>*</span>
            </Typography>

            <ReasonMaster
              // action={action?.action}
              action={"INTRACTIONREASON"}
              // drug={drug}
              value={{
                title: reasonData?.overridereasoncode ?? "",
              }}
              type={"INTERACTIONREASON"}
              onChange={(e) =>
                handleChangeReason(e, dataIndex, "overridereasoncode")
              }
              disabled={isHide}
            />
            <Typography className={classes.reasonFieldLabel}>
              Other Reason
            </Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              placeholder="Type Here"
              value={reasonData?.overridenotes ?? ""}
              disabled={isHide}
              onChange={(e) =>
                handleChangeReason(e.target.value, dataIndex, "overridenotes")
              }
            />
          </CardContent>
        ) : (
          <CardContent>No {selectedTabName} Found...</CardContent>
        )}
      </Grid>
      {selectedState && (
        <CardActions className={classes?.action}>
          <Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={messageRead}
                  onChange={(e) => handleChange(e.target.checked, dataIndex)}
                  name="yes"
                  color="primary"
                />
              }
              label="Yes, I read the above message"
            />
          </Grid>
          <Grid style={{ marginLeft: "auto" }}></Grid>
          <Button
            onClick={handleCancel}
            size="small"
            variant="outlined"
            color="primary"
          >
            cancel
          </Button>
          <Button
            onClick={handleContinue}
            size="small"
            variant="contained"
            color="primary"
          >
            Continue
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

function AlertDialog({
  alertDialogOpen,
  state,
  setFun = () => null,
  handleCloseAlertDialog = () => null,
  ...props
}) {
  // <--------------------Slices State -------------->
  const medication = useSelector(
    (state) => state?.MedicationMastersSlice?.get_allergy_interaction?.data
  );
  // <--------------------Hooks -------------->
  const dialogStyles = useDialogStyles();
  const dialogContentStyles = useDialogContentStyles();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [selectedTabName, setSelectedTabName] = React.useState("");
  const [MedicationSlice, setMedicationSlice] = React.useState([]);
  const [medicationSlice, setmedicationSlice] = React.useState([]);

  React.useEffect(() => {
    setmedicationSlice(
      props?.alertData?.["ALERT-MESSAGE"]?.length > 0
        ? [props.alertData]
        : medication
    );
  }, [medication, props.alertData]);

  const FilteredData =
    Array?.isArray(medicationSlice) &&
    medicationSlice?.filter(
      (value) => value?.[Object.keys(value)?.[0]]?.length > 0
    );

  //<---------------------------- Life Cycles ---------------------------->//
  React.useEffect(() => {
    let MedicationData =
      Array?.isArray(medicationSlice) &&
      medicationSlice?.filter(
        (value) => value?.[Object.keys(value)?.[0]]?.length > 0
      );
    let selectedtab = Object.keys(MedicationData?.[selectedTab] || {})?.[0];

    setSelectedTabName(selectedtab);
    // if (MedicationSlice.length === 0) {
    setMedicationSlice(
      Array?.isArray(MedicationData) ? [...MedicationData] : []
    );
    // }
  }, [medicationSlice, selectedTab]);

  // <--------------------Event Handlers -------------->
  const onhandleTabChange = (e, val) => {
    setSelectedTab(val);
    setActiveIndex(0);
  };

  const handleContinue = (alertlength, categoryLength) => {
    if (
      MedicationSlice?.[selectedTab]?.[selectedTabName]?.[activeIndex]
        ?.messageRead
    ) {
      setActiveIndex((prev) => {
        //To move to next alert
        if (prev < alertlength - 1) {
          return prev + 1;
        } else {
          setSelectedTab((prev) => {
            // to move to next catagory
            if (prev < categoryLength - 1) {
              return prev + 1;
            } else {
              // to close the model
              handleCloseAlertDialog(MedicationSlice, "save");
              //tab state
              return 0;
            }
          });
          //Active index state
          return 0;
        }
      });

      if (props?.alertData?.["ALERT-MESSAGE"]?.length > 0) {
        if (props.handleSaveAndAnother) {
          props.handleSaveAndAnother(MedicationSlice);
        }
      }
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Check Yes,I read the above message!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  const handleCancel = () => {
    setSelectedTab(0);
    setActiveIndex(0);
    setSelectedTabName("");
    setMedicationSlice([]);
    handleCloseAlertDialog(MedicationSlice);
    if (props.handleChangeDose) {
      props.handleChangeDose();
    }
  };

  const handleChange = (checked, index) => {
    let AlertData = JSON.parse(JSON.stringify(MedicationSlice));

    if (
      !AlertData[selectedTab][selectedTabName][index]["overridedetails"]?.[0]
        ?.overridereasoncode
      //  && !AlertData[selectedTab][selectedTabName][index]["overridedetails"]?.[0]
      //   ?.overridenotes
    ) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please select the suggested reason.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }

    AlertData[selectedTab][selectedTabName][index]["messageRead"] = checked;
    AlertData[selectedTab][selectedTabName][index]["alertType"] =
      selectedTabName;
    setMedicationSlice(AlertData);
  };

  const handleChangeReason = (value, index, key) => {
    let AlertData = JSON.parse(JSON.stringify(MedicationSlice));

    // AlertData[selectedTab][selectedTabName][index]['overridedetails'] = [{
    //   overridereasoncode: key === 'overridereasoncode' ? value : '',
    //   overridenotes: key === 'overridenotes' ? value : '',
    //   overridebyid: "",
    //   overrideroleid: "",
    // }];
    if (!value) {
      AlertData[selectedTab][selectedTabName][index]["messageRead"] = false;
    }
    if (
      AlertData[selectedTab][selectedTabName][index]["overridedetails"]
        ?.length > 0
    ) {
      AlertData[selectedTab][selectedTabName][index]["overridedetails"][0] = {
        ...AlertData[selectedTab][selectedTabName][index]["overridedetails"][0],
        [key]: value,
      };
    } else {
      AlertData[selectedTab][selectedTabName][index]["overridedetails"] = [];
      AlertData[selectedTab][selectedTabName][index]["overridedetails"].push({
        [key]: value,
      });
    }
    setMedicationSlice(AlertData);
  };

  const getAcknowledgeStatus = () => {
    let error = false;
    MedicationSlice.forEach((val, i) => {
      let selectedtab = Object.keys(MedicationSlice?.[selectedTab] || {})?.[0];
      val[selectedtab].forEach((v) => {
        if (!v?.["messageRead"]) {
          error = true;
        }
      });
    });

    return error;
  };

  // <--------------------Renderer -------------->
  return (
    <StyledDialog
      id="Alet_dialog"
      open={alertDialogOpen}
      handleClose={() => {
        getAcknowledgeStatus()
          ? props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Please Acknowledge you have read alert by clicking Checkbox.",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            })
          : handleCancel();
      }}
      title={"INSTRUCTION AND INTERACTION CHECKING"}
      dialogProps={{
        classes: dialogStyles,
      }}
      contentProps={{
        classes: dialogContentStyles,
      }}
    >
      <Tabs
        style={{ position: "absolute", background: "#fff", width: "100%" }}
        variant="scrollable"
        scrollButtons="auto"
        value={selectedTab}
        onChange={onhandleTabChange}
      >
        {Array.isArray(medicationSlice) &&
          FilteredData?.map((tab, i) => {
            return (
              <Tab
                label={Object.keys(tab)?.[0]?.replaceAll("-", " ")}
                {...a11yProps(i)}
              />
            );
          })}
      </Tabs>
      {Array.isArray(medicationSlice) &&
        MedicationSlice?.map((val, i) => {
          return (
            <TabPanel
              style={{ paddingTop: "35px", paddingBottom: "35px" }}
              value={selectedTab}
              index={i}
            >
              <AlertUI
                html={val?.[selectedTabName]?.[activeIndex]?.IAMTEXTN ?? ""}
                message={
                  val?.[selectedTabName]?.[activeIndex]?.Message ||
                  val?.[selectedTabName]?.[activeIndex]?.alerttitle
                }
                index={activeIndex}
                dataIndex={activeIndex}
                isAlert={val?.[selectedTabName]?.length > 0}
                messageRead={
                  MedicationSlice?.[selectedTab]?.[selectedTabName]?.[
                    activeIndex
                  ]?.messageRead ?? false
                }
                selectedTabName={selectedTabName}
                handleContinue={() =>
                  handleContinue(
                    val?.[selectedTabName]?.length,
                    FilteredData?.length
                  )
                }
                handleCancel={() => {
                  props?.alertData?.["ALERT-MESSAGE"]?.length > 0
                    ? props.handleChangeDose && props.handleChangeDose()
                    : getAcknowledgeStatus()
                    ? props.alert.setSnack({
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please Acknowledge you have read alert by clicking Checkbox.",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.right,
                      })
                    : handleCancel();
                }}
                handleChange={handleChange}
                handleChangeReason={handleChangeReason}
                isDup={val?.[selectedTabName]?.[activeIndex]?.alertmessage}
                reasonData={
                  val?.[selectedTabName]?.[activeIndex]?.overridedetails?.[0] ??
                  {}
                }
                isHide={props?.isHide ?? false}
                selectedState={props?.selectedState ?? false}
              />
            </TabPanel>
          );
        })}
    </StyledDialog>
  );
}
export default withAllContexts(AlertDialog);
