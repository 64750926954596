import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons';
import React, { useState, useEffect } from "react";
import { UIColor } from '../../../themes/theme';
import Tooltip from "@material-ui/core/Tooltip";



const SplitCashPopup = ({
    setOpenSplitPayer = () => null,
    handlePayerSplitUpdate = () => null,
    finData = [],
    payerSplitData = {},
    payerSplitIndex
}) => {

    const [payerSplit, setPayerSplit] = useState([]);
    const [serviceDesc, setServiceDesc] = useState([]);
    
  useEffect(() => {
    setPayerSplit([]);
    const payerDtls = [];
    finData?.map((payer )=>{
        if(payer?.payer !== process.env.REACT_APP_PAYER_SELFPAY){
            let val = payerSplitData?.payerDtls.find(x => x.payerid === payer?.payer && x.planid == payer?.planid);
            payerDtls.push({
                name: payer.title_name,
                netamt: payerSplitData?.net,
                billrate: payerSplitData?.gross,
                grossamt: payerSplitData?.gross,
                payerid: payer?.payer,
                payershare: val?.payershare ?? 0,
                _id: "",
                billqty: val?.billqty ?? 0,
                discamt: payerSplitData?.discount,
                chargecodeid: payerSplitData?.chargecodeid,
                maxlimitamt: val?.maxlimitamt ?? 0,
                otherpayershare: val?.otherpayershare ?? 0,
                copayamt: val?.copayamt ?? 0,
                planid: payer?.planid ?? val?.planid,
                priority: payer?.priority?.display ?? val?.priority
            });
        }
    });
    setServiceDesc(payerSplitData?.description);
    setPayerSplit(payerDtls);
  }, []);
  
  const handleSplitEdit = (e, payer) => {
    const data = JSON.parse(JSON.stringify(payerSplit));
    const payerIndex = data.findIndex(
        (v) => v?.payerid === payer?.payerid && v?.planid == payer?.planid
    );
    data[payerIndex].payershare = e.target.value;
    setPayerSplit(data);

  };
    const SavePayerData = () => {
        const data = JSON.parse(JSON.stringify(payerSplitData));
        handlePayerSplitUpdate(payerSplitIndex, data, payerSplit);
        // setOpenSplitPayer(false);
      };

    return (
        <Grid container direction="column" style={{ width: "300px", padding: "18px", borderRadius: "4px", }} >
            <Grid item container justifyContent='space-between' alignItems='center' >
                <Grid item>
                    <Tooltip title={serviceDesc}>
                        <Typography  style={{ fontSize: "16px", color: "#101010", fontWeight: "500" }} >
                            {serviceDesc?.length > 22 ? serviceDesc.slice(0, 22) + "..." : serviceDesc}
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <IconButton onClick={()=>{setOpenSplitPayer(false)}} >
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid style={{ padding: "10px 0" }}>

                {payerSplit?.map((payer )=>{
                    return(
                        <Grid style={{ paddingBottom: "10px" }}>
                            <Grid item>
                                <Typography style={{ fontSize: "12px", color: "#6f6f6f", }} gutterBottom>{payer?.name}</Typography>
                            </Grid>
                            <Grid item>
                                <TextField variant='outlined' size='small' fullWidth onChange={(e) => handleSplitEdit(e, payer)} //disabled={finData.length>1? false : true}
                                // InputProps={{
                                //     startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                                // }}
                                value={payer?.payershare} />
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid container justifyContent='flex-end' spacing={2}>
                {/* <Grid item>
                    <Button variant='outlined' color='inherit' style={UIColor.tertiaryText} size='small' onClick={()=>{setOpenSplitPayer(false)}}> Close</Button>
                </Grid> */}
                <Grid item>
                    <Button variant="contained" color='primary' size='small' onClick={()=>{SavePayerData()}}> Ok</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SplitCashPopup;