import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  urls,
  dbName,
} from "../../utils";

const SAMPLE_COLLECTED = createAsyncThunk(
  `DashboardSlice/sampleCollected`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          practitionerid: payload || "Practitioner/10341",
          tenantid: "",
          facilityid: "",
        },
        queryid: "dbd7cc02-f9c5-4121-b5b2-dac2e6c66a49",
      };

      let data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      data = data.map((val) => {
        return { value: val.name, label: val._id };
      });
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const REGISTERED_BY = createAsyncThunk(
  `DashboardSlice/registeredby`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          orgid: payload,
        },
        queryid: "19d9ce08-a183-4f60-9fcc-4992bff9a8c1",
      };

      let data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      data = data.map((val) => {
        return { value: val.name, label: val._id };
      });
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const ADMINISTRATED_BY = createAsyncThunk(
  `DashboardSlice/administratedby`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          orgid: payload,
        },
        queryid: "64d81678-fd51-40a9-afad-8e483b6be56c",
      };

      let data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      data = data.map((val) => {
        return { value: val.name, label: val._id };
      });
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const BRANCH_CODE = createAsyncThunk(
  `DashboardSlice/branchCode`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          orgid: payload,
        },
        queryid: "83fb1f96-8e94-44ba-ac77-b17c0c694cb8",
      };

      let data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      data = data.map((val) => {
        return { value: val.name, label: val._id, code: val?.entitycode };
      });
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const SAVE_ORDER_SAMPLE = createAsyncThunk(
  `DashboardSlice/saveOrderSample`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.saveOrderSample
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const SAVE_ORDER_STATEINFO = createAsyncThunk(
  `DashboardSlice/saveOrderStateInfo`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.saveOrderStateInfo
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const Pic_Review_Sheet_Practitioner_DropDown = createAsyncThunk(
  `DashboardSlice/picReviewSheetFacilityDropDown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { organizationId = "" } = payload;

      const body = {
        db_name: dbName,
        filter: {
          orgid: organizationId,
        },

        queryid: "2d237e1c-1432-453d-9bdf-b22e40aeac61",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const Dashboard_Slice = createSlice({
  name: "DashboardSlice",
  initialState: {
    sampleCollected: {
      ...defaultState.List,
    },
    registeredby: {
      ...defaultState.List,
    },
    administratedby: {
      ...defaultState.List,
    },
    branchCode: {
      ...defaultState.List,
    },
    saveOrderSample: {
      ...defaultState.Info,
    },
    saveOrderStateInfo: {
      ...defaultState.Info,
    },
    picReviewSheetFacilityDropDown: {
      ...defaultState.Info,
    },
  },
  extraReducers: {
    //sampleCollected
    [SAMPLE_COLLECTED.fulfilled]: (state, action) => {
      state.sampleCollected = action?.payload ?? {};
    },
    [SAMPLE_COLLECTED.pending]: (state, action) => {
      state.sampleCollected.loading = true;
    },
    [SAMPLE_COLLECTED.rejected]: (state, action) => {
      state.sampleCollected = action?.payload ?? {};
    },

    //registeredby

    [REGISTERED_BY.fulfilled]: (state, action) => {
      state.registeredby = action?.payload ?? {};
    },
    [REGISTERED_BY.pending]: (state, action) => {
      state.registeredby.loading = true;
    },
    [REGISTERED_BY.rejected]: (state, action) => {
      state.registeredby = action?.payload ?? {};
    },

    //administratedby

    [ADMINISTRATED_BY.fulfilled]: (state, action) => {
      state.administratedby = action?.payload ?? {};
    },
    [ADMINISTRATED_BY.pending]: (state, action) => {
      state.administratedby.loading = true;
    },
    [ADMINISTRATED_BY.rejected]: (state, action) => {
      state.administratedby = action?.payload ?? {};
    },

    //branchCode

    [BRANCH_CODE.fulfilled]: (state, action) => {
      state.branchCode = action?.payload ?? {};
    },
    [BRANCH_CODE.pending]: (state, action) => {
      state.branchCode.loading = true;
    },
    [BRANCH_CODE.rejected]: (state, action) => {
      state.branchCode = action?.payload ?? {};
    },
    //saveOrderSample
    [SAVE_ORDER_SAMPLE.fulfilled]: (state, action) => {
      state.saveOrderSample = action?.payload ?? {};
    },
    [SAVE_ORDER_SAMPLE.pending]: (state, action) => {
      state.saveOrderSample.loading = true;
    },
    [SAVE_ORDER_SAMPLE.rejected]: (state, action) => {
      state.saveOrderSample = action?.payload ?? {};
    },
    //saveOrderStateInfo
    [SAVE_ORDER_SAMPLE.fulfilled]: (state, action) => {
      state.saveOrderStateInfo = action?.payload ?? {};
    },
    [SAVE_ORDER_SAMPLE.pending]: (state, action) => {
      state.saveOrderStateInfo.loading = true;
    },
    [SAVE_ORDER_SAMPLE.rejected]: (state, action) => {
      state.saveOrderStateInfo = action?.payload ?? {};
    },
    //picReviewSheetFacilityDropDown
    [Pic_Review_Sheet_Practitioner_DropDown.fulfilled]: (state, action) => {
      state.picReviewSheetFacilityDropDown = action?.payload ?? {};
    },
    [Pic_Review_Sheet_Practitioner_DropDown.pending]: (state, action) => {
      state.picReviewSheetFacilityDropDown.loading = true;
    },
    [Pic_Review_Sheet_Practitioner_DropDown.rejected]: (state, action) => {
      state.picReviewSheetFacilityDropDown = action?.payload ?? {};
    },
  },
});

export const DashboardActions = {
  SAMPLE_COLLECTED,
  REGISTERED_BY,
  ADMINISTRATED_BY,
  BRANCH_CODE,
  SAVE_ORDER_SAMPLE,
  SAVE_ORDER_STATEINFO,
  Pic_Review_Sheet_Practitioner_DropDown,
};
export default Dashboard_Slice;
