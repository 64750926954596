import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  CardActions,
  IconButton,
  List,
  ListItem,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import {
  axiosCall,
  urls,
  __tenantid__,
  AxiosCall,
  dbName,
  query_ids,
  formatDateFromEpoch,
  AlertProps,
} from "../../utils";
import { Col, Divider, Icons, Image, Row, Text } from "qdm-component-library";
import { UIColor } from "../../themes/theme";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import icons8trash from "../../assets/img/svg/icons8-trash_.svg";
import { epochToDatetime } from "../../utils";
import jwtDecode from "jwt-decode";
import Addicon from "../../assets/img/icons8-add.svg";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import HistoryAccordion from "./historyAccordion";
import { Pagination } from "@material-ui/lab";
import icons8edit from "../../assets/img/svg/icons8-edit_.svg"
import printIcon from "../../assets/img/svg/print-icon-noframe.svg"
import axios from "axios";
import { AlertContext } from "../../context";

const styles = {
  vitalsHead: {
    padding: "6px 14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
    paddind: 10,
  },
  vitailsCard: {
    // border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    marginBottom: 0,
    background: "#fff",
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "red",
  },
  inputStyle: {
    padding: 5,
    marginBottom: 20,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  listVitals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagsStyle: {
    padding: "4px 12px",
    borderRadius: "50px",
    border: "1px solid #0071F2",
    color: "#0071F2",
    background: "#F5FAFF",
    fontSize: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  tapSelectStyle: {
    padding: "4px 8px",
    borderColor: UIColor.lineBorderFill.color,
    margin: "4px 10px 6px 0px",
    borderRadius: "8px",
    minWidth: "auto",
    "& span": {
      fontSize: "12px",
    },
  },
  TapSelectcol: {
    display: "flex",
    margin: "auto 0",
    marginBottom: "12px",
    paddingLeft: "4px",
  },
  CalendarStyle: {
    padding: 5,
    marginBottom: 10,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  CalendarStyleIcon: {
    position: "absolute",
    right: "14px",
    bottom: "8px",
    cursor: "pointer",
  },
  textColor: {
    fontFamily: "pc_regular",
    fontSize: 12,
    paddingLeft: 6,
    color: UIColor.tertiaryText.color,
  },
  textColor1: {
    fontFamily: "pc_regular",
    fontSize: 12,
    color: UIColor.tertiaryText.color,
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: "16px",
  },
  editAllergyIcon: {
    padding: "6px 10px",
    background: UIColor.greyBackground.color,
    borderRadius: "50px",
    cursor: "pointer",
  },
  userIcon: {
    width: 25,
    height: 25,
    borderRadius: 50,
    backgroundColor: "#E2EEFB",
    marginRight: 15,
    padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalCard: {
    width: 250,
    padding: 20,
  },
  modalHeading: {
    fontSize: 16,
    color: UIColor.primaryText.color,
    textAlign: "center",
    marginBottom: 10,
  },
  modalSubTitle: {
    fontSize: 14,
    color: "#242424",
    textAlign: "center",
    marginBottom: 20,
  },
  outlinedButton: {
    borderColor: "#DEDEDE",
    color: UIColor.primaryText.color,
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_regular !important",
    display: "block",
  },
  containedButton: {
    backgroundColor: UIColor.error.color,
    borderColor: UIColor.error.color,
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_semibold !important",
    marginBottom: 10,
    color: "#fff",
  },
  modal: {
    backgroundColor: "#fff",
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    overflow: "hidden",
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: "50%",
    backgroundColor: "#6B6B6B",
    margin: "0px 2px",
    display: "inline-block",
  },
  wrapper: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: UIColor.lineBorderFill.color,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
  },
  statustextColor: {
    color: UIColor.success.color,
    fontSize: 12,
    fontWeight: "bold",
    paddingLeft: 6,
  },
};

const ProgressNotes = (props) => {
  const dispatch = useDispatch();
  // * <-------------------STATE------------------->
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState({ id: null, open: false });
  const [allNotes, setAllNotes] = useState([]);
  const [notesTypes, setNotesTypes] = useState([]);
  const [notesHistory, setNotesHistory] = useState([]);
  const alert = useContext(AlertContext);
  const [isSave,setIsSave] = useState(false);

  // * <-------------------EVENTS------------------->
  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo =
    userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const _facility_ = decodedUserinfo?.facilityid ?? "";

  const practitionerid = decodedUserinfo?.practitionerid ?? "";
  const progressData = props?.progressNotesData
  const handleClose = () => {
    setOpen(false);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update current page when page is changed
  };

  // Assuming treatmentPlanHistory and pageSize are defined somewhere in your component's scope
  const pageSize = 2; // Number of items per page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleItems = notesHistory.slice(startIndex, endIndex);

  const handleNotes = (e, index, notesType, notes_id) => {
    let existingObject = notesTypes.find((obj) => obj?.doc_id ? obj?.doc_id === notes_id : "");
    let data = notesTypes;
    if ( notes_id != undefined && existingObject?.doc_id === notes_id) {
      data?.map((v,i)=>{
        if(v?._id === existingObject?._id){
          setNotesTypes((prevArray) =>
            prevArray.map((obj) =>
              obj.doc_id === notes_id
                ? { ...obj, value: e.target.value } // Replace value with new input
                : obj
          ))
        }
        
      })
      
    } else {
      data?.map((v,i)=>{
        if(v?._id === notesType){
          v.value = e.target.value
          setNotesTypes(data)
        }
      })
    }
  };

  const handleEdit = async (i, type, _id) => {
    setOpen(true);
    setEditOpen({
      id: _id,
      open: true,
    });
  };
  const addNotes =()=>{
    setEditOpen({
      open: false,
    });
    setOpen(true)
  }
  const handleSave = async () => {
    setIsSave(true)
    const now = Date.now(); // Get milliseconds since the Epoch
    const epochTimeInSeconds = Math.floor(now / 1000);
    let payloadList = [];
   notesTypes?.map((v,i)=>{
    if(v?.value){
    let payload = {
      _id: v?.doc_id || "",
      tenantid: __tenantid__,
      facilityid: _facility_,
      patientid: props?.patientgetData?.Patient?.[0]?._id,
      encounterid: props?.patientgetData?._id,
      clicnicaldocdate: epochTimeInSeconds,
      clicnicaldoctype: v?._id,
      clicnicaldocdesc: v?.value,
      practitionerid: practitionerid,
    };
          payloadList.push(payload);
    }
   })
    // state.map((v, i) => {
    //   if (allNotes[i]?.clicnicaldoctype?._id === v?.type && !v?._id) {
    //     let tempData = state;
    //     tempData?.splice(i, 1);
    //     setState(...tempData);
    //   } else {
    //     if (v?.value !== undefined && v?.type !== undefined) {
    //       let payload = {
    //         _id: v?._id || "",
    //         tenantid: __tenantid__,
    //         facilityid: _facility_,
    //         patientid: props?.patientgetData?.Patient?.[0]?._id,
    //         encounterid: props?.patientgetData?._id,
    //         clicnicaldocdate: epochTimeInSeconds,
    //         clicnicaldoctype: v?.type,
    //         clicnicaldocdesc: v?.value,
    //         practitionerid: practitionerid,
    //       };
    //       payloadList.push(payload);
    //     }
    //   }
    // });

    let jwtObj = { jwtToken: localStorage.getItem("JwtToken") };
    if (payloadList?.length > 0) {
      await axiosCall(payloadList, urls.saveClinicalNotes, jwtObj);
    }
    setIsSave(false)
    setOpen(false);
    getData();
  };
  
  const getData = async () => {
    const patientId = props?.patientgetData?.Patient?.[0]?._id;
    const encounterId = props?.patientgetData?._id;
    const notesType = await axiosCall(
      {
        db_name: dbName,
        filter: {
          type: ["CLINICALNOTETYPE"],
          tenantid: __tenantid__,
          facilityid: _facility_,
          lang: "en",
        },
        queryid: "d5d4aeb3-3f50-4394-93e8-c9bde7ab3753",
      },
      urls?.readQdmQueries
    );
     setNotesTypes(notesType[0]?.CLINICALNOTETYPE?.Value);
    const data =  await axiosCall(
      {
        db_name: dbName,
        filter: {
          patientid: patientId,
          encounterid: encounterId,
        },
        queryid:"1d37ee29-f8c4-41bb-b454-e7e3db97bb9e",
      },
      urls?.readQdmQueries
    );
    setAllNotes(data);
    let list = [];
    data?.map((v, i) => {
      let obj = {
        id: i,
        value: v?.clicnicaldocdesc,
        type: v?.clicnicaldoctype?._id,
        _id: v?._id || "",
      };
      list.push(obj);
    });
    let datas = notesType[0]?.CLINICALNOTETYPE?.Value;
    // datas?.map((v,i)=>{
    //   list?.map((j,i)=>{
    //     if(j?.type === v?._id){
    //       v.value = j?.value
    //     }
    //   })
    // })
    for (let index in datas) {
      const v = datas[index];
      for (let j in list) {
        const listItem = list[j];
        if (listItem && listItem.type === v?._id) {
          v.value = listItem.value;
          v.doc_id=listItem._id
        }
      }
    }
    setNotesTypes(datas)

   
      const pnHistory = await dispatch(
        actions.PROGRESSNOTES_READ_HISTORY({
          patientId:patientId,
          encounterId:encounterId
        })
      );
      
      setNotesHistory(pnHistory?.payload?.data);
      
  };

  const printChart = async()=>{
    let JwtToken = localStorage.getItem("JwtToken");
    let header = {
      "Content-Type": "application/json",
      JwtToken: JwtToken 
    };
    let payload = {
      "reportid": "c2560584-9a2f-482d-a126-94ee011a416c",
      "inputparams": {
          "@patientid": props?.patientgetData?.Patient?.[0]?._id,
          "@encounterid":  props?.patientgetData?._id,
      },
      "result": []
    };
    try{
      let Response = await axios({
        method: "POST",
        url: urls?.jasperReport,
        headers:header,
        data: JSON.stringify(payload),
      });
      if(Response?.status == 200){
        const response = await axios.get(Response?.data?.downloadUrl, {
          method: "GET",
          responseType: "blob",
        });
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });
        const pdfDataUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfDataUrl, "_blank");
      } 
    }catch (error) {
        if(error?.response?.status == 400) {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "No Documents !!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        } else {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please Contact Admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
      }
  }

  // * <-------------------LIFE CYCLE------------------->

  useEffect(() => {
    
    if(progressData?.length > 0)
    {
      setAllNotes(progressData);
    }
    else {
      getData();
    }
    
  }, [progressData]);
  
  // * <-------------------RENDER------------------->
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          <Text
            inLineStyles={{
              fontWeight: 600,
              fontSize: "18px",
              color: UIColor.primaryColor.color,
              margin: "10px 5px 10px 5px",
            }}
          >
            {"Progress Notes Entry"}
          </Text>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!editOpen?.open
              ? notesTypes?.map((type, i) => {
                const isdisabled = allNotes.find((obj) => obj.clicnicaldoctype?._id === type?._id);
                  return (
                    <>
                      <Typography variant="body1" color="textPrimary">
                        {type?.display}
                      </Typography>

                      <TextField
                        onChange={(e) => {
                          handleNotes(e, i, type?._id);
                        }}
                        multiline
                        minRows={4}
                        variant="outlined"
                        fullWidth
                        id={`notes${i + type}`}
                        value={type?.value}
                        // value={""}
                        disabled={isdisabled}
                      />
                    </>
                  );
                })
              : notesTypes?.map((type, i) => {
                  if (editOpen?.id == type?._id) {
                    return (
                      <>
                        <Typography variant="body1" color="textPrimary">
                          {type?.display}
                        </Typography>

                        <TextField
                          onChange={(e) => {
                            handleNotes(
                              e,
                              i,

                              type?._id,
                              type?.doc_id
                            );
                          }}
                          multiline
                          minRows={5}
                          variant="outlined"
                          fullWidth
                          id={`notes${i + type}`}
                          value={type?.value}
                          
                        />
                      </>
                    );
                  }
                })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            color="primary"
            // disabled={state.length <= 2}
            disabled={isSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Row inLineStyles={styles.vitailsCard}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inLineStyles={styles.vitalsHead}
        >
          <Grid container justifyContent="space-between" alignItems="center">
                <Grid item >
                <Text
                  inLineStyles={{
                    fontWeight: 600,
                    fontSize: "14px",
                    color: UIColor.primaryColor.color,
                    margin: "10px 5px 10px 5px",
                  }}
                >
                  {"Progress Notes"}
                </Text>
                </Grid>
                <Grid item >

                <IconButton onClick={printChart}>
                  <img src={printIcon} alt="Add"  style={{ borderRadius: "50px", paddingTop: "3px", height:"20px",width:"22px", background: UIColor.greyBackground.color,}}/>
                </IconButton>
               
                  { allNotes.length <
                    notesTypes?.length && (
                    <IconButton onClick={addNotes}>
                      <img src={Addicon} alt="Add" />
                    </IconButton>
                  )}

                </Grid>

          </Grid>
         

        
        </Col>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {allNotes.length > 0 ? (
              <List>
                {allNotes.map((note, i) => {
                  return (
                    <List style={{ flexGrow: 1 }} lg={12}>
                      <ListItem
                        style={
                          allNotes.length > 0
                            ? {
                                borderTop: i === 0 ? "none" : "1px solid",
                                borderColor:
                                  i === 0
                                    ? "none"
                                    : UIColor.lineBorderFill.color,
                                paddingTop: i === 0 ? "none" : "20px",
                              }
                            : {}
                        }
                      >
                        <Grid container>
                          <Grid item container justifyContent="space-between">
                            <Grid item>
                              {" "}
                              <Text
                                inLineStyles={{
                                  color: UIColor.primaryColor.color,
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  fontFamily: "pc_regular",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {note?.clicnicaldoctype?.display}
                              </Text>
                            </Grid>
                            <Grid item>
                              {/*<IconButton size="small">
                            <Text
                              inLineStyles={{ ...styles.editAllergyIcon }}
                            >
                              <Image src={icons8trash} alt="delete_icon" />
                            </Text>
                          </IconButton>*/}
                              <IconButton
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  handleEdit(
                                    i,
                                    note?.clicnicaldoctype?.display,
                                    note?.clicnicaldoctype?._id
                                  ); //type,_id
                                }}
                              >
                                <Text
                                  inLineStyles={{ ...styles.editAllergyIcon }}
                                >
                                <Image
                                src={icons8edit}
                                alt="edit_icon"
                               style={{paddingTop:"3px"  }}
                              />
                                </Text>
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Grid item style={{ width: "80%" }}>
                            <Text
                              inLineStyles={{
                                color: UIColor.secondaryButtonColor.color,
                                fontSize: "14px",
                                fontWeight: 500,
                                fontFamily: "pc_regular",
                                overflowWrap: "anywhere",
                              }}
                            >
                              {note?.clicnicaldocdesc}
                            </Text>
                          </Grid>
                          <Grid item container justifyContent="flex-end">
                            <Text inLineStyles={styles.textColor1}>
                              {note?.practitionerid?.name} {" -  "}{" "}
                              {formatDateFromEpoch(note?.clicnicaldocdate)}
                            </Text>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  );
                })}
              </List>
            ) : (
              <Typography
                variant="caption"
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "15px 0px",
                  display: "block",
                }}
              >
                No data available
              </Typography>
            )}
          </Grid>
        </Grid>
        <HistoryAccordion> 
        {visibleItems.length ? (
          visibleItems.map((note, i) => (
            <Grid container spacing={2} key={i}>
              <Grid item xs={12}>
                <List style={{ flexGrow: 1}} lg={12}>
                  <ListItem 
                  style={
                    notesHistory.length > 1 ? { // Use length > 1 for consistent behavior
                      borderTop: i === 0 ? "none" : "1px solid",
                      borderColor: i === 0 ? "none" : UIColor.lineBorderFill.color,
                      paddingTop: i === 0 ? "none" : "20px",
                    }:{}
                  }
                  >
                    <Grid container>
                      <Grid item container justifyContent="space-between">
                        <Grid item>
                          <Text
                            inLineStyles={{
                              color: UIColor.primaryColor.color,
                              fontSize: "14px",
                              fontWeight: 600,
                              fontFamily: "pc_regular",
                              overflowWrap: "break-word",
                            }}
                          >
                            {note?.clicnicaldoctype?.display}
                          </Text>
                        </Grid>
                        <Grid item> {/* Placeholder for any content */}
                        </Grid>
                      </Grid>
  
                      <Grid item style={{ width: "80%",marginTop: "10px" }}>
                        <Text
                          inLineStyles={{
                            color: UIColor.secondaryButtonColor.color,
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "pc_regular",
                            overflowWrap: "anywhere",
                          }}
                        >
                          {note?.clicnicaldocdesc}
                        </Text>
                      </Grid>
  
                      <Grid item container justifyContent="flex-end">
                        <Text inLineStyles={styles.textColor1}>
                          {note?.practitionerid?.name} {" -  "}
                          {formatDateFromEpoch(note?.clicnicaldocdate)}
                        </Text>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          ))
        ) : (
          <Typography
            variant="caption"
            style={{
              width: "100%",
              textAlign: "center",
              margin: "5px",
              display: "block",
            }}
          >
            No data available
          </Typography>
        )
      }
      
      {notesHistory.length > 2 && (
        <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "10px" ,borderTop:"1px solid" , borderColor:UIColor.lineBorderFill.color}}>
          <Pagination count={Math.ceil(notesHistory.length / pageSize)} page={currentPage} onChange={handlePageChange} />
        </div>)}
      </HistoryAccordion>
      </Row>
    </>
  );
};
export default ProgressNotes;