import React, { useEffect, useState } from "react";
import {
  Div,
  Paper,
  Select,
  TextInput,
  H6,
  Col,
  Row,
  Button,
  SideMenu,
  Divider,
  Text,
} from "qdm-component-library";
import "./style.css";
import { useDispatch } from "react-redux";
import { UIColor } from "../../themes/theme";
import {
  Card,
  CardHeader,
  Dialog,
  Grid,
  IconButton,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import FileViewer from "react-file-viewer";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import {
  AxiosCall,
  ToBase64,
  readFileAsBase64,
  urls,
  AlertProps,
} from "../../utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import withAllContexts from "../../hoc/withAllContexts";
import actions from "../../redux/actions";
import { getSize } from "../patientRegistration/components/handlers";
import jwtDecode from "jwt-decode";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: "transparent",
  },
  dialogRoot: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },

    zIndex: "999999 !important",
  },
  chips: {
    "& .MuiChip-root": {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 8,
      },
    },
  },
  autocompleteBorder: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
        borderRadius: 8,
      },
    },
  },
}));

const CashierCollectionSummary = (props) => {
  let styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    containedButton: {
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontFamily: "pc_medium !important",
      fontSize: 14,
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "#FF0000",
    },
  };
  const dispatch = useDispatch();
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const classes = useStyles();
  const [cashierdatas, setCashierdatas] = useState({
    // ...props?.referralRegistrationdatas,
  });
  const [facilityNameData, setFacilityNameData] = useState([]);
  const [practitionerNameData, setPractitionerNameData] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState("");

  // const [opensidemenu, setopensidemenu] = useState(false);
  // let isHealthDisease = false;
  // const [state, setState] = React.useState({
  //   openFile: null,
  //   viewFileName: null,
  //   fileType: null,
  //   open: false,
  // });
  const setData = (name, value) => {
    let updatedData = { ...cashierdatas };
    updatedData[name] = value;
    setCashierdatas(updatedData);
  };

  const GetIdAndDisplay1 = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        value: item.name,
        _id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const GetIdAndDisplay = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        chargecode: item.chargecode,
        value: item.longdesc,
        _id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };

  const FlterOptions3 = (props, type, istrue, index) => {
    let filteredArray = props.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        // isdisabled:false,
        value: data[index]?.display,
        _id: data[index]?._id,
        label: data[index]?.display,
      };
      list.push(datatemp);
    }
    return list;
  };

  const facilityName = async () => {
    const response = await dispatch(
      actions.Facility_Load_Query_DROPDOWN({
        organizationId: decodedUserinfo?.facilityid,
      })
    );
    setFacilityNameData(response?.payload?.data);
  };
  const practitionerName = async () => {
    const response = await dispatch(
      actions.Practitoner_Load_Query_DROPDOWN({
        practitionerId: decodedUserinfo?.practitionerid,
      })
    );
    setPractitionerNameData(response?.payload?.data);
  };
  const { labelStyle, borderStyle, containedButton, errorborderStyle } = styles;
  const { parent_id } = props;
  // const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  useEffect(() => {
    facilityName();
    practitionerName();
  }, []);
  const currentDate = new Date().toISOString().split("T")[0];
  useEffect(() => {
    if (!cashierdatas || Object.keys(cashierdatas).length === 0) {
      setData("date", currentDate);
    }
  }, []);

  function dateToEpoch(dateString) {
    var date = new Date(dateString);
    var epochTime = date.getTime();
    var epochTimeInSeconds = Math.floor(epochTime / 1000);
    return epochTimeInSeconds;
  }

  var dateEpoch = dateToEpoch(cashierdatas?.date);

  const generateAndOpenJasperReport = async () => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const payload = {
      reportid: "03d5eefe-70fb-4985-a4f5-2eb867fd91b5",
      inputparams: {
        "@loginuserid": cashierdatas?.Period?._id ?? "",
        "@billdate": dateEpoch,
        "@facilityid": cashierdatas?.facility?._id ?? "",
      },

      result: [],
    };

    AxiosCall("post", urls.jasperReport, payload, header).then(async (res) => {
      if (res?.downloadUrl) {
        const response = await axios.get(res?.downloadUrl, {
          method: "GET",
          responseType: "blob",
        });
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });
        const pdfDataUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfDataUrl, "_blank");
        // setDownloadUrl(res?.downloadUrl);
        // setFileType("pdf");
      } else {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "There is no report available.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
    });
    props?.handleModalClose();
  };
  useEffect(() => {
    if (downloadUrl) {
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.target = "_blank";
      downloadLink.download = "report.pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [downloadUrl]);
  return (
    <Div id={`${parent_id}referral_parent_parent_div`} className="pi-root">
      <Paper
        id={`${parent_id}referral_parent_parent_paper`}
        className="pi-paper"
      >
        <Div id={`${parent_id}referral_parent_label_div`}>
          <H6 id={`${parent_id}referral_parent_label_h6`} className="pi-title">
            Cash Collection Summary Report
          </H6>
        </Div>
        <Row id={`${parent_id}referral_parent_parent_row`}>
          <Col
            id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <TextInput
              id={`${parent_id}referral_parent_Date_of_Referral_In_label_textinput`}
              onChange={(e) => setData("date", e.target.value)}
              value={cashierdatas?.date ?? ""}
              label="Date"
              //   placeholder=""
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor="#0071F2"
              type="date"
              disabled={props?.IsViewMode}
              isRequired={true}
            />
          </Col>
        </Row>
        <Row>
          <Col
            id={`${parent_id}referral_parent_Referring_from_Facility_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}referral_parent_Referring_from_Facility_select`}
              options={GetIdAndDisplay1(facilityNameData)}
              onChangeValue={(e) => setData("facility", e)}
              value={cashierdatas?.facility?.value}
              label="Facility"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.IsViewMode}
              isRequired={false}
              // inLineStyles={
              //   props?.isError &&
              //   !referralRegistrationdatas?.Referring_from_Facility?.value
              //     ? errorborderStyle
              //     : borderStyle
              // }
            />
          </Col>
        </Row>
        <Row>
          <Col
            id={`${parent_id}referral_parent_Referring_from_Facility_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}referral_parent_Referring_from_Facility_select`}
              options={GetIdAndDisplay1(practitionerNameData)}
              onChangeValue={(e) => setData("Period", e)}
              value={cashierdatas?.Period?.value}
              label="Period"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.IsViewMode}
              isRequired={false}
              // inLineStyles={
              //   props?.isError &&
              //   !referralRegistrationdatas?.Referring_from_Facility?.value
              //     ? errorborderStyle
              //     : borderStyle
              // }
            />
          </Col>
        </Row>
        <Button
          id={`${parent_id}_Financial_save_buttom`}
          inLineStyles={{
            ...containedButton,
            marginTop: 30,
            marginBottom: 20,
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}
          onClick={() => generateAndOpenJasperReport()}
        >
          Submit
        </Button>
      </Paper>
    </Div>
  );
};

export default withAllContexts(CashierCollectionSummary);
