import React, { useContext, useState } from "react";
import {
  Typography,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Collapse,
  Grid,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import CustomListing from "./components/customList";
import { handleCollapse, listClick } from "./handlers";
import useStyles from "./styles";
import CashierCollectionSummary from "../../../screens/cashierCollections/index";
import CloseIcon from "@material-ui/icons/Close";
import { Div, Iframe, Modal } from "qdm-component-library";
import { AppRoutes } from "../../../router/routes";
import PatientReviewChartReport from "../../patientReviewChartReport";
import { AlertProps, axiosCall, getFacilityId, query_ids, urls } from "../../../utils";
import { AlertContext } from "../../../context";
import PicReviewSheet from "../../../screens/PicReviewSheet";

export const CustomDrawer = (props) => {
  const alertContext = useContext(AlertContext);
  const { list } = props;
  const classes = useStyles(props);
  const navigate = useNavigate();
  const [state, setState] = useState({
    index: null,
  });
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalPicReviewSheet, setModalPicReviewSheet] = React.useState(false);
  const [modalOpenforPatientChart, setModalOpenforPatientChart] =
    React.useState(false);

  // const [ireports, setireports] = React.useState({
  //   modal : null,
  //   name : "",
  //   ilink : ""
  // });
  const[PractList,setPractList] = React.useState([]);

  const handleModalClose = () => {
    setModalOpen(false);
    setModalOpenforPatientChart(false);
    setModalPicReviewSheet(false);
  };
  const onClickListing = async (data, index, parentIndex, navigate, props) => {
    if (data?.key_ === "cashCollection") {
      setModalOpen(true);
    } else if (data?.key_ === "nursingProcedureSummaryReport" || data?.key_ === "patientAttendanceSummaryReport" || data?.key_ === "billRegisterReport" || data?.key_ === "claimSummaryReport" || data?.key_ === "attendanceReport1" || data?.key_ === "monthwiseAttendanceReport" || data?.key_ === "observationReportDaily" || data?.key_ === "observationReportWeekly" || data?.key_ === "observationReportMonthly" || data?.key_ === "epoConsumptionReport" || data?.key_ === "panelReport" || data?.key_ === "patientReport" || data?.key_ === "cancelNoshowReport") {
      navigate(AppRoutes.iframes, {
        state: {
          link: data?.ilink,
          name: data?.label,
        },
      });
      props?.onClose();
    } else if (data?.key_ === "patientReviewChartReport") {
      const orgid = getFacilityId()
      let payload = {
        db_name: process.env.REACT_APP_DB,
          filter: {
             orgid: orgid
        },
        queryid: query_ids.getpatientreviewreport
        
        }
      const resp = await axiosCall(payload,urls.readQdmQueries)
      if(resp?.length === 0){
        alertContext?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "something went wrong Practitioner List is Empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
      setPractList(resp);
      setModalOpenforPatientChart(true);
    } else if (data?.link) {
      navigate({
        pathname: AppRoutes?.iframes,
        state: data.ilink,
      });
      props?.onClose && props?.onClose();
    }else  if (data?.key_ === "PICReviewSheetReport") {
      setModalPicReviewSheet(true);
    }
  };
  return (
    <div className={classes.root}>
      {list?.length &&
        list?.map((_, index) => {
          return (
            <div className="border">
              <div
                key={index}
                className={classes.rowCenterBtn}
                onClick={() => listClick(_, index, state, setState, navigate)}
              >
                <CustomListing label={_?.label} />
                {_?.children?.length ? (
                  <div
                    className={clsx(classes.expand, {
                      [classes.expandOpen]:
                        state.index === index ? true : false,
                    })}
                  >
                    <ExpandLessIcon />
                  </div>
                ) : null}
              </div>

              {_?.children && (
                <Collapse
                  id={`${props?.parent_id}_collapse_parent_collapse`}
                  in={state.index === index ? true : false}
                  timeout="auto"
                  unmountOnExit
                >
                  <div className={classes.children}>
                    {_?.children?.map((child, i) => {
                      return (
                        <div className="child">
                          <CustomListing
                            icon={child?.icon}
                            label={child?.label}
                            color={child?.color}
                            backgroundColor={child?.backgroundColor}
                            onClick={() =>
                              onClickListing(child, i, index, navigate, props)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </Collapse>
              )}
            </div>
          );
        })}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }} // Center the modal
      >
        <div
          style={{ backgroundColor: "#fff", padding: "20px", outline: "none" }}
        >
          <CashierCollectionSummary handleModalClose={handleModalClose} />
        </div>
      </Modal>
      <Modal
        open={modalPicReviewSheet}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }} // Center the modal
      >
        <div
          style={{ backgroundColor: "#fff", padding: "20px", outline: "none" }}
        >
          <PicReviewSheet handleModalClose={handleModalClose} />
        </div>
      </Modal>
      <Modal
        open={modalOpenforPatientChart}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }} // Center the modal
      >
        <div
          style={{ backgroundColor: "#fff", padding: "20px", outline: "none" }}
        >
          <PatientReviewChartReport handleModalClose={handleModalClose} PractList={PractList}/>
        </div>
      </Modal>
      {/* <Dialog onClose={()=>{
        setireports({
          modal:false,
          name:"",
          ilink:""
        })
      }} aria-labelledby="customized-dialog-title" open={ireports?.modal} fullWidth maxWidth="lg" > 
        <Grid container justifyContent="space-between" alignItems="center" style={{padding:"5px 25px"}}>
          <Grid item>
            <Typography component="h2" variant="h6" style={{fontWeight:"600",color:"#01205C"}} > {ireports?.name}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={()=>{
              setireports({
                modal:false,
                name:"",
                ilink:""
              })
            }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Iframe src={ireports?.ilink} allowfullscreen height={600}/>
      </Dialog> */}
    </div>
  );
};

CustomDrawer.defaultProps = {
  list: [],
};
