import React, { useContext, useState } from "react";
import { predialysis } from "../vitalsJson";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
  Divider,
  TextareaAutosize,
} from "@material-ui/core";
import { Image, Select, Text, TextInput } from "qdm-component-library";
import { UIColor } from "../../../themes/theme";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import { AlertContext } from "../../../context";
import {
  AlertProps,
  dbName,
  fetchData,
  getUserInfo,
  personRequests,
  urls,
} from "../../../utils";
import actions from "../../../redux/actions";
import { useDispatch } from "react-redux";
import Addicon from "../../../assets/img/icons8-add.svg";
import trashIcon from "../../../assets/img/svg/icons8-trash.svg";
import calendarIcon from "../../../assets/img/svg/icons8-calendar-grey.svg";
import jwtDecode from "jwt-decode";

const styles = {
  labelStyle: {
    fontSize: "14px",
    fontSWeight: "500",
    margin: "0",
    color: "#6A6A6A",
    marginBottom: "6px",
    fontFamily: "pc_regular !important",
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "red",
  },
  inputStyle: {
    padding: 5,
    marginBottom: 20,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  actionButton: {
    backgroundColor: "#FFFFFF",
    border: "solid 1px #DEDEDE",
    borderRadius: 6,
    color: "black",
    marginBottom: 8,
    boxShadow: "none",
    "&:hover": {},
    "&:first-child": {
      backgroundColor: "#FF4D4A",
      borderColor: "#FF4D4A",
      color: "#ffffff",
      fontWeight: 600,
    },
  },
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
}));
// function Adddialysistype({
// data = [],
// handlesave = () => false,
// statekey = {},
// handlecancel = () => false,
// handleEdit = () => false,
// edit = false,
// editstate = {},
// isErr = false,
// errorField = {}
// }) {
const Adddialysistype = (props) => {
  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo = userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const facilityId = decodedUserinfo?.facilityid ?? "";
  const {
    // data,
    handlesave,
    statekey,
    handlecancel,
    handleEdit,
    edit,
    editstate,
  } = props;
  const [data, setData] = useState(props?.data);
  const [state, setState] = useState({});
  const dispatch = useDispatch();
  const [errorsField, setErrorsFields] = React.useState({});
  const [errorField, setErrorFields] = React.useState({});
  const [hdMachineNo, setHdMachineNo] = React.useState([]);
  const [heparinDropDown, setHeparinDropDown] = React.useState([]);
  const [shiftTypeDropDown, setShiftTypeDropDown] = React.useState([]);
  const [practionerName, setPractionerName] = React.useState([]);
  const [datas, setDatas] = React.useState(false);
  const [isErr, setisErr] = React.useState(false);
  const [isErrs, setisErrs] = React.useState(false);
  const [isHepatitisTypeDisabled, setIsHepatitisTypeDisabled] = useState(false);
  const [striptestList, setstriptestList] = React.useState([]);
  const [iscommandbox, setiscommandbox] = React.useState(false);
  const alert = useContext(AlertContext);
  const classes = useStyles();
  React.useEffect(() => {
    if (edit) {
      setiscommandbox(editstate?.CMD?.obsvalue ? true : false);
      editstate?.Hepatatis?.obsvalue === "No"
      ? setIsHepatitisTypeDisabled(true)
      : setIsHepatitisTypeDisabled(false);
      setState(editstate);
    } else {
      setState(data);
      if (props?.statekey === "treatmentChart") {
        defaultValue(data, "treatmentChart");
      }
      if (props?.statekey === "predialysis") {
        defaultValue(data, "predialysis");
      }
      if (props?.statekey === "postdialysis") {
        defaultValue(data, "postdialysis");
      }
      if (props?.statekey === "intradialysis") {
        defaultValue(data, "intradialysis");
      }
    }
  }, [edit]);

  // const handlechange = (statekey, key, value, min_range, max_range) => {
  //   try {
  //     let obj = JSON.parse(JSON.stringify(state));
  //     obj[key].obsvalue = value;
  //     if (value < min_range || value > max_range) {
  //       obj[key].error = true;
  //       setErrorsFields(obj);
  //       setisErr(true);
  //     } else {
    //       obj[key].error = false;
  //       setErrorsFields(obj);
  //       setisErr(false);f
  //     }
  //     setState(obj);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const handlesaved = (statekey, value) => {
    let error = false;

    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        const obs = value[key];
        if (obs.obsvalue === "" && obs.mandatory) {
          if (key === "HepatatisType" && state?.Hepatatis?.obsvalue === "No") {
            continue;
          } else {
            errorField[obs.obscode] = { error: true };
            error = true;
            setisErrs(true);
            setErrorFields({ ...errorField });
            setDatas(true);
          }
        }
      }
    }

    if (error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the mandatory field",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }
    handlesave(
      statekey,
      state,
      props?.orderlineData,
      props?._id,
      props?.dialysisdataall
    );
  };

  const HD_Machine_No = async () => {
    const response = await dispatch(
      actions.HD_Machine_No({ encounterId: props?.encounterId })
    );
    setHdMachineNo(response?.payload?.data);
  };
  const HeparinDropDown = async () => {
    const response = await dispatch(actions.HeparinDropDown(""));
    setHeparinDropDown(response?.payload?.data);
  };
  const ShiftTypeDropDown = async () => {
    const response = await dispatch(actions.ShiftTypeDropDown(""));
    setShiftTypeDropDown(response?.payload?.data);
  };
  const Practioner_Name = async () => {
    const response = await dispatch(
      actions.Practioner_Name({ PractitionerId: props?.practid })
    );
    // handlechange("predialysis", "StaffID", response?.payload?.data?.[0]?.name);
    setPractionerName(response?.payload?.data);
  };
  const GetIdAndDisplay = (datas) => {
    let list = [];
    for (let index in datas) {
      const item = datas[index];
      var datatemp = {
        value: item.longdesc,
        _id: item._id,
      };
      list.push(datatemp);
    }
    return list.map((item) => item.value);
    // return list;
  };
  const FlterOptions3 = (props, type, code) => {
    let filteredArray = props.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?.display,
        _id: data[index]?._id,
      };

      list.push(datatemp);
    }
    if (code === "Qb (ml/min)") {
      return Array.from({ length: 11 }, (_, i) => 150 + i * 25).map(String);
    } else if (code === "Qd (ml/min)") {
      return Array.from({ length: 15 }, (_, i) => 150 + i * 25).map(String);
    } else {
      return list.map((item) => item.value);
    }
  };
  const handleEdited = (statekey, value) => {
    let error = false;

    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        //key === "Hepatatis" && value === "No" ? obj["HepatatisType"].obsvalue = "" : obj[key].obsvalue = value;
        let obs = value[key];
        if (obs.obsvalue === "" && obs.mandatory) {
          if (key === "HepatatisType" && state?.Hepatatis?.obsvalue === "No") {
            continue;
          } else {
            errorField[obs.obscode] = { error: true };
            error = true;
            setisErrs(true);
            setErrorFields({ ...errorField });
          }
        }
      }
    }
    if (error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Mandatory Fields cannot be empty and out of range",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }
    handleEdit(
      statekey,
      state,
      props?.orderlineData,
      props?._id,
      props?.dialysisdataall
    );
  };
  const handlechange = (
    statekey,
    key,
    value,
    min_range,
    max_range,
    obscodes
  ) => {
    try {
      let obj = {};
      if (key === "StaffID" && !edit) {
        obj = JSON.parse(JSON.stringify(state));
      } else {
        obj = JSON.parse(JSON.stringify(state));
      }
      if (key === "Hepatatis" && value === "No") {
        obj["HepatatisType"].obsvalue = "";
        setIsHepatitisTypeDisabled(true);
      } else {
        setIsHepatitisTypeDisabled(false);
      }
      obj[key].obsvalue = value;
      console.log(value, key, "[]{}[]");
      if (value === process.env.REACT_APP_TREATMENTCHART_HEPARIN) {
        setiscommandbox(true);
        obj["CMD"].mandatory = true;
      } else if (key === "Type") {
        setiscommandbox(false);
        obj["CMD"].obsvalue = "";
        obj["CMD"].mandatory = false;
      }
      if (obscodes === "BP" || obscodes === "BPSit" || obscodes === "BPStnd") {
        if (value.includes(" ") && !value.includes("/")) {
          let val = obj[key].obsvalue;
          let result = val.replace(/\s/g, "");
          obj[key].obsvalue = result + "/";
        }
        if (value.includes("/")) {
          let [value1, value2] = value.split("/");
          if (value1 < min_range || value1 > max_range) {
            obj[key].error = true;
            setErrorsFields((prevErrors) => ({
              ...prevErrors,
              [key]: { error: true },
            }));
            setisErr(true);
          } else {
            if (value2 < min_range || value2 > max_range) {
              obj[key].error = true;
              setErrorsFields((prevErrors) => ({
                ...prevErrors,
                [key]: { error: true },
              }));
              setisErr(true);
            } else {
              obj[key].error = false;
              setErrorsFields((prevErrors) => ({
                ...prevErrors,
                [key]: { error: false },
              }));
              setisErr(false);
            }

            errorField[obj[key].obscode] = { error: false };
            setErrorFields({ ...errorField });
          }
        } else {
          obj[key].error = true;
          setErrorsFields((prevErrors) => ({
            ...prevErrors,
            [key]: { error: true },
          }));
          setisErr(true);
        }
      } else {
        if (value < min_range || value > max_range) {
          obj[key].error = true;
          setErrorsFields((prevErrors) => ({
            ...prevErrors,
            [key]: { error: true },
          }));
          setisErr(true);
        } else {
          obj[key].error = false;
          setErrorsFields((prevErrors) => ({
            ...prevErrors,
            [key]: { error: false },
          }));
          setisErr(false);
          errorField[obj[key].obscode] = { error: false };
          setErrorFields({ ...errorField });
        }
      }
      // obj[key].practitionerName = practitionerName?.[0]?.name;
      // obj[key].LastVisitDate = new Date().toISOString().split("T")[0];
      setState(obj);
    } catch (error) {
      console.log(error);
    }
  };
  const idwgweight = (key) => {
    let obj = {};
    obj = JSON.parse(JSON.stringify(state));
    //state?.IDWG(PrelastHDi/kg)?.obsvalue
    let labelval = "IDWG";
    let dryweights = parseInt(state?.TargetDryWeight?.obsvalue);
    let preHDWeights = parseInt(state?.PreHDWeight?.obsvalue);
    let prevPostHDWeights = parseInt(state?.PrevPostHDWeight?.obsvalue);
    if (!isNaN(preHDWeights) && !isNaN(prevPostHDWeights)) {
      let calvalue = preHDWeights - prevPostHDWeights;
      // let idwgValue = Math.floor((calvalue / dryweights) * 100)
      obj[labelval].obsvalue = calvalue.toString();
      setState(obj);
    }
  };
  const totalweightloss = (key) => {
    let obj = {};
    obj = JSON.parse(JSON.stringify(state));
    let labelval = "TWL";
    let preHDWeights = parseInt(
      props?.dialysisData?.component?.[0]?.treatmentChart?.[0]?.PreHDWeight
        ?.obsvalue
    );
    let prevPostHDWeights = parseInt(state["PHDW"]?.obsvalue);
    if (!isNaN(preHDWeights) && !isNaN(prevPostHDWeights)) {
      let calvalue = preHDWeights - prevPostHDWeights;
      obj[labelval].obsvalue = calvalue.toString();
      setState(obj);
    }
  };
  const defaultValue = async(data, key) => {
    let obj = {};
    obj = JSON.parse(JSON.stringify(data));
    let dialysisDateValue = props?.dialysisDateValue;
    let lastVisitDateValue = props?.lastVisitDateValue;
    let arrivedTimeValue = props?.arrivedTimeValue;
    let startTimeValue = props?.startTimeValue;
    let endTimeValue = props?.endTimeValue;
    const payload = personRequests.getStripTestByList(facilityId);
    const queryDataResp = await fetchData(payload, urls.readQdmQueries);
    
    const userInfo = getUserInfo();
    const staffId = jwtDecode(localStorage.getItem("UserInfo"))?.practitionerid ;

    if (key === "treatmentChart") {
      if (dialysisDateValue) {
        obj["DialysisDate"].obsvalue = dialysisDateValue.toString();
        setState(obj);
      }
      if (lastVisitDateValue) {
        obj["LastVisitDate"].obsvalue = lastVisitDateValue.toString();
        setState(obj);
      }
      if (props?.hdMachineNo) {
        obj["HDMachineNo"].obsvalue = props?.hdMachineNo?.value;
        setState(obj);
      }
      if (props?.hdDuration) {
        obj["Duration"].obsvalue = props?.hdDuration;
        setState(obj);
      }
      if (props?.previousWeight) {
        obj["PrevPostHDWeight"].obsvalue = props?.previousWeight.toString(); //Number();
        setState(obj);
      }
      if (props?.frequency) {
        obj["Frequency"].obsvalue = props?.frequency?.value;
        setState(obj);
      }
      if (props?.needle) {
        obj["AVFneedlesize"].obsvalue = props?.needle?.value;
        setState(obj);
      }
      // if (props?.dialysisstate) {
      //   obj["DialysateCa2"].obsvalue = props?.dialysisstate?.value;
      //   setState(obj);
      // }
      if (props?.shift) {
        obj["Shift"].obsvalue = props?.shift?.value;
        setState(obj);
      }
      // if (userInfo?.name) {
      //   obj["StaffID"].obsvalue = userInfo?.name;
      //   setState(obj);
      // }
      if (props?.qb) {
        obj["Qb"].obsvalue = String(props?.qb?.value);
        setState(obj);
      }
      if (props?.qd) {
        obj["Qd"].obsvalue = String(props?.qd?.value);
        setState(obj);
      }
      if (props?.dialysateCa2) {
        obj["DialysateCa2"].obsvalue = props?.dialysateCa2?.value;
        setState(obj);
      }
    } else if (key === "predialysis") {
      if (arrivedTimeValue) {
        obj["TimeArrived"].obsvalue = arrivedTimeValue?.value;
        setState(obj);
      }
      if (startTimeValue) {
        obj["TimeStart"].obsvalue = startTimeValue?.value;
        setState(obj);
      }
      if (props?.hepatitis) {
        obj["Hepatatis"].obsvalue = props?.hepatitis?.value;
        setState(obj);
        if (props?.hepatitis?.value === "No") {
          setIsHepatitisTypeDisabled(true);
        }
      }
    } else if (key === "postdialysis") {
      if (endTimeValue) {
        obj["EndTime"].obsvalue = endTimeValue?.value;
        setState(obj);
      }
    } else if (key === "intradialysis") {
      // obj["StaffID"].obsvalue = userInfo?.name;
      // setState(obj);
    }
    if (staffId) { 
      for (const item of queryDataResp) {
        if (item._id === staffId){
          obj["StaffID"].obsvalue = item.name;
          if(obj.striptest){
            obj["striptest"].obsvalue = item.name;
          }
          setState(obj);
        }
       }
     }
  };
  const handleAddEntry = (list, key) => {
    let temp = JSON.parse(JSON.stringify(state));
    temp?.[key]?.push(list?.COMPLICATION?.[0]);
    let datavalue = JSON.parse(JSON.stringify(data));
    datavalue?.[key]?.push(list?.COMPLICATION?.[0]);
    setData(datavalue); //json
    setState({ ...temp }); // value
  };
  const handlecomplicationchange = (
    groupname,
    statekey,
    list,
    value,
    index
  ) => {
    let obj = {};
    if (groupname === "StaffID" && !edit) {
      obj = JSON.parse(JSON.stringify(data));
    } else {
      obj = JSON.parse(JSON.stringify(state));
    }

    if (groupname === "Complication") {
      obj[groupname.toUpperCase()]?.map((v, i) => {
        if (index === i) {
          v[statekey].obsvalue = value;
        }
      });
      setState(obj);
      return;
    }
  };
  const handleComplitionDelete = (value, index) => {
    let temp = data;
    let statevalue = JSON.parse(JSON.stringify(state));
    //temp?.["COMPLICATION"].remove(index);

    if (statevalue?.["COMPLICATION"].length == 1) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Atleast one Complication should be entered",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
      temp?.["COMPLICATION"]?.splice(index, 1);
      statevalue?.["COMPLICATION"]?.splice(index, 1);
      setData(temp);
      setState({ ...statevalue });
    }
  };
  const getStripListByData = async (key) => {
    const payload = personRequests.getStripTestByList(facilityId);
    const queryDataResp = await fetchData(payload, urls.readQdmQueries);
    setstriptestList(queryDataResp);
  };

  React.useEffect(() => {
    let value = Object.keys(data).filter((li) => li === "HDMachineNo");

    if (value?.length > 0) {
      HD_Machine_No();
      HeparinDropDown();
    }
    ShiftTypeDropDown();
    getStripListByData();
  }, []);
  // React.useEffect(() => {
  //   let value = Object.keys(data).filter((li) => li === "StaffID");

  //   if (value?.length > 0) {
  //     Practioner_Name();
  //   }
  // }, []);
  // React.useEffect(() => {
  //   let value = Object.keys(data).filter((li) => li === "DialysisDate");
  //   if (value?.length > 0) {
  //     handlechange("predialysis", "DialysisDate", new Date().toISOString().split("T")[0]);
  //   }
  // }, []);

  const striptestByList = (data) => {
    let striptestbyList = [];
    // queryDataResp?.map((v)=>{
    //   let obj = {
    //     _id : v?._id,
    //     value:v?.name
    //   }
    //   striptestList.push(obj);
    // })
    // return striptestList.map((item) => item.value);

    for (let index in data) {
      const item = data[index];
      var datatemp = {
        value: item.name,
        _id: item._id,
      };
      striptestbyList.push(datatemp);
    }
    return striptestbyList.map((item) => item.value);
  };
  return (
    <div style={{ margin: "10px 0px", overflowX: "hidden" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "5px 25px" }}
      >
        <Grid item>
          <Typography
            component="h2"
            variant="h6"
            style={{
              fontWeight: "600",
              color: "#01205C",
              textTransform: "capitalize",
            }}
          >
            {statekey.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={handlecancel}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        style={
          props?.historyView
            ? { margin: "16px", pointerEvents: "none" }
            : { margin: "16px" }
        }
      >
        {Object.keys(data)?.map((v, i) => {
          const groupname =
            data[v]?.groupname || data[v]?.[0]?.complication?.groupname;
          const isFirstInGroup =
            i === 0 ||
            data?.[Object.keys(data)[i - 1]]?.groupname !== groupname;
          if (groupname === "Weight") {
            return (
              <>
                {isFirstInGroup && (
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      {groupname}
                    </Typography>
                  </Grid>
                )}
                <Grid item key={`${v}-${i}`}>
                  {data?.[v]?.type === "text" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      isRequired={data?.[v]?.mandatory}
                      size={"small"}
                      // type={"text"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      iconText={data?.[v]?.default_unit}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                    />
                  )}
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        isRequired={data?.[v]?.mandatory}
                        id="size-small-standard"
                        size="small"
                        // options={data?.[v]?.dropdownvalues ?? []}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option.label}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                  {data?.[v]?.type === "number" && (
                    <>
                      {data?.[v]?.obscode === "IDWG" ||
                      data?.[v]?.obscode === "TWL" ? (
                        <>
                          <TextInput
                            label={data?.[v]?.shortdesc}
                            isRequired={data?.[v]?.mandatory}
                            size={"small"}
                            type={"number"}
                            iconText={data?.[v]?.default_unit}
                            onChange={(e) => {
                              handlechange(
                                "predialysis",
                                v,
                                e.target.value,
                                data?.[v]?.validation_min,
                                data?.[v]?.validation_max
                              );
                            }}
                            disabled={true}
                            value={state?.[v]?.obsvalue ?? ""}
                            labelStyle={styles.labelStyle}
                            placeholder={""}
                            // style={styles.inputFiledStyle}
                            style={
                              isErrs &&
                              errorField[data?.[v]?.obscode]?.error === true
                                ? styles.errorborderStyle
                                : styles.inputFiledStyle
                            }
                            //hoverColor="#0071F2"
                            hoverColor={UIColor.lineBorderFill.color}
                            // error={
                            //   isErr && errorsField[data?.[v]?.obscode]?.error === true
                            //     ? true
                            //     : false
                            // }
                          />
                          <div
                            style={{
                              // marginTop: "3%",
                              position: "absolute",
                              // bottom: 20,
                              marginBottom: "10px",
                            }}
                          >
                            {isErr &&
                            errorsField[data?.[v]?.obscode]?.error === true ? (
                              <span style={{ color: "red", fontSize: 12 }}>
                                {"Value should be " +
                                  data?.[v]?.validation_min +
                                  " to " +
                                  data?.[v]?.validation_max +
                                  "."}
                              </span>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <>
                          <TextInput
                            label={data?.[v]?.shortdesc}
                            isRequired={data?.[v]?.mandatory}
                            size={"small"}
                            type={"number"}
                            iconText={data?.[v]?.default_unit}
                            onChange={(e) => {
                              handlechange(
                                "predialysis",
                                v,
                                e.target.value,
                                data?.[v]?.validation_min,
                                data?.[v]?.validation_max
                              );
                            }}
                            onBlurCapture={(e) => idwgweight(e, "stateLabel")}
                            value={state?.[v]?.obsvalue ?? ""}
                            labelStyle={styles.labelStyle}
                            placeholder={""}
                            // style={styles.inputFiledStyle}
                            style={
                              isErrs &&
                              errorField[data?.[v]?.obscode]?.error === true
                                ? styles.errorborderStyle
                                : styles.inputFiledStyle
                            }
                            //hoverColor="#0071F2"
                            hoverColor={UIColor.lineBorderFill.color}
                            // error={
                            //   isErr && errorsField[data?.[v]?.obscode]?.error === true
                            //     ? true
                            //     : false
                            // }
                          />
                          <div
                            style={{
                              // marginTop: "3%",
                              position: "absolute",
                              // bottom: 20,
                              marginBottom: "10px",
                            }}
                          >
                            {isErr &&
                            errorsField[data?.[v]?.obscode]?.error === true ? (
                              <span style={{ color: "red", fontSize: 12 }}>
                                {"Value should be " +
                                  data?.[v]?.validation_min +
                                  " to " +
                                  data?.[v]?.validation_max +
                                  "."}
                              </span>
                            ) : null}
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {data?.[v]?.type === "date" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      isRequired={data?.[v]?.mandatory}
                      iconText={data?.[v]?.default_unit}
                      size={"small"}
                      type={"date"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                    />
                  )}
                </Grid>
              </>
            );
          } else if (groupname === "Heparin") {
            return (
              <>
                {isFirstInGroup && (
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      {groupname}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  {data?.[v]?.type === "text" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      size={"small"}
                      type={"text"}
                      iconText={data?.[v]?.default_unit}
                      // onChange={(e) => {
                      //   handlechange("predialysis", v, e.target.value);
                      // }}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        if (data?.[v]?.shortdesc === "Staff ID") {
                          newValue = "staff 1";
                          handlechange("predialysis", v, newValue);
                        } else {
                          handlechange("predialysis", v, newValue);
                        }
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                      isRequired={data?.[v]?.mandatory}
                    />
                  )}
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        options={FlterOptions3(heparinDropDown, "HEPARINTYPE")}
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option.label}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                  {data?.[v]?.type === "number" && (
                    <>
                      <TextInput
                        label={data?.[v]?.shortdesc}
                        isRequired={data?.[v]?.mandatory}
                        size={"small"}
                        type={"number"}
                        iconText={data?.[v]?.default_unit}
                        onChange={(e) => {
                          handlechange(
                            "predialysis",
                            v,
                            e.target.value,
                            data?.[v]?.validation_min,
                            data?.[v]?.validation_max
                          );
                        }}
                        value={state?.[v]?.obsvalue ?? ""}
                        labelStyle={styles.labelStyle}
                        placeholder={""}
                        // style={styles.inputFiledStyle}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? styles.errorborderStyle
                            : styles.inputFiledStyle
                        }
                        //hoverColor="#0071F2"
                        hoverColor={UIColor.lineBorderFill.color}
                        // error={
                        //   isErr && errorsField[data?.[v]?.obscode]
                        //     ? true
                        //     : false
                        // }
                      />
                      <div
                        style={{
                          // marginTop: "3%",
                          position: "absolute",
                          // bottom: 20,
                        }}
                      >
                        {isErr &&
                        errorsField[data?.[v]?.obscode]?.error === true ? (
                          <span style={{ color: "red", fontSize: 12 }}>
                            {"Value should be " +
                              data?.[v]?.validation_min +
                              " to " +
                              data?.[v]?.validation_max +
                              "."}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                  {data?.[v]?.type === "date" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      isRequired={data?.[v]?.mandatory}
                      size={"small"}
                      type={"date"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      iconText={data?.[v]?.default_unit}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                    />
                  )}
                  {data?.[v]?.type === "select" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        options={
                          data?.[v]?.shortdesc === "Loading" ||
                          data?.[v]?.shortdesc === "Maintenance"
                            ? Array.from(
                                { length: 20 },
                                (_, i) => (i + 1) * 500
                              ).map(String)
                            : data?.[v]?.dropdownvalues ?? []
                        }
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => {
                          if (option == null) {
                            return "";
                          }
                          return String(option);
                        }}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option.label}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                  {data?.[v]?.type === "textC" && iscommandbox && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      size={"small"}
                      type={"text"}
                      iconText={data?.[v]?.default_unit}
                      // onChange={(e) => {
                      //   handlechange("predialysis", v, e.target.value);
                      // }}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        handlechange("predialysis", v, newValue);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                      isRequired={state?.[v]?.mandatory}
                    />
                  )}
                </Grid>
              </>
            );
          } else if (groupname === "Hd Duration") {
            return (
              <>
                {isFirstInGroup && (
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      {groupname}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  {data?.[v]?.type === "text" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      size={"small"}
                      type={"text"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      iconText={data?.[v]?.default_unit}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                      isRequired={data?.[v]?.mandatory}
                    />
                  )}
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        options={FlterOptions3(shiftTypeDropDown, "DAYTYPE")}
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option.label}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                  {data?.[v]?.type === "number" && (
                    <>
                      <TextInput
                        label={data?.[v]?.shortdesc}
                        isRequired={data?.[v]?.mandatory}
                        size={"small"}
                        type={"number"}
                        onChange={(e) => {
                          handlechange(
                            "predialysis",
                            v,
                            e.target.value,
                            data?.[v]?.validation_min,
                            data?.[v]?.validation_max
                          );
                        }}
                        value={state?.[v]?.obsvalue ?? ""}
                        labelStyle={styles.labelStyle}
                        placeholder={""}
                        // style={styles.inputFiledStyle}
                        iconText={data?.[v]?.default_unit}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? styles.errorborderStyle
                            : styles.inputFiledStyle
                        }
                        //hoverColor="#0071F2"
                        hoverColor={UIColor.lineBorderFill.color}
                        // error={
                        //   isErr && errorsField[data?.[v]?.obscode]
                        //     ? true
                        //     : false
                        // }
                      />
                      <div
                        style={{
                          // marginTop: "3%",
                          position: "absolute",
                          // bottom: 20,
                        }}
                      >
                        {isErr &&
                        errorsField[data?.[v]?.obscode]?.error === true ? (
                          <span style={{ color: "red", fontSize: 12 }}>
                            {"Value should be " +
                              data?.[v]?.validation_min +
                              " to " +
                              data?.[v]?.validation_max +
                              "."}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                  {data?.[v]?.type === "date" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      isRequired={data?.[v]?.mandatory}
                      size={"small"}
                      type={"date"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      iconText={data?.[v]?.default_unit}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                    />
                  )}
                  {data?.[v]?.type === "select" && (
                    <>
                      <>
                        <p
                          style={{
                            fontSize: "14px",
                            fontSWeight: "500",
                            margin: "0",
                            color: "#6A6A6A",
                            marginBottom: "6px",
                            fontFamily: "pc_regular !important",
                          }}
                        >
                          {data?.[v]?.shortdesc}
                          <span
                            style={{
                              color: "red",
                              fontSWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            *
                          </span>
                        </p>
                        {/* <Autocomplete
                          // style={{ width: 190 }}
                          style={
                            isErrs &&
                            errorField[data?.[v]?.obscode]?.error === true
                              ? {
                                  width: 190,
                                  border: "1px solid red",
                                  borderRadius: 8,
                                }
                              : { width: 190 }
                          }
                          className={classes.autocomplete}
                          id="size-small-standard"
                          size="small"
                          isRequired={true}
                          options={
                            data?.[v]?.shortdesc === "Frequency (/week)"
                              ? data?.[v]?.dropdownvalues ?? [] //Array.from({ length: 53 }, (_, i) => i)
                              : data?.[v]?.dropdownvalues ?? []
                          }
                          popupIcon={
                            <ExpandMoreIcon
                              id={`${v}_allergy_type_expandmoreicon`}
                              fontSize="small"
                              style={{ color: "#b4aeae" }}
                            />
                          }
                          disableClearable
                          getOptionLabel={(option) => {
                            if (option == null) {
                              return "";
                            }
                            return String(option);
                          }}
                          renderInput={(params) => (
                            <TextField
                              id={`${v}_allergy_type_textfield`}
                              {...params}
                              variant="outlined"
                              iconText={data?.[v]?.default_unit}
                            />
                          )}
                          renderOption={(option) => (
                            <Typography
                              id={`${v}_allergy_${option.label}_typography`}
                              style={{ width: "100%" }}
                              noWrap
                            >
                              {option}
                            </Typography>
                          )}
                          classes={{ popper: "muiPopperDropdown" }}
                          value={state?.[v]?.obsvalue ?? ""}
                          // onChange={(e, newValue) => {
                          //   handlechange("predialysis", v, newValue);
                          // }}
                          // onChange={(e, newValue) => {
                          //   if (data?.[v]?.shortdesc === "Frequency (/week)") {
                          //     newValue = newValue === undefined || isNaN(newValue) ? 0 : Number(newValue);
                          //   }
                          //   handlechange("predialysis", v, newValue);
                          // }}
                          onChange={(e, newValue) => {
                            if (data?.[v]?.shortdesc === "Frequency (/week)") {
                              newValue = String(newValue);
                            }
                            handlechange("predialysis", v, newValue);
                          }}
                        /> */}
                        <Autocomplete
                          // style={{ width: 190 }}
                          style={
                            isErrs &&
                            errorField[data?.[v]?.obscode]?.error === true
                              ? {
                                  width: 190,
                                  border: "1px solid red",
                                  borderRadius: 8,
                                }
                              : { width: 190 }
                          }
                          //className={"type"}
                          className={classes.autocomplete}
                          id="size-small-standard"
                          size="small"
                          // options={data?.[v]?.dropdownvalues ?? []}
                          options={FlterOptions3(
                            shiftTypeDropDown,
                            "SHIFTTYPE"
                          )}
                          isRequired={data?.[v]?.mandatory}
                          popupIcon={
                            <ExpandMoreIcon
                              id={`${v}_allergy_type_expandmoreicon`}
                              fontSize="small"
                              style={{ color: "#b4aeae" }}
                            />
                          }
                          disableClearable
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              id={`${v}_allergy_type_textfield`}
                              {...params}
                              variant="outlined"
                              placeholder=""
                              //label={data?.[v]?.shortdesc}
                              // inputProps={{
                              //   ...params.inputProps,
                              //   style: { fontSize: "14px" },
                              // }}
                              iconText={data?.[v]?.default_unit}
                            />
                          )}
                          renderOption={(option) => (
                            <Typography
                              id={`${v}_allergy_${option.label}_typography`}
                              style={{ width: "100%" }}
                              noWrap
                            >
                              {option}
                            </Typography>
                          )}
                          classes={{ popper: "muiPopperDropdown" }}
                          value={state?.[v]?.obsvalue ?? ""}
                          onChange={(e, newValue) => {
                            handlechange("predialysis", v, newValue);
                          }}
                        />
                      </>
                    </>
                  )}
                </Grid>
              </>
            );
          } else if (groupname === "Flow Rate") {
            return (
              <>
                {isFirstInGroup && (
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      {groupname}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  {data?.[v]?.type === "text" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      size={"small"}
                      type={"text"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                      isRequired={data?.[v]?.mandatory}
                      iconText={data?.[v]?.default_unit}
                    />
                  )}
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        // options={data?.[v]?.dropdownvalues ?? []}
                        options={FlterOptions3(
                          heparinDropDown,
                          "FLOWRATEAVFNEEDLESIZE",
                          data?.[v]?.shortdesc
                        )}
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option.label}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                  {data?.[v]?.type === "number" && (
                    <>
                      <TextInput
                        label={data?.[v]?.shortdesc}
                        isRequired={data?.[v]?.mandatory}
                        size={"small"}
                        type={"number"}
                        onChange={(e) => {
                          handlechange(
                            "predialysis",
                            v,
                            e.target.value,
                            data?.[v]?.validation_min,
                            data?.[v]?.validation_max
                          );
                        }}
                        value={state?.[v]?.obsvalue ?? ""}
                        labelStyle={styles.labelStyle}
                        placeholder={""}
                        iconText={data?.[v]?.default_unit}
                        // style={styles.inputFiledStyle}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? styles.errorborderStyle
                            : styles.inputFiledStyle
                        }
                        //hoverColor="#0071F2"
                        hoverColor={UIColor.lineBorderFill.color}
                        // error={
                        //   isErr && errorsField[data?.[v]?.obscode]
                        //     ? true
                        //     : false
                        // }
                      />
                      <div
                        style={{
                          // marginTop: "3%",
                          position: "absolute",
                          // bottom: 20,
                        }}
                      >
                        {isErr &&
                        errorsField[data?.[v]?.obscode]?.error === true ? (
                          <span style={{ color: "red", fontSize: 12 }}>
                            {"Value should be " +
                              data?.[v]?.validation_min +
                              " to " +
                              data?.[v]?.validation_max +
                              "."}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                  {data?.[v]?.type === "date" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      isRequired={data?.[v]?.mandatory}
                      size={"small"}
                      type={"date"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      iconText={data?.[v]?.default_unit}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                    />
                  )}
                </Grid>
              </>
            );
          } else if (groupname === "Dialyzer") {
            return (
              <>
                {isFirstInGroup && (
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      {groupname}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  {data?.[v]?.type === "text" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      size={"small"}
                      type={"text"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      iconText={data?.[v]?.default_unit}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                      isRequired={data?.[v]?.mandatory}
                    />
                  )}
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        // options={data?.[v]?.dropdownvalues ?? []}
                        options={
                          data?.[v]?.shortdesc === "Dialysate Ca2+"
                            ? FlterOptions3(shiftTypeDropDown, "DIALYSATE")
                            : FlterOptions3(heparinDropDown, "DIALYZERHIGHFLUX")
                        }
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option.label}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                  {data?.[v]?.type === "number" && (
                    <>
                      <TextInput
                        label={data?.[v]?.shortdesc}
                        isRequired={data?.[v]?.mandatory}
                        size={"small"}
                        type={"number"}
                        onChange={(e) => {
                          handlechange(
                            "predialysis",
                            v,
                            e.target.value,
                            data?.[v]?.validation_min,
                            data?.[v]?.validation_max
                          );
                        }}
                        iconText={data?.[v]?.default_unit}
                        value={state?.[v]?.obsvalue ?? ""}
                        labelStyle={styles.labelStyle}
                        placeholder={""}
                        // style={styles.inputFiledStyle}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? styles.errorborderStyle
                            : styles.inputFiledStyle
                        }
                        //hoverColor="#0071F2"
                        hoverColor={UIColor.lineBorderFill.color}
                        // error={
                        //   isErr && errorsField[data?.[v]?.obscode]
                        //     ? true
                        //     : false
                        // }
                      />
                      <div
                        style={
                          {
                            // marginTop: "3%",
                            // position: "absolute",
                            // bottom: 20,
                          }
                        }
                      >
                        {isErr &&
                        errorsField[data?.[v]?.obscode]?.error === true ? (
                          <span style={{ color: "red", fontSize: 12 }}>
                            {"Value should be " +
                              data?.[v]?.validation_min +
                              " to " +
                              data?.[v]?.validation_max +
                              "."}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                  {data?.[v]?.type === "date" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      isRequired={data?.[v]?.mandatory}
                      size={"small"}
                      type={"date"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      iconText={data?.[v]?.default_unit}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                    />
                  )}
                  {data?.[v]?.type === "select" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        isRequired={true}
                        options={
                          data?.[v]?.shortdesc === "No of reuse"
                            ? Array.from({ length: 16 }, (_, i) => i)
                            : data?.[v]?.dropdownvalues ?? []
                        }
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => {
                          if (option == null) {
                            return "";
                          }
                          return String(option);
                        }}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option.label}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        // onChange={(e, newValue) => {
                        //   handlechange("predialysis", v, newValue);
                        // }}
                        onChange={(e, newValue) => {
                          if (data?.[v]?.shortdesc === "No of reuse") {
                            newValue = String(newValue);
                          }
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                </Grid>
              </>
            );
          } else if (groupname === "Hourly Monitoring") {
            return (
              <>
                {isFirstInGroup && (
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      {groupname}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  {data?.[v]?.type === "text" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      size={"small"}
                      type={"text"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                      isRequired={data?.[v]?.mandatory}
                      disabled
                    />
                  )}
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        options={data?.[v]?.dropdownvalues ?? []}
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option.label}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                  {data?.[v]?.type === "number" && (
                    <>
                      <TextInput
                        label={data?.[v]?.shortdesc}
                        isRequired={data?.[v]?.mandatory}
                        size={"small"}
                        type={data?.[v]?.obscode === "BP" ? "text" : "number"}
                        onChange={(e) => {
                          if (data?.[v]?.obscode === "BP") {
                            let pattern = state?.[v]?.obsvalue.includes("/")
                              ? /^[0-9]+(?:\/[0-9]*)?$/
                              : /^[0-9\/\s]*$/;
                            let result = pattern.test(e.target.value);
                            if (result || e.target.value === "") {
                              handlechange(
                                "predialysis",
                                v,
                                e.target.value,
                                data?.[v]?.validation_min,
                                data?.[v]?.validation_max,
                                data?.[v]?.obscode
                              );
                            }
                          } else {
                            handlechange(
                              "predialysis",
                              v,
                              e.target.value,
                              data?.[v]?.validation_min,
                              data?.[v]?.validation_max
                            );
                          }
                        }}
                        value={state?.[v]?.obsvalue ?? ""}
                        labelStyle={styles.labelStyle}
                        placeholder={
                          data?.[v]?.obscode === "BP" ? "Eg : 80/120" : ""
                        }
                        // style={styles.inputFiledStyle}
                        iconText={data?.[v]?.default_unit}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? styles.errorborderStyle
                            : styles.inputFiledStyle
                        }
                        //hoverColor="#0071F2"
                        hoverColor={UIColor.lineBorderFill.color}
                        // error={
                        //   isErr && errorsField[data?.[v]?.obscode]
                        //     ? true
                        //     : false
                        // }
                      />
                      <div
                        style={{
                          // marginTop: "3%",
                          position: "absolute",
                          // bottom: 20,
                        }}
                      >
                        {isErr &&
                        errorsField[data?.[v]?.obscode]?.error === true ? (
                          <span style={{ color: "red", fontSize: 12 }}>
                            {"Value should be " +
                              data?.[v]?.validation_min +
                              " to " +
                              data?.[v]?.validation_max +
                              "."}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                  {data?.[v]?.type === "date" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      isRequired={data?.[v]?.mandatory}
                      size={"small"}
                      type={"date"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      iconText={data?.[v]?.default_unit}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      inLineStyles={{
                        width: "190px",
                      }}
                    />
                  )}
                  {data?.[v]?.type === "time" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      isRequired={data?.[v]?.mandatory}
                      size={"small"}
                      type={"time"}
                      // type={"datetime-local"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      iconText={data?.[v]?.default_unit}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      inLineStyles={{
                        width: "190px",
                      }}
                    />
                  )}
                  {data?.[v]?.type === "select" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        options={
                          data?.[v]?.shortdesc === "Qb - mL/min"
                            ? Array.from(
                                { length: 11 },
                                (_, i) => 150 + i * 25
                              ).map(String)
                            : data?.[v]?.shortdesc === "Heparin"
                            ? Array.from(
                                { length: 11 },
                                (_, i) => 0 + i * 500
                              ).map(String)
                            : data?.[v]?.shortdesc === "Qd - mL/min"
                            ? Array.from(
                                { length: 15 },
                                (_, i) => 150 + i * 25
                              ).map(String)
                            : data?.[v]?.dropdownvalues ?? []
                        }
                        isRequired={data?.[v]?.mandatory}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => {
                          if (option == null) {
                            return "";
                          }
                          return String(option);
                        }}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option.label}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        // onChange={(e, newValue) => {
                        //   handlechange("predialysis", v, newValue);
                        // }}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                </Grid>
              </>
            );
          } else if (groupname === "Complication") {
            return (
              <React.Fragment key={1}>
                {isFirstInGroup && (
                  <Grid item xs={12}>
                    <Grid
                      container
                      xs={10}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          style={{ fontWeight: "bold", fontSize: "14px" }}
                        >
                          {groupname}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div style={{ cursor: "pointer", marginTop: "8px" }}>
                          <Text
                            onClick={() => handleAddEntry(data, v)}
                            style={{
                              float: "right",
                              color: "#2A60BC",
                              fontSize: "14px",
                              fontFamily: "pc_medium",
                            }}
                          >
                            + Add New
                          </Text>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {state?.[v]?.map((datas, i) => (
                  <Grid item container spacing={2}>
                    <Grid
                      container
                      spacing={2}
                      style={{ margin: "10px 0px 0px 12px" }}
                    >
                      <Grid
                        container
                        xs={10}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item container xs={10} spacing={2}>
                          <Grid item>
                            {datas?.time?.type === "time" && (
                              <TextInput
                                label={datas?.time?.shortdesc}
                                isRequired={datas?.time?.mandatory}
                                size={"small"}
                                type={"time"}
                                // type={"datetime-local"}
                                onChange={(e) => {
                                  handlecomplicationchange(
                                    groupname,
                                    "time",
                                    datas,
                                    e.target.value,
                                    i
                                  );
                                }}
                                value={state[v]?.[i]?.time?.obsvalue ?? ""}
                                labelStyle={styles.labelStyle}
                                placeholder={""}
                                style={
                                  isErrs && !state[v]?.[i]?.time?.obsvalue
                                    ? styles.errorborderStyle
                                    : styles.inputFiledStyle
                                }
                                inLineStyles={{
                                  width: "190px",
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid item style={{ marginRight: "15px" }}>
                          <Grid
                            item
                            style={{
                              marginTop: "20px",
                              cursor: "pointer",
                            }}
                          >
                            <Image
                              id={`${props?.parent_id}_${i}_patientidentification_delete_image`}
                              src={trashIcon}
                              alt="delete"
                              onClick={() => handleComplitionDelete(datas, i)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={5}
                      direction="column"
                      style={{ marginLeft: "5px" }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {datas?.complication?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      {datas?.complication?.type === "text" && (
                        <TextareaAutosize
                          // label={datas?.complication?.shortdesc}
                          size={"small"}
                          type={"text"}
                          multiline
                          variant="outlined"
                          onChange={(e) => {
                            handlecomplicationchange(
                              groupname,
                              "complication",
                              datas,
                              e.target.value,
                              i
                            );
                          }}
                          value={state[v]?.[i]?.complication?.obsvalue ?? ""}
                          // labelStyle={styles.labelStyle}
                          placeholder={""}
                          // style={styles.inputFiledStyle}
                          style={
                            isErrs && !state[v]?.[i]?.complication?.obsvalue
                              ? styles.errorborderStyle
                              : {
                                  width: "100%",
                                  height: "50px",
                                  outline: "1px solid rgb(222, 229, 236) ",
                                  border: "0px",
                                  borderRadius: "8px",
                                  padding: "5px",
                                }
                          }
                          //hoverColor="#0071F2"
                          // hoverColor={UIColor.lineBorderFill.color}
                          isRequired={datas?.complication?.mandatory}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      container
                      xs={5}
                      direction="column"
                      style={{ marginLeft: "5px" }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {datas?.action?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      {datas?.action?.type === "text" && (
                        <TextareaAutosize
                          label={datas?.action?.shortdesc}
                          size={"small"}
                          type={"text"}
                          multiline
                          onChange={(e) => {
                            handlecomplicationchange(
                              groupname,
                              "action",
                              datas,
                              e.target.value,
                              i
                            );
                          }}
                          value={state[v]?.[i]?.action?.obsvalue ?? ""}
                          // labelStyle={styles.labelStyle}
                          placeholder={""}
                          // style={styles.inputFiledStyle}
                          style={
                            isErrs && !state[v]?.[i]?.action?.obsvalue
                              ? styles.errorborderStyle
                              : {
                                  width: "100%",
                                  height: "50px",
                                  outline: "1px solid rgb(222, 229, 236) ",
                                  border: "0px",
                                  borderRadius: "8px",
                                  padding: "5px",
                                }
                          }
                          //hoverColor="#0071F2"
                          // hoverColor={UIColor.lineBorderFill.color}
                          isRequired={datas?.action?.mandatory}
                        />
                      )}
                    </Grid>
                    {state?.[v]?.length > 1 && (
                      <Grid
                        item
                        xs={10}
                        style={{
                          borderBottom: "2px dotted #DEE5EC ",
                          margin: "5px 0px 5px 10px ",
                        }}
                      ></Grid>
                    )}
                  </Grid>
                ))}
              </React.Fragment>
            );
          } else if (groupname === "Nursing Notes") {
            return (
              <>
                {isFirstInGroup && (
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      {groupname}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  {data?.[v]?.type === "text" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      size={"small"}
                      type={"text"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      iconText={data?.[v]?.default_unit}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                      isRequired={data?.[v]?.mandatory}
                    />
                  )}
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        isRequired={data?.[v]?.mandatory}
                        options={
                          data?.[v]?.obscode === "StaffID"
                            ? striptestByList(striptestList)
                            : data?.[v]?.obscode === "NN"
                            ? FlterOptions3(shiftTypeDropDown, "NURSINGNOTES")
                            : data?.[v]?.dropdownvalues || []
                        }
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                            iconText={data?.[v]?.default_unit}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option.label}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                  {data?.[v]?.type === "number" && (
                    <>
                      <TextInput
                        label={data?.[v]?.shortdesc}
                        isRequired={data?.[v]?.mandatory}
                        size={"small"}
                        type={"number"}
                        onChange={(e) => {
                          handlechange(
                            "predialysis",
                            v,
                            e.target.value,
                            data?.[v]?.validation_min,
                            data?.[v]?.validation_max
                          );
                        }}
                        value={state?.[v]?.obsvalue ?? ""}
                        labelStyle={styles.labelStyle}
                        iconText={data?.[v]?.default_unit}
                        placeholder={""}
                        // style={styles.inputFiledStyle}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? styles.errorborderStyle
                            : styles.inputFiledStyle
                        }
                        //hoverColor="#0071F2"
                        hoverColor={UIColor.lineBorderFill.color}
                        // error={
                        //   isErr && errorsField[data?.[v]?.obscode]
                        //     ? true
                        //     : false
                        // }
                      />
                      <div
                        style={{
                          // marginTop: "3%",
                          position: "absolute",
                          // bottom: 20,
                        }}
                      >
                        {isErr &&
                        errorsField[data?.[v]?.obscode]?.error === true ? (
                          <span style={{ color: "red", fontSize: 12 }}>
                            {"Value should be " +
                              data?.[v]?.validation_min +
                              " to " +
                              data?.[v]?.validation_max +
                              "."}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                  {data?.[v]?.type === "date" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      isRequired={data?.[v]?.mandatory}
                      size={"small"}
                      type={"date"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      iconText={data?.[v]?.default_unit}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                    />
                  )}
                  {data?.[v]?.type === "labelName" && (
                    <React.Fragment>
                      <TextInput
                        label={data?.[v]?.shortdesc}
                        isRequired={data?.[v]?.mandatory}
                        size={"small"}
                        type={"text"}
                        // disabled
                        // type={"datetime-local"}
                        onChange={(e) => {
                          handlechange("predialysis", v, e.target.value);
                        }}
                        value={state?.[v]?.obsvalue ?? ""}
                        labelStyle={styles.labelStyle}
                        // disabled={data?.[v]?.shortdesc==="Staff ID" ? true : false}
                        placeholder={""}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? styles.errorborderStyle
                            : styles.inputFiledStyle
                        }
                        //hoverColor="#0071F2"
                        hoverColor={UIColor.lineBorderFill.color}
                      />
                    </React.Fragment>
                  )}
                </Grid>
              </>
            );
          } else {
            return (
              <>
                <Grid item>
                  {data?.[v]?.type === "text" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      isRequired={data?.[v]?.mandatory}
                      size={"small"}
                      // type={"text"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={{
                        fontSize: "12px",
                        fontSWeight: "500",
                        margin: "0",
                        color: "#6A6A6A",
                        marginBottom: "6px",
                        fontFamily: "pc_regular !important",
                      }}
                      placeholder={""}
                      // style={styles.inputFiledStyle}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      //hoverColor="#0071F2"
                      hoverColor={UIColor.lineBorderFill.color}
                      inLineStyles={{
                        width: "190px",
                      }}
                    />
                  )}
                  {data?.[v]?.type === "autocomplete" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontSWeight: "500",
                          margin: "0",
                          color: "#6A6A6A",
                          marginBottom: "6px",
                          fontFamily: "pc_regular !important",
                        }}
                      >
                        {data?.[v]?.shortdesc}
                        <span
                          style={{
                            color: "red",
                            fontSWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          *
                        </span>
                      </p>
                      <Autocomplete
                        // style={{ width: 190 }}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? {
                                width: 190,
                                border: "1px solid red",
                                borderRadius: 8,
                              }
                            : { width: 190 }
                        }
                        //className={"type"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        isRequired={true}
                        // options={data?.[v]?.dropdownvalues ?? []}
                        options={
                          data?.[v]?.obscode === "striptest"
                            ? striptestByList(striptestList)
                            : data?.[v]?.obscode === "StaffID"
                            ? striptestByList(striptestList)
                            : GetIdAndDisplay(hdMachineNo)
                        }
                        iconText={data?.[v]?.obsuom}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${v}_allergy_type_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            id={`${v}_allergy_type_textfield`}
                            {...params}
                            variant="outlined"
                            placeholder=""
                            //label={data?.[v]?.shortdesc}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${v}_allergy_${option.label}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={state?.[v]?.obsvalue ?? ""}
                        onChange={(e, newValue) => {
                          handlechange("predialysis", v, newValue);
                        }}
                      />
                    </>
                  )}
                  {data?.[v]?.type === "select" && (
                    <>
                      <Grid item>
                        <p
                          style={{
                            fontSize: "14px",
                            fontSWeight: "500",
                            margin: "0",
                            color: "#6A6A6A",
                            marginBottom: "6px",
                            fontFamily: "pc_regular !important",
                          }}
                        >
                          {data?.[v]?.shortdesc}
                          <span
                            style={{
                              color: "red",
                              fontSWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            *
                          </span>
                        </p>

                        <Autocomplete
                          style={
                            isErrs &&
                            errorField[data?.[v]?.obscode]?.error === true
                              ? {
                                  width: 190,
                                  border: "1px solid red",
                                  borderRadius: 8,
                                }
                              : { width: 190 }
                          }
                          className={classes.autocomplete}
                          id="size-small-standard"
                          size="small"
                          isRequired={isHepatitisTypeDisabled}
                          options={data?.[v]?.dropdownvalues ?? []}
                          popupIcon={
                            <ExpandMoreIcon
                              id={`${v}_allergy_type_expandmoreicon`}
                              fontSize="small"
                              style={{ color: "#b4aeae" }}
                            />
                          }
                          disableClearable
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              id={`${v}_allergy_type_textfield`}
                              {...params}
                              variant="outlined"
                            />
                          )}
                          renderOption={(option) => (
                            <Typography
                              id={`${v}_allergy_${option.label}_typography`}
                              style={{ width: "100%" }}
                              noWrap
                            >
                              {option}
                            </Typography>
                          )}
                          classes={{ popper: "muiPopperDropdown" }}
                          value={state?.[v]?.obsvalue ?? ""}
                          onChange={(e, newValue) => {
                            handlechange("predialysis", v, newValue);
                          }}
                          disabled={
                            (state?.[v]?.obscode === "Hepatatis"
                              ? false
                              : isHepatitisTypeDisabled) ||
                            (props?.editstate?.Hepatatis?.obsvalue === "No" &&
                              state?.[v]?.obscode === "HepatatisType")
                              ? isHepatitisTypeDisabled
                              : false
                          }
                        />
                      </Grid>
                    </>
                  )}
                  {data?.[v]?.type === "number" && (
                    <>
                      <TextInput
                        label={data?.[v]?.shortdesc}
                        isRequired={data?.[v]?.mandatory}
                        size={"small"}
                        type={
                          data?.[v]?.obscode === "BP" ||
                          data?.[v]?.obscode === "BPSit" ||
                          data?.[v]?.obscode === "BPStnd"
                            ? "text"
                            : "number"
                        }
                        onChange={(e) => {
                          if (
                            data?.[v]?.obscode === "BP" ||
                            data?.[v]?.obscode === "BPSit" ||
                            data?.[v]?.obscode === "BPStnd"
                          ) {
                            let pattern = state?.[v]?.obsvalue.includes("/")
                              ? /^[0-9]+(?:\/[0-9]*)?$/
                              : /^[0-9\/\s]*$/;
                            let result = pattern.test(e.target.value);
                            if (result || e.target.value === "") {
                              handlechange(
                                "predialysis",
                                v,
                                e.target.value,
                                data?.[v]?.validation_min,
                                data?.[v]?.validation_max,
                                data?.[v]?.obscode
                              );
                            }
                          } else {
                            handlechange(
                              "predialysis",
                              v,
                              e.target.value,
                              data?.[v]?.validation_min,
                              data?.[v]?.validation_max
                            );
                          }
                        }}
                        value={state?.[v]?.obsvalue ?? ""}
                        labelStyle={styles.labelStyle}
                        iconText={data?.[v]?.default_unit}
                        placeholder={
                          data?.[v]?.obscode === "BP" ||
                          data?.[v]?.obscode === "BPSit" ||
                          data?.[v]?.obscode === "BPStnd"
                            ? "Eg : 80/120"
                            : ""
                        }
                        // style={styles.inputFiledStyle}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? styles.errorborderStyle
                            : styles.inputFiledStyle
                        }
                        //hoverColor="#0071F2"
                        hoverColor={UIColor.lineBorderFill.color}
                        onBlurCapture={(e) => {
                          if (data?.[v]?.obscode === "PHDW") {
                            totalweightloss(e, "stateLabel");
                          }
                        }}
                        disabled={data?.[v]?.obscode === "TWL" ? true : false}
                        // error={
                        //   isErr && errorsField[data?.[v]?.obscode]
                        //     ? true
                        //     : false
                        // }
                      />
                      <div
                        style={{
                          // marginTop: "3%",
                          position: "absolute",
                          // bottom: 20,
                        }}
                      >
                        {isErr &&
                        errorsField[data?.[v]?.obscode]?.error === true ? (
                          <span style={{ color: "red", fontSize: 12 }}>
                            {"Value should be " +
                              data?.[v]?.validation_min +
                              " to " +
                              data?.[v]?.validation_max +
                              "."}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                  {data?.[v]?.type === "date" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      dateIcon={calendarIcon}
                      labelStyle={styles.labelStyle}
                      type={"date"}
                      isRequired={data?.[v]?.mandatory}
                      size="medium"
                      value={state?.[v]?.obsvalue ?? ""}
                      placeholder={""}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      inLineStyles={{
                        width: "190px",
                      }}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                    />
                  )}
                  {data?.[v]?.type === "time" && (
                    <TextInput
                      label={data?.[v]?.shortdesc}
                      isRequired={data?.[v]?.mandatory}
                      size={"small"}
                      type={"time"}
                      // type={"datetime-local"}
                      onChange={(e) => {
                        handlechange("predialysis", v, e.target.value);
                      }}
                      value={state?.[v]?.obsvalue ?? ""}
                      labelStyle={styles.labelStyle}
                      placeholder={""}
                      style={
                        isErrs && errorField[data?.[v]?.obscode]?.error === true
                          ? styles.errorborderStyle
                          : styles.inputFiledStyle
                      }
                      inLineStyles={{
                        width: "190px",
                      }}
                    />
                  )}
                  {data?.[v]?.type === "labelName" && (
                    <React.Fragment>
                      <TextInput
                        label={data?.[v]?.shortdesc}
                        isRequired={data?.[v]?.mandatory}
                        size={"small"}
                        type={"text"}
                        // disabled
                        // type={"datetime-local"}
                        onChange={(e) => {
                          handlechange("predialysis", v, e.target.value);
                        }}
                        value={state?.[v]?.obsvalue ?? ""}
                        labelStyle={styles.labelStyle}
                        placeholder={""}
                        // disabled={data?.[v]?.shortdesc==="Staff ID" ? true : false}
                        style={
                          isErrs &&
                          errorField[data?.[v]?.obscode]?.error === true
                            ? styles.errorborderStyle
                            : styles.inputFiledStyle
                        }
                        //hoverColor="#0071F2"
                        hoverColor={UIColor.lineBorderFill.color}
                      />
                    </React.Fragment>
                  )}
                </Grid>
              </>
            );
          }
        })}
      </Grid>
      {!props?.historyView && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "5px 30px",
          }}
        >
          <span style={{ display: "flex", gap: "15px" }}>
            <Button
              onClick={handlecancel}
              color="primary"
              fullWidth
              className={styles.actionButton}
            >
              cancel
            </Button>{" "}
            <Button
              variant="contained"
              onClick={() => {
                edit
                  ? handleEdited(statekey, state)
                  : handlesaved(statekey, state);
              }}
              color="primary"
              fullWidth
              className={styles.actionButton}
            >
              save
            </Button>
          </span>
        </div>
      )}
    </div>
  );
};

export default Adddialysistype;
