import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { urls, defaultState, defaultReject, fetchData } from "../../utils";

const SAVE_ADVANCE_PAYMENT = createAsyncThunk(
  `saveAdvance/advancePaymentSlice`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        { body: JSON.stringify(payload) },
        urls.patientAdvanceSave
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      alert(error.message);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
// const GET_ADVANCE_NO = createAsyncThunk(
//   `advanceNumber/advancePaymentSlice`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const { patientId, encounterId } = payload;

//       const body = {
//         db_name: dbName,
//         filter: {
//           tenantid: "",
//           facilityid: "",
//           patientid: patientId,
//           encounter: encounterId,
//         },
//         queryid: "98bbc74c-3c02-4039-b467-983d71e6cb47",
//       };
//       const data = await fetchData(
//         { body: JSON.stringify(body) },
//         urls.readQdmQueries
//       );
//       console.log(data, "redux billing");
//       return {
//         ...defaultState.List,
//         data: data,
//       };
//     } catch (error) {
//       alert(error.message);
//       return rejectWithValue({
//         ...defaultReject,
//         message: error.message,
//       });
//     }
//   }
// );
// const PAYMENT_DROPDOWN = createAsyncThunk(
//   `advanceNumber/payment_dropDown`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const { tenantid } = payload;

//       const body = {
//         db_name: dbName,
//         filter: {
//           type: ["RC_PAYMENTMODE"],
//           tenantid: "1234",
//           facilityid: "",
//           lang: "en",
//         },
//         queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
//       };
//       const data = await fetchData(
//         { body: JSON.stringify(body) },
//         urls.readQdmQueries
//       );
//       return {
//         ...defaultState.List,
//         data: data,
//       };
//     } catch (error) {
//       alert(error.message);
//       return rejectWithValue({
//         ...defaultReject,
//         message: error.message,
//       });
//     }
//   }
// );

// const SEARCH_CODE_DESC = createAsyncThunk(
//   `advanceNumber/Search_Code_Desc`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const { encounterId, patientId } = payload;

//       const body = {
//         db_name: dbName,
//         filter: {
//           tenantid: "",
//           facilityid: "",
//           patientid: patientId,
//           encounter: encounterId,
//           searchcode: "%%",
//           isbilled: false,
//         },
//         queryid: "c4847003-26cf-4b0c-b341-eec1d6747545",
//       };
//       const data = await fetchData(
//         { body: JSON.stringify(body) },
//         urls.readQdmQueries
//       );
//       return {
//         ...defaultState.List,
//         data: data,
//       };
//     } catch (error) {
//       alert(error.message);
//       return rejectWithValue({
//         ...defaultReject,
//         message: error.message,
//       });
//     }
//   }
// );
const AdvancePaymentSlice = createSlice({
  name: "advancePaymentSlice",
  initialState: {
    saveAdvance: {
      ...defaultState.Info,
    },
    // advanceNumber: {
    //   ...defaultState.Info,
    // },
    // payment_dropDown: {
    //   ...defaultState.Info,
    // },
    // Search_Code_Desc: {
    //   ...defaultState.Info,
    // },
  },
  extraReducers: {
    // SAVE_ADVANCE_PAYMENT
    [SAVE_ADVANCE_PAYMENT.fulfilled]: (state, action) => {
      state.saveAdvance = action?.payload ?? {};
    },
    [SAVE_ADVANCE_PAYMENT.pending]: (state, action) => {
      state.saveAdvance.loading = true;
    },
    [SAVE_ADVANCE_PAYMENT.rejected]: (state, action) => {
      state.saveAdvance = action?.payload ?? {};
    },
    // GET_ADVANCE_NO
    // [GET_ADVANCE_NO.fulfilled]: (state, action) => {
    //   state.advanceNumber = action?.payload ?? {};
    // },
    // [GET_ADVANCE_NO.pending]: (state, action) => {
    //   state.advanceNumber.loading = true;
    // },
    // [GET_ADVANCE_NO.rejected]: (state, action) => {
    //   state.advanceNumber = action?.payload ?? {};
    // },
    // //PAYMENT_DROPDOWN
    // [PAYMENT_DROPDOWN.fulfilled]: (state, action) => {
    //   state.payment_dropDown = action?.payload ?? {};
    // },
    // [PAYMENT_DROPDOWN.pending]: (state, action) => {
    //   state.payment_dropDown.loading = true;
    // },
    // [PAYMENT_DROPDOWN.rejected]: (state, action) => {
    //   state.payment_dropDown = action?.payload ?? {};
    // },
    // //SEARCH_CODE_DESC
    // [SEARCH_CODE_DESC.fulfilled]: (state, action) => {
    //   state.Search_Code_Desc = action?.payload ?? {};
    // },
    // [SEARCH_CODE_DESC.pending]: (state, action) => {
    //   state.Search_Code_Desc.loading = true;
    // },
    // [SEARCH_CODE_DESC.rejected]: (state, action) => {
    //   state.Search_Code_Desc = action?.payload ?? {};
    // },
  },
});

export const advancePaymentActions = {
  SAVE_ADVANCE_PAYMENT,
  // GET_ADVANCE_NO,

  // PAYMENT_DROPDOWN,
  // SEARCH_CODE_DESC,
};

export default AdvancePaymentSlice;
