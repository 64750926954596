import moment from "moment";
import { getFacilityId, getTenantId } from "./helperFunction";
import jwtDecode from "jwt-decode";

export const dbName = process.env.REACT_APP_DB;
export const __tenantid__ = process.env.REACT_APP_TENANTID;
export const _lang_ = process.env.REACT_APP_LANGUAGE;
//let userInfo = jwtDecode(localStorage.getItem("UserInfo"));
//const org_id = userInfo?.orgid
//export const _facility_ = facilityId; //token?.facilityid;
//let jwtToken = jwtDecode(localStorage.getItem("JwtToken"));
//const facility_id = jwtToken?.facilityid

export const query_ids = {
  SearchPractitioner_QID: "2fef3beb-6f38-48b0-955f-90f88dab2822",
  masterSearch: "0f0e1609-e0ca-4757-b922-538491e3334a",
  getRelatedPerson: "ff38b85a-7363-4fb3-9a8a-2b861ddffeff",
  readAppointment: "90825b98-b426-4308-8605-9614b9c7c87b",
  getSymptoms_QID: "b15e9945-28c7-4ec7-9fc7-022f58ba766f",
  getAllSpeciality_QID: "c64c4a00-9c2d-4069-bd99-53d351606ef8",
  getSpeciality_QID: "e44815f9-8e63-406f-855f-09225f11c11d",
  getHospitals_QID: "f3434d9b-2811-4472-b382-2bb01f57a3b3",
  getpatient_QID: "2f9f73a3-1ef4-4065-8981-fc187d2ff694",
  reasonForAppointment_QID: "0f0e1609-e0ca-4757-b922-538491e3334a",
  readPerson_QID: "ff38b85a-7363-4fb3-9a8a-2b861f05beef",
  readAppointment_QID: "0bfea3d4-fe24-43f9-b5b8-cf9ff4365fab",
  upsertReadAppointment: {
    practionerid: "7ca4f92c-4450-443b-b1b5-5fdfe5b5a168",
    clinicid: "e95600d5-3452-44f4-90f1-a002f177a584",
  },
  GetAllMasters: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
  "appointmentReducer/allAppointments": "8e5d6d33-fb5c-49a3-9ce5-0a71b8385bdd",
  "appointmentReducer/readAppointment": "dc135384-8e30-4d2a-b5ae-1e97817b807a",
  "personApiSlice/readPerson": "98323af5-6f47-4f37-9024-b111589ecc92",
  "personApiSlice/getRelatedPerson": "046d3653-f3f2-49f7-b674-bd23363ab281",
  "patientReducer/patientInfo": "79af89da-ce69-4c02-8148-fd0740933909",
  GetPayerTypeQID: "ff5276b6-b60f-455e-b9ac-166d990a680d",
  GetPayerQID: "4ad5677f-4835-4e15-b46f-74c79d316e6d",
  GetGuarantorTypeQID: "1da94ab9-de31-4d39-92a7-3f47555c84d3",
  GetGuarantorIDQID: "4ad5677f-4835-4e15-b46f-74c79d316e6d",
  GetPlanQID: "808c9d27-66d2-4d8e-a4f6-0f66b2446a32",
  GetFacilityNameID: "3d93a182-4e8b-465f-946e-c692ebc92235",
  GetserviceRequestedID: "7e7bbd3c-6fb7-4ec0-ae36-1a7aac51d16c",
  GetPractitionerNameID: "b605bb6d-9c8b-44e3-8b84-4cd0d703fa5c",
  GetSponserNameID: "df82acc0-b333-4644-ab51-5aaf94cb2c97",
  readAppointment: "90825b98-b426-4308-8605-9614b9c7c87b",
  "slotApiSlice/slotAvailability": "f5ea11b3-43cd-44e4-b644-33860d9a6104",
  getreferral: "655a5654-5ec0-44f2-ac76-3b6f29a0a285",
  getOrgByPatient: "ae83d4ec-3969-4aa5-b5e9-699e2fa0de8c",
  getfinancial: "c226c37b-ded7-47d2-b2ff-3f5e031015a3",
  getreasonMaster: "0161c069-3c45-4b8b-b7e7-cf6bdc3be274",
  reasonForAppointment: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
  "appointmentReducer/reoccuranceAppointment":
    "39e9c18e-7f02-4004-bf28-e720bd86955c",
  "appointmentReducer/checkAppointmentAgainstPatient":
    "a3e99353-be77-4f25-8633-42edaca54d93",
  "appointmentReducer/readClinicInfo": "06825067-9abc-460d-8a54-ac6d7f137d60",
  "appointmentReducer/generalInstruction":
    "062e1332-1453-4e50-825a-1dcc5f25b7a8",
  readslot: "b896a3c0-290c-4aa4-b791-741269c6bb0a",
  get_ordercatalog: "19a03543-34a3-4c09-a866-a0284797cf7f",
  get_ordernursecatalog: "ca04073c-13f0-4d46-9e74-a073f5811fb4",
  getordercategory: "2d31764f-035e-45c9-adc7-c9b05eda2e42",
  gettreatmentordercategory: "9b2af109-70ea-47db-a952-c10e2899212b",
  durationType: "16ccea8f-08f6-4134-a5c9-c9ab3e5c04d3",
  durgMaster: "b91f7673-a5c0-4155-82c3-508896e46304",
  strengthUom: "6c033b06-3407-4d12-af84-af6fb3ba4f2c",
  organization: "6d18b211-1c07-4313-a8ef-3d409137c86d",
  DiagnosisAgainstEncounter: "b92c85e8-a43b-481d-b43b-332a82c2c961",
  PractitionerRole: "b2457a4e-2f20-40f9-b536-90ec2625cdd4",
  readDialysis: "39fcb94e-bc8e-4ecc-a714-01ed80eb0ba9",
  getAppointmentAgainstEncounter: "3fded20b-6ccf-45fc-82d4-a9af15bbe0a1",
  allNotesAgainstPatientidAndEncoid: "eaa4e83d-b777-49dc-8ba2-fbcdbe4bf1eb",
  getProgressNotesType: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
  getPatientCategoryDialysis: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
  docc_masters_read: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
  docc_practioner_read: "151f849e-1d87-4996-a965-ac2d175e7f24",
  docc_encounter_details: "bf48a673-ad6c-4574-a34e-498451aef223",
  docc_pract_agaisnt_categ: "072ca70c-76a5-4a37-9dc2-f8e60457a0f6",
  docc_single_read: "ffe65260-ff59-490d-b040-9f0a75486103",
  docc_against_category: "cef2dd25-60a6-4d7f-a4cf-34c6cde412ad",
  docc_doctor_read: "5f928e29-d108-46cd-ba77-d8a53ade3845",
  docc_read: "50131a76-fecc-443e-92a9-9c649fe58fd7",
  dashboard_tilequery: "037d88a5-f2af-4990-8ab9-7f47b04fb83d",
  currentVisitquery: "69d59d09-b0fd-4279-9603-d1a594cd9374",
  payer_wise_bill_query: "6f33d39b-0b44-48e1-86d6-898ce485c827",
  payer_wise_bill_value_query: "f916aeea-a110-45ee-b1a6-7732c19722d4",
  cash_bill_query: "bc6dbf82-88dd-44a3-a06a-ee8e96d8bf05",
  payer_mode_wise_query: "5658f67b-4956-4591-ac3c-de4e5308e0ac",
  pendingtaskquery: "ee35b066-3b0c-4cf8-8dac-af8863e34adc",
  getOutstandingdata: "128df1c3-5a57-49c8-b3bd-d93fa2f48b15",
  viewBillAndReceipt: "8ab78b02-aa8d-4ec3-bda7-bf64e0b356f2",
  getlatestEncounter: "3a263b55-1d94-42f6-abf6-c76b4c40076b",
  getpackageorder: "a331cdc4-7810-4753-914d-62e30bfc2c0d",
  casemanagername: "fb1fc586-1d85-4279-8abf-6a2e01f43fd9",
  hubdetails: "1cea07c9-fd54-44c6-83be-4776cfab6c4b",
  appointmenttype: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
  scheduledspeciality: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
  scheduleddoctorname: "64c4d72c-7f03-42fe-8a1d-1e3a7d75e318",
  scheduledfacilityaddress: "a14a8502-c46f-4389-a06a-39ce44a3f891",
  scheduledfacilityname: "8fafd489-d79c-4b0f-bacd-dc24c6a78b16",
  servicebooked: "ff48ba1a-50fa-477f-ae17-efe546b1d002",
  transferreason: "0161c069-3c45-4b8b-b7e7-cf6bdc3be274",
  hubto: "2c3baeac-e987-48ad-bb3b-2b53d05896e2",
  scheduledBy: "c947862a-fab4-4125-881e-a01277ac8b65",
  diagnosisname: "289ee358-17b6-43ac-898b-ebf30143b4eb",
  diagnosistypeandconsent: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
  getdaignosistypeandconsent: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
  getreasonMaster:"0161c069-3c45-4b8b-b7e7-cf6bdc3be274",
  getpatientreviewreport:"2d237e1c-1432-453d-9bdf-b22e40aeac61"
};

export const authRequests = {
  LoginKeycloakUserRequest: (username, password, tenantid) => {
    return {
      username: username,
      password: password,
      tenantid: tenantid,
    };
  },
  PermissionRoleRequest: (id) => {
    return {
      db_name: `${dbName}`,
      entity: `PermissionRoleMapping`,
      filter: `PermissionRoleMapping.role_id == ${id}`,
      return_fields: `{PermissionRoleMapping}`,
    };
  },
  JWTTokenParamsRequest: (emailId) => {
    return {
      db_name: `${dbName}`,
      entity: "Person,Practitioner",
      filter: {
        Person: `(Person.telecom)[*].value any =='${emailId}' && Person.activestatus==true`,
        Practitioner:
          "Practitioner.PersonID == Person.Id && Practitioner.activestatus==true let oganid=(for Organization in Organization filter Organization.id == first(document(first(document(Practitioner.practitioner_role)[*].OrgID)).ParentOrgID) && Organization.activestatus==true return Organization) let enterid=(for Organization in Organization filter first(first(oganid).ParentOrgID)== Organization.id && Organization.activestatus==true return Organization)  let location=(for LocationMaster in LocationMaster filter LocationMaster.managingOrgID==first(document(Practitioner.practitioner_role)[*].OrgID) && LocationMaster.activestatus==true return LocationMaster)",
      },
      return_fields:
        "{Person_id:Person._id,practitionerID:Practitioner._id,practitioner_role_id:first(Practitioner.practitioner_role),facilityid:first(document(Practitioner.practitioner_role)[*].OrgID),orgid:first(oganid)._id,enterpriseid:first(enterid)._id,locationid:first(location)._id}",
    };
  },
  GetFacilityByUserIdRequest: (username) => {
    return {
      db_name: dbName,
      entity: "Person",
      filter: `(Person.telecom)[*].value any =='${username}' && Person.activestatus==true`,
      return_fields: "keep(Person,'_id','_key','facilityid','Id')",
    };
  },
  GetIsNurseTriageRequiredByFacilityRequest: (facilityId) => {
    return {
      db_name: dbName,
      entity: "EntityParametersSetup",
      filter: `EntityParametersSetup.entityid=='${facilityId}' && document(EntityParametersSetup.parameterid).code=='ISNURSETRIAGEREQD'`,
      return_fields: "EntityParametersSetup",
    };
  },
  CountryCodeList: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        entity: "SMGeographicMaster",
        sort: "SMGeographicMaster.GeoLocationISDCode",
        filter:
          "lower(document(SMGeographicMaster.geogLevelType).display)=='country' && SMGeographicMaster.activestatus == true && SMGeographicMaster.active == true",
        return_fields:
          "{_id:SMGeographicMaster._id,id:SMGeographicMaster.id,geogLevelName:SMGeographicMaster.geogLevelName,parentGeogLevelType:SMGeographicMaster.parentGeogLevelType,parentGeogLevelCode:SMGeographicMaster.parentGeogLevelCode,GeoLocationISDCode:SMGeographicMaster.GeoLocationISDCode}",
      }),
    };
  },
};

export const personRequests = {
  //isCashier

  getDetailsAgainstPatientSearchByIsCashier: (
    startDate,
    endDate,
    unBilled,
    patientMrn,
    patientIdName,
    patientIdNumber,
    patientName,
    mobileCountryCode,
    patientMobileNumber,
    facilityId,
    page,
    perpage

  ) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          startdate: startDate,
          enddate: endDate,
          unbilled: unBilled,
          MRN: `${patientMrn}`,
          name: `%${patientName}%`,
          telecom: `%${mobileCountryCode}%${patientMobileNumber}%`,
          idsourcecode: `${patientIdName}`,
          idnumber: `%${patientIdNumber}%`,
          tenantid: __tenantid__,
          facilityid: `${facilityId}`,
          lang: _lang_,
          Encounterid: "",
          page: page? page : 0,
	        perPage: perpage
        },
        queryid: "92b337c9-8219-4313-b1f0-8473d6d3d6cb",
      }),
    };
  },

  //-------------------

  getcountrycode: (facilityId) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          tenantid: __tenantid__,
          facilityid: facilityId,
          lang: _lang_,
        },
        queryid: "ed6c39fe-5f04-4692-a47c-0cb6fcc6e001",
      }),
    };
  },

  getStripTestByList: (facilityId) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          tenantid: __tenantid__,
          facilityid: facilityId,
        },
        queryid: "4787edf8-27a5-4805-abc3-201dec3f118d",
      }),
    };
  },

  SearchPractitionerRequest: (payload) => {
    const { email = "" } = payload;
    return {
      body: JSON.stringify({
        db_name: dbName,
        queryid: query_ids["SearchPractitioner_QID"],
        filter: {
          emailId: email,
        },
      }),
    };
  },
  GetOrganisationRequest: (orgid) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        entity: "Organization",
        filter: `Organization.id==${orgid}`,
        return_fields:
          "{_id:Organization._id,OrgID:Organization.id,name:Organization.name}",
      }),
    };
  },
  ReadPersonRequest: (phone) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        queryid: query_ids.readPerson_QID,
        filter: {
          username: phone,
        },
      }),
    };
  },
  GetRelatedPerson: (userid) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        queryid: query_ids["personApiSlice/getRelatedPerson"],
        filter: { userid },
      }),
    };
  },
  getPatientDetailsAgainstMrn: (mrn) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          MRN: `%${mrn}%`,
          tenantid: __tenantid__,
          facilityid: "",
          lang: _lang_,
        },
        queryid: "31eadc25-6d1c-417c-9b9b-ae2b73fb54eb",
      }),
    };
  },

  getPatientDetailsAgainstIDProof: (idNumber, idName) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          idsourcecode: `${idName}`,
          idnumber: `%${idNumber}%`,
          tenantid: __tenantid__,
          facilityid: "",
          lang: _lang_,
        },
        queryid: "dcdb67d3-abc4-41e0-8d65-e2c7817a7290",
      }),
    };
  },

  getPatientDetailsAgainstName: (name) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          name: `%${name}%`,
          tenantid: __tenantid__,
          facilityid: "",
          lang: _lang_,
        },
        queryid: "334bab31-1cd3-45c0-b65f-9b8b746adcdd",
      }),
    };
  },

  getPatientDetailsAgainstMobile: (code, mobile) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          telecom: `%${code}%${mobile}%`,
          tenantid: __tenantid__,
          facilityid: "",
          lang: _lang_,
        },
        queryid: "1735ba86-1e66-48ac-8870-3a3998f94bd3",
      }),
    };
  },

  getPatientDetailsAgainstAppointmentId: (appId) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          appoinmentno: `%${appId}%`,
          tenantid: __tenantid__,
          facilityid: "",
        },
        queryid: "c51e6e7f-df44-405c-95a0-4064e8d69b75",
      }),
    };
  },

  masterSearch: (typeData, facilityId) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          type: typeData,
          tenantid: __tenantid__,
          facilityid: facilityId,
          lang: _lang_,
        },
        queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
      }),
    };
  },

  getDetailsAgainstPatientSearch: (
    patientMrn,
    patientIdName,
    patientIdNumber,
    patientName,
    mobileCountryCode,
    patientMobileNumber,
    patientAppId,
    facilityId
  ) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          MRN: `${patientMrn}`,
          idsourcecode: `${patientIdName}`,
          idnumber: `${patientIdNumber}`,
          name: `${patientName}`,
          telecom: `${mobileCountryCode}%${patientMobileNumber}`,
          appoinmentno: `${patientAppId}`,
          tenantid: __tenantid__,
          facilityid: facilityId,
          lang: _lang_,
        },
        queryid: "f52ec488-6ecc-434a-a94f-33c2d891e890",
      }),
    };
  },

  queueManagement: (orgId, permissionRoleId, Start, End) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          orgid: orgId,
          permissionroleid: permissionRoleId,
          start: Start,
          end: End,
        },
        queryid: "faff1916-e697-4019-8200-cb87b30046b4",
      }),
    };
  },
  QueueDisplayMap: (roleid, facilityId) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          roleid: roleid,
          tenantid: __tenantid__,
          facilityid: facilityId,
        },
        queryid: "1980dd52-17fb-478c-adb6-4beaf184b976",
      }),
    };
  },

  getAllTab: (info) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          orgid: info.org_id,
          //"Organization/10138",
          permissionroleid: info.idmroleid,
          //"IDM_PermissionRoleMapping/10022",
        },
        queryid: "8a1efd4b-bc02-4b8a-8a34-b924df0fe1f5",
      }),
    };
  },
};

export const searchRequest = {
  getCities: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        entity: "hospital_master",
        return_fields: {
          city: "city",
          hospital_id: "id",
        },
      }),
    };
  },
  getSymptom: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        queryid: query_ids.getSymptoms_QID,
      }),
    };
  },
  getAllSpeciality: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        queryid: query_ids.getAllSpeciality_QID,
      }),
    };
  },
  getSpeciality: (speciality) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        queryid: query_ids.getSpeciality_QID,
        filter: {
          symptom: speciality,
        },
      }),
    };
  },
  specialityAgainstPractitioner: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        entity: "Practitioner",
        filter:
          "Practitioner.activestatus == true && Practitioner.status == true",
        return_fields:
          "merge(Practitioner,{name:(for hm in HumanNameMaster filter hm._id in Practitioner.name return merge(hm,{use:(for cod in CodeableConceptMaster filter cod._id== hm.use return document(cod.coding)),prefix:(for cod in CodeableConceptMaster filter cod._id == hm.prefix return document(cod.coding)),suffix:(for cod in CodeableConceptMaster filter cod._id == hm.suffix return document(cod.coding))}))},{SPECIALTY:(for PractitionerRole in PractitionerRole filter Practitioner.practitioner_role any == PractitionerRole._id for CodeableConceptMaster in CodeableConceptMaster filter PractitionerRole.SpecialtyID == CodeableConceptMaster._id return merge(CodeableConceptMaster,{coding:document(CodeableConceptMaster.coding)}) ) } )",
        sort: "first(document(Practitioner.name)[*].text)",
      }),
    };
  },
  getHospitals: (location, city) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        queryid: query_ids.getHospitals_QID,
        filter: {
          city: location?.locality || city,
          // location,
          // "city": "Mumbai",
          // "location": "Bandra West"
        },
      }),
    };
  },
  practitionerAgainstOrganization: (practitionerId) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        entity: "Practitioner,Organization",
        filter: {
          Practitioner: `Practitioner.id==${practitionerId}`,
          Organization:
            "document(Practitioner.practitioner_role)[*].OrgID any == Organization._id",
        },
        return_fields:
          "{address: (let add = Document(Organization.address[0]) return merge(add, { city: Document(add.city) })), OrganizationName:Organization.name, OrgID:Organization.id}",
      }),
    };
  },
};

export const organizationRequest = {
  getPatient: (patientid) => {
    return {
      db_name: dbName,
      filter: {
        patientid: [patientid],
      },
      queryid: query_ids.getpatient_QID,
    };
  },
  getReferral: (patientid, reffId) => {
    return {
      db_name: dbName,
      entity: "ReferralTrx",
      filter: `ReferralTrx.patientId==${patientid} && ReferralTrx._id=="${reffId}"`,
      returnFields: "ReferralTrx",
    };
  },
  getFinancial: (patientId) => {
    return {
      db_name: dbName,
      entity: "Account",
      filter: `Account.linkidenvalue == '${patientId}' && Account.activestatus==true`,
      return_fields: "Account",
    };
  },
};

export const appoinmentRequest = {
  reasonForAppointment: () => {
    return {
      db_name: dbName,
      queryid: query_ids.reasonForAppointment_QID,
      filter: {
        type: ["Reason"],
      },
    };
  },
  reasonForApptCancelModify: () => {
    return {
      db_name: dbName,
      entity: "CodingMaster",
      filter: `CodingMaster.Type =='APPCANCELBYUSER' && CodingMaster.activestatus==true && CodingMaster.status == true`,
      return_fields:
        "KEEP(CodingMaster,'_id','id','_key','code','display','Type')",
    };
  },
  readPractData: (type, id) => {
    const inputData = {
      db_name: dbName,
      filter: {
        practid: id?.[0],
        tenantid: "",
        facilityid: "",
      },
      queryid: query_ids["upsertReadAppointment"][type],
    };
    if (type === "clinicid") {
      delete inputData["filter"];
      inputData["filter"] = {};
      inputData["filter"]["locid"] = id?.[0];
    }
    return inputData;
  },
  readSlot: ({ start, end, id }) => ({
    db_name: `${dbName}`,
    entity: "ResourceTimeTable",
    filter: `ResourceTimeTable.effectivefrom<= ${start} and ResourceTimeTable.effectiveto>=${end}  and DOCUMENT(ResourceTimeTable.resourcecode)[*].id any == ${id} and ResourceTimeTable.activestatus==true`,
    return_fields: `merge(ResourceTimeTable,{slot:(for slt in Slot filter slt.timetable_id==ResourceTimeTable._id AND slt.status!='blocked' AND slt.start >= ${moment
      .utc()
      .unix()} AND slt.activestatus==true sort slt._id asc RETURN merge(slt, {DayType: Document(slt.DayType)}))}), and slt.activestatus==true return merge(slt,{DayType: document(Document(slt.app_duration_id).daytype)}))})`,
  }),
  checkAppointmentAgainstPatient: (patientId, start) => {
    return {
      db_name: dbName,
      entity: "Appointment",
      filter: `Appointment.PersonID any ==${patientId} && Appointment.start ==${start}  && Appointment.activestatus==true`,
      return_fields:
        "{_id:Appointment._id,PersonID:Appointment.PersonID,_key:Appointment._key,appno:Appointment.appno,id:Appointment.id,start:Appointment.start,slotID:Appointment.slotID,end:Appointment.end,doctorName:CONCAT(CONTAINS(document(document(Appointment.resourcecode).name)[*].prefix,'CodeableConceptMaster')?first(document(first(document(document(document(Appointment.resourcecode).name)[*].prefix)[*].coding))[*].display):first(document(document(document(Appointment.resourcecode).name)[*].prefix)[*].display),'. ',first(document(document(Appointment.resourcecode).name)[*].text),' ',first(document(document(Appointment.resourcecode).name)[*].given),' ',first(document(document(Appointment.resourcecode).name)[*].family),' ',CONTAINS(document(document(Appointment.resourcecode).name)[*].suffix,'CodeableConceptMaster')?first(document(first(document(document(document(Appointment.resourcecode).name)[*].suffix)[*].coding))[*].display):first(document(document(document(Appointment.resourcecode).name)[*].suffix)[*].display)),allowToBookAppointment:CONTAINS(document(Appointment.resourcecode).name,'HumanNameMaster')?false:true}",
    };
  },
};

export const registrationRequests = {
  GetAllMastersRequest: (payload) => {
    const { facilityId = "" } = payload;
    return {
      body: JSON.stringify({
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: [
            "ALTERNATEIDTYPE",
            "RELATIONSHIP",
            "USE",
            "NAMEPREFIX",
            "GENDER",
            "AGETYPUOM",
            "MARITALSTATUS",
            "LANGUAGE",
            "CONTACTSYSTEM",
            "ADDRESSUSE",
            "PRIORITY",
            "ADDRESSTYPE",
            "PATIENTTYPE",
            "PATIENTIMPORTANCETYPE",
            "RACE",
            "ETHNICITY",
            "RELIGION",
            "BLOODGROUP",
            "GENDER",
            "DISABILITY",
            "STARTTYPE",
            "REASON",
            "VISITTYPE",
            "SPECIALTY",
            "SERVICECATEGORY",
            "SERVICETYPE",
            "SERVICE",
            "MEMBERTYPE",
            "FINANCIALCLASS",
            "FINANCIALCLASSTYPE",
            "ENCOUNTERTYPE",
            "ENCOUNTERCLASS",
            "STARTTYPE",
            "PRACTTYPE",
            "VISITTYPE",
            "REVISEVISITREASON",
            "ENCOUNTERREASONTYPE",
            "ALTYPE",
            "CCSEVERITY",
            "CCVERIFICTIONSTATUS",
            "ALREACTIONCODE",
            "CCASSERTEDBY",
            "CCCLINICALSTATUS",
            "RELATIONSHIP",
            "ROUTE",
            "ALSUBSTANCECODE",
            "LABPRIORITY",
            "ORDERNATURE",
            "ORDERMODE",
            "INDICATIONREASON",
            "PATINSTRUCTION",
            "PREINSTRUCTION",
            "DEPINSTRUCTION",
            "CCBODYSITE",
            "SPECIMENTYPE",
            "SPECCOLLMETHOD",
            "RADSTATUS",
            "FOLLOWUOM",
            "PRESCRIPTIONTYPE",
            "ENCOUNTERUSE",
            "VERIFICATIONSTATUS",
            "RANK",
            "CLINICALSTATUS",
            "MENIFESTATION",
            "GRADECODE",
            "GRADETYPE",
            "ASSESMENTREF",
            "DURATIONUOM",
            "RC_PAYMENTMODE",
            "REGISTRATIONTYPE",
            "CURRENCY",
            "DISTANCEUNIT",
            "PATIENTCATEGORY",
            "TRANSPORTREQD",
            "HEPATITIS",
            "OTHERHEALTHCONDITIONS",
            "OCCUPATION",
            "SUPPLYOROWN",
            "EXTINT",
            "PRACTROLE",
            "SPECIALTY",
            "ORGTYPE",
            "REFERRALFOR",
            "INTENTTYPE",
          ],
          tenantid: __tenantid__,
          facilityid: facilityId,
          lang: "en",
        },
      }),
    };
  },

  PayerTypeRequest: (payload) => {
    const { facilityId = "" } = payload;
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          tenantid: "",
          facilityid: "",
          lang: "",
        },
        queryid: query_ids["GetPayerTypeQID"],
      }),
    };
  },
  // PayerRequest: (payload) =>{
  //   const { payertype = "" } = payload;
  //   return{
  //     db_name: "primarycareng",
  //     filter: {
  //     tenantid: "",
  //     payertype: payertype,
  //     facilityid: "",
  //     lang: ""
  //   },
  //     queryid: "4ad5677f-4835-4e15-b46f-74c79d316e6d"
  //   }

  // },
};
export const reasonmasterquery = {
  getreasonMasterRequest: (payload) => {
    const { facilityId = "", type = [] } = payload;
    return {
      body: JSON.stringify({
        db_name: dbName,
        queryid: query_ids["getreasonMaster"],
        filter: {
          type: type,
          tenantid: "",
          facilityid: getFacilityId(),
          lang: "en",
        },
      }),
    };
  },
};

export const queries = {
  IdmGetPermission: (id) => {
    return {
      db_name: `${dbName}`,
      entity: `PermissionRoleMapping`,
      filter: `PermissionRoleMapping.role_id == ${id}`,
      return_fields: `{PermissionRoleMapping}`,
    };
  },
  deleteCC: (id) => `[{
    "db_name": "${dbName}",
    "entity": "CA_AllergyIntolerance",
    "filter": "CA_AllergyIntolerance._id =='${id}'"
    "return_fields": "CA_AllergyIntolerance",
}]`,
  serviceOrder: (orgId) => {
    return `{
        "db_name": "${dbName}",
        "entity": "CA_PerformingLocationforOrdLocn",
        "filter": "CA_PerformingLocationforOrdLocn.activestatus==true && CA_PerformingLocationforOrdLocn.ordering_org ==  '${orgId}' && CA_PerformingLocationforOrdLocn.service_applicability == 'CodingMaster/10292' && length(CA_PerformingLocationforOrdLocn.order_criteria) > 0",
        "return_fields": "CA_PerformingLocationforOrdLocn"
    }`;
  },
  organization: (ids) => {
    return `{
"db_name": "${dbName}",
"entity": "Organization",
      "filter": "Organization._id in ${`${JSON.stringify(ids)}`.replace(
        /"/g,
        "'"
      )}",
"return_fields":
  "{_id:Organization._id,_key:Organization._key,name:Organization.name,orgId:Organization.id}"
}`;
  },
  locationquery: (ids) => {
    return `{
  "db_name": "${dbName}",
  "entity": "LocationMaster",
        "filter": "LocationMaster._id in ${`${JSON.stringify(ids)}`.replace(
          /"/g,
          "'"
        )}",
  "return_fields": "LocationMaster"
}`;
  },
  codablemasterGetFinancial: {
    query: (patientId) =>
      `Account.linkidenvalue == '${patientId}' && Account.activestatus==true`,
    //  `Account.guarantor[0].patient_id==${patientId}`,
    // "filter": "Account.patient_id==@patient_id",
    returnFields: "Account",
  },
  alleryMaster_value: (input_text) => {
    return {
      onchange: {
        db_name: dbName,
        filter: {
          searchcode: `'%${input_text}%'`,
        },
        queryid: "ede063d9-758e-42a9-a722-c27874d8dec5",
      },
      knownallergy: {
        db_name: dbName,
        entity: "AllergyMaster",
        filter: `AllergyMaster.status==true and AllergyMaster.allergytypeind !='Medication' and AllergyMaster.shortdesc LIKE '%${input_text}%'`,
        return_fields:
          "{_id:AllergyMaster._id,id:AllergyMaster.id,code:AllergyMaster.code,shortdesc:AllergyMaster.shortdesc}",
      },
    };
  },
  laborder_delete: (id) => {
    return {
      db_name: `${dbName}`,
      entity: `CA_OrderLine`,
      filter: `CA_OrderLine._id == ${id}`,
      metadata_dbname: `${process.env.REACT_APP_METADATA_DBNAME}`,
    };
  },
  laborder_read: (encounter_id, order_id) => {
    return {
      db_name: dbName,
      entity: "CA_OrderLine",
      // filter: `CA_OrderLine.encounter_Id=="${encounter_id}" && CA_OrderLine.Oltype=='LAB' && CA_OrderLine.parentOrderId=='' && CA_OrderLine.activestatus==true`,
      filter: `CA_OrderLine.encounter_Id=="${encounter_id}" && CA_OrderLine.Oltype=='LAB' && CA_OrderLine.activestatus==true`,
      return_fields:
        "MERGE(CA_OrderLine,{childRecurrent:(for doc in CA_OrderLine_CA_OrderLine_E filter doc._to == CA_OrderLine._id RETURN MERGE(DOCUMENT(doc._from),{Requestor:(for req in TO_ARRAY(DOCUMENT(doc._from).Requestor) return merge(req,{organization:(document(req.organization)),practId:(for prac in Practitioner filter prac._id==req.practId return {practitioner_id: prac.practitioner_id, id:prac._id,name:(for nam in HumanNameMaster filter nam._id in prac.name return merge(nam,{use:(for cod in CodeableConceptMaster filter cod._id == nam.use return document(cod.coding)[*].display),prefix:(for cod in CodeableConceptMaster filter cod._id == nam.prefix return document(cod.coding)[*].display),suffix:(for cod in CodeableConceptMaster filter cod._id == nam.suffix return document(cod.coding)[*].display)}))}),practRole:document(req.practRole)}))})),org_Id:DOCUMENT(CA_OrderLine.org_Id),Requestor:(for req in TO_ARRAY(CA_OrderLine.Requestor) return merge(req,{organization:(document(req.organization)),practId:(for prac in Practitioner filter prac._id==req.practId return {practitioner_id: prac.practitioner_id, id:prac._id,name:(for nam in HumanNameMaster filter nam._id in prac.name return merge(nam,{use:(for cod in CodeableConceptMaster filter cod._id == nam.use return document(cod.coding)[*].display),prefix:(for cod in CodeableConceptMaster filter cod._id == nam.prefix return document(cod.coding)[*].display),suffix:(for cod in CodeableConceptMaster filter cod._id == nam.suffix return document(cod.coding)[*].display)}))}),practRole:document(req.practRole)}))},{orderCatalog:document(CA_OrderLine.orderCatalog).shortdesc},{orderCatalogId:document(CA_OrderLine.orderCatalog)._id})",
    };
  },
  laborder_organization: (OrgId) => {
    return {
      db_name: `${dbName}`,
      entity: "Organization",
      return_fields:
        "{_id:Organization._id,_key:Organization._key,name:Organization.name,orgId:Organization.id}",
    };
  },
  laborder_PractitionerRole: (OrgId) => {
    return {
      db_name: dbName,
      entity: "PractitionerRole",
      filter: `DOCUMENT(PractitionerRole.EntityName).id==${OrgId}`,
      return_fields:
        "DISTINCT DOCUMENT( DOCUMENT(PractitionerRole.role).coding)[0]",
    };
  },
  // laborder_PractitionerName: (OrgId, id, _id) => {
  laborder_PractitionerName: (_id) => {
    return {
      db_name: dbName,
      entity: "Practitioner",
      filter: `DOCUMENT( DOCUMENT(DOCUMENT(Practitioner.practitioner_role)[*].role)[0].coding[0])._id =='${_id}'`,
      return_fields:
        "MERGE(Practitioner,{name:(FOR hn IN TO_ARRAY(Practitioner.name) RETURN MERGE(DOCUMENT(hn),{use:(FOR cod IN TO_ARRAY(DOCUMENT(DOCUMENT(hn).use).coding) RETURN MERGE(DOCUMENT(DOCUMENT(hn).use),{coding:DOCUMENT(cod)})),prefix:(FOR cod IN TO_ARRAY(DOCUMENT(DOCUMENT(hn).prefix).coding) RETURN MERGE(DOCUMENT(DOCUMENT(hn).use),{coding:DOCUMENT(cod)})),suffix:(FOR cod IN TO_ARRAY(DOCUMENT(DOCUMENT(hn).suffix).coding) RETURN MERGE(DOCUMENT(DOCUMENT(hn).use),{coding:DOCUMENT(cod)}))}))})",
    };

    //"filter": `DOCUMENT(DOCUMENT(Practitioner.practitioner_role)[0].EntityName).id==${OrgId} AND DOCUMENT( DOCUMENT(DOCUMENT(Practitioner.practitioner_role)[*].role)[0].coding[0])._id =='${_id}'`,
  },
  laborder_Location: () => {
    return {
      db_name: `${dbName}`,
      entity: "LocationMaster",
      return_fields: "LocationMaster",
    };
  },
  laborder_status: () => {
    return {
      db_name: `${dbName}`,
      entity: "CodeableConceptMaster",
      filter: "CodeableConceptMaster.Type=='RADSTATUS'",
      return_fields:
        "MERGE(CodeableConceptMaster,{coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
    };
  },
  Practitioner: (orgId) => {
    return {
      db_name: `${dbName}`,
      entity: "PractitionerRole,Organization,Practitioner",
      filter: {
        PractitionerRole: `PractitionerRole.OrgID == ${orgId}`,
        Practitioner: "PractitionerRole.id==Practitioner.PractitionerRoleID",
      },
      return_fields:
        " DISTINCT MERGE({_id:Practitioner._id},{id:Practitioner.id},{PractitionerName:DOCUMENT(Practitioner.name)})",
    };
  },
  get_ordered_status: () => {
    return {
      db_name: dbName,
      entity: "CodingMaster",
      filter:
        "CodingMaster.Type=='MEDRECORDSTATUS' && CodingMaster.display ==  'ordered' && CodingMaster.activestatus==true && CodingMaster.status==true",
      return_fields:
        "KEEP(CodingMaster,'_id','id','_key','code','display','Type')",
    };
  },
  DrugStoreDispLoc: () => {
    return {
      db_name: dbName,
      entity: "DrugStoreDispLoc",
      return_fields: "DrugStoreDispLoc",
    };
  },
  singleReadEditInfo: (key) => {
    return {
      db_name: dbName,
      filter: {
        phorderlineid: key,
        tenantid: "",
        facilityid: "",
      },
      queryid: "944d0411-78b5-40bd-acca-eba7acffb883",
    };
  },
  InfusionTimeUoM: () => {
    return {
      db_name: dbName,
      entity: "DrugUOM",
      filter:
        "document(DrugUOM.UOMType).code in ['DU010'] && DrugUOM.IsDosageQuantityUOM==true && DrugUOM.IsDosageStrengthUOM==true && DrugUOM.status==true && DrugUOM.activestatus==true",
      return_fields:
        "keep(DrugUOM,'_id','UOM','UOMType','longdesc','shortdesc')",
    };
  },
  PharmacyFramework_Patientbanner: (id) => {
    return {
      db_name: dbName,
      entity: "Patient",
      filter: `Patient._id=='${id}' && Patient.activestatus==true`,
      return_fields:
        "merge({_id:Patient._id},{birthDate:Patient.birthDate},{id:Patient.id},{_key:Patient._key},{photo:document(Patient.photo)},{name:(for doc in HumanNameMaster filter doc._id in Patient.name return merge(doc,{use:(document(doc.use)),prefix:(document(doc.prefix)),suffix:(document(doc.suffix))}))},{age:Patient.age},{bloodGroup:document(Patient.bloodGroup)},{telecom:(for doc in ContactPointMaster filter doc._id in Patient.telecom return merge(doc,{system:(document(doc.system)),use:(document(doc.use)),valueprefix:(document(doc.valueprefix)),rank:(document(doc.rank))}))},{alias:Patient.alias},{gender:document(Patient.gender)},{PatientType:document(Patient.patientTypecode).display},{EmergencyDetails:(FOR RelatedPerson IN RelatedPerson FILTER RelatedPerson.PatientID==[ to_string(Patient.id) ] && RelatedPerson.activestatus==true Return merge(keep(RelatedPerson,'_id','_key'),{name:first(document(RelatedPerson.name)[*].text)},{relationship:document(RelatedPerson.relationship).display},{telecom:document(RelatedPerson.telecom)[*].value}))},{address:concat(DOCUMENT(Patient.address)[0].text,',',DOCUMENT(Patient.address)[0].line,',',document(DOCUMENT(Patient.address)[0].city).geogLevelName,',',document(DOCUMENT(Patient.address)[0].district).geogLevelName,',',document(DOCUMENT(Patient.address)[0].state).geogLevelName,',',document(DOCUMENT(Patient.address)[0].postalCode).geogLevelName,',',document(DOCUMENT(Patient.address)[0].country).geogLevelName)})",
    };
  },
  PharmacyFramework_Encounterbanner: (patientId, loggedUserInfo) => {
    // return {
    //   db_name: dbName,
    //   entity: "Encounter",
    //   filter: `Encounter.patient_id == ${id} && Encounter.activestatus == true`,
    //   return_fields:
    //     "merge({_id:Encounter._id},{id:Encounter.id},{_key:Encounter._key},{encounterdate:Encounter.encounterdate},{encounter_no:Encounter.encounter_no},{type_id:Encounter.type},{type:DOCUMENT(Encounter.type)[0].code},{visit_type:Encounter.visit_type},{location:first(document((Encounter.location)[*].location_id)[*].shortdesc)},{Practitioner_id:(for part in Encounter.participant for practId in Practitioner filter practId.id == part.Practitioner_id return merge(keep(practId,'_id'),{name:(for doc in HumanNameMaster filter doc._id in practId.name return merge(doc,{use:(document(document(doc.use).coding)),prefix:(document(document(doc.prefix).coding)),suffix:(document(document(doc.suffix).coding))}))}) )},{PractitionerRole_id:(for part in Encounter.participant for practRole in PractitionerRole filter practRole.id == part.PractitionerRole_id return document(document(practRole.role).coding))},{speciality:document(first(document(Encounter.practitioner_details[*].speciality)).coding)[*].display}, {Location:(for loc in LocationMaster filter first(Encounter.location[*].location_id) == loc._id return merge(loc,{locationType:merge(document(loc.locationType),{coding:document(document(loc.locationType).coding)})},{locationRoletype:document(loc.locationRoletype)}))})",
    //   sort: "Encounter.encounterdate desc",
    // };

    return {
      db_name: dbName,
      entity: "Organization,Encounter",
      filter: {
        Organization: `Organization._id == '${loggedUserInfo?.facilityid}'`,
        Encounter: `Encounter.patient_id == ${patientId} && Encounter.Organization_id == Organization.id && Encounter.activestatus == true`,
      },
      return_fields:
        "merge({_id:Encounter._id},{id:Encounter.id},{_key:Encounter._key},{encounterdate:Encounter.encounterdate},{encounter_no:Encounter.encounter_no},{type_id:Encounter.type},{type:DOCUMENT(Encounter.type)[0].code},{visit_type:Encounter.visit_type},{location:first(document((Encounter.location)[*].location_id)[*].shortdesc)},{Practitioner_id:(for part in Encounter.participant for practId in Practitioner filter practId.id == part.Practitioner_id return merge(keep(practId,'_id'),{position:document(document(practId.position).coding)[*].display},{telecom:(for tel in ContactPointMaster filter tel._id in practId.telecom return merge(tel,{system:document(tel.system)}))},{name:(for doc in HumanNameMaster filter doc._id in practId.name return merge(doc,{use:(document(document(doc.use).coding)),prefix:(document(document(doc.prefix).coding)),suffix:(document(document(doc.suffix).coding))}))}) )},{PractitionerRole_id:(for part in Encounter.participant for practRole in PractitionerRole filter practRole.id == part.PractitionerRole_id return document(document(practRole.role).coding))},{speciality:document(first(document(Encounter.practitioner_details[*].speciality)).coding)[*].display}, {Location:(for loc in LocationMaster filter first(Encounter.location[*].location_id) == loc._id return merge(loc,{locationType:merge(document(loc.locationType),{coding:document(document(loc.locationType).coding)})},{locationRoletype:document(loc.locationRoletype)}))})",
      sort: "Encounter.encounterdate desc",
    };
  },
  deleteDiagnosis: (id) => {
    return {
      db_name: `${dbName}`,
      entity: "CA_Diagnosis",
      filter: `CA_Diagnosis._id =='${id}'`,
    };
  },
};

export const registerQuery = {
  statusRead: () => {
    return {
      db_name: dbName,
      entity: "CodingMaster",
      filter:
        "lower(CodingMaster.display)==lower('Checked-in') && CodingMaster.activestatus==true",
      return_fields: "CodingMaster._id",
    };
  },
};

export const slotRequest = {
  readSlot: (data) => {
    return {
      db_name: dbName,
      filter: {
        effectivefrom: data.startdate,
        effectiveto: data.enddate,
        practitionerid: data.practitionerid,
        //"Practitioner/10236",
        tenantid: "",
        //process.env.REACT_APP_TENANTID,
        //"1234",
        facilityid: data.facilityid,
        //"Organization/10138",
        slotstart: moment.utc().unix(),
        isfrontdesk: data?.isfrontdesk,
      },
      queryid: query_ids.readslot,
    };
  },
  slotAvailability: (_id) => {
    return {
      db_name: dbName,
      queryid: query_ids["slotApiSlice/slotAvailability"],
      filter: {
        slotid: _id,
      },
    };
  },
};

export const dialysis = {
  readDialysis: (encounterId) => {
    return {
      db_name: dbName,
      filter: {
        encounterid: encounterId,
        tenantid: "",
        facilityid: "",
      },
      queryid: query_ids.readDialysis,
    };
  },
};

export const signOff = {
  getAllMasterSignOff: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["REASONTYPE", "FOLLOWUOM", "RECORDSTATUS"],
          lang: "en",
          tenantid: process.env.REACT_APP_TENANTID,
          facilityid: "",
        },
      }),
    };
  },
};
export const generateNumber = {
  billGenerator: (facilityId) => {
    return {
      db_name: dbName,
      doc: {
        billno: "",
      },
      entity: "RCM_billhdr",
      fieldname: "billno",
      is_metadata: "true",
      metadataId: process.env.REACT_APP_METADATAID,
      metadata_dbname: process.env.REACT_APP_META_DB_NAME,
      ruleparams: [
        {
          facilityid: facilityId,
        },
      ],
    };
  },
  recieptGenerator: (facilityId) => {
    return {
      db_name: dbName,
      doc: {
        receiptno: "",
      },
      entity: "RCM_Paymentdtls",
      fieldname: "receiptno",
      is_metadata: "true",
      metadataId: process.env.REACT_APP_METADATAID,
      ruleparams: [
        {
          facilityid: facilityId,
        },
      ],
    };
  },
};

export const docc = {
  docc_category_speciality_reason_read: () => {
    return {
      db_name: dbName,
      filter: {
        type: ["DOCCATEGORY", "SPECIALTY", "REASON"],
        tenantid: "",
        facilityid: "",
        lang: "en",
      },
      queryid: query_ids.docc_masters_read,
    };
  },
  docc_speciality: () => {
    return {
      db_name: dbName,
      filter: {
        type: ["SPECIALTY"],
        tenantid: "",
        facilityid: "",
        lang: "en",
      },
      queryid: query_ids.docc_masters_read,
    };
  },

  docc_practioner: (pid, eid) => {
    return {
      db_name: dbName,
      filter: {
        patientid: pid,
        encounterid: eid,
        tenantid: "",
        facilityid: "",
      },
      queryid: query_ids.docc_practioner_read,
    };
  },
  docc_encounter_details: (pid) => {
    return {
      db_name: dbName,
      filter: {
        patientid: pid,
        tenantid: "",
        facilityid: "",
      },
      queryid: query_ids.docc_encounter_details,
    };
  },
  docc_pract_agaisnt_categ: (pid, doc_cat, doc_type) => {
    return {
      db_name: dbName,
      filter: {
        patientid: pid,
        doccategory: doc_cat,
        doctype: doc_type,
        tenantid: "",
        facilityid: "",
      },
      queryid: query_ids.docc_pract_agaisnt_categ,
    };
  },
  docc_single_read: (id) => {
    return {
      db_name: dbName,
      filter: {
        id: id,
        tenantid: "",
        facilityid: "",
      },
      queryid: query_ids.docc_single_read,
    };
  },
  docc_against_category: (
    doc_cate,
    speciality,
    encounter,
    practitioner,
    pId
  ) => {
    return {
      db_name: dbName,
      filter: {
        patientid: pId,
        doccategory: doc_cate,
        tenantid: "",
        facilityid: "",
      },
      queryid: query_ids.docc_against_category,
    };
  },
  docc_doctor_read: (category) => {
    return {
      db_name: dbName,
      filter: {
        category: category,
        tenantid: "",
        facilityid: "",
      },
      queryid: query_ids.docc_doctor_read,
    };
  },
  docc_read: (pateint_id) => {
    return {
      db_name: dbName,
      filter: {
        patientid: pateint_id,
        tenantid: "",
        facilityid: "",
      },
      queryid: query_ids.docc_read,
    };
  },
};

export const hospitalRequest = {
  typeofbkapmntcall: () => {
    return {
      db_name: dbName,
      filter: {
        type: ["APPTYPE"],
        tenantid: "",
        facilityid: "",
        lang: "en",
      },
      queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
    };
  },
};
export const transferCase = {
  casemanagername: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          facilityid: getFacilityId(),
          tenantid: getTenantId(),
        },
        queryid: query_ids.casemanagername,
      }),
    };
  },
  transferreason: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          facilityid: getFacilityId(),
          tenantid: getTenantId(),
          lang: "en",
          type: ["ERREFQTRANSFERREASON"],
        },
        queryid: query_ids.transferreason,
      }),
    };
  },
  hubdetails: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          orgid: getFacilityId(),
        },
        queryid: query_ids.hubdetails,
      }),
    };
  },
  hubto: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          orgid: getFacilityId(),
        },
        queryid: query_ids.hubto,
      }),
    };
  },
};
export const patientReferralQuery = {
  daignosisname: (input_text="") => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          searchcode: `%${input_text}%`,
        },
        queryid: query_ids.diagnosisname,
      }),
    };
  },
  daignosistypeandconsent: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        queryid: query_ids["getdaignosistypeandconsent"],
        filter: {
          type: [
            "EXTINT",
            "ORGTYPE",
            "SPECIALTY",
            "REASON",
            "DIAGNOSISTYPE",
            "CONSENT",
          ],
          tenantid: "",
          facilityid: getFacilityId(),
          lang: "en",
        },
      }),
    };
  },
};

export const TransferandSchedule = {
  appointmenttype: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          type: ["CONSULTTYPE", "SPECIALTY"],
          tenantid: "1234",
          facilityid: "",
          lang: "en",
        },
        queryid: query_ids.appointmenttype,
      }),
    };
  },

  scheduleddoctorname: (facility, specilaityid) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          facilityid: facility,
          specialtyid: specilaityid,
          tenantid: "",
        },
        queryid: query_ids.scheduleddoctorname,
      }),
    };
  },
  servicebooked: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          name: `%%`,
        },
        queryid: query_ids.servicebooked,
      }),
    };
  },
  scheduledfacilityaddress: (facilityId) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          facilityid: facilityId,
        },
        queryid: query_ids.scheduledfacilityaddress,
      }),
    };
  },
  scheduledBy: (practid) => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        filter: {
          practid: practid,
          facilityid: getFacilityId(),
          tenantid: getTenantId(),
        },
        queryid: query_ids.scheduledBy,
      }),
    };
  },
  scheduledfacilityname: () => {
    return {
      body: JSON.stringify({
        db_name: dbName,
        queryid: query_ids.scheduledfacilityname,
      }),
    };
  },
};
