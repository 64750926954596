import AppAlert from "./App.Alert";
import "./App.css";
import AppRouter from "./router";
import { Provider } from "react-redux";
import store from "./redux";
//import AppMeeting from "./App.meeting"
import AssembleReg from "./context/assembleReg";
import PatientDetailsContext from "./context/consultant";
import AppAuth from "./App.auth";
import Appstatecontext from "./context/appstatecontext";
import AppDialog from "./App.dialog";
import AppBackdrop from "./App.backdrop";
import AppTheme from "./App.theme";
import AppErrorboundaries from "./App.Errorboundaries";
function App() {
  return (
    <Provider store={store}>
      <AppErrorboundaries>
        <AppTheme>
          <AppAuth>
            <AppAlert>
              <AppBackdrop>
                <AppDialog>
                  <AssembleReg>
                    <PatientDetailsContext>
                      <Appstatecontext>
                        <AppRouter />
                      </Appstatecontext>
                    </PatientDetailsContext>
                  </AssembleReg>
                </AppDialog>
              </AppBackdrop>
            </AppAlert>
          </AppAuth>
        </AppTheme>
      </AppErrorboundaries>
    </Provider>
  );
}

export default App;
