import React, { useEffect, useRef, useState } from "react";
import {
  Div,
  Avatar,
  H6,
  Card,
  Text,
  Image,
  Icons,
  Row,
  Modal,
  Divider,
} from "qdm-component-library";
//import { NoUndefinedVariablesRule } from "graphql";
import { UIColor } from "../../themes/theme";
import identificationDocuments from "../../assets/img/svg/icons8-identification-documents.svg";
import groupIcon from "../../assets/img/svg/Group 90503.svg";
import phoneIcon from "../../assets/img/svg/icons8-phone.svg";
import qdmstyles from "./../referrel/styles.json";
import ReferralBannerListView from "./../referrel/referralViewDetails";
import { Popover, makeStyles } from "@material-ui/core";
import FileUpload from "../../assets/img/svg/icons8-file.svg";
import viewIcon from "../../assets/img/svg/icons8-eye.svg";
import { ToBase64 } from "../../utils";
import { UploadFiles } from "../navBar";
import Outstanding from "../../screens/patientDashboard/components/outstanding";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    marginTop: 10,
    padding: 10,
  },
}));

const PatientInfoCard = (props) => {
  const [open, setOpen] = React.useState(null);
  const [image, setimage] = React.useState("");
  const [openRef, setopenRef] = useState(false);
  const [position, setPosition] = useState({ mouseX: null, mouseY: null });
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("propspatientCard", props, location);
  const [OutstandingData, setOutstandingData] = useState();

  const openPopover = (e) => {
    // this.setState({
    //   open: e?.currentTarget ?? null,
    // });
    setPosition({
      mouseX: e.clientX - 350,
      mouseY: e.clientY - -40,
    });
    setOpen(true);
  };
  const closePopover = (e) => {
    setPosition({ mouseX: null, mouseY: null });
    setOpen(null);
  };
  const handleopenRef = () => {
    setopenRef(!openRef);
  };
  const handleOpenModel = (value) => {
    setOpen(null);
    setimage([value]);
    setopenRef(!openRef);
  };
  const {
    ReferralData,
    letter = ReferralData?.name?.[0],
    noright,
    parent_id,
  } = props;
  const styles = {
    rowFlex: {
      display: "flex",
      alignItems: "center",
    },
    columnFlex: {
      display: "flex",
      flexDirection: "column",
    },
    h6: {
      fontSize: 14,
      color: UIColor.primaryColor.color,
      margin: 0,
    },
    span: {
      fontSize: 12,
      color: UIColor.tertiaryText.color,
    },
    cardStyle: {
      //boxShadow: '0px 10px 25px #0000000A',
      boxShadow: "none",
      borderRadius: 8,
      // padding: 10
    },
    avatar: {
      margin: 2,
      marginRight: 15,
      width: 48,
      height: 48,
      borderRadius: 8,
      background: "#F0F0FA",
    },
    img: {
      width: 16,
      height: 16,
      //marginRight: 10,
      margin:"0px 9px -2px 1px",
    },
  };

  const { rowFlex, columnFlex, h6, span, cardStyle, avatar, img } = styles;
  const { padding } = props;
  const getOutstanding = async () => {
    const Outstanding = await dispatch(
      actions.GET_OUTSTANDING_DATA(location?.state?.patientId)
    );
    setOutstandingData(Outstanding?.payload?.data?.[0]?.outstandingamount);
  };
  const OutstandingClick = () => {
    navigate(AppRoutes.paymentCollections, {
      state: {
        patientid: location?.state?.patientId,
        encounterid: "",
        receiptno: "",
        receiptdate: "",
      },
    });
  };

  useEffect(() => {
    getOutstanding();
  }, []);

  return (
    <Card
      id={`${parent_id}_patientinfocard_parent_card`}
      style={{
        boxShadow: "none",
        borderRadius: 8,
        padding: padding ? 10 : 0,
      }}
    >
      <Div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderRight: "1px solid #E5E5E5",
          padding: "0px 5px",
          height: "10vh",
        }}
      >
        <Div id={`${parent_id}_patientinfocard_parent_div`} style={rowFlex}>
          {props.pic ? (
            <Avatar
              id={`${parent_id}_patientinfocard_avatar_one`}
              src={props.pic}
              variant="rounded"
              inLineStyles={avatar}
            />
          ) : (
            <Avatar
              id={`${parent_id}_${props.name?.[0]}_patientinfocard_avatar`}
              variant="rounded"
              inLineStyles={avatar}
              style={{ margin: "0px 12px 27px 0px" }}
            >
              {props.name?.[0]}
            </Avatar>
          )}

          <Div id={`${parent_id}_patientinfocard_sub_div`} style={columnFlex}>
            <Text id={`${parent_id}_patientinfocard_text`} style={{display: "flex",alignItems:"center",margin: "0px 0px 1px 3px"}}>
              <H6
                id={`${parent_id}_${props.name}_patientinfocard_name_h6`}
                className="pc_semibold"
                style={h6}
              >
                {/* {props.name} */}
                {props.name.slice(0, 10) + (props.name.length > 10 ? ".." : "")}
              </H6>
              <Text
                id={`${parent_id}_patientinfocard_dot_text`}
                className="pc_regular"
                style={{ ...span, margin: "0px 5px" }}
              >
                •
              </Text>
              <Text
                id={`${parent_id}_patientinfocard_gender_text`}
                className="pc_regular"
                style={span}
              >
                {props.age + ", " + props.gender}
              </Text>
            </Text>
            <Div
              id={`${parent_id}_patientinfocard_doc_div`}
              style={{ display: "flex", alignItems: "center", margin:"2px 0px 15px 3px"}}>
              <Div >
                <Div style={{ display:"flex",}}>
                  <Div
                    id={`${parent_id}_patientinfocard_doc_sub_div`}
                    style={{ marginRight: 10 }}
                  >
                    <Image
                      id={`${parent_id}_patientinfocard_identification_doc_image`}
                      style={img}
                      src={identificationDocuments}
                      alt="id"
                    />
                    <Text
                      id={`${parent_id}_${props.mrn_id}_patientinfocard_text`}
                      className="pc_regular"
                      style={span}
                      inLineStyles={{ textAlign: "center" }}
                    >
                      {props.mrn_id}
                    </Text>
                  </Div>
                  <Div id={`${parent_id}_patientinfocard_group_div`}>
                    <Image
                      id={`${parent_id}_patientinfocard_group_image`}
                      style={img}
                      src={groupIcon}
                      alt="group"
                    />
                    <Text
                      id={`${parent_id}_${props.pi_id}_patientinfocard_text`}
                      className="pc_regular"
                      style={span}
                    >
                      {props.pi_id}
                    </Text>
                  </Div>
                  <Div
                    id={`${parent_id}_patientinfocard_group_div`}
                    style={{ marginLeft: "5px" }}
                  >
                    <Image
                      id={`${parent_id}_patientinfocard_group_image`}
                      style={{width: "16px",height: "16px",margin: "0px 5px -3px 1px"}}
                      src={phoneIcon}
                      alt="group"
                    />
                    <Text
                      id={`${parent_id}_${props.patientphonenum}_patientinfocard_text`}
                      className="pc_regular"
                      style={span}
                    >
                      {props.patientphonenum}
                    </Text>
                  </Div>
                </Div>
                <Div
                  id={`${parent_id}_patientinfocard_doc_div`}
                  style={{ marginTop: 7 }}
                >
                  <Outstanding
                    OutstandingData={OutstandingData}
                    OutstandingClick={OutstandingClick}
                  />
                </Div>
              </Div>

              <Div></Div>

              <Popover
                //   anchorEl={isUpload}
                open={Boolean(position.mouseX && position.mouseY)}
                anchorReference="anchorPosition"
                anchorPosition={
                  position.mouseY !== null && position.mouseX !== null
                    ? { top: position.mouseY, left: position.mouseX }
                    : undefined
                }
                onClose={closePopover}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                classes={{ paper: classes.muiPopovers }}
              >
                <UploadFiles
                  id={`${parent_id}_topnavbar_upload_uploadfiles`}
                  // uploadDocument={uploadDocument}
                  // files={files}
                  viewfiles={props.attachments}
                  isViewMode={true}
                />
              </Popover>
            </Div>
          </Div>
        </Div>
        <Div
          id={`${parent_id}_referabanner_openref_div`}
          style={{
            width: "30px",
            height: "30px",
            border: "1px solid #E0E0E0",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={(e) => openPopover(e)}
        >
          <Icons
            id={`${parent_id}_referabanner_eye_icons`}
            inLineStyles={{ color: "black", fontSize: "20px ​!importan" }}
            fontIcon={"eye"}
            ariaHidden={"true"}
            size={"small"}
          />
        </Div>
      </Div>
      {/* <Modal
        id={`${parent_id}_referabanner_modal`}
        inLineStyles={{ borderRadius: 16 }}
        open={openRef}
        onClose={handleopenRef}
        width={1000}
      >
        <ReferralBannerListView
          parent_id={"doctor_listing_referral"}
          ReferralData={ReferralData?.[0]}
          attachmenst={props.attachments}
          image={image}
        />
      </Modal> */}
    </Card>
  );
};

PatientInfoCard.defaultProps = {
  pic: null,
  name: "",
  age: null,
  gender: null,
  mrn_id: null,
  pi_id: null,
};

export default PatientInfoCard;
