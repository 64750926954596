import React, { useState, useEffect } from "react";
import { Avatar, Card } from "qdm-component-library";
import { Typography, makeStyles, } from "@material-ui/core";
import { UIColor } from "../../../themes/theme";
// import { encounterData } from "./dummyData";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import moment from "moment";
import cardIcon from "../../../assets/img/svg/Group 90506.svg"

const useStyles = makeStyles((theme) => ({

  avatarCls: {
    "& img": {
      width: "30px !important",
      height: "30px !important",
      objectFit: "contain !important",
    },
  },
  headerFonts: {
    fontSize: 14,
    color: UIColor.primaryColor.color,
    margin: 0,
    fontWeight: 600,
    fontFamily: "pc_semibold !important",
  },
  dot: {
    padding: "2px",
    backgroundColor: UIColor.tertiaryText.color,
    borderRadius: "50%",
  },
  alignItemsSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  alignItems: {
    display: "flex",
    alignItems: "center",
  },
  subText: {
    fontSize: 12,
    color: UIColor.tertiaryText.color,
    fontFamily: "pc_regular !important",
  },
  circle: {
    border: `1px solid ${UIColor.tertiaryText.color}`,
    borderRadius: "50%",
    width: "21px",
    height: "21px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  arrow: {
    fontSize: "15px",
    color: UIColor.tertiaryText.color,
    cursor: "pointer",
  },
}));

const EncounterCard = (props) => {
//   const props = {
//     "classes": {
//         "avatarCls": "EncounterCard-avatarCls-29",
//         "headerFonts": "EncounterCard-headerFonts-30",
//         "dot": "EncounterCard-dot-31",
//         "alignItemsSpaceBetween": "EncounterCard-alignItemsSpaceBetween-32",
//         "alignItems": "EncounterCard-alignItems-33",
//         "subText": "EncounterCard-subText-34",
//         "circle": "EncounterCard-circle-35",
//         "arrow": "EncounterCard-arrow-36"
//     },
//     "encounterDetails": [
//         {
//             "Organization_id": 138,
//             "_id": "Encounter/12221",
//             "_key": "12221",
//             "class": {
//                 "_key": "10049",
//                 "_id": "CodingMaster/10049",
//                 "_rev": "_e8UTuZy--q",
//                 "Type": "ENCOUNTERCLASS",
//                 "activestatus": true,
//                 "code": "AMB",
//                 "createdby": "",
//                 "createddate": 1635939713,
//                 "display": "Ambulatory",
//                 "gmconfigvalues": {},
//                 "id": 49,
//                 "shortdesc": "Ambulatory",
//                 "status": true,
//                 "updatedate": 1635939713,
//                 "updatedby": ""
//             },
//             "encounter_no": "",
//             "encounterdate": 1694662072,
//             "encounterenddate": 1694716199,
//             "id": 2232,
//             "location": [
//                 {
//                     "location_id": "",
//                     "status": "",
//                     "period": "",
//                     "physicalType": ""
//                 }
//             ],
//             "practitioner_details": [
//                 {
//                     "clinic": "HEMODIALYSIS",
//                     "id_name": "FrontDesk SIT Primarycare ",
//                     "period": "",
//                     "role": "Front desk",
//                     "speciality": {
//                         "_key": "10456",
//                         "_id": "CodingMaster/10456",
//                         "_rev": "_gff1NSi---",
//                         "Type": "SPECIALTY",
//                         "activestatus": true,
//                         "code": "SP008",
//                         "createdby": "",
//                         "createddate": 1692627582,
//                         "display": "Nephrology",
//                         "gmconfigvalues": {},
//                         "id": 456,
//                         "shortdesc": "Nephrology",
//                         "status": true,
//                         "updatedate": 1692627582,
//                         "updatedby": "",
//                         "collection_name": "CodingMaster"
//                     },
//                     "type": ""
//                 }
//             ],
//             "type": [
//                 {
//                     "_key": "10264",
//                     "_id": "CodingMaster/10264",
//                     "_rev": "_e8UTuaC--i",
//                     "Type": "ENCOUNTERTYPE",
//                     "activestatus": true,
//                     "code": "OP",
//                     "createdby": "",
//                     "createddate": 1635944642,
//                     "display": "Outpatient",
//                     "gmconfigvalues": {},
//                     "id": 264,
//                     "shortdesc": "Outpatient",
//                     "status": true,
//                     "updatedate": 1635944642,
//                     "updatedby": ""
//                 }
//             ],
//             "visit_type": "First Visit"
//         }
//     ],
//     "counter": 0,
//     "parent_id": "patient_detail",
//     "pic": "images/Group 90506.svg",
//     "titles": [
//         "65892",
//         "OP",
//         "type",
//         "Cardiology"
//     ],
//     "date": "01 Jan 22 10:10 - 01 Jan 22 23:00",
//     "languages": "lang",
//     "name": ""
// };
  const [encounterDataList, setEncounterDataList] = useState([]);
  const [counter, setCounter] = useState(0);
  const classes = useStyles();

  const styles = {
    cardStyle: {
      boxShadow: "none",
      borderRadius: 8,
      marginLeft: "5px",
    },
    avatar: {
      margin: 0,
      marginRight: 15,
      width: 48,
      height: 48,
      borderRadius: 8,
      backgroundColor: "#F0F0FA",
    },
  };

  const constructData = (encounters) => { 
    const list = encounters.map((val, i) => ({
      
      encounterName: val?._key ? `EN - ${val?._key}` : "",
      type: val?.type?.shortDesc ?? "",
      speciality: val?.practitioner_details?.speciality?.display ?? "-",
      encounterStart: val?.encounterdate ?? "",
      encounterEnd: val?.encounterenddate ?? "",
      location: val?.location?.[i]?.physicalType ?? "",
      doctorName: val?.practitioner_details?.idname,
    }));
    return list;
  };

  useEffect(() => {
    const encounters = props.encounterDetails;
    const constructDataList = constructData(encounters);
    setEncounterDataList(constructDataList);
  }, [props.encounterDetails]);

  const onIncrement = () => {
    props.onIncrement();
  };

  const onDecrement = () => {
    props.onDecrement();
  };

  const makeDate = (date) => {
    return moment.unix(date).format("DD MMM YY HH:mm");
  };
  // const { classes, parent_id, padding } = props;
  const { avatar, cardStyle } = styles;

  return (
    <div>
      <Card
        id={`${props.parent_id}_patientstatus_parent_card`}
        style={styles.cardStyle}
      >
        <div
          id={`${props.parent_id}_patientstatus_parent_div`}
          className={classes.alignItemsSpaceBetween}
          style={{ alignItems: "start", marginRight: "10px" }}
        >
          <div className={classes.alignItems}>
            <Avatar
              id={`${props.parent_id}_patientstatus_${props.name?.[0]}_avatar`}
              src={cardIcon}
              variant="rounded"
              className={classes.avatarCls}
              inLineStyles={styles.avatar}
            />
            <div>
              <div className={classes.alignItemsSpaceBetween}>
                <div className={classes.alignItems} style={{ gap: "8px" }}>
                  <Typography className={classes.headerFonts}>
                    {encounterDataList?.[props?.counter]?.encounterName}
                  </Typography>
                  <div className={classes.dot}></div>
                  <Typography className={classes.headerFonts}>
                    {encounterDataList?.[props?.counter]?.type}
                  </Typography>
                  <div className={classes.dot}></div>
                  <Typography className={classes.headerFonts}>
                    {encounterDataList?.[props?.counter]?.speciality}
                  </Typography>
                </div>
              </div>
              <div className={classes.alignItems} style={{ marginTop: "10px" }}>
                <Typography className={classes.subText}>
                  On{" "}
                  {makeDate(encounterDataList?.[props?.counter]?.encounterStart)}{" "}
                  -{" "}
                  {makeDate(encounterDataList?.[props?.counter]?.encounterEnd)}
                </Typography>
                <div className={classes.dot} style={{ margin: "0px 6px" }}></div>
                <div>
                  <Typography className={classes.subText}>
                    {encounterDataList?.[props?.counter]?.doctorName}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.alignItems} style={{ gap: "6px" }}>
            <div className={classes.circle} onClick={onDecrement}>
              <ChevronLeftIcon className={classes.arrow} />
            </div>
            <div className={classes.circle} onClick={onIncrement}>
              <ChevronRightIcon className={classes.arrow} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

EncounterCard.defaultProps = {
  pic: null,
  name: "",
  titles: [],
  date: null,
  languages: null,
};

export default EncounterCard;
